import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('What to do if You Suspect Someone is Thinking about Suicide - Treatment Connection');
    useMetaDescription('If you are noticing warning signs or are concerned someone is thinking about suicide, talk to them. Learn how to start the conversation.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">What to do if You Suspect Someone is Thinking about Suicide</h1>
                    <p>According to the Centers for Disease Control and Prevention (CDC), there were over 47,000* suicide deaths in 2017 Suicide is complicated and tragic, but it can be prevented.</p>
                    <p>The infographic below from the Nation Alliance on Mental Health (NAMI) can help you navigate a conversation with a friend or loved one if you suspect they are thinking about suicide.</p>

                    <div className="infographic-wrapper">
                        <object className="infographic" type="application/pdf" data="/educational_materials/OpenBeds_Suicide.pdf#zoom=100&scrollbar=0&toolbar=0&navpanes=0&view=Fit">
                            <a href="/educational_materials/OpenBeds_Suicide.pdf">
                                <img src="/images/infographics/suicide.png" alt="If you are noticing warning signs or are concerned someone is thinking about suicide, talk to them. Learn how to start the converstation." />
                            </a>
                        </object>
                    </div>

                    <p>Source: <a rel="noreferrer" target="_blank" href="https://www.cdc.gov/nchs/fastats/suicide.htm">Centers for Disease Control and Prevention, Suicide and Self-Inflicted Injury</a></p>
                </div>
            </div>
        </div>
    )
}
