import React, { useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppStorage} from '../../hooks';
import { getConfigurations } from '../../actions/selectattributes';


export const StateHelpline = (props) => {
    const dispatch = useDispatch();

    const stateConfigurations = useSelector((state) => state.stateConfigurations);
    const stateAttributes = useSelector((state) => state.stateAttributes);

    const [ stateInfo, setStateInfo ] = useAppStorage('session', 'lastStateConfigs', {});

    const bodyRef = useRef();
    const headingRef = useRef();

    useEffect(() => {
        const { state_code } = stateAttributes || {};
        if (state_code) {
            dispatch(getConfigurations(state_code));
        }
    }, [stateAttributes, dispatch]);

    useEffect(() => {
        if(stateInfo !== stateConfigurations) {
            setStateInfo(stateConfigurations);
        }
    }, [stateConfigurations, stateInfo, setStateInfo]);

    useEffect(() => {
        const timer = setTimeout(setRelativeIdOnHeading, 100);
        return () => clearTimeout(timer);
    }, []);

    const setRelativeIdOnHeading = () => {
        const accordianBodyWrapper = bodyRef.current;
        const accordianBody = accordianBodyWrapper?.querySelector('.accordion__body');
        const accordianBodyId = accordianBody?.getAttribute('aria-labelledby');
        const heading = headingRef.current;

        if (heading) {
            heading.setAttribute('id', accordianBodyId);
        }
    }

    if (!stateInfo || stateInfo.enabled !== true) {
        return null;
    }

    const { additionalHTML, additionalHTMLCentered, emergency_message } = stateInfo;

    return (
        <>
            <div className="panel panel-default panel-body center" expanded={"true"} disabled={true}>
                <h4 className='text-default'>State Helpline and Resources</h4>
                <hr />

                <div ref={bodyRef} className="row">
                    <div className={`col-xs-12 ${stateInfo.wideSeal ? "col-sm-12 wide-seal" : "col-sm-4"}`}>
                        <StateHelpSeal {...stateInfo} />
                    </div>
                    <div className={`col-xs-12 ${stateInfo.wideSeal ? "col-sm-12 help-links my-2" : "col-sm-8"}`}>
                        <StateHelpLinks {...stateInfo} />
                        {emergency_message && (
                            <p style={{
                                borderTop: "1px solid #ddd",
                                textAlign: "center",
                                margin: "0 -15px -5px -15px",
                                padding: "10px"
                            }} dangerouslySetInnerHTML={{ __html: emergency_message }} />
                        )}
                    </div>
                </div>
            </div>        
            <div style={{display: !!additionalHTML ? 'block' : 'none'}} className="panel panel-default panel-body" expanded={"true"} disabled={true}>
                <div style={{textAlign: additionalHTMLCentered ? 'center' : 'left'}} dangerouslySetInnerHTML={{__html: additionalHTML}}/>
            </div>
        </>
    );
}

const StateHelpSeal = ({seal, sealAlternativeText, wide, sealUrl, additionalSeals}) => {
    return seal && (
        <>
            <ConditionalWrap
                condition={sealUrl}
                wrap={children => <a href={sealUrl} target="_blank" rel="noreferrer">{children}</a>}
            >
                <img
                    src={seal}
                    alt={sealAlternativeText}
                    style={wide ? {maxHeight: 100, maxWidth: "100%"} : {maxWidth: "100%", maxHeight: 150}}
                />
            </ConditionalWrap>
            
            { additionalSeals && additionalSeals.map((additionalSeal, index) => (
                <ConditionalWrap
                    condition={additionalSeal.sealUrl}
                    wrap={children => <a href={additionalSeal.sealUrl} target="_blank" rel="noreferrer">{children}</a>}
                >
                <br/>
                    <img
                        src={additionalSeal.seal}
                        alt={additionalSeal.sealAlternativeText}
                        style={additionalSeal.wideSeal ? {maxHeight: 100, maxWidth: "100%", marginBottom: "4px"} : {maxWidth: "100%", maxHeight: 150, marginBottom: "4px"}}
                    />
                </ConditionalWrap>
            ))}
        </>
    )
}

const StateHelpLinks = (links, wideSeal) => {
    if (!links.websites && !links.phoneNumbers) {
        return null;
    }
    const phoneHeader = links.phoneNumbersHeader || '';
    const websites = links.websites || [];
    const phoneNumbers = links.phoneNumbers || [];
    const textAlign = wideSeal ? "center": undefined

    return (
        <>
            {websites.map((website, index) => (
                <p key={index}><a href={website} style={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: textAlign,
                    whiteSpace: "nowrap"
                }} target={"_blank"} rel="noopener noreferrer">{website}</a></p>
            ))}
            {phoneHeader !== '' && (
                <p style={{fontWeight: "bold", fontFamily: "sans-serif", textAlign}}>
                    <span>{phoneHeader}</span>
                </p>
            )}
            {phoneNumbers.map((phone, index) => (
                <React.Fragment key={`phone${index}`}>
                    {phone.description && <span>{phone.description}</span>}
                    {phone.description && <p style={{textAlign}}>{phone.description}</p>}
                    <p style={{textAlign}}><a href={`tel:${phone.number}`}>{phone.number}</a></p>
                </React.Fragment>
            ))}
            {links.emails.map((email, index) => (
                <React.Fragment key={"email" + index}>
                    {email.description  && (
                        <p style={{textAlign}}>
                            {email.description && (<span>{email.description}</span>)}
                        </p>
                    )}
                    <p style={{textAlign}}>
                        <a href={"mailto:" + email.email}>{email.email}</a>
                    </p>
                </React.Fragment>
            ))}
        </>
    )
}

const ConditionalWrap = ({condition, wrap, children}) => condition ? wrap(children) : children;

export default StateHelpline;

