import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('What to do in a Mental Health Crisis - Treatment Connection');
    useMetaDescription('Worried a loved one is in a mental health crisis or nearing a crisis? Learn how to assess the situation and techniques to help de-escalate the crisis.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">What to do in a Mental Health Crisis</h1>
                    <p>If you're worried that you or a loved one is in crisis or nearing a mental health crisis, seek help. The infographic below from NAMI (National Alliance on Mental Health) walks you through where to start and gives techniques that may help deescalate a crisis.</p>

                    <div className="infographic-wrapper">
                        <object className="infographic" type="application/pdf" data="/educational_materials/OpenBeds_Mental_Health_Crisis.pdf#zoom=100&scrollbar=0&toolbar=0&navpanes=0&view=Fit">
                            <a href="/educational_materials/OpenBeds_Mental_Health_Crisis.pdf">
                                <img src="/images/infographics/mental-health-crisis.png" alt="Worried a loved one is in a mental health crisis or nearing a crisis? Learn how to assess the situation and techniques to help de-escalate the crisis." />
                            </a>
                        </object>
                    </div>
                </div>
            </div>
        </div>
    )
}
