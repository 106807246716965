import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appUrls } from '../../appUrls';

const ExpressInterestAgainModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Request</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p className="text-left">
                    <b>You have already contacted this treatment facility. Do you want to contact them again?</b>
                </p>
                <div className="mt20">
                    <Button className="btn btn-primary" onClick={props.onConfirm}>
                        Yes
                    </Button>
                    <span className="spaceBtn" />
                    <Button onClick={props.onHide}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export const ContactModal = (props) => {
    const renderExpressInterestAgainModal = (props) => {
        return (
            <ExpressInterestAgainModal
                show={!!props.againExpressingInterestInOrganization}
                onHide={() => {
                    props.resetAgainExpressingInterestState();
                }}
                onConfirm={() => {
                    props.expressInterest(
                        props.againExpressingInterestInOrganization,
                        props.againExpressingInterestInService,
                        props.againExpressingInterestInOrganizationService,
                        props.againExpressingInterestInState
                    );
                }}
            />
        );
    };
    return (
        <>
            {renderExpressInterestAgainModal(props)}
            <Modal
                show={props.show}
                onHide={() => {
                    props.onHide();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login/Sign-up</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5 className="text-left">
                        <b>
                            Click on Login if you already have an account. Otherwise, click on Contact Me to ask the
                            provider to contact you. Or click on Create an Account to create an anonymous account to
                            receive a response from the selected facility.
                        </b>
                    </h5>
                    <br></br>
                    <div className="mt10">
                        <Link
                            className="btn btn-primary"
                            to={appUrls.USER_LOGIN}
                            onClick={() => props.logEvent('Contact Treatment Button -> Login')}
                        >
                            Login
                        </Link>
                        <Link
                            className="btn btn-primary ml15"
                            to="/patients/register"
                            onClick={() => props.logEvent('Contact Treatment Button -> Create an Account')}
                        >
                            Create an Account
                        </Link>
                    </div>

                    <div>
                        <hr className="login-divider"></hr> <p className="divider-or">or</p>{' '}
                        <hr className="login-divider"></hr>
                        <div className="mt5">
                            <Link
                                className="btn btn-primary"
                                to="/contactme"
                                onClick={() => props.logEvent('Contact Treatment Button -> Create an Account Button')}
                            >
                                Contact Me
                            </Link>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ContactModal;
