/**  
 * TYPE is the name of the attribute object
 * DATA is the name of the data from the backend
*/
export const SUBSTANCES = {
  TYPE: "substances",
  DATA: "substances",
  KEY: "id",
  NAME: "substance",
};
export const PAYMENT_TYPE = {
  TYPE: "payment",
  DATA: "payment_types",
  KEY: "id",
  NAME: "payment_type_name",
};

export const TREATMENT = {
  TYPE: "treatment",
  DATA: "medical_conditions",
  KEY: "medical_condition_id",
  NAME: "medical_condition_name",
};
export const SEEKING_TREATMENT = {
  TYPE: "seekingTreatmentType",
  DATA: "service_type",
  KEY: "id",
  NAME: "service_type",
};
export const TREATMENT_TYPE = {
  TYPE: 'treatmentType',
  DATA: SEEKING_TREATMENT.DATA,
  KEY: SEEKING_TREATMENT.KEY,
  NAME: SEEKING_TREATMENT.NAME,
  RECEVING: "receiving",
  
};
export const ADDITIONAL_SERVICES = {
  TYPE: "additionalServices",
  DATA: SEEKING_TREATMENT.DATA,
  KEY: SEEKING_TREATMENT.KEY,
  NAME: SEEKING_TREATMENT.NAME,
  ADDITIONAL: "additional",
};
export const GENDER_AND_AGE = {
  TYPE: "genderAndAge",
  DATA: "gender_and_age",
  KEY: "gender_and_age_id",
  NAME: "gender_and_age",
};
export const SPECIAL_POPULATION = {
  TYPE: "specialPopulation",
  DATA: "special_conditions",
  KEY: "id",
  NAME: "name",
};
export const PROVIDERS_ONSITE = {
  TYPE: "providersOnsite",
  DATA: "providers_onsite",
  KEY: "providers_id",
  NAME: "providers_name",
};
export const LANGUAGES_SERVED = {
  TYPE: "languagesServed",
  DATA: "languages_served",
  KEY: "id",
  NAME: "language",
};