import { useEffect } from "react";

function useMetaDescription(description) {
  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
  }, [description]);
}

export default useMetaDescription;
