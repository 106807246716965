import React from "react";
import { components } from "react-select";

import screenReaderAnnouncement from "../../accessibility-helpers/screenReaderAnnouncement";

class SingleValue extends React.Component {
  constructor(props) {
    super();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.children != prevProps.children) {
      setTimeout(() => {
        screenReaderAnnouncement(this.props.children + " selected");
      }, 100);
    }
  };

  render() {
    return (
      <components.SingleValue {...this.props}>
        {this.props.children}
      </components.SingleValue>
    );
  }
}

export default SingleValue;
