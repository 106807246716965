/**
 * Wrap axios in list of middlewares, first middleware will be the first one to process RequestConfig and last one
 * to process Response, as it will be received from next middleware called from inside of current middleware.
 * Actual HTTP request will be issued after last middleware calling next
 * Any middleware can return early without calling next, therefore skipping rest of the call chain.
 *
 * Middleware interface (config, next): Promise<Response>
 *  config - Request configuration
 *  next - next middleware in chain
 *  Response - Axios http response
 * noopMiddleware is provided as an example
 *
 * Example of full call chain with 2 middlewares: M1, M2
 * axios.get(RequestConfig) ->
 *   M1<process config> -> next(RequestConfig) ->
 *   M2<process config> -> next(RequestConfig ->
 *       HTTP_REQUEST -> Response ->
 *   M2<process response> -> return ->
 *   M1<process response> -> return ->
 * return to axios caller
 *
 * @param axios axios instance to wrap
 * @param middlewares list of middlewares to wrap axios with.
 * @returns axios
 */
export const injectMiddleware = (axios, middlewares) => {
    let compoundAdapter = axios.defaults.adapter;

    // wrap inside out
    for (let i = middlewares.length - 1; i >= 0; i--) {
        if (typeof middlewares[i] === 'object' && typeof middlewares[i].check === 'function') {
            // Because JS is interesting, we have to bind the object
            // in to the method to allow `this.` to work within the
            // object; https://stackoverflow.com/questions/4011793/this-is-undefined-in-javascript-class-methods#comment115236177_4011815
            let check = middlewares[i].check.bind(middlewares[i]);

            compoundAdapter = wrapMiddleware(check, compoundAdapter);

            continue;
        }

        compoundAdapter = wrapMiddleware(middlewares[i], compoundAdapter);
    }

    axios.defaults.adapter = compoundAdapter;

    return axios;
};

const wrapMiddleware = (outer, inner) => {
    return (config) => {
        return outer(config, inner);
    }
};

const noopMiddleware = (config, next) => {
    // process RequestConfig
    const response = next(config); // call next middleware in chain
    // process Response
    return response;
}
