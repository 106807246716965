import React from 'react';
import ReactDOM from 'react-dom';
import AccessibleSelect from '../accessibility-helpers/accessible-select';
import { Helmet } from 'react-helmet';
//import bg from './../../assets/previewedited3.png'
import { connect } from 'react-redux';
import {  } from '../messages'
import {
    fetchPosts,
    updateZipcode,
    updateLocation,
    updateDistance,
    updatePayment,
    updateTreatment,
    updateProvidersOnsite,
    updateSpecialPopulation,
    updateLanguagesServed,
    updateGenderAndAge,
    updateSeekingTreatment,
    updateSubstances,
    fetchAttributes,
    updateStateAttributes,
    updateSearchlist,
    getStates,
    getConfigurations,
    updateLimitToState,
} from '../../actions/selectattributes';
import { patientProfileDetails } from '../../actions/login';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { TreatmentDefinitionsModal } from './TreatmentDefinitionsModal';
import { Messages } from '../messages';
import { appUrls } from '../../appUrls';
import { gaLogEvent } from '../../actions/googleAnalytics';
import { DistanceField, LocationField, CheckboxField } from '../SearchFields';

const defaultDistance = { value: 250, label: '250 miles' };

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attributes: null,
            zip_error: false,
            profileSubs: '',
            profileTreat: '',
            showTreatmentInfoModal: false,
            genderAndAge: [],
            // showLoaderScr:true,
            resolutionSize: 0,
            headerHeight: null,
            location_error: null,
            location_name: '',
            stateAttributes: null,
            searchLocation: null,
        };

        this.fullPageScrollableSection = React.createRef();
        this.fullPageHomeSection = React.createRef();
        this.fullPageHomeSectionInner = React.createRef();
        this.myHeader = React.createRef();
        this.aboutRef = React.createRef();
    }

    componentWillMount() {
        this.updateDimensions();
        this.setUpHomepageTopSectionHeight();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('resize', this.setUpHomepageTopSectionHeight);
        if (window.location.hash === '#about') {
            this.aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        this.props.fetchPosts();
        this.props.getStates();
        let emptyJson = {
            searchLocation: {},
            stateAttributes: {},
            distance: defaultDistance,
            substances: [],
            treatmentType: [],
            paymentType: [],
            genderAndAge: [],
            seekingTreatmentType: [],
            specialPopulation: [],
            providersOnsite: [],
            languagesServed: [],
            limitToState: false,
        };
        this.props.updateLocation(emptyJson.searchLocation);
        this.props.updateStateAttributes(emptyJson.stateAttributes);
        this.props.updateDistance(emptyJson.distance);
        this.props.updateSubstances(emptyJson.substances);
        this.props.updatePayment(emptyJson.substances);
        this.props.updateGenderAndAge(emptyJson.substances);
        this.props.updateSeekingTreatment(emptyJson.treatmentType);
        this.props.updateSpecialPopulation(emptyJson.specialPopulation);
        this.props.updateProvidersOnsite(emptyJson.providersOnsite);
        this.props.updateLanguagesServed(emptyJson.languagesServed);
        this.props.updateTreatment(emptyJson.treatmentType);
        this.props.updateLimitToState(emptyJson.limitToState);
    }

    needHelp(tab) {
        this.props.history.push({
            pathname: '/patients/need_help',
            state: { tab: tab },
        });
    }

    logEvent(action) {
        this.props.gaLogEvent({
            category: 'Homepage',
            action: action,
        });
    }

    redirectToSearchList = () => {
        if (false === this.handleStateDependentContentClick()) {
            return;
        }
        sessionStorage.removeItem("lastSearchDetails")
        this.props.updateSearchlist({data:[], pagination:{}});

        sessionStorage.setItem(
            'lastSearchDetails',
            JSON.stringify({
                searchLocation: this.props.searchLocation,
                distance: this.props.distance,
                substances: this.props.substances,
                treatmentType: this.props.seekingTreatmentType,
                limitToState: this.props.limitToState,
            })
        );
        var fieldsFilled = '';
        if (this.props.searchLocation?.State != null) {
            fieldsFilled = fieldsFilled + 'Location ';
        }
        if (this.props.searchLocation?.Longitude != null) {
            fieldsFilled = fieldsFilled + 'Longitude, ';
        }
        if (this.props.searchLocation?.Latitude != null) {
            fieldsFilled = fieldsFilled + 'Latitude, ';
        }
        if (this.props.distance != null) {
            fieldsFilled = fieldsFilled + 'Distance, ';
        }
        if (this.props.substances != null && this.props.substances.length !== 0) {
            fieldsFilled = fieldsFilled + 'Substances, ';
        }
        if (this.props.seekingTreatmentType != null && this.props.seekingTreatmentType.length !== 0) {
            fieldsFilled = fieldsFilled + 'Service Type, ';
        }

        fieldsFilled = fieldsFilled.substring(0, fieldsFilled.length - 2);

        this.logEvent('Search Button - ' + fieldsFilled);

        if (this.state.searchLocation?.State) {
            this.props.history.push(appUrls.SEARCH_RESULT_LIST);
        }
    };

    handleShowTreatmentInfoModal = (e) => {
        if (this.handleStateDependentContentClick()) {
            e.preventDefault();
            e.stopPropagation();
            this.logEvent('Service Definition Button');

            this.setState({ showTreatmentInfoModal: true });
        }
    };

    handleClose = (modal) => {
        let treatment = this.state.showTreatmentInfoModal;

        if (modal === 'treatment') {
            treatment = false;
        }

        this.setState({ showTreatmentInfoModal: treatment });
    };

    updateLocation = async(searchLocation) => {
        this.setState({ searchLocation: searchLocation });
        this.props.updateLocation(searchLocation);
        var stateName = searchLocation?.State || '';
        if (stateName.length > 0) {
            const stateCode = await this.props.fetchAttributes(stateName, true).then((response) => {
                this.setState({ stateAttributes: response });
                return response?.state_code;
            });
            stateCode && this.props.getConfigurations(stateCode);

        } else {
            this.setState({ stateAttributes: null });
        }

        // this.props.updateLocation(e.target.value);
    };

    updateDistance = (selectedOption) => {
        this.props.updateDistance(selectedOption);
    };

    updateTreatmentType = (selectedOption) => {
        this.props.updateSeekingTreatment(selectedOption);
    };

    filterdSubstancesOptions = () => {
        let substancesArr = this.substancesOptions();
        return substancesArr.filter((sub) => {
            return sub.value !== 1;
        });
    };

    updateSubstances = (selectedOption) => {
        let isSelectAll = false;
        selectedOption.forEach((opt) => {
            if (Number(opt.value) === 1) {
                isSelectAll = true;
            }
        });
        let options = [];

        options = isSelectAll === true ? this.filterdSubstancesOptions() : selectedOption;

        this.props.updateSubstances(options);
    };

    serviceTypeOptions = () => {
        let serviceTypesArr = [];
        if (this.state.stateAttributes) {
            this.state.stateAttributes.service_type.forEach((service) => {
                if ((service.type == 'receiving' || service.type == 'additional') && service.view_availability == 1) {
                    serviceTypesArr.push({ value: service.id, label: service.service_type, type: service.type, ...service, });
                }
            });
        }
        return serviceTypesArr;
    };
    
    treatmentValue = (profileData) => {
        let treatmentArray = this.props.seekingTreatmentType;

        return treatmentArray;
    };

    substanceValue = () => {
        let substanceArray = this.props.substances;

        return substanceArray;
    };

    substancesOptions = () => {
        let substancesArr = [];
        if (this.state.stateAttributes) {
            this.state.stateAttributes.substances.forEach((substance) => {
                if (substance.substance !== 'All') {
                    substancesArr.push({
                        value: substance.id,
                        label: substance.substance,
                    });
                }
            });
        }
        return substancesArr;
    };

    /**
     * Next two functions get header height and calculate full page height based on that.
     */
    getHeaderHeight = (reference) => {
        if (reference) {
            let headerElementHeight = ReactDOM.findDOMNode(reference.current);

            if (!headerElementHeight) {
                return;
            }

            headerElementHeight = headerElementHeight.clientHeight;

            this.setState({
                headerHeight: headerElementHeight,
            });
        }
    };

    setUpHomepageTopSectionHeight = () => {
        setTimeout(() => {
            var windowHeight = window.innerHeight;
            var fullPageHomeSection = ReactDOM.findDOMNode(this.fullPageHomeSection.current);
            var fullPageHomeSectionInner = ReactDOM.findDOMNode(this.fullPageHomeSectionInner.current);

            if (!fullPageHomeSectionInner) {
                return;
            }

            var fullPageHomeSectionInnerHeight = fullPageHomeSectionInner.clientHeight;
            var setHeight = windowHeight - this.state.headerHeight;

            if (fullPageHomeSectionInnerHeight < setHeight) {
                fullPageHomeSection.style.height = setHeight + 'px';
            } else {
                fullPageHomeSection.style.height = 'auto';
            }
        }, 1000);
    };

    updateDimensions = () => {
        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = body.clientWidth,
            height = body.clientHeight;

        this.setState({ resolutionSize: width });
        // if you are using ES2015 I'm pretty sure you can do this: this.setState({width, height});
    };

    handleLocationError = (error) => {
        return this.state.location_error = error;
    };

    getSelectedLocation = (searchLocation) => {
        // this.setState({ this.: searchLocation.State });
        if (searchLocation.State.length === 0 && searchLocation.Latitude.length === 0 && searchLocation.Longitude.length === 0) {
            this.setState({ location_error: Messages.EMPTY_LOCATION });
            this.setState({ attributes: false });
            return;
        }
        this.setState({ location_error: null });
        // this.props.updateLocation(searchLocation);
        // this.setState({ attributes: true });
    };

    render() {
        let scrollOverValue = false;
        if (document.documentElement.clientWidth > 1000) {
            scrollOverValue = true;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Home - Treatment Connection</title>
                    <meta
                        name="description"
                        content="Learn about, search for and express interest in substance use or mental health treatment through our state vetted network of treatment providers."
                        data-react-helmet="true"
                    ></meta>
                </Helmet>

                <Header fixedToTop={true} setHeight={this.getHeaderHeight} />

                <div className="main-content-scrollable-area">
                    <main ref={this.fullPageScrollableSection}>
                        <span id="main-content" tabIndex="-1"></span>
                        <div className="section home home-background" ref={this.fullPageHomeSection}>
                            <div className="container homepage-search-panel" ref={this.fullPageHomeSectionInner}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="search-sec">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h1 className="home-header">
                                                        Are you Seeking <br />
                                                        Substance Use or Mental Health Treatment?
                                                    </h1>

                                                    <h2 className="home-subtext">
                                                        These facilities have been reviewed by the state government where it is located. Additional restrictions may apply if seeking treatment outside of your state of residence.
                                                    </h2>
                                                </div>
                                                {/* <div className="col-md-3">
                                    <p className="emergency_text">Call 911 in Emergency</p>
                                    </div> */}
                                            </div>
                                            <div>
                                                <div className="row home-button-row align-items-center">
                                                    <div className="col-md-3 mb-15">
                                                       <LocationField 
                                                            id="field-location-filter" 
                                                            name="location"
                                                            handleUpdate={this.updateLocation}
                                                            labelClass="text-color-dark-blue mb-0 d-block"
                                                            required={true} 
                                                        />
                                                    </div>
                                                    <div className="col-md-2 mb-15">
                                                        <label
                                                            className="text-color-dark-blue mb-0"
                                                            htmlFor="distance"
                                                            id="distance_label"
                                                        >
                                                            Distance
                                                            <span className="sr-only">. Select a value</span>
                                                        </label>
                                                        <DistanceField
                                                          inputId="distance"
                                                          labelId="distance_label"
                                                          className="homeinput homeborder"
                                                          placeholder="Select Value"
                                                          value={this.props.distance}
                                                          handleUpdate={this.updateDistance}
                                                          isDisabled={!this.state.stateAttributes}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mb-15">
                                                        <label
                                                            className="text-color-dark-blue mb-0"
                                                            htmlFor="substances"
                                                            id="substances_label"
                                                        >
                                                            Substances Treated
                                                            <span className="sr-only">. Select one or more values</span>
                                                        </label>
                                                        <div onClick={this.handleStateDependentContentClick}>
                                                            <AccessibleSelect
                                                              inputId="substances"
                                                              labelId="substances_label"
                                                              isClearable={false}
                                                              options={this.substancesOptions()}
                                                              className="homeinput homeborder"
                                                              placeholder="Select One or More Values"
                                                              isMulti
                                                              value={this.substanceValue()}
                                                              onChange={this.updateSubstances}
                                                              isDisabled={!this.state.stateAttributes}
                                                              hideSelectedOptions={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-15">
                                                        <label
                                                            className="text-color-dark-blue mb-0"
                                                            htmlFor="treatment_type"
                                                            id="treatment_type_label"
                                                        >
                                                            Service Type
                                                            <span className="sr-only">. One or more values</span>
                                                        </label>
                                                        <div onClick={this.handleStateDependentContentClick}>
                                                            <AccessibleSelect
                                                                inputId="treatment_type"
                                                                labelId="treatment_type_label"
                                                                isClearable={false}
                                                                options={this.serviceTypeOptions()}
                                                                className="homeinput search-treatment-type homeborder"
                                                                placeholder="One or More Values"
                                                                isMulti
                                                                value={this.treatmentValue(this.state.profileTreat)}
                                                                onChange={this.updateTreatmentType}
                                                                isDisabled={!this.state.stateAttributes}
                                                                hideSelectedOptions={false}
                                                            />
                                                        </div>
                                                        <button
                                                            className="home-treatment-information"
                                                            onClick={this.handleShowTreatmentInfoModal}
                                                            type="button"
                                                            disabled={!this.state.stateAttributes}
                                                        >
                                                            <span className="sr-only">
                                                                Questions? View more information.
                                                            </span>
                                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-md-12 mb-15">
                                                        <div className="checkbox">
                                                            <label class="text-color-dark-blue mb-0 d-block">
                                                                <CheckboxField
                                                                    id="chkLimitToState"
                                                                    value="true" 
                                                                    checked={this.state.limitToState}
                                                                    handleChange={this.props.updateLimitToState}
                                                                />
                                                                Limit Search to State
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className="row home-button-row">
                                                    <div className="col-md-2">
                                                        <button
                                                            className="btn btn-primary home-search-button"
                                                            type="button"
                                                            onClick={this.redirectToSearchList}
                                                            disabled={!this.state.stateAttributes}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mb-15 row home-button-row ">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <hr className="home-divider" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-12 col-sm-12">
                                                        <a
                                                            href="/assessment"
                                                            className="btn btn-primary btn-homepage home-assessment-button"
                                                        >
                                                            <img src="/images/icon_self_assessment.svg" alt="" />
                                                            What level of substance use treatment do I need?
                                                        </a>

                                                        <a
                                                            href={appUrls.RESOURCE_LIST}
                                                            className="btn btn-primary home-education-button"
                                                            onClick={() =>
                                                                this.logEvent('Educational Materials Button')
                                                            }
                                                        >
                                                            <img src="/images/education_icon_home.png" alt="" />
                                                            Educational Resources
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="emergency_container">
                                                <p className="col-lg-6 col-md-6 col-sm-12 pl-0 emergency_text">
                                                    If you or a loved one are experiencing suicidal thoughts, call the
                                                    988 Suicide & Crisis Lifeline to get help today by dialing{' '}
                                                    <a href="tel:+1988">988</a>.
                                                </p>

                                                <p className="col-lg-6 col-md-6 col-sm-12 pl-0 emergency_text">
                                                    If you or a loved one are experiencing a life-threatening emergency,
                                                    call <a href="tel:+1911">911</a>.
                                                </p>

                                                <p className="col-lg-6 col-md-6 col-sm-12 pl-0 emergency_text">
                                                    Need help finding treatment? Call{' '}
                                                    <a href="tel:+1-833-275-2043">(833) 275-2043</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section about" id="about" ref={this.aboutRef}>
                            <div className="row">
                                <div className="col-md-12 home-patient-side">
                                    <div className="container">
                                        <div className="col-md-12 home_info_bar">
                                            <img
                                                src="/images/icon_seeking_treatment.png"
                                                height="50"
                                                className="home-icon"
                                                alt=""
                                            />
                                            <h2 className="homeTxt">
                                                ARE YOU SEEKING SUBSTANCE USE OR MENTAL HEALTH TREATMENT?
                                            </h2>

                                            <br></br>
                                        </div>
                                        <div className="row">
                                            <ol className="row-fluid tri-list">
                                                <li className="col-md-4 tri-divider">
                                                    <div className="tri-item">
                                                        <div className="tri-divider">
                                                            <img
                                                                className="responsiveImg"
                                                                src="images/search_icon3.png"
                                                                style={{ cursor: 'pointer' }}
                                                                alt=""
                                                                onClick={this.needHelp.bind(this, 1)}
                                                            />
                                                        </div>

                                                        <div className="float-left">
                                                            <span
                                                                className="bubble"
                                                                onClick={this.needHelp.bind(this, 1)}
                                                            >
                                                                <span aria-describedby="tri_header1">1</span>
                                                            </span>
                                                        </div>
                                                        <h3 className="tri-header h4" id="tri_header1" tabIndex="0">
                                                            Search for Treatment
                                                        </h3>
                                                        <p>
                                                            Search for treatment facilities near you and filter the
                                                            results based on your specific needs.{' '}
                                                            <a href="/patients/need_help?tab=1">
                                                                Learn More
                                                                <span className="sr-only">
                                                                    {' '}
                                                                    about searching for treatment options
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </li>

                                                <li className="col-md-4 tri-divider">
                                                    <div className="tri-item">
                                                        <div className="tri-divider">
                                                            <img
                                                                className="responsiveImg"
                                                                style={{ cursor: 'pointer' }}
                                                                src="images/learn_icon5.png"
                                                                alt=""
                                                                onClick={this.needHelp.bind(this, 2)}
                                                            />
                                                        </div>

                                                        <div className="float-left">
                                                            <span
                                                                className="bubble"
                                                                onClick={this.needHelp.bind(this, 2)}
                                                            >
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                        <h3 className="tri-header h4" id="tri_header2" tabIndex="0">
                                                            Learn about Treatment
                                                        </h3>
                                                        <p>
                                                            Learn about the different types of treatment options and
                                                            take our interactive assessment to see which is right for
                                                            you.{' '}
                                                            <a href="/patients/need_help?tab=2">
                                                                Learn More
                                                                <span className="sr-only">
                                                                    {' '}
                                                                    about available treatment options
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </li>

                                                <li className="col-md-4 tri-divider">
                                                    <div className="tri-item">
                                                        <div className="tri-divider">
                                                            <img
                                                                className="responsiveImg"
                                                                src="images/interest_icon7.png"
                                                                style={{ cursor: 'pointer' }}
                                                                alt=""
                                                                onClick={this.needHelp.bind(this, 3)}
                                                            />
                                                        </div>

                                                        <div className="float-left">
                                                            <span
                                                                className="bubble"
                                                                onClick={this.needHelp.bind(this, 3)}
                                                            >
                                                                <span>3</span>
                                                            </span>
                                                        </div>
                                                        <h3 className="tri-header h4" id="tri_header3" tabIndex="0">
                                                            Express Interest
                                                        </h3>
                                                        <p>
                                                            {' '}
                                                            Reach out to treatment facilities and express interest in
                                                            getting help through our anonymous online portal.{' '}
                                                            <a href="/patients/need_help?tab=3">
                                                                Learn More
                                                                <span className="sr-only">
                                                                    {' '}
                                                                    about how to contact treatment facilities
                                                                </span>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <Footer />
                </div>

                <TreatmentDefinitionsModal
                    show={this.state.showTreatmentInfoModal}
                    treatmentTypes={this.state.stateAttributes ? this.state.stateAttributes.service_type : []}
                    onClose={() => {
                        this.handleClose('treatment');
                    }}
                />
            </React.Fragment>
        );
    }

    handleZipKeyPress = (e) => {
        if (e.key === 'Enter') {
            // on Enter trigger submit to validate
            this.redirectToSearchList();
        }
    };

    validateLocationDistance(stateName, distance) {
        if (stateName) {
            return this.validateLocation(stateName, false);
        }
        return this.validateLocation(stateName, true);
    }

    validateLocation(stateName) {
        if (stateName && stateName.length > 0) {
            return true;
        }
        return false;
    }

    handleStateDependentContentClick = () => {
        if (this.state.stateAttributes === null) {
            this.setState({
                location_error: Messages.EMPTY_LOCATION,
            });
            return false;
        }
        return !!this.state.stateAttributes;
    };
}

const mapStateToProps = (state) => {
    return {
        profileData: state.patientProfile,
        attributes: state.post,
        zipcode: state.zipcode,
        distance: state.distance,
        payment: state.payment,
        treatment: state.treatment,
        substances: state.substances,
        loginDetails: state.loginDetails,
        seekingTreatmentType: state.seekingTreatmentType,
        genderAndAge: state.genderAndAge,
        providersOnsite: state.providersOnsite,
        languagesServed: state.languagesServed,
        specialPopulation: state.specialPopulation,
        searchLocation: state.searchLocation,
        stateAttributes: state.stateAttributes,
        updateLocation: state.updateLocation,
        limitToState: state.limitToState,
    };
};

export default connect(mapStateToProps, {
    fetchPosts,
    getStates,
    getConfigurations,
    updateZipcode,
    updateLocation,
    updateStateAttributes,
    fetchAttributes,
    updateDistance,
    updateProvidersOnsite,
    updateSpecialPopulation,
    updateLanguagesServed,
    updatePayment,
    updateTreatment,
    updateSeekingTreatment,
    updateSubstances,
    updateGenderAndAge,
    updateSearchlist,
    updateLimitToState,
    patientProfileDetails,
    gaLogEvent,
})(Search);
