import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Preparing for a Mental Health Crisis - Treatment Connection');
    useMetaDescription('No one wants to worry about a possible mental health crisis. This NAMI infographic offers tips on how to prepare for a crisis.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Preparing for a Mental Health Crisis</h1>
                    <p>It's important to understand that general behavior changes often occur before a mental health crisis, like sleeplessness, preoccupation with certain activities, and unpredictable outbursts, to name a few. Don't ignore these changes. It may be difficult but talk to your loved one and encourage them to visit their doctor and/or therapist.</p>
                    <p>The NAMI infographic below offers additional tips on how to prepare for a crisis.</p>

                    <div className="infographic-wrapper">
                        <object className="infographic" type="application/pdf" data="/educational_materials/OpenBeds_Preparing_Mental_Health_Crisis.pdf#zoom=100&scrollbar=0&toolbar=0&navpanes=0&view=Fit">
                            <a href="/educational_materials/OpenBeds_Preparing_Mental_Health_Crisis.pdf">
                                <img src="/images/infographics/preparing-mental-health-crisis.png" alt="No one wants to worry about a possible mental health crisis. This NAMI infographic offers tips on how to prepare for a crisis." />
                            </a>
                        </object>
                    </div>
                </div>
            </div>
        </div>
    )
}
