import React from 'react';

export const PhoneNumberLink = (props) => {
    const {phoneNumber} = props;

    return renderLink(phoneNumber, trimNonNumbers(phoneNumber));
};

const renderLink = (text, number) => {
    return (
        <a href={"tel:" + number}>{text}</a>
    );
};

const trimNonNumbers = (string) => {
    return string && string.replace(/\D/g, "");
};
