
export function formatPhoneNumber(phoneNumber) {
    //console.log(phoneNumber);
    if (phoneNumber) {
        let phoneNumberString = String(phoneNumber);

        // Remove country code only if present
        if (phoneNumberString.match(/^\+\d/)) {
            phoneNumberString = phoneNumberString.substring(2);
        }

        // Normalize string and remove unnecessary non-digit characters
        phoneNumberString = phoneNumberString
            .replace(/\D/g, "")
            .slice(0, 10);

        // Check if the number length equals 10
        if (phoneNumberString.length === 10) {
            // Reformat and return phone number
            return phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        return phoneNumberString;
    }
}
