/**
 *
 *
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import { DistanceField, SearchField, LocationField, SearchFilter, CheckboxField } from '../SearchFields';
import './searchlist.css'
import { useAppStorage, useStoredValues } from '../../hooks';
import * as ATTRIBUTES from '../../actions/constants';
import { updateSearchlist, fetchAttributes } from '../../actions/selectattributes';
import {fetchUrlParameters} from "../../utils/url";

const defaultDistance = { value: 250, label: '250 miles' };
const distanceOptions = [
  { value: 5, label: '5 Miles' },
  { value: 10, label: '10 Miles' },
  { value: 15, label: '15 Miles' },
  { value: 25, label: '25 Miles' },
  { value: 50, label: '50 Miles' },
  { value: 100, label: '100 Miles' },
  { value: 250, label: '250 Miles' },
  { value: 500, label: '500 Miles' },
  { value: '', label: 'Any distance' },
]

const AccordionContent = ({heading, selected, children, ...props}) => (
  <AccordionItem expanded={selected? 'true': 'false'} className="panel panel-default panel-body p-0">
      <fieldset>
        <legend className="h4 my-0">
          <AccordionItemHeading>
            <AccordionItemButton style={{ backgroundColor: 'white', color: '#17376F' }}>
              {heading}
            </AccordionItemButton>
          </AccordionItemHeading>
        </legend>
        <AccordionItemPanel>
       {children}
        </AccordionItemPanel>
      </fieldset>
    </AccordionItem>
)

export const SearchFilters = ({parent, ...props}) => {
  const dispatch = useDispatch();

  const stateAttributes = useSelector((state) => state.stateAttributes);

  const [ session, setSession, removeSession ] = useAppStorage('session', 'lastSearchDetails');
  const [ attributes, setAttributes, updateAttributes ] = useStoredValues('attributes', stateAttributes);

  const [ distance, setDistance, updateDistance ] = useStoredValues('distance', session?.distance || defaultDistance);
  const [ searchLocation, setSearchLocation, updateSearchLocation ] = useStoredValues('searchLocation', session?.searchLocation);
  const [ searchTerms, setSearchTerms, updateSearchTerms ] = useStoredValues('searchTerms', session?.searchTerms || '');
  const [ limitToState, setLimitToState, updateLimitToState ] = useStoredValues('limitToState', session?.limitToState || false);

  const [ payment, setPayment, updatePayment ] = useStoredValues(ATTRIBUTES.PAYMENT_TYPE.TYPE, session?.payment);
  const [ substances, setSubstances, updateSubstances ] = useStoredValues(ATTRIBUTES.SUBSTANCES.TYPE, session?.substances);
  const [ treatmentType, setTreatmentType, updateTreatmentType ] = useStoredValues(ATTRIBUTES.TREATMENT_TYPE.TYPE, props.treatmentType);
  const [ additionalServices, setAdditionalServices, updateAdditionalServices ] = useStoredValues(ATTRIBUTES.ADDITIONAL_SERVICES.TYPE, props.additionalServices);
  const [ seekingTreatment, , updateSeekingTreatment ] = useStoredValues(ATTRIBUTES.SEEKING_TREATMENT.TYPE, session?.treatmentType);
  const [ genderAndAge, setGenderAndAge, updateGenderAndAge ] = useStoredValues(ATTRIBUTES.GENDER_AND_AGE.TYPE, session?.genderAndAge);
  const [ specialPopulation, setSpecialPopulation, updateSpecialPopulation ] = useStoredValues(ATTRIBUTES.SPECIAL_POPULATION.TYPE, session?.specialPopulation);
  const [ providersOnsite, setProvidersOnsite, updateProvidersOnsite ] = useStoredValues(ATTRIBUTES.PROVIDERS_ONSITE.TYPE, session?.providersOnsite);
  const [ languagesServed, setLanguagesServed, updateLanguagesServed ] = useStoredValues(ATTRIBUTES.LANGUAGES_SERVED.TYPE, session?.languagesServed);


  const wasSelected = {
    payment: session?.payment?.length > 0,
    substances: session?.substances?.length > 0,
    seekingTreatment: session?.treatmentType?.length > 0,
    genderAndAge: session?.genderAndAge?.length > 0,
    specialPopulation: session?.specialPopulation?.length > 0,
    providersOnsite: session?.providersOnsite?.length > 0,
    languagesServed: session?.languagesServed?.length > 0,
    ...props.wasSelected,
  };

  const [isLoading, setIsLoading ]= useState(false);

    useEffect( () => {
        const urlParams = fetchUrlParameters();
        if (Object.values(urlParams).length > 0) {
            let location =  {
                Latitude: urlParams.lat,
                Longitude: urlParams.long,
                PlaceId: '',
                State: urlParams.stateCode,
                Text: '',
                ZipCode: '',
            }
            updateSearchLocation(location);
            //form sessionlastsearch by searching the dom
            distanceOptions.forEach((item) => {
                if (item.value == urlParams.distance) {
                    updateDistance(item);
                }
            })
            updateSearchTerms(urlParams.search_terms);
            updateLimitToState(urlParams.limit_to_state);
        }
    }, [])


  useEffect( () => {
    const _stateName = searchLocation?.State
    if(_stateName){
      setIsLoading(true);
      parent?.setState({searchPerformed: false});
      const stateCode = stateAttributes?.state_code?.toUpperCase();
      if(!stateCode || parent?.getState(_stateName) !== stateCode) {
        dispatch(fetchAttributes(_stateName, true)).
        finally( () => setIsLoading(false))
      } else {
        setIsLoading(false)
      }
    }
  }, [searchLocation?.State, parent, dispatch])

    useEffect( () => {
        if (!attributes || Object.keys(attributes).length == 0) {
            return;
        }

        const urlParams = fetchUrlParameters();
        //for substances
        if(Object.keys(urlParams).length !== 0){
          updateDemographic(urlParams, attributes, 'substances', 'substances', 'id', 'substance', updateSubstances);
          updateDemographic(urlParams, attributes, 'treatment_type', 'service_type', 'id', 'service_type', updateSeekingTreatment);
          updateDemographic(urlParams, attributes, 'payment_type', 'payment_types', 'id', 'payment_type_name', updatePayment);
          updateDemographic(urlParams, attributes, 'gender_and_age', 'gender_and_age', 'gender_and_age_id', 'gender_and_age', updateGenderAndAge);
          updateDemographic(urlParams, attributes, 'special_populations', 'special_conditions', 'id', 'name', updateSpecialPopulation);
          updateDemographic(urlParams, attributes, 'providers_on_site', 'providers_onsite', 'providers_id', 'providers_name', updateProvidersOnsite);
          updateDemographic(urlParams, attributes, 'languages_served', 'languages_served', 'id','language', updateLanguagesServed);
        }

        //trigger page change
        parent?.setState({ isPageChanged: true });

    }, [attributes, parent])

  const updateDemographic = (urlParams, attributes, demographic, attrDemographic, attributeId, attributeName, updateFunction) => {
        let substanceParams = urlParams[demographic] ? urlParams[demographic].split(",") : [];
        if (attributes[attrDemographic]) {
            let demographics = [];
            (attributes[attrDemographic]).forEach((item) => {
                if (substanceParams.includes(item[attributeId])) {
                    demographics.push({value: item[attributeId], label: item[attributeName]});
                }
            })
            updateFunction(demographics);
        }
    }

  const setSeekingTreatment = (serviceType) => {
    let _treatmentType = [];
    let _additionalServices = [];

    if(serviceType && Array.isArray(serviceType)){
      serviceType.forEach((item) => {
          _treatmentType.push(item)
      });
      setTreatmentType(_treatmentType);
      setAdditionalServices(_additionalServices);
    }
  }

  const handleLimitToState = (limit_to_state) => {
    updateLimitToState(limit_to_state);
    setSession({ ...session, limitToState: limit_to_state });
  }

  const applyFilters = async (event) => {
    event && event.preventDefault();
    if(!searchLocation || !searchLocation?.State) return;

    removeSession()
    dispatch(updateSearchlist({data: [], pagination:{}}));

    parent.applyFilters(props);
  }

  const disableForm = (loading) => {
    setIsLoading(loading);
  }

  const clearFilters = () => {
    removeSession()
    //--------------
    setDistance(defaultDistance)
    setSearchTerms('')
    setLimitToState(false)
    setSubstances([])
    setSeekingTreatment([])
    setTreatmentType([])
    setAdditionalServices([])
    setPayment([])
    setGenderAndAge([])
    setSpecialPopulation([])
    setProvidersOnsite([])
    setLanguagesServed([])
    //--------------
    updateDistance(defaultDistance)
    updateSearchTerms('')
    updateLimitToState(false)
    updateSubstances([])
    updateSeekingTreatment([])
    updateTreatmentType([])
    updateAdditionalServices([])
    updatePayment([])
    updateGenderAndAge([])
    updateSpecialPopulation([])
    updateProvidersOnsite([])
    updateLanguagesServed([])
    //--------------
    parent?.clearFilters()
  }

  return (
    <>
      <form id="form-services-search" className="form" onSubmit={applyFilters} onReset={clearFilters}>

        <div className="search-field panel panel-default panel-body sticky-panel p-0">
          <div className="form-group mb-0">
              <button
                  className="btn btn-default btn-flat"
                  type="reset"
                  disabled={!searchLocation || isLoading}
              >
                  Clear Filters
              </button>
              <button
                  className="pull-right btn btn-primary btn-flat"
                  type="submit"
                  disabled={!searchLocation || isLoading}
              >
                  Apply Filters
              </button>
          </div>
        </div>

        <div className="search-field panel panel-default p-0">
          <div className="form-group">
              <div className="media search-limit">
                <div className="media-left">
                  <i className="fa fa-info-circle fa-2x text-primary"></i>
                </div>
                <div className="media-body text-primary">
                  Search for a location (ZIP Code, County or State) within the Treatment Connection network. This service is available in Delaware, Ohio, Nevada, New Mexico, North Dakota, Maine, Massachusetts, Washington.
                </div>
              </div>
              <div className="mb-3">
                <LocationField
                  id="field-searchLocation-filter"
                  name="searchLocation"
                  className="form-control searchinput"
                  label="Location"
                  isLoading={isLoading}
                  defaultValue={searchLocation}
                  attributes={attributes}
                  handleChange={setSearchLocation}
                  handleUpdate={updateSearchLocation}
                  disableForm={disableForm}
                /> <span className='sr-only' aria-hidden="true">(required)</span>
              </div>
              <div>
                <CheckboxField
                    id="fieldLimitToStateFilter"
                    value="true"
                    checked={limitToState}
                    handleChange={setLimitToState}
                    handleUpdate={handleLimitToState}
                    label={"Limit to State"}
                  />
              </div>
              <div>
                <DistanceField
                  id="field-distance-filter"
                  name="distance"
                  label="Distance From Location"
                  value={distance}
                  attributes={attributes}
                  handleChange={setDistance}
                  handleUpdate={updateDistance}
                />
              </div>
          </div>
        </div>

        <div className="search-field panel panel-default">
          <div className="form-group">
            <SearchField
              id="field-searchTerms-filter"
              name="searchTerms"
              value={searchTerms}
              label="Search"
              handleChange={setSearchTerms}
              handleUpdate={updateSearchTerms}
            />
          </div>
        </div>

        <div className="clearfix"></div>

        <Accordion accordion={null} allowMultipleExpanded={true} allowZeroExpanded={true} >
          <AccordionContent heading="Service Type" selected={wasSelected?.seekingTreatment}>
            <SearchFilter
              id="field-treatment-types-filter"
              name={`${ATTRIBUTES.SEEKING_TREATMENT.DATA}[]`}
              attributeData={ATTRIBUTES.SEEKING_TREATMENT.DATA}
              attributeName={ATTRIBUTES.SEEKING_TREATMENT.NAME}
              attributeKey={ATTRIBUTES.SEEKING_TREATMENT.KEY}
              attributes={attributes}
              selectedValues={seekingTreatment}
              handleChange={setSeekingTreatment}
              handleUpdate={updateSeekingTreatment}
            />
          </AccordionContent>
          <AccordionContent heading="Substances Treated" selected={wasSelected?.substances}>
            <SearchFilter
              id="field-substances-filter"
              name={`${ATTRIBUTES.SUBSTANCES.DATA}[]`}
              attributeData={ATTRIBUTES.SUBSTANCES.DATA}
              attributeName={ATTRIBUTES.SUBSTANCES.NAME}
              attributeKey={ATTRIBUTES.SUBSTANCES.KEY}
              attributes={attributes}
              selectedValues={substances}
              handleChange={setSubstances}
              handleUpdate={updateSubstances}
            />
          </AccordionContent>

          <AccordionContent heading="Payment Type" selected={wasSelected?.payment}>
            <SearchFilter
              id="field-payment-types-filter"
              name={`${ATTRIBUTES.PAYMENT_TYPE.DATA}[]`}
              attributeData={ATTRIBUTES.PAYMENT_TYPE.DATA}
              attributeName={ATTRIBUTES.PAYMENT_TYPE.NAME}
              attributeKey={ATTRIBUTES.PAYMENT_TYPE.KEY}
              attributes={attributes}
              selectedValues={payment}
              handleChange={setPayment}
              handleUpdate={updatePayment}
            />
          </AccordionContent>

          <AccordionContent heading="Gender And Age" selected={wasSelected?.genderAndAge}>
            <SearchFilter
              id="field-gender-and-age-filter"
              name={`${ATTRIBUTES.GENDER_AND_AGE.DATA}[]`}
              attributeData={ATTRIBUTES.GENDER_AND_AGE.DATA}
              attributeName={ATTRIBUTES.GENDER_AND_AGE.NAME}
              attributeKey={ATTRIBUTES.GENDER_AND_AGE.KEY}
              attributes={attributes}
              selectedValues={genderAndAge}
              handleChange={setGenderAndAge}
              handleUpdate={updateGenderAndAge}
            />
          </AccordionContent>

          <AccordionContent heading="Special Populations" selected={wasSelected?.specialPopulation}>
            <SearchFilter
              id="field-special-populations-filter"
              name={`${ATTRIBUTES.SPECIAL_POPULATION.DATA}[]`}
              attributeData={ATTRIBUTES.SPECIAL_POPULATION.DATA}
              attributeName={ATTRIBUTES.SPECIAL_POPULATION.NAME}
              attributeKey={ATTRIBUTES.SPECIAL_POPULATION.KEY}
              attributes={attributes}
              selectedValues={specialPopulation}
              handleChange={setSpecialPopulation}
              handleUpdate={updateSpecialPopulation}
            />
          </AccordionContent>

          <AccordionContent heading="Providers on Site" selected={wasSelected?.providersOnsite}>
            <SearchFilter
              id="field-providers-on-site-filter"
              name={`${ATTRIBUTES.PROVIDERS_ONSITE.DATA}[]`}
              attributeData={ATTRIBUTES.PROVIDERS_ONSITE.DATA}
              attributeName={ATTRIBUTES.PROVIDERS_ONSITE.NAME}
              attributeKey={ATTRIBUTES.PROVIDERS_ONSITE.KEY}
              attributes={attributes}
              selectedValues={providersOnsite}
              handleChange={setProvidersOnsite}
              handleUpdate={updateProvidersOnsite}
            />
          </AccordionContent>

          <AccordionContent heading="Languages Served" selected={wasSelected?.languagesServed}>
            <SearchFilter
              id="field-languages-served-filter"
              name={`${ATTRIBUTES.LANGUAGES_SERVED.DATA}[]`}
              attributeData={ATTRIBUTES.LANGUAGES_SERVED.DATA}
              attributeName={ATTRIBUTES.LANGUAGES_SERVED.NAME}
              attributeKey={ATTRIBUTES.LANGUAGES_SERVED.KEY}
              attributes={attributes}
              selectedValues={languagesServed}
              handleChange={setLanguagesServed}
              handleUpdate={updateLanguagesServed}
            />
          </AccordionContent>
        </Accordion>

      </form>

      <div className="clearfix"></div>
    </>
  );
};

export default SearchFilters;
