import React from 'react';
import Footer from './../layout/footer'
import Header from './../layout/header'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import openbedsApi from './../../openbedsApi';
import {getStates} from './../../actions/selectattributes'
import {submitLogin} from './../../actions/login'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {formatPhoneNumber} from "../../utils/phoneNumbers";

let disablefields = true

const contactMethodPopover = (
    <Popover id="popover-positioned-top" title="Contact Method Definitions">
        <p>If you are a <u><b>Referring Provider</b></u>, you will be notified using:</p>
        <ol>
            <li>Your selected <u>Contact Method </u> regarding messages sent to you by a receiving provider using the
                ‘Referral Request Messaging’ page of OpenBeds, and
            </li>
            <li> Your selected <u>Contact Method</u> regarding messages you are copied on by users in your organization
                using the ‘Referral Request Messaging’ page of OpenBeds.
            </li>
        </ol>
        <p>If you are a <u><b>Receiving Provider</b></u>, you will be notified using:</p>
        <ol>
            <li> Your <u>Service’s e-mail address</u> concerning:
                a) referrals to your Service from a referring provider using the ‘Referral Request’ page of OpenBeds,
                and
                b) subsequent messages from the referring provider using the ‘Referral Request Messaging’ page of
                OpenBeds.
            </li>
            <li> Your selected <u>Contact Method</u> regarding messages you are copied on by users in your organization
                using the ‘Referral Request Messaging’ page of OpenBeds.
            </li>
        </ol>
    </Popover>
);


class ProviderMyProfile extends React.Component{
    constructor(props) {
        super(props);
        const values = this.props.location.pathname.split('/');

        this.state = {
            states: [],
            loadStates: false,
            statevalue: '',
            contact: "",
            submitButtonDisabled: false,
            orgnameOptions: [],
            showLoaderScr: true,
            stateError: false,
            validateToken: values[3],
            profile: {
                first_name: '',
                last_name: '',
                organization_name: '',
                organization_phone: '',
                organization_website_url: '',
                organization_address1: '',
                organization_address2: '',
                city: '',
                state: '',
                zipcode: '',
                email: '',
                mobile: '',
                contactMethods: '',
                role: '',

            },
            providerOptions: [
                { value: '8',label: 'Receiving Provider' },
                { value: '4',label: 'Receiving Service Administrator' },
            ]
        }
    }

    componentDidMount(){
        this.props.getStates();

        if(this.state.validateToken)
        {
            if( window.sessionStorage )
            {
              if(!sessionStorage.getItem( 'firstLoad' ))
              {
               sessionStorage[ 'firstLoad' ] = true;
               window.location.reload();
              }else{
               sessionStorage.removeItem( 'firstLoad' );
              }
            }
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.states!==this.props.states) {
            this.setState({states: this.props.states});
        }

    }

    componentWillMount(){
        this.getProviderProfileDetails()
    }

    getProviderProfileDetails = () => {
        if(this.state.validationInProgress){
            return;
        }
        this.setState({validationInProgress: true});
        let parent = this

        let access = this.props.loginDetails.access_token

        let details = {}
        let postheaders = {
            'access-token':access,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];

        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");
        openbedsApi.post('/validateUser/'+this.state.validateToken,formBody,{headers: postheaders})
            .then(function (response) {
                if(response.data.status === 'ERROR') {
                    if(response.data.message === 'Your account has been deleted.') {
                        parent.props.history.push('/account-deleted');
                    } else {
                        parent.props.history.push('/link-expired');
                    }
                }
                if(response.data.data.length !== 0) {
                    let providerProfile = {}

                    providerProfile = {
                        first_name: response.data.data.fname,
                        last_name: response.data.data.lname,
                        organization_name: response.data.data.organisation,
                        organization_phone: response.data.data.phone_number,
                        organization_website_url: response.data.data.org_url,
                        organization_address1: response.data.data.address_line1,
                        organization_address2: response.data.data.address_line2,
                        city: response.data.data.city,
                        state: response.data.data.state,
                        zipcode: response.data.data.zip,
                        email: response.data.data.email,
                        mobile: response.data.data.mobile,
                        contactMethods: response.data.data.contact_method,
                        id: response.data.data.id,
                        user_id: response.data.data.user_id,
                        role: response.data.data.role
                    }

                    parent.setState({
                        profile: providerProfile,
                        showLoaderScr: false
                    }, () => {
                        parent.props.initialize( parent.state.profile );
                    });

                }
                parent.setState({validationInProgress: false});
            })
            .catch(function (error) {
                parent.setState({validationInProgress: false});
                /* console.log(error);*/
            });
    }

    statesVal = () =>{
        return this.state.states.states.map(state=>{
            return <option value={state.id} key={state.id}>{state.name}</option>
        })
    }

    renderError = ({error,touched},isValid,checkvalid) => {
        if(error && touched && (isValid || checkvalid)){
            return <span className="error">{error} </span>

        }
    }

    updateContact = (e) => {
        this.setState({contact:e.target.value})
    }

    stateInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className, options}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }

        return (
            <div className={elemClass}>
                <label className="control-label">
                    {label}
                    {
                        isValid ? <span className="text-red strongFont font10">*</span>: false
                    }
                    {
                        pretext
                    }
                </label>
                <select  {...input} disabled={readonly} className="form-control" value={input.value} >
                    <option value="">-- Select State--</option>
                    { options ?
                        options.states.map(state=>{
                            return <option value={state.id} key={state.id}>{state.name}</option>
                        })
                        : false
                    }
                </select>
                {this.renderError(meta,isValid,checkvalid)}
            </div>
        )
    }

    selectInputContact = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
            <div className={elemClass}>
                <label className="control-label">Select Contact Method<span className="text-red strongFont font10">*</span> <small>(may change later from My Profile)</small>  </label>
                <OverlayTrigger trigger="click" placement="top" overlay={contactMethodPopover} rootClose>
                    <a href="javascript:void(0);"><span className="glyphicon glyphicon-question-sign" aria-hidden="true" data-toggle="tooltip" data-placement="left"></span></a>
                </OverlayTrigger>

                <select {...input} className="form-control" id="contactMethods" required="" aria-required="true">
                    <option value="">-- Select --</option>
                    <option value="1">Call Institution</option>
                    <option value="2">Call Mobile</option>
                    <option value="3">E-Mail</option>
                    <option value="4">Use OpenBeds site for messaging</option>
                    <option value="5">Text Mobile</option>
                </select>
                {this.renderError(meta,isValid,checkvalid)}
            </div>
        )
    }

    selectInput = (props) =>{
        return (
            <div className={props.elemClass}>
                <label className="control-label">{props.label}
                    {
                        props.isValid ? <span className="text-red strongFont font10">*</span>: false
                    }
                </label>
                <select {...props.input} disabled={props.readonly} className="form-control" value={props.input.value} placeholder={props.placeholder}>
                    { props.options ? (
                        <>
                            {
                                props.options.map(o => {
                                    return <option value={o.value} key={o.value}>{o.label}</option>
                                })
                            }
                        </>)
                        : false
                    }
                </select>
                {this.renderError(props)}
            </div>
        )
    }


    textInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className,userValues}) =>{

        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
        <div className={elemClass}>
            <label className="control-label">
            {label}
            {
                isValid ? <span className="text-red strongFont font10">*</span>: false
            }
            {
                pretext
            }
            </label>
            <input {...input} className="form-control serviceAddressLine1 s_address" placeholder={placeholder} title={title} type="text" disabled={readonly} type={type} />
            {this.renderError(meta,isValid,checkvalid)}
        </div>
        )
    }
    passwordInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
        <div className={elemClass}>
            <label className="control-label">
            {label}
            {
                isValid ? <span className="text-red strongFont font10">*</span>: false
            }
            {
                pretext
            }
            </label>
            <input {...input} className="form-control serviceAddressLine1 s_address"  placeholder={placeholder}  title={title} type="password" disabled={readonly} />
            {this.renderError(meta,isValid,checkvalid)}
        </div>
        )
    }

    textInputauto = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
        <div className={elemClass}>
            <label className="control-label">
            {label}
            {
                isValid ? <sapn className="text-red strongFont font10">*</sapn>: false
            }
            {
                pretext
            }
            </label>
            <Typeahead
               {...input}
               disabled
                options={this.state.orgnameOptions}
                />
            {this.renderError(meta,isValid,checkvalid)}
        </div>
        )
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    formSubmit = (formValues)  =>  {
        this.setState({showLoader: true, submitButtonDisabled: true})

        let details = formValues

        details.contactMethods = formValues.contactMethods
        details.phone_number=formValues.organization_phone
        details.org_url = formValues.organization_website_url
        details.address_line1 = formValues.organization_address1
        details.address_line2 = formValues.organization_address2
        details.zip = formValues.zipcode
        details.mobileNo = formValues.mobile;
        details.token = this.state.validateToken;
        details.org_id = this.state.profile.id;
        details.user_id = this.state.profile.user_id;
        details.admin_activated = 1;

        if(details.mobileNo === null) {
            details.mobileNo = '';
        }

        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain'
        }
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        let parent = this
            openbedsApi.post('/update_service_admin', formBody, {headers: postheaders})
            .then( async (response) => {
                if (response.data.status === "ERROR" || response.data.status === "Failure") {
                    NotificationManager.error('', response.data.message);
                    parent.setState({
                        showLoader: false,
                        submitButtonDisabled: false,
                    })
                } else if (response.data.status === "SUCCESS") {
                    NotificationManager.success('', 'Your account has been created successfully! Redirecting to Update Service Availability.');
                    await parent.sleep(4000);
                    await parent.props.submitLogin(details.email,details.password);
                    if(this.props.loginDetails) {
                        if (this.props.loginDetails.status === "SUCCESS") {
                            sessionStorage.userDetails = JSON.stringify(this.props.loginDetails)

                            if (this.props.loginDetails.type === "Provider" || this.props.loginDetails.type === "4a provider" || this.props.loginDetails.type === "Admin") {
                                this.props.history.push("/search-treatment-facilities");
                                this.setState({
                                    showLoader: false,
                                    submitButtonDisabled: false,
                                })
                            }
                        }
                    }
                }
            });
    }

    

    reload =() => {
        window.location.reload()
    }


    render(){
          return (
<div>
    {this.state.showLoaderScr ? <div className="se-pre-con_ajax"></div> : false}
    <Header/>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span className="heading-txt">Registration Form</span>

                        <div className="clearfix"></div>
                    </div>
                    <div className="panel-body">
                        <form onSubmit={this.props.handleSubmit(this.formSubmit)} className="validator-form register_user" id="register_user">
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="first_name" readonly={disablefields}  component={this.textInput} label="First name" title="Please enter at least 3 and no more than 50 characters"  isValid={true} />
                                </div>
                                <div className="col-sm-6">
                                    <Field name="last_name" readonly={disablefields}  component={this.textInput} label="Last name" title="Please enter at least 3 and no more than 50 characters" value="abc" isValid={true}/>

                                    <div className="form-group"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="organization_name" readonly={disablefields} component={this.textInput} label="Organization Name" title="Please enter at least 3 and no more than 50 characters" value="abc" isValid={true}/>
                                </div>
                                <div className="col-sm-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="organization_phone" readonly={disablefields}   component={this.textInput} label="Organization Phone No." isValid={true} title="Please enter a valid phone no."  />
                                </div>
                                <div className="col-sm-6">
                                    <Field name="organization_website_url"  readonly={disablefields} checkvalid={true} component={this.textInput} label="Organization Website Url" isValid={false} title={`Please enter valid url, e.g. http://abc.com  or https://abc.com" type="url`}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="organization_address1"  readonly={disablefields}  component={this.textInput} label="Organization Address line 1" isValid={true} />
                                </div>
                                <div className="col-sm-6">
                                    <Field name="organization_address2" readonly={disablefields}   component={this.textInput} label="Organization Address line 2"  isValid={false} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="city" readonly={disablefields}  component={this.textInput}  label="City"  isValid={true}/>
                                </div>
                                <div className="col-sm-4">
                                    <Field name="state"  readonly="disabled" component={this.stateInput} options={this.props.states} label="State" title=""  placeholder="" isValid={true} type="select" disabled="disabled" />
                                </div>
                                <div className="col-sm-2">
                                    <Field name="zipcode" readonly={disablefields}  component={this.textInput} label="Zip Code" isValid={true}/>
                                </div>
                            </div>
                            <div className="row">
                               <div className="col-md-6">
                                   <Field name="role" readonly={disablefields}  options={this.state.providerOptions} component={this.selectInput} label="Type of Provider" placeholder="Type of Provider" multi={false} isValid={true}/>
                               </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="email" readonly={disablefields} component={this.textInput} label="Email" pretext={<small>(Please use your work email, DO not use personal email)</small>} isValid={true}/>
                                </div>
                                <div className="col-sm-6">
                                    <Field name="mobile" component={this.textInput} label="Mobile Phone No." title="Please enter a valid phone no."  placeholder="Mobile Phone No." isValid={true}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="password" component={this.passwordInput} label="Password" placeholder="Password" isValid={true}/>
                                </div>
                                <div className="col-sm-6">
                                    <Field name="confirm_password" component={this.passwordInput} label="Confirm Password" title="Please enter a valid phone no."  placeholder="Confirm Password" isValid={true}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field name="contactMethods" component={this.selectInputContact} label="Select Contact Method" title="(may change later from My Profile)"  placeholder="Select Contact Method" isValid={true} type="select"/>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary mr10" disabled={this.state.submitButtonDisabled}>Submit
                                        {this.state.showLoader ? <span className="fa fa-spinner fa-spin pageLoader">&nbsp;</span> : false}
                                    </button>
                                    <a className="btn btn-info" href="/search-treatment-facilities">Cancel</a>
                                </div>
                            </div>
                            <div className="row">
                                <hr className="dotted"/>
                                <div className="col-sm-12 serviceadminTitle"></div>
                                <div className="col-sm-12 serviceadminDetail"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <NotificationContainer/>
    <Footer />
</div> )
  }
}

const validate = (formValues) => {
    let errors ={}

     if(!formValues.contactMethods){
         errors.contactMethods = "Please select contact method"
     }else
     {
        if(formValues.contactMethods==="2" || formValues.contactMethods==="5"){
             if(!formValues.mobile || formValues.mobile.length <= 9){
                 if(formValues.mobile){
                     if(formValues.mobile.length>=1){
                         formValues.mobile  = formValues.mobile.replace(/[^\d]/g, "");
                     }
                 }
                 errors.mobile = "Please enter a valid phone number"
             }else{
                 formValues.mobile = formatPhoneNumber(formValues.mobile)
             }
         }
     }
    const phoneregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    if(formValues.mobile){
        if(formValues.mobile.length >= 9){
            formValues.mobile = formatPhoneNumber(formValues.mobile)
        }
        if(!phoneregex.test(formValues.mobile)) {
            errors.mobile = "Please enter a valid phone number";
        }
    } else {
        errors.mobile = "Please enter a valid phone number";
    }

     const passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/
     if(!formValues.password || !passwordregex.test(formValues.password)){
         errors.password = "Please enter at least one number, one uppercase, lowercase letter, one special character ( @, !, #, $, & ) and at least 8 or more characters"
     }

     if(!formValues.confirm_password || formValues.confirm_password !== formValues.password){
         errors.confirm_password = "Password and Confirm password did not match"
     }



    return errors
}

let providerValidateProfileForm = reduxForm({
    form: 'register_user',
    validate,
    enableReinitialize: true,
    keepDirty: true,
    keepDirtyOnReinitialize: true
})(ProviderMyProfile);

const mapStateToProps=(state)=>{

    return {
        loginDetails:state.loginDetails,
        initialValues: state.profile,
        states: state.states,
    }
}


providerValidateProfileForm = connect(
    mapStateToProps,
    {
        getStates,
        submitLogin
    }
)(providerValidateProfileForm);

export default providerValidateProfileForm;
