import React from 'react';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import { Logout } from '../../actions/login';
import openbedsApi from '../../openbedsApi';
import screenReaderAnnouncement from "../accessibility-helpers/screenReaderAnnouncement";


class InactivityMonitor extends React.Component {
    constructor(props) {
        super(props);

        const expiration = props.expiration;
        const warningOffset = 20000; // 20 seconds
        const warningTime = (expiration) ? expiration - warningOffset : null;

        this.resetTimeout = this.resetTimeout.bind(this);
        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.refreshApiAccessTokenThrottled = throttle(this.refreshApiAccessToken, 3000);

        this.state = {
            warningTime,
            logoutTime: expiration,
            showWarning: false,
        };
    }

    componentDidMount() {
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keydown',
        ];

        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }

    componentWillUnmount() {
        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
        this.clearTimeoutFunc();

    }

    resetTimeout = (e) => {
        this.refreshApiAccessTokenThrottled();
        this.clearTimeoutFunc();
        this.setTimeout();

        /* If the modal was open (only when 20 sec prior to logout, we want to 
        confirm that the logout process was halted. */
        if (this.state.showWarning) {
            e.preventDefault(); // to stop triggering controls behind the modal, if they have focus
            e.stopPropagation(); // to stop triggering controls behind the modal, if a child element has focus
            screenReaderAnnouncement('You have successfully stopped the inactivity logout.')
        }
        this.setState({ showWarning: false });
    };

    clearTimeoutFunc = () => {
        if (this.warningTimeout) {
            clearTimeout(this.warningTimeout);
        }

        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    };

    setTimeout = () => {
        this.warningTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.logoutTime);
    };

    warn() {
        this.setState({ showWarning: true });
    }

    logout() {
        this.props.Logout();
    }

    refreshApiAccessToken = () => {
        const accessToken = this.props.accessToken;

        const postheaders = {
            'access-token': accessToken,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain',
        };

        const data = new FormData();

        openbedsApi.post('/refreshApiAccessToken', data, { headers: postheaders })
            .then((response) => {})
            .catch(error => {});
    }

    render() {
        return (
            <div className="inactivity-wrapper">
                {this.state.showWarning &&
                    <>
                        <div className="inactivity-warning" aria-live="polite" role="alert">
                            Due to inactivity, you will be logged out in
                            <strong>20 seconds</strong>
                            Press any key to prevent being logged out.
                        </div>
                        <div className="inactivity-warning-backdrop"></div>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const expiration = (state.loginDetails.expires_in) ? state.loginDetails.expires_in * 1000 : null;

    return {
        expiration,
        accessToken: state.loginDetails.access_token,
    };
};

export default connect(mapStateToProps, { Logout })(InactivityMonitor);
