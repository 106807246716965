import { Component, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const AnyReactComponent = ({ orgDetails, state, defaultShow, center, resolutionSize, setSelected, reRender }) => {
  const overlayRef = useRef(null)
  const popoverRef = useRef(null)

  const clickedPin = e => {
    overlayRef.current.handleToggle();
    if(overlayRef.current.state.show) {
        e.target.closest(".markerButton").removeAttribute("aria-expanded");
    } else {
        e.target.closest(".markerButton").setAttribute("aria-expanded", "true");
    }
    if (setSelected) {
      setSelected(orgDetails.service_id);
    }
    //bounce effect
    e.target.classList.add("bounce");
    var targetEvent = e.target;
    setTimeout(function() {targetEvent.classList.remove("bounce")}, 300);
  };
  
  var overlay = <OverlayTrigger ref={overlayRef} delay={0} shouldUpdatePosition rootClose  trigger={null} placement="top" overlay={<Popover id="facilityPopOver" ref={popoverRef} style={{width: "200px", wordBreak: 'break-word', wordWrap: 'break-word' }}>
        <div className="facility-popover-header">{orgDetails.organisation } - {orgDetails.service_name}</div>
        <p> {orgDetails.service_street_no},&nbsp;{ (orgDetails.service_street_name) ? <br/> : '' }{orgDetails.service_street_name}
        <br/>
        {orgDetails.service_city},&nbsp;
        {orgDetails.state_code ? orgDetails.state_code : orgDetails.popup.service_state_code}&nbsp;{orgDetails.service_zip} <br/>
        {orgDetails.pfs_dedicated_phone_no} <br/>
        {orgDetails.org_url}</p>
    </Popover>}>
    <button onClick={(e) => { clickedPin(e) }} className="pin markerButton" aria-haspopup="true">
      <img src="/images/Map-Marker-PNG-File.png" alt={ orgDetails.service_street_no + " " + ((orgDetails.service_street_name) ?? '') + " " + orgDetails.service_city + " " + (orgDetails.state_code ? orgDetails.state_code : orgDetails.popup.service_state_code) + ' ' + orgDetails.service_zip}></img>
    </button>
  </OverlayTrigger>;

  useEffect(() => {
      if (defaultShow == orgDetails.service_id) {
        //overlayRef.current.handleToggle(); //this is working ok but if you clicked on it already it closes it
        overlayRef.current.show() //this approach means that clicking out and then clicking the modal doesnt change defaultshow so it is not re-rendered
      } else {
        overlayRef.current.hide()
      }
    }, [reRender]);


    useEffect(() => { //if popup goes out of bounding box area => hide it
        if (popoverRef.current && resolutionSize) {
          var topBound = 130; //height of map is not variable
          var bottomBound = resolutionSize.h; //height of map is not variable
          var leftBound = resolutionSize.w/2;
          var rightBound = resolutionSize.w - 201; //minus right bound
          if (popoverRef.current.props.positionLeft < leftBound || popoverRef.current.props.positionLeft > rightBound ||
              popoverRef.current.props.positionTop > bottomBound || popoverRef.current.props.positionTop < topBound) {
            overlayRef.current.handleHide() //need to reset the position
          }
        }
      }, [center]);

  return overlay
}

function mapOptions (maps) {
  return {
    gestureHandling: "greedy",
    fullscreenControl: false,
    zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_TOP
    }
  };
}

class SimpleMap extends Component {

  static defaultProps = {
    center: {
      lat: 38.911898,
      lng: -77.016693
    },
    zoom: 14
  };

  constructor(props) {
      super(props);

      this.state = {
        center: {
          lat: 38.911898,
          lng: -77.016693
        },
        flipper: false
      }

  }

  mapAltered = () => {
    if (this.state.flipper) {
      this.setState({flipper: false})
    } else {
      this.setState({flipper: true})
    }
  }

  render() {
    var pinList = []; //stores lat and long and notifies if there is a location conflict
    var rcomponents = [];
    if (this.props.facility_list) { //this is only for multiple invocation of simple map
      this.props.facility_list.map((provider, index) => {
        if (provider) {
          if (provider.service_latitude && provider.service_longitude) { //for some services that are bugged and dont have proper long lat set
              var lat = parseFloat(provider.service_latitude);
              var long = parseFloat(provider.service_longitude);
              if ( pinList.indexOf(String(lat) + ", " + String(long)) != -1) {
                lat += 0.0001 //add offset
                long += 0.0001 //add offset
              }
              pinList.push(String(lat) + ", " + String(long))
              rcomponents.push(<AnyReactComponent
                key={provider.service_id} //this is for unique key
                lat={lat}
                lng={long}
                orgDetails={provider} //passing the org details to the popover
                state= {this.props.stateName[index]} //state code not included in org details
                defaultShow= {this.props.selectedFacility} //passing manual show popover
                center={this.state.flipper} //for triggering map reposition
                resolutionSize={this.props.resolutionSize} //boundary resolution
                setSelected={this.props.setSelected} //for setting the highlighted on the list
                reRender={this.props.reRender}
              />)
            }
          }
      })
    }
    
    return (
      // Important! Always set the container height explicitly
      // old was 60vh to something smaller for list
      <div style={{ height: this.props.mapHeight +"px" , width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: this.props.googleApiKey }}
          defaultCenter={this.props.center}
          center={this.props.center}
          defaultZoom={this.props.zoom}
          onChange={this.mapAltered}
          options={mapOptions} //this is for disabling the two finger scroll on mobile devices
        >
          {rcomponents}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
