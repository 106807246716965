import React from "react";

export default () => {
    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Treatments available</h1>

                    <h4>Crisis Stabilization</h4>
                    <div>Provides short term intensive care for patients seeking immediate help. This may include a
                        psychiatric assessment, a medical assessment and
                        connection to community supports upon discharge. The goal is to stabilize the patient and
                        address their immediate needs. The patient can be
                        referred to a residential treatment program if they need continued treatment or discharged home
                        with outpatient supports in place.
                    </div>
                    <h4>Inpatient Withdrawal Management</h4>
                    <div>Short term medically monitored withdrawal. The patient is provided with 24 hour supervision by
                        healthcare professionals with a physician
                        available on site or by phone. Depending on the severity and type of substance used; they may
                        receive medication assisted detox. The goal of this
                        level of treatment is medical stabilization during the period of withdrawal and the patient is
                        then connected with additional residential treatment
                        or outpatient treatment depending on their needs.
                    </div>
                    <h4>Residential Substance Use Treatment</h4>
                    <div>The goal of the residential treatment is to get the patient actively engaged in their treatment
                        and to set them up for success upon return home.
                        Services usually include a psychiatric evaluation, individual counseling, group counseling,
                        treatment planning, and linkage to outpatient support
                        upon discharge. There are short term programs which can range from a few weeks to 6 months and
                        long-term programs which range from 6
                        months to 1 year. These are highly structured programs staffed with substance abuse
                        professionals.
                    </div>
                    <h4>Psychiatric Inpatient</h4>
                    <div>Length of treatment varies by facility and severity of the patient. Short term treatment can
                        range from a few weeks to a couple months or longterm care which is established by individual
                        need and state regulations. This level of care is used for patients requiring psychiatric
                        stabilization.
                        This may be helping the patient through a crisis or manage worsening psychiatric symptoms. In
                        addition, psychiatric inpatient is used for patients
                        with acute danger to self or others. Facilities are staffed with mental health and medical
                        professionals. Patients can expect psychiatric evaluations,
                        individual counseling, group therapy, life skills training and connection to community support
                        and outpatient programs.
                    </div>
                    <h4>Partial Hospitalization</h4>
                    <div>This level of treatment is sometimes referred to as a Day Treatment Program. The patient is
                        typically required to spend about 4 hours/day, 5
                        days/week in a highly structured environment. The patient continues to live at home and travels
                        back and forth to treatment each morning. This
                        level is appropriate for someone who requires a high level of care but does not need 24 hour
                        monitoring. These programs are usually equipped to
                        manage dual diagnosis, mental health and substance use. They are staffed with healthcare
                        professionals including providing access to a psychiatric
                        evaluation. The patient is expected to participate in individual counseling, group sessions, and
                        life skills trainings. Following completion of the
                        program they are generally referred to outpatient counseling.
                    </div>
                    <h4>Intensive Outpatient Treatment</h4>
                    <div>IOP offers patients with treatment for a few hours/day, about 3-5 days/week. This level of
                        treatment allows the patient to continue to work,
                        attend school and live at home while still offering intensive structured treatment. There is an
                        emphasis on group sessions, but patients will also
                        generally meet with an individual therapist weekly. These programs are geared toward substance
                        use but are also prepared for dual diagnosis with
                        a mental health condition. Upon completion, the patient is referred to a lower level of
                        outpatient counseling.
                    </div>
                    <h4>Medication Assisted Treatment. OTP and non-OTP</h4>
                    <div>Treatment can be provided in an inpatient or outpatient setting. An OTP, Opioid Treatment
                        Program, must meet specific requirements and be
                        certified by SAMHSA. The treatment includes medication that is used to treat opioid addiction in
                        addition to behavioral counseling. MAT can also
                        be used to treat alcohol used disorder. Typical medications are Buprenorphine, (Suboxone,
                        Subutex), Methadone (only administered from an OTP
                        facility), naltrexone (e.g. Vivitrol), Campral and disulfiram. Medications reduce the cravings
                        and other symptoms associated with withdrawal from a
                        substance, or induce negative feelings when a substance is taken. Treatment is tailored to the
                        individual and can be ongoing.
                    </div>
                    <h4>Outpatient Counseling</h4>
                    <div>Outpatient counseling serves patients with substance use disorder, mental health conditions or
                        co-occurring disorders. Treatment can take place
                        in either group or an individual session and typically occurs weekly. The patient is encouraged
                        to be engaged and to be actively using the learned
                        skills in their daily life to facilitate recovery.
                    </div>
                    <h4>Recovery Housing</h4>
                    <div>Recovery housing offers a safe drug and alcohol free environment. It is used for individuals to
                        remove the triggers associated with “people, places
                        and things”. While recovery residences vary widely in structure, all are centered on peer
                        support and a connection to services that promote longterm recovery. Many residents live in
                        recovery housing during and/or after outpatient addiction treatment. Length of stay is
                        self-determined and
                        can last for several months to years.
                    </div>
                    <h4>Recovery Support Services</h4>
                    <div>Services provided to an individual and/or their families to help stabilize and support
                        recovery. Supportive services may include employment
                        services, anger management classes, recovery mentoring/relapse prevention management, peer
                        recovery coaching, care coordination, access to
                        childcare, transportation, sober housing, and other support.
                    </div>
                </div>
            </div>
        </div>
    )
}
