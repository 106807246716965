// SearchResults.js
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SearchResult from './SearchResult';
import SimpleMap from  '../simplemap';
import { Messages } from '../messages';
import { ActiveFilters } from './ActiveFilters';
import { MapModal } from './MapModal';
import { ContactModal } from './ContactModal';
import { gaLogEvent} from '../../actions/googleAnalytics';
import { ListPagination } from '../generic/ListPagination';
import { Popover } from 'react-bootstrap';
 

export const enquirePopOver = (
    <Popover id="enquirePopOver">
        <p>Click here to send an anonymous enquiry to this facility</p>
    </Popover>
  );
export const SearchResults = ({parent, attributes, ...props}) => {
    const searchLocation = useSelector(state => state.searchLocation);
    const searchlist = useSelector(state => state.searchlist);
    const stateAttributes = useSelector(state => state.stateAttributes);
    const stateConfigurations = useSelector(state => state.stateConfigurations);
    
    const [ searchResults, setSearchResults ] =  useState(searchlist?.data || []);
    const [ center, setCenter ] = useState(props.center || { lat: 38.9, lng: -77 });
    const [ lat, setLat ] = useState(props.lat || 38.9);
    const [ long, setLong ] = useState(props.lang || -77);
    const [ googleApiKey, setGoogleApiKey ] = useState(props.googleApiKey);
    const [ mapShow, setMapShow ] = useState(props.mapShow || false);
    const [ showLoader, setShowLoader ] = useState(props.showLoader || false);
    const [ organisation, setOrganisation ] = useState(props.organisation || '');
    const [ listMapFacility, setListMapFacility ] = useState(props.searchlist || []);
    const [ againExpressingInterestInOrganization, setAgainExpressingInterestInOrganization] = useState(null);
    const [ againExpressingInterestInService, setAgainExpressingInterestInService] = useState(null);
    const [ againExpressingInterestInState, setAgainExpressingInterestInState] = useState(null);
    const [ againExpressingInterestInOrganizationService, setAgainExpressingInterestInOrganizationService] = useState(null);
    const [ show, setShow ] = useState(props.show || false);
    const [ searchPerformed, setSearchPerformed ] = useState(props.searchPerformed || false);

    const controlsRef = useRef()
    
    const [ isMapView, setIsMapView ] = useState(props.isMapView || props.MapView ||false);
    const [ showRedisSearchLoader, setShowRedisSearchLoader ] = useState(props.showRedisSearchLoader || true);
    const [ resolutionSize, setResolutionSize ] = useState(props.resolutionSize || window.innerWidth);
    const [ resolutionSizeH, setResolutionSizeH ] = useState(props.resolutionSize_H || window.innerHeight);

    const { message, data:_searchResults, status, pagination, total_records } = searchlist || {};
    const totalRecords = total_records || pagination?.total_records || 0;
    const [totalPages, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    var stateList = [];
    
    const updateParentProps = useCallback((newProps) => {
        parent.setState(newProps)
    }, [parent])

    useEffect(() => {
      const _searchResults = searchlist?.data || [];
      if (_searchResults) {
        var ind = _searchResults.length;
        _searchResults.forEach((provider, index) => {
          if (provider) {
            if (
              index < ind &&
              provider.service_latitude &&
              provider.service_longitude
            ) {
              let center = {
                lat: parseFloat(provider.service_latitude),
                lng: parseFloat(provider.service_longitude),
              };
              updateParentProps({ center: center });
              setCenter(center);
              ind = index;
            }
          }
          return provider;
        });
        setSearchResults(_searchResults);
      }
      const _pagination = searchlist?.pagination
      if (_pagination) {
        setTotalPage(Math.ceil(totalRecords / _pagination?.per_page));
        setCurrentPage(_pagination);
      }
      //another search got started and "finished" early don't set Loader state
      const _message = searchlist?.message
      if (_message !== "CANCELLED") {
        setShowRedisSearchLoader(false);
      }
    }, [searchlist, updateParentProps]);

    // useEffect(() => {
    //   window.addEventListener('resize', updateDimensions);

    //   //set the map height
    //   setTimeout(() => {
    //       updateDimensions();
    //   }, 1000);

    //   return () => {
    //       window.removeEventListener('resize', updateDimensions);
    //   };
    // });

  const updateDimensions = () => {
      setResolutionSize(window.innerWidth);
  };

    const handleMapShow = (event, prov) => {
        event.preventDefault();
        setCenter({
          lat: parseFloat(prov.service_latitude),
          lng: parseFloat(prov.service_longitude),
        });
        setLat(prov.service_latitude);
        setLong(prov.service_longitude);
        setOrganisation(prov.organisation);
        setListMapFacility([prov]);
        setMapShow(true);      
    };

    const handleExpressInterest = (
        organizationId,
        serviceId,
        organizationServiceId,
        state,
        expressingInterestAgain
    ) => {
        gaLogEvent({
            category: 'Search Results',
            action: 'Contact Treatment Button',
        });
        if (expressingInterestAgain) {
            setAgainExpressingInterestInOrganization(organizationId);
            setAgainExpressingInterestInService(serviceId);
            setAgainExpressingInterestInOrganizationService(organizationServiceId);
            setAgainExpressingInterestInState(state);
        } else {
            expressInterest(organizationId, serviceId, organizationServiceId, state);
        }
    };
    const expressInterest = (organization_id, service_id, organization_services_id, state) => {
        if (props.loginDetails) {
            if (props.loginDetails.type === 'Patient') {
                setShowLoader(true);
                sessionStorage.setItem('org_id', organization_id);
                sessionStorage.setItem('service_uuid', service_id);
                sessionStorage.setItem('organization_services_id', organization_services_id);
                sessionStorage.setItem('state', state);
                props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
            } else {
                handleShow(organization_id, service_id, organization_services_id, state);
            }
        }
    };
    const resetAgainExpressingInterestState = () => {
        setAgainExpressingInterestInOrganization(null);
        setAgainExpressingInterestInService(null);
        setAgainExpressingInterestInOrganizationService(null);
        setAgainExpressingInterestInState(null);
    };
    const handleShow = (organization_id, service_id, organization_services_id, state) => {
        setShow(true);
        sessionStorage.setItem('org_id', organization_id);
        sessionStorage.setItem('service_uuid', service_id);
        sessionStorage.setItem('organization_services_id', organization_services_id);
        sessionStorage.setItem('state', state);
    };

    const handleClose = (modal) => {
        sessionStorage.removeItem('org_id');
        sessionStorage.removeItem('state');

        if (modal === 'login') {
            setShow(false);
        }
        if (modal === 'map') {
            setMapShow(false);
        }
    };

    const logEvent = (action) => {
        gaLogEvent({
            category: 'Search Results',
            action: action,
        });
    };
    
    const changeViewMode = (mode) => {
        if (mode === 'list') {
            updateParentProps({selected_panel: ''})
        }
        updateParentProps({view_mode: mode})
    };

    // highlight the correct panel
    const selectThisPanel = (panel, center) => {
        updateParentProps({flipFlop: !props.flipFlop})

        if (center.lat && center.lng) {
            updateParentProps({selected_panel: panel, center: center})
        } else {
            updateParentProps({selected_panel: panel, center: { lat: 38.9, lng: -77 }})
        }
    };

    //
    const deselectPanels = () => {
        updateParentProps({selected_panel: ''})
    };

    const setSelected = (org_serv_id) => {
        updateParentProps({selected_panel: org_serv_id})
        //scroll to selected on the list
        if (org_serv_id) {
            var panelToScroll = document.getElementById(org_serv_id);
            panelToScroll.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const handlePageChange = (page) => {
        if(page === currentPage) {
            return;
        }
        updateParentProps({page: page})
        setCurrentPage(page);
        parent?.getAllValues(page);
    };
    
    const Controls = () => {
        return (
            <>
            <button
                type="button"
                className={`view-mode-list btn btn-flat btn-${ isMapView ? 'default' : 'switch' }`}
                onClick={() => { setIsMapView(false) }}
            >
                {/* <i className="fa fa-list-ul" aria-hidden="true"></i> */}
                List View
                {!isMapView && (<span className="sr-only">. selected</span>)}
            </button>
            <button
                type="button"
                className={`view-mode-map btn btn-flat btn-${ isMapView ? 'switch ' : 'default' }`}
                onClick={() => { setIsMapView(true) }}
            >
                {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
                Map View
                {isMapView && (<span className="sr-only">. selected</span>)}
            </button>
            </>
        );
    };

    const SearchContent = (props) => {
        return searchResults.map((provider, index) => {
            if (!provider) {
                return null;
            }
    
            const { service_id, service_latitude, service_longitude, organisation_services_id } = provider;
            const panelId = organisation_services_id || service_id;
            const latLng = {
                lat: parseFloat(service_latitude),
                lng: parseFloat(service_longitude),
            };
    
            const selectPanel = () => {
                selectThisPanel(panelId, latLng);
            };
    
            const isSelected = props.selected_panel === panelId;
    
            return (
                <li
                    key={`list-${provider.service_id}-${index}`}
                    className={`panel panel-default mb-2 ${ props.selected ? "highlighted" : "unhighlighted" }-panel mode-${isMapView ? "map" : "list"}`}
                    id={provider.service_id}
                    onClick={props.selectThisPanel}
                    style={
                    props.resolutionSize >= 992 && props.resolutionSize < 1200
                        ? { width: (props.resolutionSize * 0.95 - 60) * 0.75 + 5 }
                        : undefined
                    }
                >
                    <div className="panel-body mb-2">
                        <SearchResult
                            provider={provider}
                            showloader={props.showloader}
                            selectThisPanel={selectPanel}
                            selected={isSelected}
                            view_mode={props.view_mode}
                            resolutionSize={resolutionSize}
                            deselectPanels={deselectPanels}
                            isMapView={isMapView}
                            setState={props.setState}
                            enquirePopOver={enquirePopOver}
                            handleExpressInterest={handleExpressInterest} 
                            {...props}
                        />
                    </div>
                </li>
            );
        });
    };

    const MapContent = (props) => {
        return (
            <div className="map-view-map-container">
                <section
                    aria-label={
                        'Interactive Location Google Map. Search results are at the heading ' +
                        totalRecords +
                        ' matches found based on your search or on the List View tab'
                    }
                >
                    <SimpleMap
                        key={props.service_id}
                        mapHeight={props.mapHeight}
                        lat={props.lat}
                        long={props.long}
                        center={props.center}
                        zoom={8}
                        organisation={props.organisation}
                        facility_list={props.searchlist}
                        stateName={stateList}
                        selectedFacility={props.selected_panel}
                        resolutionSize={{
                            w: resolutionSize,
                            h: resolutionSizeH,
                        }}
                        setSelected={setSelected}
                        reRender={props.flipFlop}
                        googleApiKey={props.googleApiKey}
                    />
                </section>
            </div>
        )
    }

    if(!searchLocation){
        return (
            <h3 className="mt-0 top">{Messages.EMPTY_LOCATION}</h3>
        )
    }

    return (
        <>
            <div className="list-group">
                {stateAttributes?.state_code && stateConfigurations?.banner_message && (
                <div className="list-group-item">
                    <p dangerouslySetInnerHTML={{__html: stateConfigurations?.banner_message}}></p>
                </div>
                )}
                <ActiveFilters parent={parent} {...props} />
            </div>

            {totalRecords > 0 ? (
                <div className="search-result" style={resolutionSize < 1200 ? { marginBottom: '0px' } : {}}>
                    <div className="d-sm-flex justify-content-between my-2" ref={controlsRef}>
                        <h4 aria-live="polite" className="my-0">
                            <strong>{totalRecords} matches found based on your search.</strong>
                        </h4>
                        <div className="flex-sm-auto text-right">
                            <Controls />
                        </div>
                    </div>
                    {isMapView ? (
                        <div className="row map-view-container">
                            <ul
                                className={ resolutionSize < 1200 ? 'map-view-list-container-mobile ' + (props.selected_panel === '' ? 'mobile-hidden' : '') : 'col-md-4 map-view-list-container' }
                            >
                                <SearchContent {...props} />
                            </ul>

                            <div className={resolutionSize < 1200 ? '' : 'col-md-8'} style={resolutionSize < 1200 ? undefined : { height: props.mapHeight }}>
                                <MapContent {...props} />
                            </div>
                        </div>
                    ) : (
                        <ul className="list-view-list-container">
                            <SearchContent
                                handleMapShow={handleMapShow} 
                                {...props} />   
                            <MapModal
                                show={mapShow}
                                lat={lat}
                                long={long}
                                center={center}
                                organisation={organisation}
                                facility_list={listMapFacility}
                                onHide={() => {
                                    handleClose('map');
                                }}
                                googleApiKey={googleApiKey}
                            />
                            <ContactModal
                                show={show}
                                onHide={() => {
                                    handleClose('login');
                                }}
                                logEvent={(event) => {
                                    logEvent(event);
                                }}
                                againExpressingInterestInOrganization={againExpressingInterestInOrganization}
                                againExpressingInterestInService={againExpressingInterestInService}
                                againExpressingInterestInOrganizationService={againExpressingInterestInOrganizationService}
                                againExpressingInterestInState={againExpressingInterestInState}
                                expressInterest={expressInterest}
                                resetAgainExpressingInterestState={resetAgainExpressingInterestState}
                            />        
                        </ul>
                    )}
                </div>
            ) : (
                <h4 className="mt-0 bottom">
                    {searchPerformed ? Messages.NO_RECORDS_FOUND : Messages.NOT_SEARCHED_YET}
                    
                </h4>
            )}
            {totalPages > 1 && (
                <ListPagination
                    alignment="right"
                    visiblePageCount="7"
                    pageCount={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            )}
        </>
    );
      
}
export default SearchResults;
