//action creator
import openbedsApi from "../openbedsApi";
import * as ACTION_TYPES from "./actionTypes";
import { backendUrl } from '../apiurl';
import { fetchStateAttributes} from "../apiClient";

// to feach all attributes
export const fetchPosts = () => async (dispatch) => {
  const response = await openbedsApi.get("/getAttributes");

  dispatch({ type: ACTION_TYPES.FETCH_POSTS, payload: response.data });
};


export const fetchApiKey  = () => async (dispatch) => {
  let data = new FormData();
  data.append('secretKeyName', 'google_maps.api_key');

  let postheaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json, text/plain',
  };

  const axiosConfig = {
      baseURL: backendUrl,
      headers: {
          Accept: 'application/json',
      },
      withCredentials: true,
  };

  openbedsApi
      .post('api/get_api_keys', data, axiosConfig)
      .then(function (response) {
          if (response.data.status === 'SUCCESS') {
            const payload = response.data?.data || response.data
            dispatch({ type: ACTION_TYPES.FETCH_API_KEYS, payload });
          }
      })
      .catch(function (error) {
          throw Error(error.message);
      });
}

export const searchLocations = async(data) => {

  return openbedsApi.post('pfs_search_location', data)
    .then(function (response) {
      // dispatch({ type: ACTION_TYPES.SEARCH_LOCATIONS, payload: response });
      return response;
    })
    .catch(function (error) {
      // It's a good idea to handle errors such as request cancellation
      if (error.name !== 'AbortError') {
      }
      if (error.response.status === 422 || error.response.status === 404) {
      } 
      return error;
      
    });
}

export const getUsedAttributes = (state, patientTaxonomy = null) => async (dispatch) => {
  try {
    if (state) {
      const params = { state, patient_taxonomy: patientTaxonomy || state ? 1 : 0 }
      const response = await openbedsApi.get("/getStateAttributes", { params })
      const payload = response.data?.data || response.data || response
      dispatch({ type: ACTION_TYPES.UPDATE_STATE_ATTRIBUTES, payload });
    } else {
      const response = await openbedsApi.get('/getAttributes');
      const payload = response.data?.data || response.data || response
      dispatch({ type: ACTION_TYPES.GET_ALL_ATTRIBUTES, payload });
    }
  } catch (error) {
    console.error(error)
  }
};

export const getConfigurations = (state) => async (dispatch) => {
  let url = "/configurations/";
  if (state) {
    url += state;
    const response = await openbedsApi.get(url);
    dispatch({ type: ACTION_TYPES.GET_STATE_CONFIG, payload: response.data });
  } else {
    const response = await openbedsApi.get(url);
    dispatch({ type: ACTION_TYPES.GET_PFS_CONFIG, payload: response.data });
  }
};

export const getStates = () => async (dispatch) => {
  const response = await openbedsApi.get("/getStates");
  const payload = response.data?.data || []
  dispatch({ type: ACTION_TYPES.FETCH_STATES, payload });
};

export const getMoreInfo = (service_id) => async (dispatch) => {
  let userDetails = sessionStorage.userDetails
    ? JSON.parse(sessionStorage.userDetails)
    : "";

  let access = userDetails.access_token;

  let details = {
    service_id: service_id,
  };
  let postheaders = {
    "access-token": access,
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json, text/plain",
  };
  let formBody = [];

  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");

  openbedsApi
    .post("/get_i_information_by_service", formBody, { headers: postheaders })
    .then(function (response) {
      dispatch({ type: ACTION_TYPES.FETCH_MORE_INFO, payload: response.data });
    });
};

export const fetchSearchlist =
  (cancelTokenSource, details, gaLogEvent) => async (dispatch) => {
    let userDetails = sessionStorage.userDetails
      ? JSON.parse(sessionStorage.userDetails)
      : "";

    let formBody = new FormData();
    for (let property in details) {
      if (details.hasOwnProperty(property)) {
        formBody.append(property, details[property]);
      }
    }

    var headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": userDetails.access_token,
    };

    try {
      const axiosConfig = {
        baseURL: backendUrl,
        headers: headers,
        withCredentials: true,
      };

      const res = await openbedsApi.post("/api/get_providers_by_search", formBody, axiosConfig,{
        headers: headers,
        cancelToken: cancelTokenSource.token,
      });

      if (details.page <= 1) {
        dispatch({ type: ACTION_TYPES.FETCH_SEARCHLIST, payload: res.data });

        if ( ("data" in res.data) === false || res.data.data.length === 0 ) {
          if(details.search_terms != null && details.search_terms.length !== 0){
            dispatch(gaLogEvent({
              category: 'Search Results',
              action: 'Search Results - Provider Name Search - No Results Returned'
            }));
          }
          dispatch(gaLogEvent({
            category: 'Search Results',
            action: 'Search Results - No Results Returned'
          }));
        }
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_SEARCHLIST, payload: res.data });
      }
    } catch (e) {
      if (e.message === "CANCELLED") {
        return "CANCELLED";
      }
    }
  };

export const fetchProvider = async ( details ) => {
        let userDetails = sessionStorage.userDetails
            ? JSON.parse(sessionStorage.userDetails)
            : "";

        let formBody = new FormData();
        for (let property in details) {
            if (details.hasOwnProperty(property)) {
                formBody.append(property, details[property]);
            }
        }

        var headers = {
            Accept: "application/json, text/plain",
            "Content-Type": "application/x-www-form-urlencoded",
            "access-token": userDetails.access_token,
        };

        try {
            const axiosConfig = {
                baseURL: backendUrl,
                headers: headers,
                withCredentials: true,
            };

            const res = await openbedsApi.post("/api/get_providers_by_search", formBody, axiosConfig,{
                headers: headers
            });
            return res.data;
        } catch (e) {
            if (e.message === "CANCELLED") {
                return "CANCELLED";
            }
        }
    };


export const performLocationSearch = (formData, abortController)  => async(dispatch) => {
  try {
    const response = await  openbedsApi.post('pfs_search_location', formData, { signal: abortController.signal });
    const payload =  response.data?.data || response.data || response;
    dispatch({ type: ACTION_TYPES.SEARCH_LOCATIONS, payload});
    return payload
  } catch (error) {
    if (error.name !== 'AbortError' && error.message !== 'Request timed out') {
      console.error("Error fetching search results:", error);
    }
    throw error;
  }
};


export const updateSearchlist = (searchlist) => {
  return { 
    type: ACTION_TYPES.FETCH_SEARCHLIST, 
    payload: searchlist 
  }
};
  

export const updateZipcode = (zipcode) => {
  return {
    type: ACTION_TYPES.UPDATE_ZIPCODE,
    payload: zipcode,
  };
};

// to update Location
export const updateLocation = (location) => {
  // returns an action
  return {
    type: ACTION_TYPES.UPDATE_LOCATION,
    payload: location,
  };
};

// to update distance from zipcode
export const updateDistance = (distance) => {
  return {
    type: ACTION_TYPES.UPDATE_DISTANCE,
    payload: distance,
  };
};

export const updatePayment = (payment) => {
  return {
    type: ACTION_TYPES.UPDATE_PAYMENT_TYPE,
    payload: payment,
  };
};

export const updateTreatment = (treatment) => {
  return {
    type: ACTION_TYPES.UPDATE_TREATMENT,
    payload: treatment,
  };
};

export const updateTreatmentType = (treatmentType) => {
  return {
    type: ACTION_TYPES.UPDATE_TREATMENT_TYPE,
    payload: treatmentType,
  };
};

export const updateSubstances = (substance) => {
  return {
    type: ACTION_TYPES.UPDATE_SUBSTANCES,
    payload: substance,
  };
};

export const updateSeekingTreatment = (seekingTreatment) => {
  return {
    type: ACTION_TYPES.UPDATE_SEEKING_TREATMENT_TYPE,
    payload: seekingTreatment,
  };
};
export const updateAdditionalServices = (additionalServices) => {
  return {
    type: ACTION_TYPES.UPDATE_ADDITIONAL_SERVICES,
    payload: additionalServices,
  };
};
export const updateGenderAndAge = (genderAndAge) => {
  return {
    type: ACTION_TYPES.UPDATE_GENDER_AND_AGE_TYPE,
    payload: genderAndAge,
  };
};

export const updateSearchTerms = (searchTerms) => {
  return {
    type: ACTION_TYPES.UPDATE_SEARCH_TERMS,
    payload: searchTerms,
  };
};

export const updateLimitToState = (limitToState) => {
  return {
    type: ACTION_TYPES.UPDATE_LIMIT_TO_STATE,
    payload: limitToState,
  };
};

export const updateProvidersOnsite = (providersOnsite) => {  
  return {
    type: ACTION_TYPES.UPDATE_PROVIDERS_ON_SITE,
    payload: providersOnsite,
  };
};

export const updateSpecialPopulation = (specialPopulation) => {
  return {
    type: ACTION_TYPES.UPDATE_SPECIAL_POPULATION,
    payload: specialPopulation,
  };
};

export const updateLanguagesServed = (languagesServed) => {
  return {
    type: ACTION_TYPES.UPDATE_LANGUAGES_SERVED,
    payload: languagesServed,
  };
};

export const updateStateConfigurations = (stateConfigs) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.UPDATE_STATE_CONFIGURATIONS, payload: stateConfigs });
  } catch (e) {
    //
  }
};

export const updateStateAttributes = (attributes) => {
  return {
    type: ACTION_TYPES.UPDATE_STATE_ATTRIBUTES,
    payload: attributes,
  };
};

// to feach all attributes
export const fetchAttributes = (stateName, taxonomy = "1") => async (dispatch) => {
  try {
    const response = await fetchStateAttributes(stateName, taxonomy);
    dispatch(updateStateAttributes(response));
    return response;
  } catch (e) {
    return null;
  }
};
