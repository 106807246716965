import React from "react";
import { Helmet } from "react-helmet";

export default () => {
    return (
        <div className="edu_container">
        <Helmet>
          <title>What is MAT for Opioid Use Disorder - Treatment Connection</title>
          <meta name="description" content="An effective MAT program can help reduce cravings and lowers the risk of relapse and overdose. Learn the types of medications used in MAT programs." data-react-helmet="true"></meta>
        </Helmet>

            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1 table-col-container">
                    <h1 className="header-font">What is MAT for opioid use disorder</h1>

                    <p>Medication-assisted treatment (MAT) is the use of medications, in combination with counseling and
                        behavioral therapies, to provide a whole patient approach to the treatment of substance use
                        disorders. An effective MAT program can help the individual lead meaningful family, work and
                        social activities, reduces cravings and lowers the risk of relapse and overdose.</p>

                    <div className="table-container">
                    <table>
                        <thead>
                        <tr>
                            <td>Medication</td>
                            <td>Administration</td>
                            <td>Health</td>
                            <td>Starting and stopping</td>
                            <td>Possible side effects</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Methadone</td>
                            <td>
                                <ul>
                                    <li>Under the supervision of a physician and dispensed from regulated clinics</li>
                                    <li>Pill and liquid forms</li>
                                    <li>Taken once daily</li>
                                    <li>Lessens the symptoms of opiate withdrawal and blocks the euphoric effects of
                                        opiates
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Approved for women who are pregnant or breastfeeding**</li>
                                    <li>Do not take methadone with any other medication before checking with your doctor
                                        or health care provider
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Can be started immediately</li>
                                    <li>Safe for long term use as maintenance therapy</li>
                                    <li>Length of program should be a minimum of 12 months and can last years*</li>
                                    <li>Gradually reduce dosage or possibility of withdrawal symptoms when stopped</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Difficulty or shallow breathing</li>
                                    <li>Feel lightheaded or faint</li>
                                    <li>Experience hives or a rash; swelling of the face, lips, tongue, or throat</li>
                                    <li>Chest pain</li>
                                    <li>Experience a fast or pounding heartbeat</li>
                                    <li>Hallucinations or confusion</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Buprenorphine (Suboxone, Subutex)</td>
                            <td>
                                <ul>
                                    <li>Can be provided at a variety of settings</li>
                                    <li>Film or pill form</li>
                                    <li>Taken daily</li>
                                    <li>Lessens withdrawal and blocks euphoric effects of opiates</li>
                                    <li>Less abuse potential than methadone</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Do not take other medications without first consulting your doctor</li>
                                    <li>Do ensure that a physician monitors any liver-related health issues that you
                                        mayhave
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Must wait 12-24 hours after last use before beginning program</li>
                                    <li>Safe for long term use</li>
                                    <li>Recommended to gradually reduce dosage or will experience withdrawal symptoms
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Nausea, vomiting, and constipation</li>
                                    <li>Muscle aches and cramps</li>
                                    <li>Cravings</li>
                                    <li>Inability to sleep</li>
                                    <li>Distress and irritability</li>
                                    <li>Fever</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Naltrexone</td>
                            <td>
                                <ul>
                                    <li>Administered in pill or injectable form</li>
                                    <li>Long acting injectable is effective for 30 days</li>
                                    <li>Blocks opiates from having an effect on the brain and helps stop cravings</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Lowered tolerance to opioids increases overdose risk if relapse occurs</li>
                                    <li>Liver damage is possible at high doses, must consult with a physician</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Must wait 7-10 days after last use before beginning program.</li>
                                    <li>Length of treatment should be individualized</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Upset stomach or vomiting</li>
                                    <li>Diarrhea</li>
                                    <li>Headache</li>
                                    <li>Nervousness</li>
                                    <li>Sleep problems/tiredness</li>
                                    <li>Joint or muscle pain</li>
                                    <li>Liver injury</li>
                                    <li>Injection site reactions</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <p>Information obtained from SAMHSA website <a
                        href="https://www.samhsa.gov/medication-assisted-treatment"
                        target="_blank">https://www.samhsa.gov/medication-assisted-treatment/</a></p>
                    <p>*According to the National Institute on Drug Abuse publication Principles of Drug Addiction
                        Treatment: A Research-Based Guide – 2012</p>
                    <p>**Methadone Treatment for Pregnant Women – 2009.</p>
                </div>
            </div>
        </div>
    )
}
