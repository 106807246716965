import React from "react";
import {Link, Redirect as RouterRedirect, Route, Switch} from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../layout/header";
import Footer from "../layout/footer";
import QuestionsToAsk from "./educational_materials/QuestionsToAsk";
import TreatmentsAvailable from "./educational_materials/TreatmentsAvailable";
import StagesOfRelapse from "./educational_materials/StagesOfRelapse";
import BreakingThroughStigma from "./educational_materials/BreakingThroughStigma";
import WhatIsMat from "./educational_materials/WhatIsMat";
import TreatmentSettings from "./educational_materials/TreatmentSettings";
import QualityTreatmentSud from "./educational_materials/QualityTreatmentSud";
import HelpingMentalHealthSud from "./educational_materials/HelpingMentalHealthSud";
import WarningSigns from "./educational_materials/WarningSigns";
import MentalHealthCrisis from "./educational_materials/MentalHealthCrisis";
import Suicide from "./educational_materials/Suicide";
import PreparingMentalHealthCrisis from "./educational_materials/PreparingMentalHealthCrisis";
import CrisisPlan from "./educational_materials/CrisisPlan";
import {appUrls} from "../../appUrls";

const pdfMaterials = {
    'questions-about-treatment': "/educational_materials/OpenBeds_Questions_to_Ask.pdf",
    'treatments_available': "/educational_materials/OpenBeds_Treatments Available.pdf",
    'stages-of-relapse': "/educational_materials/OpenBeds_Stages_Relapse.pdf",
    'stigma-of-addiction': "/educational_materials/OpenBeds_Stigma.pdf",
    'what-is-mat': "/educational_materials/OpenBeds_WhatisMAT.pdf",
    "treatment-settings": "/educational_materials/OpenBeds_Treatment_Settings.pdf",
    "quality-treatment-sud": "/educational_materials/OpenBeds_Quality_Treatment_Sud.pdf",
    "helping-mental-health-sud": "/educational_materials/OpenBeds_Helping_Loved_Ones_Sud.pdf",
    "warning-signs": "/educational_materials/OpenBeds_Warning_Signs.pdf",
    "mental-health-crisis": "/educational_materials/OpenBeds_Mental_Health_Crisis.pdf",
    "suicide": "/educational_materials/OpenBeds_Suicide.pdf",
    "preparing-mental-health-crisis": "/educational_materials/OpenBeds_Preparing_Mental_Health_Crisis.pdf",
    "crisis-plan": "/educational_materials/OpenBeds_Crisis_Plan.pdf",
};

const redirects = [
    {from: "/questions_to_ask", to: appUrls.RESOURCE_PAGES.QUESTIONS_ABOUT_TREATMENT},
    {from: "/stages_of_relapse", to: appUrls.RESOURCE_PAGES.STAGES_OF_RELAPSE},
    {from: "/breaking_through_stigma", to: appUrls.RESOURCE_PAGES.STIGMA_OF_ADDICTION},
    {from: "/what_is_mat", to: appUrls.RESOURCE_PAGES.WHAT_IS_MAT},
];

export default class Resources extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        /*document.title = 'Educational Material - Treatment Connection';
        document.querySelector('meta[name="description"]').setAttribute("content", "Looking for substance use or mental health treatment? Learn about, search for and express interest in treatment anonymously through our state vetted network of treatment providers.");
    */}

    render() {
        return (
            <React.Fragment>
                <Helmet>
                  <title>Treatment Education Resources - Treatment Connection</title>
                  <meta name="description" content="Educational resources to help you better understand how to navigate finding suitable substance use or mental health treatment and more." data-react-helmet="true"></meta>
                </Helmet>
                <Header/>
                <div className="container" style={{minHeight: 540}} role="main">
                    <span id="main-content" tabIndex="-1"></span>
                    <div className="row educational_materials_header">
                        <div className="col-md-12">
                            <div className="pull-right">
                                {this.renderPrintButton()}
                                {/* <button onClick={this.props.history.goBack} className="btn btn-primary no-print">
                                    Back
                                </button> */}
                                <img src={"/images/treatmentconnection_logo_header.png"} className="visible-print-block"
                                     style={{height: 40}} alt="Treatment Connection" />
                            </div>
                        </div>
                    </div>
                    <br className="no-print"/>
                    <Switch>
                        <Route exact={true} path={this.props.match.path + appUrls.RESOURCE_PAGES.QUESTIONS_ABOUT_TREATMENT}
                               component={QuestionsToAsk}/>
                        <Route exact={true} path={this.props.match.path + "/treatments_available"}
                               component={TreatmentsAvailable}/>
                        <Route exact={true} path={this.props.match.path + appUrls.RESOURCE_PAGES.STAGES_OF_RELAPSE}
                               component={StagesOfRelapse}/>
                        <Route exact={true} path={this.props.match.path + appUrls.RESOURCE_PAGES.STIGMA_OF_ADDICTION}
                               component={BreakingThroughStigma}/>
                        <Route exact={true} path={this.props.match.path + appUrls.RESOURCE_PAGES.WHAT_IS_MAT} component={WhatIsMat}/>

                        {/* Mental health subnav */}
                        <Route exact={true} path={this.props.match.path + "/treatment-settings"}
                               component={TreatmentSettings}/>
                        <Route exact={true} path={this.props.match.path + "/quality-treatment-sud"}
                               component={QualityTreatmentSud}/>
                        <Route exact={true} path={this.props.match.path + "/helping-mental-health-sud"}
                               component={HelpingMentalHealthSud}/>
                        <Route exact={true} path={this.props.match.path + "/warning-signs"}
                               component={WarningSigns}/>
                        <Route exact={true} path={this.props.match.path + "/mental-health-crisis"}
                               component={MentalHealthCrisis}/>
                        <Route exact={true} path={this.props.match.path + "/suicide"}
                               component={Suicide}/>
                        <Route exact={true} path={this.props.match.path + "/preparing-mental-health-crisis"}
                               component={PreparingMentalHealthCrisis}/>
                        <Route exact={true} path={this.props.match.path + "/crisis-plan"}
                               component={CrisisPlan}/>

                        {redirects.map(this.renderRedirect)}

                        <Route render={this.renderDefault}/>
                    </Switch>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }

    renderDefault() {
        return (
            <div className="row privacy_policy_panel" id="patient_help">
                <div className="col-md-push-2 col-md-8">
                    <div className="panel panel-default">

                    <div className="education-block">
                      <img className="education-icon" src="images/education_icon.png" alt=""/>
                      <h1>Educational Resources</h1>
                    </div>
                    <div className="panel-body">
                      <div className="education-body">
                        <p>Resources and documents to help you look for suitable treatment</p>
                        <hr />
                        <h2 className="h4">Substance Use Disorder Treatment</h2>
                        <ul className="educational_links">
                            <li className="btn education-links">
                                <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                <Link to={appUrls.RESOURCE_BASE + appUrls.RESOURCE_PAGES.QUESTIONS_ABOUT_TREATMENT}>
                                Questions to ask about treatment
                            </Link></li>
                            {/* <li><Link to=appUrls.RESOURCE_BASE + "/treatments_available">
                                Treatments Available
                            </Link></li> */}
                            <li className="btn education-links">
                                <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                <Link to={appUrls.RESOURCE_BASE + appUrls.RESOURCE_PAGES.STAGES_OF_RELAPSE}>
                                Stages of relapse
                            </Link></li>
                            <li className="btn education-links">
                                <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                <Link to={appUrls.RESOURCE_BASE + appUrls.RESOURCE_PAGES.STIGMA_OF_ADDICTION}>
                                Breaking through the stigma of addiction
                            </Link></li>
                            <li className="btn education-links">
                                 <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                <Link to={appUrls.RESOURCE_BASE + "/quality-treatment-sud"}>
                                    Finding Quality Treatment for Substance Use Disorders
                                </Link>
                            </li>
                            <li className="btn education-links">
                                 <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                <Link to={appUrls.RESOURCE_BASE + appUrls.RESOURCE_PAGES.WHAT_IS_MAT}>
                                What is MAT for opioid use disorder
                            </Link></li>

                          </ul>
                          <hr />
                          <h2 className="h4">Mental Health Treatment</h2>
                          <ul className="educational_links">
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/treatment-settings"}>
                                      Understanding the Different Types of Treatment Settings
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/helping-mental-health-sud"}>
                                      Helping a Loved One Dealing with Mental and/or Substance Use Disorders
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/warning-signs"}>
                                      Warning Signs of a Mental Health Crisis or Suicide
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/mental-health-crisis"}>
                                      What to do in a Mental Health Crisis
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/suicide"}>
                                      What to do if You Suspect Someone is Thinking about Suicide
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/preparing-mental-health-crisis"}>
                                      Preparing for a Mental Health Crisis
                                  </Link>
                              </li>
                              <li className="btn education-links">
                                   <span className="education-links-decorative-arrow" 
                                aria-hidden="true">
                                    {'>'}
                                </span>
                                  <Link to={appUrls.RESOURCE_BASE + "/crisis-plan"}>
                                      Creating a Mental Health Crisis Plan
                                  </Link>
                              </li>
                          </ul>
                        </div>

                  </div>
                </div>
              </div>
          </div>
        )
    }
    renderButton() {
        if (this.props.match.params.id && this.props.match.params.id in pdfMaterials) {
            return (

                <button onClick={this.props.history.goBack} className="btn btn-primary no-print"   style={{marginLeft: 55}}>
                Back
            </button>

            )
        }
        return null;
    }
    renderPrintButton() {
        if (this.props.match.params.id && this.props.match.params.id in pdfMaterials) {
            return (
                <a
                    href={pdfMaterials[this.props.match.params.id]}
                    className="btn btn-primary no-print"
                    style={{marginRight: 55}}
                    target={"_blank"}
                    rel="noopener noreferrer"
                >
                    Print
                </a>
            )
        }
        return null;
    }

    renderRedirect = (definition, id) => {
        const paths = appUrls.RESOURCE_BASE + "" + definition.from;
        return (
            <Route
                path={paths}
                key={id}
                exact
                render={() => <RouterRedirect to={appUrls.RESOURCE_BASE + definition.to}/>}
            />
        )
    }

}
