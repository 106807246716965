import React from "react";
import { components } from "react-select";

const Option = (props) => {
  /* Setting a few accessibility options. In order to get them to show up,
      we need to add them to the props.innerProps object. */
  props.innerProps[`aria-selected`] = props.isSelected ? "true" : null;
  props.innerProps[`data-selected-id`] = props.isFocused
    ? props.innerProps.id
    : null;
  props.innerProps["role"] = "option";

  return (
    <components.Option {...props}>
      {props.children}
      {props.isSelected ? <span className="sr-only">. selected</span> : null}
    </components.Option>
  );
};

export default Option;
