import React from "react";
import {Button, Modal} from "react-bootstrap";

export function SubmittedInfoModal(props) {
    const {
        show,
        patientInfo,
        onClose
    } = props;

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Submitted Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered table-comment-field">
                    <tbody>
                    <tr>
                        <td width="40%"><strong>Confidential ID</strong></td>
                        <td>{patientInfo.patient_id}</td>
                    </tr>
                    <tr>
                        <td><strong>Are you completing an application for yourself or a loved one?</strong></td>
                        <td>
                            {
                                patientInfo.completing_for === 'loved_one' ?
                                    <td>Loved One</td>
                                    :
                                    <td>Self</td>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Birth Year</strong></td>
                        <td>{patientInfo.birth_year}</td>
                    </tr>
                    <tr>
                        <td><strong>How long have you been using substance?</strong></td>
                        <td>{patientInfo.no_of_years_using_substances} Years</td>
                    </tr>
                    <tr>
                        <td><strong>Which substances are you using?</strong></td>
                        <td>{patientInfo.substances_using}</td>
                    </tr>
                    <tr>
                        <td><strong>What treatment have you had?</strong></td>
                        <td>{(patientInfo.service_type_done)}</td>
                    </tr>
                    <tr>
                        <td><strong>Are you seeking specific treatment?</strong></td>
                        <td>{(patientInfo.service_type_seeking)}</td>
                    </tr>
                    <tr>
                        <td><strong>Do you have Insurance?</strong></td>
                        <td>{patientInfo.is_insurance === "Y" ? 'Yes' : 'No'}</td>
                    </tr>
                    {patientInfo.is_insurance === "Y" ?
                        <tr>
                            <td><strong>What insurance do you have?</strong></td>
                            <td>{patientInfo.insurance_name}</td>
                        </tr>
                        : false}
                    <tr>
                        <td><strong>Any Additional Information?</strong></td>
                        <td className="rrmComment">{patientInfo.comment}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
