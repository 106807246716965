const updateNotificationRoles = () => {
    let allNotificationElements = document.querySelectorAll('.notification-container');
    let i;
    for (i = 0; i < allNotificationElements.length; i++) {
        let element = allNotificationElements[i];
        element.setAttribute('role', 'alert');
        element.setAttribute('aria-live', 'assertive');
    }
};

export default updateNotificationRoles;