import React from "react";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import "../../css/components/forms/input.css";
import "../../css/components/forms/password.css";
import classNames from "classnames";

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordIsVisible: false,
      placeholderValue: props.placeholderValue ? props.placeholderValue : null,
      updatePasswordValue: props.updatePasswordValue
        ? props.updatePasswordValue
        : null,
    };

    this.randomIdValue = "";
    this.errorMessageRandomIdValue = "";
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  componentDidMount = () => {
    this.randomIdValue = "password-field_" + Math.floor(Math.random() * 12);
    this.errorMessageRandomIdValue = this.randomIdValue + "_error-message";
  };

  togglePasswordVisibility = () => {
    this.setState({ passwordIsVisible: !this.state.passwordIsVisible });
  };

  noOpHandler = () => false;

  determinePropValue = (prop, fallBackValue) => {
    return prop ? prop : fallBackValue;
  };

  determinePropValueIfBoolean = (prop, initialValue, fallBackValue) => {
    return prop ? initialValue : fallBackValue;
  };

  render() {
    return (
      <>
        <label
          id={this.randomIdValue + "_label"}
          htmlFor={this.randomIdValue}
          className={
            this.props.hiddenLabel ? "sr-only" : "password-group-label"
          }
        >
          {this.props.labelText ? this.props.labelText : "Password"}
          {this.props.hiddenLabel ? '(required)' : ''}
        </label>
        <div
          className={classNames({
            "password-group": this.props.hideKeyIcon,
            "input-group": !this.props.hideKeyIcon,
            mt0: true,
          })}
        >
          {!this.props.hideKeyIcon ? (
            <span className="input-group-addon" aria-hidden="true">
              <i className="fa fa-key"></i>{" "}
            </span>
          ) : (
            ""
          )}

          <input
            id={this.randomIdValue}
            name="password"
            placeholder={this.determinePropValue(
              this.props.placeholderValue,
              null
            )}
            onChange={this.determinePropValue(
              this.props.updatePasswordValue,
              null
            )}
            value={this.props.passwordValue}
            onDrop={this.noOpHandler}
            onCopy={this.noOpHandler}
            onPaste={this.noOpHandler}
            autoComplete= {this.props.autoComplete ? this.props.autoComplete : "off"}
            className="form-control withPlaceholder"
            aria-describedby={
              this.errorMessageRandomIdValue +
              " " +
              this.determinePropValue(this.props.ariaDescribedByIds, "")
            }
            aria-labelledby={this.randomIdValue + "_label"}
            aria-invalid={this.determinePropValue(
              this.props.passwordHasError,
              false
            )}
            aria-required="true"
            type={this.state.passwordIsVisible ? "text" : "password"}
          />

          <div className="show-hide-password-component input-group-addon">
            <button
              aria-describedby={this.randomIdValue}
              type="button"
              aria-pressed={this.state.passwordIsVisible ? true : false}
              onClick={this.togglePasswordVisibility}
            >
              {this.state.passwordIsVisible === false ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
              <span className="sr-only">
                Hide/show your password field input
              </span>
            </button>
          </div>
        </div>
        {this.props.passwordHasError === true ? (
          <span className="error" id={this.errorMessageRandomIdValue}>
            {this.determinePropValue(
              this.props.passwordErrorMessage,
              "Password is required"
            )}
          </span>
        ) : (
          false
        )}
      </>
    );
  }
}

export default PasswordField;
