import React from 'react';
import {Button, MenuItem, Modal} from "react-bootstrap";
import {connect} from "react-redux";

class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    handleClose = () => {
        this.setState({ show: false});
    }

    render() {
      return (
          <React.Fragment>
          <footer className="text-center footer no-print">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p aria-label="trademark">Copyright &copy; {new Date().getFullYear()} <span className="text-blue">OpenBeds<sup>®</sup></span></p>
                        <ul className='list-inline'>
                            <li className='list-inline-item'><a className="btn btn-link" href="https://bamboohealth.com/privacy-policy/" target="_blank" rel="noopener noreferrer" aria-label="Privacy Policy">Privacy Policy</a></li>
                            <li className='list-inline-item'><a className="btn btn-link" href="https://bamboohealth.com/cookie-notice/" target="_blank" rel="noopener noreferrer" aria-label="Cookie Notice">Cookie Notice</a></li>
                            <li className='list-inline-item'><a className="btn btn-link" href="/sitemap" aria-label="Site map">Sitemap</a></li>
                            <li className='list-inline-item'><a className="btn btn-link" href="https://bamboohealth.com/terms-and-conditions-of-use/" target="_blank" rel="noopener noreferrer" aria-label="Usage Policy">Usage Policy</a></li>
                            { this.props.accountSelector == true ?
                            <li className='list-inline-item'><a className="btn btn-primary" style={{color:'white', padding:'6px 12px'}} href="/account_selector" aria-label="Switch Accounts">Switch Accounts</a></li>
                                : null }
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
      </React.Fragment>

      );
    }
  }
    const mapStateToProps=(state)=>{
        //  console.log(state)
        return {
            accountSelector: state.accountSelector
        }
    }

  export default connect(mapStateToProps)(Footer);
