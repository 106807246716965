import React from "react";
import { Helmet } from "react-helmet";

export default () => {
    return (
        <div className="edu_container">
        <Helmet>
          <title>Breaking the Stigma of Addiction - Treatment Connection</title>
          <meta name="description" content="Stigma is often associated with those who have a substance use or addiction problem. Learn what you can do to break through the stigma of addiction." data-react-helmet="true"></meta>
        </Helmet>

            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1 table-col-container">
                    <h1 className="header-font">Breaking through the stigma of addiction</h1>

                    <p>According to the Meriam Webster Dictionary stigma is defined as a mark of shame or discredit.
                        There is a belief that
                        individuals who abuse drugs or alcohol, are weak, morally flawed; that they chose to be
                        addicted.</p>

                    <h4>This belief is false.</h4>
                    <div>The shame and guilt associated with the stigma is one of the main reasons individuals with a
                        substance use disorder do
                        not seek treatment.
                    </div>
                    <h4>Addiction is a chronic disease that changes both brain structure and function.</h4>
                    <div>When you use drugs, the parts of your brain that control pleasure, motivation, learning and
                        memory are affected. Large
                        amounts of dopamine are released which create a feeling of immediate pleasure. This information
                        is stored in the
                        areas responsible for learning and memory which influences your decision making, emotions and
                        motivation. Your
                        brain begins to rely on the drugs to produce the dopamine and once it wears off, there is a
                        shortage. You are left
                        feeling unhappy and uninterested in the things you once enjoyed. With continued use, the
                        pleasure from taking the
                        substance may diminish, called tolerance, but since the changes in learning and memory have
                        already occurred, the
                        individual will continue to seek out the substance. Despite these changes, treatment can and
                        does help people to
                        “unlearn their addictions while developing healthy coping strategies”. (2)
                    </div>
                    <h4>How can you breakthrough the stigma of addiction?</h4>
                    <div>
                        <p>
                            <span style={{textDecoration: "underline"}}>Get help.</span> Don’t let the shame and guilt
                            created by the stigma of addiction win. Stand up for
                            your own health and wellbeing. Do no let the worry of what other people are thinking get in
                            your way to recovery. Use your resources; speak to loved ones and professionals to help find
                            a treatment program that works for you.</p>
                        <p><span style={{textDecoration: "underline"}}>Break the silence.</span> Talk about your
                            addiction, your struggles, your successes. Be open and
                            honest about your journey. Each individual story is unique but remember you are not alone.
                            By sharing with others, you create a sense of community which aids in the healing and
                            recovery process.</p>
                        <p><span style={{textDecoration: "underline"}}>Self-advocacy.</span> You are the expert on
                            yourself. Examine your life and determine what needs to
                            change in order to achieve sobriety. Be an active participant in treatment and develop a
                            strategy to achieve your goals. Speak up and ask
                            for what you need.</p>
                        <p><span style={{textDecoration: "underline"}}>Watch your language.</span> You are not defined
                            by your addiction. Person-first language is a term
                            used by medical and mental health professionals to describe separating the person from the
                            illness; not defining them by the illness. Look at the difference between these two
                            statements, “I’m just a junkie” and “I have a heroin addiction”. The latter implies that
                            there is more to you than your addiction. You can also pay attention to how you speak about
                            yourself by using language that emphasizes positive attributes and avoiding negative
                            self-talk.</p>
                    </div>
                    <p style={{textWeight: "bold"}}>By breaking through the stigma of addiction, your self-esteem and
                        self-worth will grow. And others will be able to recognize your value and see beyond your
                        addiction.
                    </p>
                    <p style={{textWeight: "bold"}}>Sources:</p>
                    <ol className="ol-sources">
                        <li><a href={"https://www.merriam-webster.com/dictionary/stigma"} target={"_blank"}>
                            https://www.merriam-webster.com/dictionary/stigma.
                        </a></li>
                        <li>
                            <a
                                href={"https://www.health.harvard.edu/newsletter_article/how-addiction-hijacks-the-brain"}
                                target={"_blank"}>
                                https://www.health.harvard.edu/newsletter_article/how-addiction-hijacks-the-brain
                            </a>
                        </li>
                        <li>
                            <a
                                href={"https://obamawhitehouse.archives.gov/sites/whitehouse.gov/files/images/Memo%20-%20Changing%20Federal%20Terminology%20Regrading%20Substance%20Use%20and%20Substance%20Use%20Disorders.pdf"}
                                target={"_blank"}>
                                The Office of National Drug Control Policy. Changing the language of addiction.
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
