import {openbedsApiClient} from '../../openbedsApi';

const csrfUrl = "/csrfAjax";

let csrfLifetime = 60; // time in seconds

class CsrfMiddleware {
    constructor() {
        this.clearPromise();
        this.setStartEndTime();
    }

    clearPromise() {
        this.csrfPromise = null;
        this.setStartEndTime();
    }

    setStartEndTime() {
        this.endTime = new Date();
        this.endTime.setSeconds(this.endTime.getSeconds() + csrfLifetime);
    }

    async makeRequest(config, next, retry) {
        let csrf = this.csrfPromise;

        if (csrf === null) {
            // request csrf token if not available
            csrf = this.requestCsrf();
        }

        // wait until csrf is available
        const csrfResponse = await csrf;

        const tokenName = csrfResponse.data['csrfName'];
        const tokenValue = csrfResponse.data['csrfHash'];

        config = this.injectCsrfToken(config, tokenName, tokenValue);

        return await next(config);
    };

    check(config, next) {
        if ((new Date()).getTime() >= this.endTime.getTime()) {
            this.clearPromise();
        }

        const url = config.url.substring(config.baseURL.length);
        if (url === csrfUrl) {
            // intercept csrf requests and reuse them
            if (this.csrfPromise === null) {
                this.csrfPromise = next(config);
            }

            return this.csrfPromise;
        }

        // Technically GET requests don't require CSRF token, but we were running into race condition
        // synchronising first request helps
        if (-1 === ["post", "put", "get"].indexOf(config.method)) {
            // csrf not needed
            return next(config);
        }

        return this.makeRequest(config, next, true);
    };

    injectCsrfToken(config, tokenName, tokenValue) {
        const data = config.data;
        if (null === data || undefined === data) {
            config.data = new FormData();
            config.data.append(tokenName, tokenValue);
        } else if (data instanceof FormData) {
            if (data.has && data.has(tokenValue)) {
                return config;
            }
            data.append(tokenName, tokenValue);
        } else if (typeof data === "string") {
            if (data.indexOf(encodeURIComponent(tokenName) + "=") === 0 ||
                data.indexOf("&" + encodeURIComponent(tokenName) + "=") !== -1) {
                return config;
            }
            const val = encodeURIComponent(tokenName) + "=" + encodeURIComponent(tokenValue);
            config.data = data + (data.length > 0 ? "&" : "") + val;
        }
        return config;
    };

    requestCsrf() {
        // Use an api client that isn't using the csrf middleware so that we don't cause it to infinitely recursively
        // refresh csrf tokens each time we need to refresh the csrf token
        return openbedsApiClient().get(csrfUrl);
    };
}

let middleware = new CsrfMiddleware();

export default middleware;
