import React from "react";
import { Helmet } from "react-helmet";
import Header from "./layout/header";
import Footer from "./layout/footer";

const PageNotFound = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Page Not Found - Treatment Connection</title>
            </Helmet>
            <Header />
            <div className="container mt-20 mb-20">
                <div className="row ">
                    <div className="col-md-push-4 col-md-4 text-center">
                        <h1 className="home-header">404 Error</h1>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-push-4 col-md-4 text-center">
                        <h1 className="home-header">Page Not Found</h1>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
 
export default PageNotFound;