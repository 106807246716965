import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Understanding the Types of Treatment Settings - Treatment Connection');
    useMetaDescription('Treatment is not one size fits all. Learn about the different treatment settings and what to expect from each to help reduce confusion and stress.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Understanding the Different Types of Treatment Settings</h1>

                    <div>
                        Treatment is not a one size fits all approach. Where you go for mental health care treatment depends on an individual’s situation and recovery needs. Knowing where to look and what to expect can help reduce confusion and stress. There are many types of mental health care providers, including psychologists, psychiatrists, psychiatric or mental health nurses, social workers, and counselors. The National Alliance for Mental Illness offers this list of typical treatment settings and what to expect from each.
                    </div>

                    <h2>Private practice</h2>
                    <p>A professional in private practice may offer individual, family, and group therapy sessions. These can be held in an office or a community setting. Meeting weekly, bi-weekly or monthly with a care provider can provide a patient better understanding of relationships, feelings, behaviors, and how to manage symptoms and reduce the risk of relapse.</p>

                    <h2>Community or county mental health centers</h2>
                    <p>A community or county mental health care center often provides public mental health care services when a referral to a private doctor or therapist is not possible. Staffing may include psychiatrists, psychologists, social workers, counselors, and peer support specialists. Typical services include outpatient services, medication management, case management services, intensive community treatment, and referrals for employment, day program services, residential treatment services, therapeutic residential services and supportive residential services. Most of the people getting services from a community or county mental health care center receive Social Security disability benefits and rely on Medicaid to fund their treatment needs.</p>

                    <h2>Emergency rooms</h2>
                    <p>When it isn't possible to get treatment from a mental health center or private doctor, or a situation escalates into an emergency and safety is a concern, a visit to an emergency room might be the only option. Situations that might require a trip to the emergency room include a suicide attempt; an assault or threatening actions against another person; hearing voices, paranoia, or confusion, and drugs or alcohol escalating a person’s mental health issue. If you are calling 911, be sure to tell the operator that it is a "mental health emergency" and ask for emergency responders with Crisis Intervention Team (CIT) training. Many first responders will approach a mental health situation differently, if they know what to expect.</p>

                    <h2>Hospitalization</h2>
                    <p>There may be times when a person is admitted to the hospital for intensive treatment. Private psychiatric hospitals, general hospitals with a psychiatric floor, or state psychiatric hospitals provide intensive mental health treatment. This can involve observation, diagnosis, changing or adjusting medications, ECT treatments, stabilization, and correcting a harmful living situation. If a person and their doctor agree that inpatient treatment is a good idea they will be admitted on a voluntary basis. If a person is refuses to accept treatment, involuntary hospitalization is an option. The legal standard for an involuntary hospitalization requires that a person be considered a “danger to self or others.” This type of hospitalization usually results in a short stay of up to 3 days but can occasionally last a week or so longer.</p>

                    <h2>Partial hospitalization or day hospitalization</h2>
                    <p>Partial hospitalization provides care and monitoring for a person who may be having acute psychotic symptoms without being a danger to self or others. It allows a person to return home at night and is much less disruptive. It can also be used as a transition from inpatient hospital care before a complete return home.</p>

                    <h2>Substance abuse centers</h2>
                    <p>Some people with mental health conditions also have substance abuse concerns. The most widely used form of treatment is with integrated intervention. With this treatment a person receives care for both a specific mental illness and substance abuse, such as a detoxification facility, acute residential treatment, or intensive outpatient programs.</p>
                    <p>Learn more at: <a rel="noreferrer" target="_blank" href="http://www.nami.org/Learn-More/Treatment/Treatment-Settings">http://www.nami.org/Learn-More/Treatment/Treatment-Settings</a>.</p>
                </div>
            </div>
        </div>
    )
}
