import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { change } from "redux-form";

let asynctypeahead = null;

const OrganizationSearchKeyPress = (e, thisForm, organizationResults) => {
  if (e.keyCode === 13 && e.target.value !== "") {
    OrganizationSearchBlur(e, thisForm, organizationResults);
  }
};

const OrganizationSearchBlur = (e, thisForm, organizationResults) => {
  let organizationInformation = {};
  let organizationExist = false;
  let disablefields = true;

  if (e.target.value !== "") {
    organizationResults.forEach((organization) => {
      if (organization.information === undefined) {
        return;
      }
      if (
        organization.label === e.target.value ||
        organization.label ===
          e.target.value + "-" + organization.information.zip
      ) {
        organizationExist = true;
        organizationInformation = organization.information;
      }
    });

    if (organizationExist === true) {
      let organization_name = e.target.value.replace(
        "-" + organizationInformation.zip,
        ""
      );

      asynctypeahead.getInstance().clear();
      asynctypeahead.getInstance().setState({ text: organization_name });

      thisForm.props.dispatch(
        change("register_user", "organization_name", organization_name)
      );
      thisForm.props.dispatch(
        change(
          "register_user",
          "organization_address1",
          organizationInformation.address_line1
        )
      );
      thisForm.props.dispatch(
        change(
          "register_user",
          "organization_address2",
          organizationInformation.address_line2
        )
      );
      thisForm.props.dispatch(
        change(
          "register_user",
          "organization_website_url",
          organizationInformation.org_url
        )
      );
      thisForm.props.dispatch(
        change(
          "register_user",
          "organization_phone",
          organizationInformation.phone_number
        )
      );
      thisForm.props.dispatch(
        change("register_user", "city", organizationInformation.city)
      );
      thisForm.props.dispatch(
        change("register_user", "statevalue", organizationInformation.state)
      );
      thisForm.props.dispatch(
        change("register_user", "zipcode", organizationInformation.zip)
      );
      thisForm.props.asyncValidate();
      thisForm.props.changedisableState(disablefields);
    } else {
      if (
        window.confirm("Are you sure you want to create a new organization?")
      ) {
        disablefields = false;
      } else {
        disablefields = true;
      }
      thisForm.props.dispatch(
        change("register_user", "organization_name", e.target.value)
      );
      thisForm.props.dispatch(
        change("register_user", "organization_address1", "")
      );
      thisForm.props.dispatch(
        change("register_user", "organization_address2", "")
      );
      thisForm.props.dispatch(
        change("register_user", "organization_website_url", "")
      );
      thisForm.props.dispatch(
        change("register_user", "organization_phone", "")
      );
      thisForm.props.dispatch(change("register_user", "city", ""));
      thisForm.props.dispatch(change("register_user", "statevalue", ""));
      thisForm.props.dispatch(change("register_user", "zipcode", ""));
      thisForm.props.changedisableState(disablefields);
    }
  }
};

const OrganizationSearch = ({ name, ...props }) => {
  const thisForm = props.thisForm;

  let keyDown = OrganizationSearchKeyPress;

  if (
    props.onKeyDownOverride !== undefined &&
    props.onKeyDownOverride === true
  ) {
    keyDown = props.onKeyDown;
  }

  return (
    <AsyncTypeahead
      id="organization_search"
      {...props}
      defaultInputValue={typeof props.value === "string" ? props.value : ""}
      onBlur={(e) =>
        OrganizationSearchBlur(e, thisForm, props.organizationSearch)
      }
      onKeyDown={(e) => keyDown(e, thisForm, props.organizationSearch)}
      isLoading={false}
      filterBy={(e) => {
        if (e.id === -1) {
          return false;
        } else {
          return true;
        }
      }}
      onSearch={(term) => {
        props.searchForOrganization(term);
      }}
      options={props.organizationSearch}
      ref={(typeahead) => (asynctypeahead = typeahead)}
      minLength={3}
    />
  );
};

export default OrganizationSearch;
