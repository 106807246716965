import 'regenerator-runtime/runtime';
import React from 'react';
import { BrowserRouter, Route, Redirect as RouterRedirect, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { CookiesProvider } from 'react-cookie';
import Search from './search/search';
import SearchList from './searchlist';
import Login from './login/login';
import Rrs from './RRS/rrs';
import PatientSignup from './login/patientsignup';
import PatientMyProfile from './login/patientmyprofile';
import ForgotPassword from './login/forgotpassword';
import ResetPassword from './login/validateToken';
import ContactMe from './login/contactme';
import AccountSelector from './login/accountSelector';
import ProviderSignup from './login/providersignup';
import Redirect from './login/redirect';
import PatientNeedHelp from './pages/patientneedhelp';
import ProviderNeedHelp from './pages/providerneedhelp';
import ProviderHelp from './pages/providerhelp';
import About from './pages/about';
import ProviderMyProfile from './login/providermyprofile';
import Resources from './pages/resources';
import Assessment from './pages/assessment';
import LinkExpired from './login/LinkExpired';
import AccountDeleted from './login/AccountDeleted';
import SiteMap from './sitemap';
// import SimpleMap from './simplemap'
import './css/bootstrap-glyphicons.css';
import './css/font-awesome.min.css';
import './css/bootstrap.min.css';
import './css/bootstrap-overrides.css';
import './css/bootstrap4-helper-classes.css';
import './css/components/buttons.css';
import './css/main.css';
import './css/style.css';
import './css/rdt.css';
import GoogleAnalytics from '../utils/googleAnalytics';
import { appUrls } from '../appUrls';
import { backendUrl } from '../apiurl';
import FacilityDetails from "./searchlist/FacilityDetails";
import validateUser from "./provider/validateUser";
import PageNotFound from "./PageNotFound";

const App = () => {
    return (
        <React.Fragment>
            <BrowserRouter>
                <React.Fragment>
                    <Switch>
                        <Route path="/" exact component={Search}></Route>
                        <Route path='/sitemap.xml' component={() => {
                            window.location.href = backendUrl + '/api/getProvidersSitemap';
                            return null;
                        }}/>
                        <Route path='/sitemap:page.xml' component={(props) => {
                            window.location.href = backendUrl + '/api/getProvidersSitemap/' + props.match.params.page;
                            return null;
                        }}/>
                        <Route path={appUrls.SEARCH_RESULT_LIST} exact component={SearchList} />
                        <Route path={appUrls.FACILITY_DETAILS + '/:state/:organization/:service_id'} exact component={FacilityDetails} />
                        <Route path="/list" exact render={redirectToSearchResultList} />
                        <Route path={appUrls.USER_LOGIN} exact component={Login} provider={false} />
                        <Route path={appUrls.USER_LOGIN + '/:token'} exact={true} component={Login} provider={false} />
                        <Route
                            path="/login"
                            exact
                            provider={false}
                            render={() => {
                                return <RouterRedirect to={appUrls.USER_LOGIN} />;
                            }}
                        />
                        <Route
                            path="/login/:token"
                            exact={true}
                            provider={false}
                            render={(props) => {
                                return <RouterRedirect to={appUrls.USER_LOGIN + '/' + props.match.params.token} />;
                            }}
                        />
                        <Route path="/account_selector" exact component={AccountSelector}></Route>
                        <Route
                            path="/register_user/validateUser/:id"
                            exact
                            component={validateUser}
                            provider={true}
                        ></Route>
                        <Route path="/patients/rrs" exact component={Rrs}></Route>
                        <Route path="/patients/register" exact component={PatientSignup}></Route>
                        <Route path="/patients/myprofile" exact component={PatientMyProfile}></Route>
                        <Route path={appUrls.FORGOT_PASSWORD} exact component={ForgotPassword}></Route>
                        <Route path="/changepass/:id" exact component={ResetPassword}></Route>
                        <Route
                            path="/auto_reset_link/:id"
                            exact
                            render={(props) => (
                                <ForgotPassword {...props} autoReset={true}>
                                    {' '}
                                </ForgotPassword>
                            )}
                        ></Route>

                        <Route path="/contactme" exact component={ContactMe}></Route>

                        <Route path="/providersignup" exact component={ProviderSignup}></Route>
                        <Route path="/provider/myprofile" exact component={ProviderMyProfile}></Route>
                        <Route path="/redirect" component={Redirect}></Route>
                        <Route path="/patients/need_help" component={PatientNeedHelp}></Route>
                        <Route path="/provider/need_help" component={ProviderNeedHelp}></Route>
                        <Route path="/provider/help" component={ProviderHelp}></Route>
                        <Route path="/about" component={About}></Route>
                        <Route path="/assessment" component={Assessment}></Route>
                        <Route path={appUrls.RESOURCE_BASE + '/:id?'} component={Resources}></Route>
                        <Route
                            path={'/resources_and_educational_materials/:id?'}
                            render={(props) => {
                                return <RouterRedirect to={appUrls.RESOURCE_BASE + '/' + props.match.params.id} />;
                            }}
                        />
                        <Route path="/link-expired" exact={true} component={LinkExpired} />
                        <Route path="/account-deleted" exact={true} component={AccountDeleted} />
                        <Route
                            path="/sitemap"
                            exact={true}
                            render={(props) => {
                                return <SiteMap />;
                            }}
                        />
                        <Route component={PageNotFound} />
                    </Switch>

                    <CookiesProvider>
                        <GoogleAnalytics />
                    </CookiesProvider>
                </React.Fragment>
            </BrowserRouter>
            <NotificationContainer />
        </React.Fragment>
    );
};

const redirectToSearchResultList = () => {
    return <RouterRedirect to={appUrls.SEARCH_RESULT_LIST} />;
};

export default App;
