import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Creating a Crisis Plan - Treatment Connection');
    useMetaDescription('A crisis plan addresses symptoms and behaviors to help prepare you for a crisis. Check out this infographic from NAMI on what should be in a crisis plan.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Creating a Mental Health Crisis Plan</h1>
                    <p>A crisis plan is developed by the person with the mental health condition and their support team and is designed to address symptoms and behaviors and help prepare for a crisis. Every plan is unique and should be updated whenever there is a change in diagnosis, medication, treatment, or providers.</p>
                    <p>
                        In the infographic below, NAMI has outlined a list of things that should be included in a crisis plan. You can also obtain a sample crisis plan at&nbsp;
                        <a rel="noreferrer" target="_blank" href="https://www.nami.org">www.nami.org</a>.
                    </p>

                    <div className="infographic-wrapper">
                        <object className="infographic" type="application/pdf" data="/educational_materials/OpenBeds_Crisis_Plan.pdf#zoom=100&scrollbar=0&toolbar=0&navpanes=0&view=Fit">
                            <a href="/educational_materials/OpenBeds_Crisis_Plan.pdf">
                                <img src="/images/infographics/crisis-plan.png" alt="A crisis plan addresses syptoms and behaviors to help prepare you for a crisis. Check out this infographic from NAMI on what should be in a crisis plan." />
                            </a>
                        </object>
                    </div>
                </div>
            </div>
        </div>
    )
}
