const about_openbeds_routes_and_route_names = [
  {
    url: "",
    page_name: "About Open Beds",
    category_heading: "true",
  },
  {
    url: "/",
    page_name: "Home",
  },
  {
    url: "/search-treatment-facilities",
    page_name: "Search Treatment Facilities",
  },
  {
    url: "/#about",
    page_name: "About",
  },
  {
    url: "/assessment",
    page_name: "Assessment",
  },
];

export default about_openbeds_routes_and_route_names;
