import React from "react";
import { components } from "react-select";

class MenuList extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.toggleMenuOpenStatus();
    }
    componentWillUnmount() {
        this.props.toggleMenuOpenStatus();
    }
    render() {
        return (
            <components.MenuList {...this.props}>
                <div role="listbox" className="multi_select_list" id={this.props.id}>
                    {this.props.children}
                </div>
            </components.MenuList>
        );
    }
};

export default MenuList;
   
