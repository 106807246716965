import React from "react";
import {connect} from "react-redux";
import Header from "../layout/header";
import Footer from "../layout/footer";

class About extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount(){
         this.props.history.push('/#about');       
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="container" role="main">
                    <div className="row educational_materials_header">
                        <div className="col-md-12">
                            <div className="pull-right"><button onClick={this.props.history.goBack} className="btn btn-primary">Back</button></div>
                        </div>
                    </div>
                    <div className="row educational_materials_header">
                        <div className="col-md-12">
                            <div className="tableheading1"><span className="fa-2x">About Treatment Connection</span></div>
                        </div>
                    </div>
                    <div className="row educational_materials">
                        <div className="col-md-push-2 col-md-8">
                            <div className="panel panel-default">
                                <div className="panel-body">

                                    <p>Placeholder Page for About Treatment Connection Website</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default connect()(About);