const setupSpacebarRoleButtonClickability = () => {
    let allSpaceElements = document.querySelectorAll('a[role="button"]');
    let i;
    for (i = 0; i < allSpaceElements.length; i++) {
        let element = allSpaceElements[i];
        allSpaceElements[i].addEventListener('keydown', (e) => { 
            let key = e.key;
            let whichKey = e.which;
            if (key === " " || whichKey === 32 || key === "Enter") {
                e.preventDefault();
                element.click();
            }
        });
    }
};

export default setupSpacebarRoleButtonClickability;