import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Finding Quality Treatment for Substance Use Disorder - Treatment Connection');
    useMetaDescription('Finding treatment for substance use disorder can be overwheling and confusing. See what you need to do to access care and find quality treatment.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Finding Quality Treatment for Substance Use Disorders</h1>

                    <div>
                        It can be overwhelming and confusing to find treatment for an alcohol or drug addiction. Effective treatment must be tailored to the needs of the individual. The Substance Abuse Mental Health Services Administration (SAMHSA) offers these steps to complete prior to utilizing a treatment center and the five signs of a quality treatment center.
                    </div>

                    <h2>Three steps to accessing care</h2>
                    <ol>
                        <li>
                            <p>
                                <em>If you have insurance:</em> Contact your insurer. Ask about your coverage and whether they have a network of preferred providers for you to use.
                            </p>
                            <p>
                                <em>If you don't have insurance:</em> Each state has funding to provide treatment for people without insurance coverage. Find where to call for information about payment for services at:&nbsp;
                                <a rel="noreferrer" target="_blank" href="https://samhsa.gov/sites/default/files/ssa-directory.pdf">samhsa.gov/sites/default/files/ssa-directory.pdf</a>
                            </p>
                        </li>
                        <li>
                            <p>Review the websites of the providers and see if they have the five signs of quality treatment detailed below.</p>
                        </li>
                        <li>
                            <p>Call for an appointment. If they can’t see you or your family member within 48 hours, find another provider. One indicator of quality is the ability to get an appointment quickly. Many programs offer walk-in services. Look for programs that can get you or a family member into treatment quickly.</p>
                        </li>
                    </ol>

                    <h2>Five signs of quality treatment</h2>
                    <ol>
                        <li>
                            <p>
                                <strong>Accreditation:</strong> Has the program been licensed or certified by the state? Is the program currently in good standing in the state? Are the staff qualified? Good quality programs will have a good inspection record and both the program, and the staff should have received training in treatment of substance use and mental disorders and be licensed or registered in the state. Does the program conduct satisfaction surveys? Can they show you how people using their services have rated them?
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Medication:</strong> Does the program offer FDA approved medication for recovery from alcohol and opioid use disorders? At this point in time, there are no FDA approved medications to help to prevent relapse from other problem substances.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Evidence-based practices:</strong> Does the program offer treatments that have been proven to be effective in treating substance use disorders including medication management therapies, such as motivational therapy, cognitive behavioral therapy, drug and alcohol counseling, education about the risks of drug and alcohol use, and peer support? Does the program either provide or help to obtain medical care for physical health issues?
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Families:</strong> Does the program include family members in the treatment process? Family members have an important role in understanding the impact of addiction on families and providing support.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Supports:</strong> Does the program provide ongoing treatment and supports beyond just treating the substance issues? For many people addiction is a chronic condition and requires ongoing medication and supports. Quality programs provide treatment for the long term which may include ongoing counseling or recovery coaching and support and helps in meeting other basic needs like sober housing, employment supports, and continued family involvement.
                            </p>
                        </li>
                    </ol>

                    <p>For more details, visit <a rel="noreferrer" target="_blank" href="https://blog.samhsa.gov/2019/01/23/struggling-with-addiction-tips-on-finding-quality-treatment">https://blog.samhsa.gov/2019/01/23/struggling-with-addiction-tips-on-finding-quality-treatment</a>.</p>
                </div>
            </div>
        </div>
    )
}
