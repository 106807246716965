import React from "react";
import ReactDOM from 'react-dom/client';
import {connect} from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../layout/header";
import Footer from "../layout/footer";
import AccessibleSelect from '../accessibility-helpers/accessible-select';
import {triageUrl} from './../../apiurl'
import openbedsApi from "../../openbedsApi";
import '../css/assessment.css';
import {NotificationManager} from "react-notifications";
import { getConfigurations } from "../../actions/selectattributes";
import { LocationField } from "../SearchFields";

class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multi: <div>Substances loading...</div>,
            isResultPage: false,
            zipCode: null,
            showZipError: false,
            location: {},
        }

        this.validZips = [];

        this.props.getConfigurations();
    }

    componentDidMount() {
        /*document.title = 'Educational Material - Treatment Connection';
        document.querySelector('meta[name="description"]').setAttribute("content", "Looking for substance use or mental health treatment? Learn about, search for and express interest in treatment anonymously through our state vetted network of treatment providers.");
    */
        const script = document.createElement("script");
        script.src = triageUrl + "/assets/assessment.tool.js";
        script.async = true;
        script.onload = () => {
            let canvas = document.getElementById( 'canvas' );
            window.AssessmentTool.setCanvas( canvas );
            let postheaders = {
                'Content-Type':'application/x-www-form-urlencoded',
                'Accept':'application/json, text/plain'
            }
            openbedsApi.get(triageUrl + '/pfsGenerateJWT',null,{headers: postheaders})
                .then( (response) => {
                    //   console.log(response)
                    if(response.data.status==="ERROR" || response.data.status==="Failure"){

                    }else if(response.data.status==="SUCCESS"){
                        window.AssessmentTool.setRenderCallback(this.insertMultiSelect);
                        window.AssessmentTool.setSubmitCallback(this.displayPostAssessmentCta);
                        window.AssessmentTool.setApiToken( response.data.data );
                        window.AssessmentTool.render( 's' );
                    }
                })
                .catch(function (error) {
                }.bind(this));

        };
        document.body.appendChild(script);
    }

    insertMultiSelect = ( response ) => {
        if (response.status == "ERROR"){
            NotificationManager.error('', response.data + " Please reload the page.");
        } else {
            //render multiselect
            window.ChunkTool.setUseCustomSelectValidation(true);
            var options = [];
            var optionNodeList = document.querySelectorAll(".triage-u6s51o-multi-select option");
            optionNodeList.forEach(option => {
                options.push({value: option.value, label: option.label});
            })
            var newSubstance = document.createElement("div");
            document.querySelector(".triage-u6s51o-multi-select").parentNode.insertBefore(newSubstance, document.querySelector(".triage-u6s51o-multi-select"));

            var multi = <AccessibleSelect
                options={options} 
                className="searchinput" 
                placeholder="Substances" 
                isMulti
                value={this.optionValue()} onChange={(o) => {
                window.ChunkTool.setNumOptionsChecked(o.length);
                    this.updateOptions(o);
                }}
            />;
            this.setState({multi: multi, isResultPage: false});
            this.validZips = response.valid_zip_codes;
            const root = ReactDOM.createRoot(newSubstance);
            root.render(multi);
        }
    }

    displayPostAssessmentCta = response => {
        this.setState({ isResultPage: true });
    }

    optionValue = () =>
    {
        //we dont want to refill the substances on browser back
        //let substanceArray = this.props.substances;
        //return substanceArray
    }


    updateOptions = (selectedOption) => {
        var optionNodeList = document.querySelectorAll(".triage-u6s51o-multi-select option");
        optionNodeList.forEach( option => {
            option.selected = false;
        })
        //console.log(selectedOption);
        //loop through selectedOption and find if any choices are 15, 16, or 17
        //if they are 15,16,17 then props.history.push to the self_assessment_retake page
        for (var option in selectedOption) {
            document.querySelector(".triage-u6s51o-multi-select option[value='"+ selectedOption[option].value +"']").selected = true;
            if ( selectedOption[option].value == '15' ||
                selectedOption[option].value == '16' ||
                selectedOption[option].value == '17' ){
                window.AssessmentTool.submitAssessment('s', false, function () {
                    window.ChunkTool.logProgress(window.ChunkTool.logTypes.EARLY);
                });
            }
        }
    }

    handleFindTreatmentButton = () => {
        const searchLocation = this.state.location
        sessionStorage.setItem('lastSearchDetails', JSON.stringify({searchLocation}));

        const urlBase = this.getTreatmentConnectionUrl();
        window.open(`${urlBase}/search-treatment-facilities`, '_blank');
    };

    getTreatmentConnectionUrl = () => {
        const loc = window.location;
        const port = loc.port ? `:${loc.port}` : '';

        return `${loc.protocol}//${loc.hostname}${port}`;
    }

    updateLocation = (location) => {
        this.setState({ location: location });
    }

    isValidZipCode = zipCode => /^[0-9]{5}$/.test(zipCode);

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Addiction Treatment Needs Assessment - Treatment Connection</title>
                </Helmet>

                <Header/>
                <div className="row privacy_policy_panel" id="patient_help" role="main">
                    <div className="assessment-need-help col-md-2">
                        <h3>Need help finding treatment?</h3>
                        <p className="assessment-need-help-cta">
                            <span>Call</span>
                            <a href="tel:1-833-275-2043">(833) 275-2043</a>
                        </p>
                        <p>for support</p>
                        Hours:{" "}
                        <span>
                          {this.props.pfsConfigurations
                            ? this.props.pfsConfigurations
                                .customer_support_hours
                            : null}
                        </span>
                    </div>
                    <div className="col-md-8">
                        <div className="canvas" id="canvas">Assessment Loading...</div>
                    </div>

                    {this.state.isResultPage &&
                        <div className="col-md-push-2 col-md-8 post-assessment-cta">
                            <div className="triage-u6s51o-navigation form-inline">
                                <div className="form-group" style={{ verticalAlign: 'initial' }}>
                                    <LocationField 
                                        id="post-assessment-cta-input"
                                        className="form-control post-assessment-cta-input" 
                                        name="location"
                                        label="For help finding treatment provider, please enter your zip code"
                                        // label={null}
                                        handleUpdate={this.updateLocation}
                                        required={true} 
                                    />
                                </div>
                                <button type="button" onClick={this.handleFindTreatmentButton}>Find Treatment</button>
                            </div>
                            <div className="inline-help">
                                Search for a location (ZIP Code, County or State) within the Treatment Connection network. This service is available in Delaware, Ohio, Nevada, New Mexico, North Dakota, Maine, Massachusetts, Washington.
                            </div>
                        </div>
                    }
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps=(state)=>{
    return {
        pfsConfigurations: state.pfsConfigurations,
        location: state.location
    }
}
export default connect(mapStateToProps, {getConfigurations})(Assessment);