import openbedsApi from '../openbedsApi';
import qs from 'querystring';

export const submitLogin =  (email,password) =>  async dispatch => {
    const params = {
        "headers": {
            'Accept': 'application/json'
        }
    };

    let details =  {email:email,password:password}

    let formBody = [];
    for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const response =  await openbedsApi.post('/login',
        formBody, params
    )
    dispatch ({type:'FETCH_LOGIN',payload:response.data})
}

export const cognitoLogin = (response) => async dispatch => {
    dispatch ({type:'FETCH_LOGIN', payload:response.data})
}

export const displayAccountSelector = (response) => async dispatch => {
    dispatch ({type:'ACCOUNT_SELECTOR', payload:response})
}

export const submitLoginSecondFactor = (code, loginToken) => async dispatch => {
    const params = {
        "headers": {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain'
        }
    };
    const formData = new FormData();
    formData.append("code", code);
    formData.append("token", loginToken);
    const response = await openbedsApi.post("/login_second_factor", formData, params);
    dispatch({type: 'FETCH_LOGIN', payload: response.data})
}

export const userLoggedIn = (data) => async dispatch => {
    dispatch ({type:'FETCH_LOGIN', payload: data})
}

export const Logout = () => async dispatch => {
    if (!sessionStorage.userDetails) {
        return;
    }

    const userDetails = JSON.parse(sessionStorage.userDetails);

    const postHeaders = {
        'access-token': userDetails.access_token,
        'Content-Type':'application/x-www-form-urlencoded',
        'Accept':'application/json, text/plain'
    };

    const details = {};

    openbedsApi.post('/logout', details, { headers: postHeaders })
        .then(function (response) {
            dispatch({
                type: 'FETCH_LOGOUT',
                payload: {
                    requestStatus: 'SUCCESS',
                    data: response.data
                }
            })
        });
};

export const sendRequest =  (hash,session) =>  async dispatch => {
    let details = {
        organization_id: sessionStorage.getItem("org_id"),
        organization_uuid: sessionStorage.getItem("org_uuid") || sessionStorage.getItem("org_id"),
        organization_services_id: sessionStorage.getItem("organization_services_id"),
        service_id: sessionStorage.getItem("service_uuid"),
        target_state: sessionStorage.getItem("state"),
    }

    let formBody = [];
    for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let userDetails = JSON.parse(sessionStorage.userDetails)

    var headers = {
        'access-token':userDetails.access_token,
        'Content-Type':'application/x-www-form-urlencoded',
        'Accept':'application/json, text/plain'
    };

    const res = await openbedsApi.post('/send_request', formBody, {headers});

    dispatch ({type:'SEND_REQUEST',payload:res.data})
}

export const clearRequestdata = () => {

    return({
        type:'UPDATE_REQUEST',
        payload:{status: "", message: ""}
    })
}

export const patientProfileDetails = (hash, session) => async dispatch => {
    const userDetails = JSON.parse(sessionStorage.userDetails);

    const headers = {
        'access-token': userDetails.access_token,
        'Content-Type':'application/x-www-form-urlencoded',
        'Accept':'application/json, text/plain'
    };

    openbedsApi.post('/patient_profile_details', null, { headers })
        .then(function (response) {
            dispatch({
                type: 'PATIENT_PROFILE_DETAILS',
                payload: response.data
            });
        });
};

export const patientRrsInfo =  (hash,session) =>  async dispatch => {
    let userDetails = JSON.parse(sessionStorage.userDetails);

    const headers = {
        'access-token':userDetails.access_token,
        'Content-Type':'application/x-www-form-urlencoded',
        'Accept':'application/json, text/plain'
    };

    const res = await openbedsApi.post('/patient_rrs_info', null, {headers});

    dispatch ({type:'PATIENT_RRS_INFO',payload:res.data})
};

export const searchForOrganization = (query) => async dispatch => {
    const details = {
        'search_query': query
    }

    openbedsApi.post('/organization_search', qs.stringify(details)).then(function (response) {
            dispatch({
                type:'ORGANIZATION_SEARCH',
                payload: response.data
            });
    });
};

export const changedisableState = (status) => {
    return({
        type:'DISABLE_STATUS',
        payload:status
    })
}




export const rrsPatientInfo = (details) => async dispatch => {
    if (sessionStorage.userDetails) {
        const userDetails = JSON.parse(sessionStorage.userDetails)

        const postHeaders = {
            'access-token': userDetails.access_token,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain'
        };

        openbedsApi.post('/rrs_i_button_out_net', qs.stringify(details), { headers: postHeaders })
            .then(function (response) {
                dispatch({
                    type: 'RRS_PATIENT_INFO',
                    payload: response.data
                });
            });
    }
};

