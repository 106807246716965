let apiurl;
let triageurl;
let backendurl;
let tmp_env;

const hostname = window && window.location && window.location.hostname;
const pfs_env = hostname.split('.')[0];

if (process.env.REACT_APP_API_URL || hostname.includes('localhost')) {
    apiurl = process.env.REACT_APP_API_URL || 'http://localhost:8020/index.php/v1.0/apis';
    triageurl = process.env.REACT_APP_TRIAGE_URL || 'http://localhost:8020/assessment';
    tmp_env = 'local';
} else {
    switch (true) {
        case /dev\.hbu\.co/.test(hostname):
            apiurl = 'https://pfs-api.ob.dev.hbu.co/index.php/v1.0/apis';
            triageurl = 'https://pfs-api.ob.dev.hbu.co/assessment';
            tmp_env = 'dev';
            break;

        case /qa\.hbu\.co/.test(hostname):
            apiurl = 'https://' + pfs_env + '-api.ob.qa.hbu.co/index.php/v1.0/apis';
            triageurl = 'https://' + pfs_env + '-api.ob.qa.hbu.co/assessment';
            tmp_env = 'qa';
            break;

        case /prep\.openbeds\.net/.test(hostname):
            apiurl = 'https://pfs-api.prep.openbeds.net/index.php/v1.0/apis';
            triageurl = 'https://pfs-api.prep.openbeds.net/assessment';
            tmp_env = 'prep';
            break;

        default:
            apiurl = 'https://api.treatmentconnection.com/index.php/v1.0/apis';
            triageurl = 'https://api.treatmentconnection.com/assessment';
            tmp_env = 'prod';
            break;
    }
}

backendurl = apiurl.replace('/index.php/v1.0/apis', '');

export const apiUrl = apiurl,
    triageUrl = triageurl,
    backendUrl = backendurl,
    env = tmp_env;

