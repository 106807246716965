/*
    Accessibility Helper for the react-bootstrap popover (tooltip) widget.
    ----

    RATIONAL:
    Tooltips are designed for small amounts of content. 
    OPENBEDS uses tooltips both for small amounts and large amounts of content.
    When there is significant content that requires the use of the overflow/scrolling nature of our tooltips,
    it is necessary that a keyboard users can scroll through the content, while
    preserving the focus state on the tooltip's trigger button. 
    This helper function utilizes `.scrollTop()` to artificially mimic a browser's default scroll rate.

    USE:
    This helper is designed to work as an `onKeyDown` event, attached to the 
    trigger button for the tooltip. The function expects two parameters:
    - the event
    - the overlay component

    EXAMPLE:
    A typical implementation should look something like:
    `onKeyDown={ e => handleOverlayScroll(e, overlayComponentName) }`

    and a full example:
    <OverlayTrigger trigger={['focus','hover']} overlay={passwordhelpToppopover}>
        <button 
            aria-disabled="true" 
            aria-describedby={passwordhelpToppopover.props.id} 
            onKeyDown={ e => handleOverlayScroll(e, passwordhelpToppopover) }
        >
            <span className="sr-only">{label}, additional information</span>
        </button>
    </OverlayTrigger>
*/
const handleOverlayScroll = (e, element) => {
    let eventKey = e.key || e.keyCode;
    let getTheCurrentElementPopover = element.props.id;
    let theCurrentPopover;

    if (getTheCurrentElementPopover) {
        theCurrentPopover = document.getElementById(getTheCurrentElementPopover);

        if (! theCurrentPopover) {
            return;
        }

        var theTopEdgeOfThePopover = theCurrentPopover.getBoundingClientRect().top;
        if (theTopEdgeOfThePopover && theTopEdgeOfThePopover < 0) {
            theCurrentPopover.scrollIntoView();
        }
    }

    if (! theCurrentPopover) {
        return;
    }

    if ( eventKey === "ArrowDown" ) {
        e.preventDefault();
        theCurrentPopover.scrollTop += 10;
        return;
    }

    if ( eventKey === "ArrowUp" ) {
        e.preventDefault();
        theCurrentPopover.scrollTop -= 10;
        return;
    }
}

export default handleOverlayScroll;