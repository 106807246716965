import React from 'react';
import AccessibleSelect from '../accessibility-helpers/accessible-select'
import { Helmet } from "react-helmet";
import Footer from './../layout/footer'
import { connect } from 'react-redux'
import {
    fetchPosts,
    updateDistance,
    updatePayment, updateSubstances, updateTreatment,
    updateZipcode,
    getStates
} from './../../actions/selectattributes'
import {searchForOrganization, changedisableState} from './../../actions/login'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { SubmissionError } from "redux-form";
import OrganizationForm from "../Forms/OrganizationForm";
import openbedsApi from "../../openbedsApi";

sessionStorage.contactInfo = ""

class ProviderSignup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            loadStates: false,
            statevalue: '',
            csrf: {},
            contact: "",
            orgnameOptions: [],
            disablefields: true,
            selectedOrg: '',
            selectedOrgZip: '',
            showLoader: false,
            orgStatus: false,
            organizationSearch: [],
        }
    }

    componentDidMount() {
        this.props.getStates();
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Provider Signup - Treatment Connection</title>
                </Helmet>
                <div>
                    <div className="container" role="main">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-right col-sm-12"><p><a href="/provider/help"
                                                                            className="btn">Help?</a></p></div>
                                <h3 className="text-center"><a href="/"><img
                                    src="/images/treatmentconnection_logo_header.png" className="loginImg"/></a></h3>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <span className="heading-txt">Registration Form</span>
                                        <span className="pull-right">
                                        <button onClick={this.props.history.goBack}
                                                className="btn btn-primary">Back</button>
                        			</span>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="red text-left fs25">
                                            If you are creating an account for an organization and service that already
                                            exists,
                                        </div>
                                        <div className="red text-left fs25 btm">
                                            please contact your Administrator to add you to the account. Do not create a
                                            new account here.
                                        </div>
                                        <OrganizationForm superAdmin={false} systemAdmin={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NotificationContainer/>
                    <Footer/>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
        organizationSearch: state.organizationSearch,
        states: state.states,
        // disableStatus: state.disableStatus,
       disableStatus: false

    }
}

export default connect(
    mapStateToProps,
    {
        fetchPosts,
        updateZipcode,
        updateDistance,
        updatePayment,
        updateTreatment,
        updateSubstances,
        searchForOrganization,
        changedisableState,
        getStates
    }
)(ProviderSignup);