import { useState, useCallback, useEffect } from 'react';
import   { useDispatch, useSelector } from 'react-redux';
import shallowEqual from 'react-redux/es/utils/shallowEqual';
import {
    updateSubstances,
    updateTreatment,
    updateTreatmentType,
    updateSeekingTreatment,
    updateAdditionalServices,
    updatePayment,
    updateSearchTerms,
    updateLimitToState,
    updateDistance,
    updateZipcode,
    updateProvidersOnsite,
    updateGenderAndAge,
    updateSpecialPopulation,
    updateLanguagesServed,
    updateLocation,
    updateStateAttributes,
} from '../actions/selectattributes';
  import * as ATTRIBUTES from '../actions/constants';

/**
 * Dispatches an action based on the state value type that exists in the redux store
 * It helps to reduce redundancy and importing state values code
 *
 * @param {string} valueType - The type of state value.
 * @param {any} newValue - The new value to update.
 * @param {function} dispatch - The dispatch function.
 * @return {void}
 */
export const dispatchAttribute = (valueType, newValue, dispatch) => {
  if(newValue === undefined) return;
  switch (valueType) {
    case 'attributes':
    case 'stateAttributes':
      return dispatch(updateStateAttributes(newValue));

    case 'facility':
    case 'searchTerms':
      return dispatch(updateSearchTerms(newValue));
      
    case 'limitToState':
      return dispatch(updateLimitToState(newValue));
      
    case 'distance':
      return dispatch(updateDistance(newValue));
      
    case 'zipCode':
    case 'zipcode':
    case 'zip_code':
      return dispatch(updateZipcode(newValue));
      
    case 'location':
    case 'searchLocation':
      return dispatch(updateLocation(newValue));
      
    case ATTRIBUTES.SUBSTANCES.TYPE:
      return dispatch(updateSubstances(newValue));
      
    case ATTRIBUTES.SEEKING_TREATMENT.TYPE:
      const additionalServices = newValue?.filter((item) => item?.type === 'additional') || []
      dispatch(updateAdditionalServices(additionalServices));
      const treatmentType = newValue?.filter((item) => item?.type !== 'additional') || []
      dispatch(updateTreatmentType(treatmentType));
      return dispatch(updateSeekingTreatment(newValue));
      
    case ATTRIBUTES.TREATMENT_TYPE.TYPE:
      return dispatch(updateTreatmentType(newValue));

    case ATTRIBUTES.TREATMENT.TYPE:
      return dispatch(updateTreatment(newValue));
      
    case ATTRIBUTES.ADDITIONAL_SERVICES.TYPE:
      return dispatch(updateAdditionalServices(newValue));
      
    case ATTRIBUTES.PAYMENT_TYPE.TYPE:
      return dispatch(updatePayment(newValue));
      
    case ATTRIBUTES.PROVIDERS_ONSITE.TYPE:
      return dispatch(updateProvidersOnsite(newValue));
      
    case ATTRIBUTES.GENDER_AND_AGE.TYPE:
      return dispatch(updateGenderAndAge(newValue));
      
    case ATTRIBUTES.SPECIAL_POPULATION.TYPE:
      return dispatch(updateSpecialPopulation(newValue));
      
    case ATTRIBUTES.LANGUAGES_SERVED.TYPE:
      return dispatch(updateLanguagesServed(newValue));
      
    default:
      console.warn(`Unknown redux state type: ${valueType}`);
      
  }
  return () => null;
};

/**
 * Generates a custom hook that manages a stored state value and initializes it if it doesn't already exist in the global redux store.
 * This hook is used to set up state locally and globally and provide means to synchronize local state with its counterpart in the redux store.
 * The hook returns an array containing the state value value, a function to set a new state value locally, and a function to handle state value changes in redux.
 *
 * @param {string} valueType - The type of state value to manage.
 * @param {any} initialValue - The initial value to initialize the state value.
 * @param {function} validator - An optional validator function to validate the state value.
 * @return {array} An array containing the state value value, a function to set a new state value, and a function to handle state value changes.
 */
export const useStoredValues = (valueType, initialValue, validator = null) => {
  const dispatch = useDispatch();
  // Get the stored value from the global state
  const storedValue = useSelector((state) => state[valueType]);

  // Set the initial value to the stored value if it exists or the initial value
  const [value, setValue] = useState(storedValue || initialValue);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && !shallowEqual(value, storedValue)) {
      dispatchAttribute(valueType, value, dispatch);
      setInitialized(true);
    }
  }, [initialized, valueType, value, storedValue, dispatch]);

  useEffect(() => {
    if (validator ? validator(storedValue) : true) {
      setValue(storedValue || initialValue);
    }
  }, [storedValue, validator, initialValue]);

  const localHandler = useCallback((input) => {
    value !== input && setValue(input);
  }, [value]);

  const globalHandler = useCallback((input) => {
    const newAttribute = input?.target?.value || input;
    if (validator ? validator(newAttribute) : true) {
      if (newAttribute !== storedValue) {
        dispatchAttribute(valueType, newAttribute, dispatch);
        setValue(newAttribute);
      }
    }
  }, [storedValue, valueType, validator, dispatch]);

  return [value, localHandler, globalHandler];
};

export default useStoredValues;