import React from "react";
import simulateKeyboardSelectionClick from "../simulate-keyboard-selection-click";
import screenReaderAnnouncement from "../../accessibility-helpers/screenReaderAnnouncement";

const setUniqueIdentifier = Math.floor(Math.random() * 10000);
const uniqueMultivalueRemove = "multi_value_remove_" + setUniqueIdentifier;

class MultiValueRemove extends React.Component {
  constructor(props) {
    super();
    this.state = {
      relativeOptionText: null,
    };
    this.innerText = React.createRef();
  }

  addMultiValueRemoveAssistiveText = (element) => {
    let relativeOptionText = element.parentNode.previousSibling.innerHTML;
    let relativeOptionTextToBeInserted = `<span class="sr-only">${relativeOptionText}</span>`;
    element.insertAdjacentHTML("beforeend", relativeOptionTextToBeInserted);

    this.setState({ relativeOptionText: relativeOptionText });
  };

  componentDidMount() {
    this.addMultiValueRemoveAssistiveText(this.innerText.current);

    setTimeout(() => {
      screenReaderAnnouncement(this.state.relativeOptionText + " selected");
    }, 100);
  }

  componentWillUnmount() {
    screenReaderAnnouncement(this.state.relativeOptionText + " deselected");
  }

  render() {
    return (
      <button
        ref={this.props.innerRef}
        {...this.props.innerProps}
        className={"multi_value_remove " + uniqueMultivalueRemove}
        onKeyDown={simulateKeyboardSelectionClick}
        type="button"
      >
        <span className="sr-only multi_remove_inner_text" ref={this.innerText}>
          Remove{" "}
        </span>
        <span aria-hidden="true">x</span>
      </button>
    );
  }
}

export default MultiValueRemove;
