import React from 'react';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";
import Footer from './../layout/footer'
import {fetchPosts, updateZipcode,updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment} from '../../actions/selectattributes';
import {sendRequest, cognitoLogin, displayAccountSelector, clearRequestdata} from '../../actions/login';
import openbedsApi from './../../openbedsApi';
import Header from "../layout/header";
import {backendUrl} from "../../apiurl";

class AccountSelector extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            accountList: []
        };
      }

    componentDidMount()
    {
        //fetch and update users
        this.fetchAccountList();
    }

    async componentWillMount(){
    }

    fetchAccountList = () => {
        const axiosConfig = {
            baseURL: backendUrl,
            headers: {
                'Accept':'application/json'
            },
            withCredentials: true,
        };
        openbedsApi.get('/login/getAvailableAccounts', axiosConfig)
            .then(async (response) => {
                if (response.data.status == "SUCCESS") {
                    const accounts = JSON.parse(response.data.data);
                    this.setState({accountList: accounts});
                    //enable account selector in sessionStorage and props
                    this.props.displayAccountSelector('true');
                    sessionStorage.setItem('accountSelector', 'true');
                } else {
                    this.props.displayAccountSelector('false');
                    sessionStorage.removeItem('accountSelector');                    
                }

            })
            .catch(function (error) {
                throw Error(error.message);
            });
    };

    //On click of a user -> make a post to backend to signin to this user_id
    handleSelectUser = async (e) => {
        const user_selected = e.target.value;

        let data = new FormData();
        data.append('user_selected', user_selected);

        data.append('pfsEndpoint', "true"); //passing post parameter to tell verify function to give us back pfs redirects

        const axiosConfig = {
            baseURL: backendUrl,
            headers: {
                'Accept':'application/json'
            },
            withCredentials: true,
        };
        openbedsApi.post('/login/select_account',data, axiosConfig)
            .then(async (response) => {
                if(response.data.status === 'SUCCESS') {
                    data = response.data.data;
                    //copied from login/login.js
                    //calls dispatch to set userDetails prop
                    await this.props.cognitoLogin(response.data);
                    //sets userDetails in sessionStorage
                    sessionStorage.userDetails = JSON.stringify(response.data.data);
                    if (response.data.data.type === "Provider" || response.data.data.type === "4a provider" || response.data.data.type === "Admin") {
                        //redirect the user based on the redirectUri returned by verify
                        window.location.href = response.data.data.redirectUri;
                        this.setState({
                            showLoader: false
                        });
                    } //dont think patients will be signing in with cognito for now
                } else {
                    this.setState({
                        showSigningInScreen: false,
                        errorMessage: response.data.data.message
                    })     
                }
            })
            .catch(function (error) {
                throw Error(error.message);
            });
    };

     render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Account Selector - Treatment Connection</title>
                </Helmet>
                <Header/>
                <div className="container" role="main">
                    <div className="row">
                        <div className="col-md-12 loginForm">
                            <section>
                                <div className="container" role="main">
                                    <div className="row">
                                        <div className="col-md-12 heading-sec">
                                            <h1>Account Selection Screen</h1>
                                            <p>Your account allows you to have multiple accounts within the system to use. Please select what account you would like to use.</p>
                                        </div>
                                    </div>
                                    <form className="form-horizontal" autoComplete="off" action="javascript:void(0)" method="post" id="account-selector-form">
                                        <input type="hidden" value="{{ csrf_key() }}" name="csrf-name"/>
                                        <input type="hidden" value="{{ csrf_token() }}" name="csrfToken"/>
                                        <table className="table table-condensed">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Organization
                                                </th>
                                                <th>
                                                    Role
                                                </th>
                                                <th>
                                                    Services
                                                </th>
                                                <th>
                                                    Email Address
                                                </th>
                                                <th width="200">
                                                    Status
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                { this.renderAccountRows() }
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        )
  };

     renderAccountRows = () => {
         let rows = [];
         const accountList = this.state.accountList;
         for(let i = 0; i < accountList.length; i++) {
            rows.push( <tr>
                <td>{accountList[i].organization}</td>
                <td>{accountList[i].role_name}</td>
                <td> { this.renderServiceList(accountList[i].services) }</td>
                <td>{accountList[i].email}</td>
                <td>
                    <b>Status:</b> <br />{ accountList[i].status == '1' ? 'Activated' :  'Not Activated' }<br />
                    <b>Admin Approval:</b>  <br />{ accountList[i].admin_activated == '1' ? 'Accepted' : ( accountList[i].admin_activated == '2' ? 'Rejected'  : 'Pending' )}<br />
                    <b>Deleted:</b> <br /> { accountList[i].is_deleted == '1' ? 'Yes' : 'No' }
                </td>
                <td>
                    { (accountList[i].status == '1'  && accountList[i].admin_activated == '1' && accountList[i].is_deleted == '0') != true ?
                        <button name="user_selected" className="btn btn-primary" value={ accountList[i].user_id } disabled="disabled">Select User</button>
                        :
                        <button name="user_selected" className="btn btn-primary" value={ accountList[i].user_id } onClick={ this.handleSelectUser }>Select User</button>
                    }
                </td>
                </tr> );
         }

         return rows;
     };

     //implement this
     renderServiceList = ( services ) => {
         let rendered = [];
         if (services) {
             for(let i = 0; i < services.length; i++) {
                 rendered.push( <> {services[i].service_name}<br /> </>);
             }
         }
         return rendered;
     }
}

const mapStateToProps=(state)=>{
    return {
        loginDetails:state.loginDetails,
        attributes:state.post,
        treatment:state.treatment,
        substances:state.substances,
        seekingTreatmentType:state.seekingTreatmentType,
        sendRequestMessage:state.sendRequest,
      }
  }

  export default connect(mapStateToProps,{fetchPosts, displayAccountSelector, updateZipcode, cognitoLogin, updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment,sendRequest,clearRequestdata})(AccountSelector);
