import 
    simulatedButtonKeyboardClicks from './simulatedButtonKeyboardClicks'

import updateNotificationRoles from './updateNotificationRoles'

const setupAllAccessibilityHelpers = () => {
    simulatedButtonKeyboardClicks();
    updateNotificationRoles();
}


export default setupAllAccessibilityHelpers