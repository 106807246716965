import "core-js";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { GA_TRACKING_ID } from './configuration/googleAnalytics';
import {googleAnalyticsEvents, ignoreAnalyticsCollection} from "./utils/googleAnalytics";
import GoogleAnalyticsGtag from '@redux-beacon/google-analytics-gtag';
import { createMiddleware } from 'redux-beacon';
import App from './components/app';
import reducer from './reducers';

let combinedMiddleware = [thunk]

if(!ignoreAnalyticsCollection()) {
    const ga = GoogleAnalyticsGtag(GA_TRACKING_ID);
    const googleMiddleware = createMiddleware(googleAnalyticsEvents, ga)
    combinedMiddleware.push(googleMiddleware);
}

let store;
if (process.env.NODE_ENV === 'development') {
    // @see https://github.com/zalmoxisus/redux-devtools-extension
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(reducer, composeEnhancers(applyMiddleware(...combinedMiddleware)));
} else {
    store = createStore(reducer, applyMiddleware(...combinedMiddleware))
}

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);