import { backendUrl } from "./apiurl";
import httpClient from "./openbedsApi";

export const fetchStateAttributes = async (stateObjValue, patientTaxonomy) => {
    const stateObjKey = (stateObjValue.length === 5 && !isNaN(stateObjValue)) ? "zip_code" : "state";
    return httpClient.get("/getStateAttributes", {
        params: {
            [stateObjKey]: stateObjValue,
            patient_taxonomy: patientTaxonomy ? 1 :0
        }
    }).then((response) => {
        return response.data.data;
    }).catch(() => {
        return false;
    })
};

export const fetchConfigurations = async (stateCode) => {
    let url = "/configurations"
    if (stateCode) {
        url += "/" + stateCode;
    }
    return httpClient.get(url).then((response) => {
        return response.data.data || response.data || response;
    }).catch(() => {
        return false;
    })
};

export const fetchContactMethodPatients = async () => { //this field is not currently dependent on zip code
  return httpClient.get("/getContactMethodsPatient", {

  }).then((response) => {
      return response.data.data;
  }).catch(() => {
      return false;
  })
};


export const fetchOrganizationAdmins = async (organizationId, headers) => {
    const formBody = new FormData();
    formBody.append("organization_id", organizationId);
    return httpClient.post('/get_organization_admin_user_list', formBody, {headers: headers})
        .then(function (response) {
            if (response.data.status === "ERROR" || response.data.status === "Failure") {
                return false;
            } else if (response.data.status === "SUCCESS") {
                return response.data.data;
            }

        })
        .catch(function (error) {
            return false;
        });
};

export const isEmailTaken = async (email) => {
    const formBody = new FormData();
    formBody.append("email", email);
    return httpClient.post('/emailExist', formBody)
        .then(function (response) {
            if (response.data.status === "SUCCESS") {
                return response.data.message === 'Email Already Exist';
            }
        })
        .catch(function (error) {
            return null;
        });
};

export const fetchApiKey = () => {
    let data = new FormData();
    data.append('secretKeyName', 'google_maps.api_key');

    let postheaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json, text/plain',
    };
    let parent = this

    const axiosConfig = {
        baseURL: backendUrl,
        headers: {
            Accept: 'application/json',
        },
        withCredentials: true,
    };

    httpClient
        .post('api/get_api_keys', data, axiosConfig)
        .then(function (response) {
            if (response.data.status === 'SUCCESS') {
                parent.setState({ googleApiKey: response.data.key });
            }
        })
        .catch(function (error) {
            throw Error(error.message);
        });
}
