import React from 'react';
import { Helmet } from 'react-helmet';
import { submitLogin, patientRrsInfo, sendRequest, clearRequestdata } from './../../actions/login';
import { fetchPosts } from './../../actions/selectattributes';
import './../css/login.css';
import { Modal, Button, Popover, OverlayTrigger} from 'react-bootstrap';
import { connect } from 'react-redux';
import Header from './../layout/header';
import Footer from './../layout/footer';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import openbedsApi from './../../openbedsApi';
import { NotificationManager } from 'react-notifications';
import queryString from 'query-string';
import { Pagination } from 'react-bootstrap';
import { SubmittedInfoModal } from './SubmittedInfoModal';
import { fetchStateAttributes } from '../../apiClient';
import { appUrls } from '../../appUrls';
import screenReaderAnnouncement from '../accessibility-helpers/screenReaderAnnouncement';

const requestPopOver = (
    <Popover id="rrs-contact-providers" aria-live>
        <p>Click the previous button to contact other providers</p>
    </Popover>
);

class Rrs extends React.Component {
    constructor(props, context) {
        super(props, context);
        const values = queryString.parse(this.props.location.search);
        this.handleShow = this.handleShow.bind(this);
        this.confirmRequest = this.confirmRequest.bind(this);

        this.state = {
            icon: false,
            iconTime: false,
            limit: 25,
            total_records: 0,
            currentPage: 1,
            data: [],
            submittedInfoModalData: null,
            showLoaderScr: true,
            showLoader: false,
            toolTipOpen: false,
            target: null,
            //   currentPage:1,
            new_enquiry: values.new_enquiry ? true : false,
            stateAttributes: {},
            ariaSortByTime: 'none',
            ariaSortById: 'none',
            showOverlay: 'false',
        };

        this.overlayTriggerButton = React.createRef();
        this.target = React.createRef();
        this.requestOverlayRef = React.createRef();
    }

    goback = () => {
        this.props.history.push(appUrls.SEARCH_RESULT_LIST);
    };

    totalRecordCount = () => {
        return this.props.patientRrs && this.props.patientRrs.total_records ? +this.props.patientRrs.total_records : 0;
    };

    getRecords = (total_records) => {
        let firstElem = 0;
        if (total_records !== 0) {
            firstElem = (this.state.currentPage - 1) * this.state.limit + 1;

            if (firstElem <= 0) {
                firstElem = 0;
            }
        }

        let secElem = firstElem + this.state.limit - 1;
        let finalElem = secElem > total_records ? total_records : secElem;
        return `${firstElem} - ${finalElem}`;
    };
    gotopage = () => {
        /* @todo implement this */
    };
    getPagination = () => {
        let numberofPages = 0;
        if (this.state.selectedTab === 1) {
            numberofPages = Math.ceil(this.totalRecordCount() / this.state.limit);
        } else {
            numberofPages = Math.ceil(this.totalRecordCount() / this.state.limit);
        }
        // this.setState({noOfpages:numberofPages})
        let rows = [];
        if (1 < this.state.currentPage - 4) {
            rows.push(
                <Pagination.Item
                    onClick={() => {
                        this.gotopage(1);
                    }}
                >
                    First
                </Pagination.Item>
            );
        }
        if (this.state.currentPage - 1 !== 0) {
            rows.push(
                <Pagination.Item
                    key="previous_page"
                    onClick={() => {
                        this.gotopage(this.state.currentPage - 1);
                    }}
                >
                    Previous
                </Pagination.Item>
            );
        }
        for (let i = 0; i < numberofPages; i++) {
            let pagClass = '';
            if (i + 1 < this.state.currentPage - 4 || i + 1 > this.state.currentPage + 4) {
                pagClass = 'hide';
            }
            rows.push(
                <Pagination.Item
                    key={i}
                    active={i + 1 === this.state.currentPage}
                    onClick={() => {
                        this.gotopage(i + 1);
                    }}
                >
                    {i + 1}
                </Pagination.Item>
            );
        }
        if (this.state.currentPage !== numberofPages) {
            rows.push(
                <Pagination.Item
                    key="next_page"
                    onClick={() => {
                        this.gotopage(this.state.currentPage + 1);
                    }}
                >
                    Next
                </Pagination.Item>
            );
        }
        if (this.state.currentPage < numberofPages - 4) {
            rows.push(
                <Pagination.Item
                    key="last"
                    onClick={() => {
                        this.gotopage(numberofPages);
                    }}
                >
                    Last
                </Pagination.Item>
            );
        }
        return rows;
        //
    };

    updateLimit = (e) => {
        this.setState({ limit: parseInt(e.target.value), currentPage: 1 });
    };

    handleClose2() {
        this.setState({ show2: false });

        this.props.history.push('/patients/rrs');
    }

    handleShow(id) {
        if (sessionStorage.getItem('highlightId')) {
            let temp_id = sessionStorage.getItem('highlightId');
            document.getElementById(temp_id).style.cssText = 'background-color:white';
        }

        sessionStorage.setItem('highlightId', id);

        let provider = this.props.loginDetails;

        this.setState({ showLoader: true, showLoaderScr: true });

        let details = {
            request_id: id,
        };
        let postheaders = {
            'access-token': provider.access_token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let parent = this;
        openbedsApi
            .post('/rrs_i_button_patient', formBody, { headers: postheaders })
            .then(function (response) {
                //   console.log(response)
                if (response.data.status === 'ERROR' || response.data.status === 'Failure') {
                    // NotificationManager.error('', response.data.message);
                    // parent.setState({
                    //     showLoader:false
                    // })
                } else if (response.data.status === 'SUCCESS') {
                    document.getElementById(id).style.cssText = 'background-color:#9FDCF3';
                    document.getElementById(id).classList.remove('strongfont');
                    parent.ensureStateAttributes(response.data.patient_data.zip);
                    parent.setState({
                        submittedInfoModalData: response.data.patient_data,
                        showLoader: false,
                        showLoaderScr: false,
                    });
                }
            })
            .catch(
                function (error) {
                    if (error.response.status == 401) {
                        sessionStorage.clear();
                        let errorArr = {
                            requestStatus: 'ERROR',
                            data: error,
                        };
                        if (this.props.loginDetails.type == 'Patient') {
                            this.props.history.push({
                                pathname: appUrls.USER_LOGIN,
                                state: { loginTimeout: true },
                            });
                        }
                    }
                }.bind(this)
            );
    }

    ensureStateAttributes = (zip) => {
        if (typeof this.state.stateAttributes[zip] === 'undefined') {
            this.setState({
                showLoaderScr: true,
                stateAttributes: { ...this.state.stateAttributes, [zip]: null },
            });
            fetchStateAttributes(zip, true).then((result) => {
                this.setState({
                    showLoaderScr: false,
                    stateAttributes: { ...this.state.stateAttributes, [zip]: result },
                });
            });
        }
    };

    getSubstances = (sub, zip) => {
        let substancesArr = [];
        const availableSubstances = this.state.stateAttributes[zip] ? this.state.stateAttributes[zip].substances : [];
        if (sub) {
            let subArr = sub.split(',');
            subArr.forEach((sub) => {
                availableSubstances.forEach((strsub) => {
                    if (+sub === +strsub.id) {
                        substancesArr.push(strsub.substance);
                    }
                });
            });
            return substancesArr.join(', ');
        } else {
            return '';
        }
    };

    getTreatment = (sub, zip) => {
        let substancesArr = [];
        const availableTreatments = this.state.stateAttributes[zip] ? this.state.stateAttributes[zip].service_type : [];
        if (sub) {
            let subArr = sub.split(',');
            subArr.forEach((sub) => {
                availableTreatments.forEach((strsub) => {
                    if (+sub === +strsub.id) {
                        substancesArr.push(strsub.service_type);
                    }
                });
            });
            return substancesArr.join(', ');
        } else {
            return '';
        }
    };

    async confirmRequest() {
        this.setState({
            showLoader: true,
        });
        let orgid = sessionStorage.getItem('org_id');
        let state = sessionStorage.getItem('state');
        await this.props.sendRequest(null, null, orgid, state);
    }

    componentWillMount() {
        let provider = this.props.loginDetails;

        if (provider.type === 'Patient') {
            this.props.patientRrsInfo().then(() => {
                this.setState({
                    showLoaderScr: false,
                });
            });
        } else {
            this.props.history.push(appUrls.USER_LOGIN);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.sendRequestMessage.status === 'SUCCESS') {
                this.props.clearRequestdata();
                this.handleClose2();
                this.props.patientRrsInfo();
                this.setState({
                    showLoaderScr: false,
                    showLoader: false,
                });
                NotificationManager.listNotify.forEach((n) => NotificationManager.remove({ id: n.id }));
                NotificationManager.success('', 'Enquiry has been successfully submitted');
            } else if (this.props.sendRequestMessage.status === 'ERROR') {
                this.props.clearRequestdata();
                this.handleClose2();
                this.props.patientRrsInfo();
                this.setState({
                    showLoaderScr: false,
                    showLoader: false,
                });
                NotificationManager.error('', this.props.sendRequestMessage.message);
            }
        }
    }

    componentDidMount() {
        if (this.state.new_enquiry) {
            this.setState({ show2: true });
        }
    }

    compareBy = (key) => {
        return function (a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    };

    compareByDate = (key) => {
        return (a, b) => {
            return new Date(b[key]) - new Date(a[key]);
        };
    };

    sortBy = (key) => {
        let arrayCopy = this.props.patientRrs.data;

        if (this.state.icon === true) {
            arrayCopy.sort(this.compareBy(key));
            this.setState({
                data: arrayCopy,
                orderedBy: 'descending',
                ariaSortByTime: 'none',
                ariaSortById: 'descending',
            });
            screenReaderAnnouncement("Tabled sorted by 'descending' id");
        } else {
            arrayCopy.sort(this.compareBy(key));
            arrayCopy.reverse();
            this.setState({
                data: arrayCopy,
                orderedBy: 'ascending',
                ariaSortByTime: 'none',
                ariaSortById: 'ascending',
            });
            screenReaderAnnouncement("Table sorted by 'ascending' id");
        }
        this.setState({ icon: !this.state.icon });
    };

    sortByTime = (key) => {
        let arrayCopy = this.props.patientRrs.data;

        if (this.state.iconTime === true) {
            arrayCopy.sort(this.compareByDate(key));
            this.setState({
                data: arrayCopy,
                orderedBy: 'descending',
                ariaSortByTime: 'descending',
                ariaSortById: 'none',
            });
            screenReaderAnnouncement("Table sorted by 'descending' time and date");
        } else {
            arrayCopy.sort(this.compareByDate(key));
            arrayCopy.reverse();
            this.setState({
                data: arrayCopy,
                orderedBy: 'ascending',
                ariaSortByTime: 'ascending',
                ariaSortById: 'none',
            });
            screenReaderAnnouncement("Table sorted by 'ascending' time and date");
        }
        this.setState({ iconTime: !this.state.iconTime });
    };
    render() {
        let patientInfo = '';
        if (this.props.patientRrs.patientInfo) {
            patientInfo = this.props.patientRrs.patientInfo[0];
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Referral Request Status - Treatment Connection</title>
                </Helmet>
                <div>
                    {this.state.showLoaderScr ? <div className="se-pre-con_ajax"></div> : false}
                    <Header page="RRS" />

                    <Modal
                        show={this.state.show2}
                        onHide={() => {
                            this.handleClose2();
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <p className="text-left">
                                <b>
                                    The information that you entered at My Profile will be sent to the provider. By
                                    clicking Submit, you are confirming that you would like to contact this provider.
                                </b>
                            </p>
                            <div className="mt20">
                                <Button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.confirmRequest();
                                    }}
                                >
                                    Submit{' '}
                                    {this.state.showLoader ? (
                                        <span className="fa fa-spinner fa-spin pageLoader">&nbsp;</span>
                                    ) : (
                                        false
                                    )}
                                </Button>
                                <span className="spaceBtn"></span>
                                <Button
                                    onClick={() => {
                                        this.handleClose2();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <main className="warper container">
                        <span id="main-content" tabIndex="-1"></span>
                        <div className="clearfix">
                            <div className="pull-right text-right">
                                <a
                                    className="btn btn-primary backbutton"
                                    href="javascript:void(0)"
                                    onClick={this.goback}
                                >
                                    Go back to make another request
                                </a>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={requestPopOver}
                                    rootClose
                                    role="tooltip"
                                    trigger={null}
                                    ref={this.requestOverlayRef}
                                >
                                    <button
                                        className="btn btn-default mb-15 ml-10"
                                        aria-disabled="true"
                                        onMouseOver={() => {this.requestOverlayRef.current.show();}}
                                        onFocus={() => {this.requestOverlayRef.current.show();}}
                                        onBlur={() => {this.requestOverlayRef.current.hide();}}
                                    >
                                        <span className="sr-only">My Requests navigation information</span>
                                        <i
                                            className="request-question-more-request fa fa-question-circle"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </OverlayTrigger>

                                <div ref={this.overlayTriggerButton}></div>
                            </div>
                        </div>
                        <form
                            id="formRequestStatus"
                            className="paginationForm"
                            method="post"
                            action="#"
                            data-url="#"
                            data-method="post"
                            data-page="requeststatus"
                        >
                            <div className="tableheading1">
                                <span className="fa-2x">
                                    <h1 className="d-inline-block mt-0 mb-0 h2">My Requests</h1>
                                    <div className="confidential-id pull-right">
                                        Your Confidential ID is {this.props.loginDetails.pid}
                                    </div>
                                </span>
                            </div>
                            <div className="container mt10">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="panel panel-default">
                                            <div className="panel-body reqTxt">
                                                <span className="requestText">
                                                    Enquiries made by you to the treatment providers are listed below.
                                                    <br />
                                                    Only the confidential ID assigned to you by the system is sent to
                                                    the treatment provider.
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Search" className="tab-pane tabs-up fade in active">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <div className="col-lg-4 col-sm-12 col-xs-12 mtext-center text-left showing pagination">
                                        {this.getRecords(this.totalRecordCount())} of {this.totalRecordCount()}
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-xs-12 mtext-center text-center paginationArea">
                                        <ul className="pagination classNamerequeststatus">
                                            {/* <li className="active disabled"><span><b>1</b></span></li>
                            <li className="page-item"><a href="#" data-ci-pagination-page="2">2</a></li><li className="page-item"><a href="#" data-ci-pagination-page="2" rel="next">Next</a></li> */}
                                        </ul>
                                    </div>

                                    <div className="col-lg-4 col-sm-12 col-xs-12 mtext-center mp0 pull-right perPageArea">
                                        <label htmlFor="perPage2" className="col-md-7 col-sm-6 text-right mtext-center">
                                            Rows Per Page{' '}
                                        </label>
                                        <div className="col-md-5 col-sm-6 text-right pull-right mtext-center">
                                            <select
                                                id="perPage2"
                                                name="per_page"
                                                className="form-control per_page advance_search perPage left"
                                                value={this.state.limit}
                                                onChange={this.updateLimit}
                                            >
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="rkyrandhir ">
                                        <table
                                            className="table table-bordered table-striped table-hover tablePagination text-center"
                                            id="requestsTable"
                                            data-col="10"
                                        >
                                            <thead>
                                                <tr align="center">
                                                    <th
                                                        width="150"
                                                        aria-sort={this.state.ariaSortByTime}
                                                        role="columnheader"
                                                        onClick={() => this.sortByTime('timestamp')}
                                                    >
                                                        Time/Date
                                                        {this.state.iconTime === true ? (
                                                            <ArrowDropDown />
                                                        ) : (
                                                            <ArrowDropUp />
                                                        )}
                                                    </th>
                                                    <th width="250">Receiving Contact Info</th>

                                                    <th
                                                        width="100"
                                                        aria-sort={this.state.ariaSortById}
                                                        role="columnheader"
                                                        onClick={() => this.sortBy('id')}
                                                    >
                                                        Request ID
                                                        {this.state.icon === true ? (
                                                            <ArrowDropDown />
                                                        ) : (
                                                            <ArrowDropUp />
                                                        )}
                                                    </th>
                                                    <th width="100">Submitted Information</th>
                                                    {/* <th className="arrow" width="125"></th> */}
                                                    {/* <th  width="1%"></th> */}
                                                </tr>
                                            </thead>

                                            <tbody id="requestsTableBody">
                                                {this.totalRecordCount() !== undefined &&
                                                this.totalRecordCount() !== 0 ? (
                                                    this.props.patientRrs.data.map((rrs) => {
                                                        let rowclass = '';
                                                        let finalstatus = '';
                                                        if (parseInt(rrs.unread) > 0) {
                                                            rowclass = 'listItem strongfont';
                                                        } else {
                                                            rowclass = 'listItem';
                                                        }

                                                        if (rrs.accept_status != '' && rrs.accept_status != null) {
                                                            if (rrs.status == 'Closed') {
                                                                finalstatus =
                                                                    rrs.accept_status + '  and  ' + rrs.status;
                                                            } else {
                                                                finalstatus =
                                                                    rrs.status + '  and  ' + rrs.accept_status;
                                                            }
                                                        } else {
                                                            finalstatus = rrs.status;
                                                        }

                                                        return (
                                                            <tr
                                                                className={rowclass}
                                                                id={rrs.id}
                                                                role="row"
                                                                key={rrs.id}
                                                            >
                                                                <td width="150" className="text-left">
                                                                    {rrs.status !== 'Opened' &&
                                                                    parseInt(rrs.unread) > 0 ? (
                                                                        <span>
                                                                            New Message <br /> {rrs.message_timestamp}
                                                                            <br />
                                                                            Requested
                                                                            <br />
                                                                            {rrs.timestamp}
                                                                        </span>
                                                                    ) : parseInt(rrs.unread) > 0 ? (
                                                                        <span>
                                                                            New Request
                                                                            <br />
                                                                            {rrs.timestamp}
                                                                        </span>
                                                                    ) : (
                                                                        rrs.timestamp
                                                                    )}
                                                                </td>
                                                                <td width="250" className="text-left">
                                                                    {rrs.receiver_institute ? (
                                                                        <>
                                                                            {rrs.receiver_institute}
                                                                            <br />{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.service ? (
                                                                        <>
                                                                            {rrs.service}
                                                                            <br />{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.service_street_no ? (
                                                                        <>
                                                                            {rrs.service_street_no}
                                                                            <br />{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.service_street_name ? (
                                                                        <>
                                                                            {rrs.service_street_name}
                                                                            <br />{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.service_city ? (
                                                                        <>{rrs.service_city} </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.receiver_service_state ? (
                                                                        <>{rrs.receiver_service_state} </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.service_zip ? (
                                                                        <>
                                                                            {rrs.service_zip}
                                                                            <br />
                                                                            Provider Notified by Service{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}

                                                                    {rrs.receiver_service_contactmethod ? (
                                                                        <>
                                                                            {rrs.receiver_service_contactmethod}
                                                                            <br />{' '}
                                                                        </>
                                                                    ) : (
                                                                        false
                                                                    )}
                                                                </td>
                                                                <td align="center" width="100">
                                                                    {rrs.id}
                                                                </td>
                                                                <td align="center" width="100">
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        onClick={() => this.handleShow(rrs.id)}
                                                                    >
                                                                        <i
                                                                            className="fa fa-info-circle reqIcon"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </td>
                                                                {/* <td align="center" width="110"><a href="#" className="messagePage"  data-toggle="tooltip" title="" data-id="281" data-original-title="View request message"><i className="fa fa-chevron-circle-right iconinfo f30"></i></a></td>
                                                                 */}
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9">No active request available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="col-sm-12 mt20">
                                    <div className="alert alert-success hidden archive_success_msg">
                                        Requests Archived Successfully!
                                    </div>
                                    <div className="">
                                        <div className="col-lg-4 col-sm-12 col-xs-12 mtext-center text-left showing pagination">
                                            {this.getRecords(this.totalRecordCount())} of {this.totalRecordCount()}
                                        </div>

                                        <div className="col-lg-4 col-sm-4 col-xs-12 text-center paginationArea">
                                            {Math.ceil(this.totalRecordCount() / this.state.limit) > 1 ? (
                                                <Pagination bsSize="medium">{this.getPagination()}</Pagination>
                                            ) : (
                                                false
                                            )}
                                        </div>
                                        <div className="col-lg-4 col-sm-12 col-xs-12 mtext-center text-right pull-right perPageArea mp0">
                                            <label
                                                htmlFor="perPage1"
                                                className="col-md-7 col-sm-6 text-right mtext-center"
                                            >
                                                Rows Per Page{' '}
                                            </label>
                                            <div className="col-md-5 col-sm-6 text-right pull-right mtext-center">
                                                <select
                                                    id="perPage1"
                                                    name="per_page"
                                                    className="form-control per_page advance_search perPage left"
                                                    value={this.state.limit}
                                                    onChange={this.updateLimit}
                                                >
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <div className="clearfix">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                            {/* {
                    this.props.patientRrs.patientInfo.map(pat=>{
                        return <h2 key={pat.patient_id}>{pat.fname}</h2>
                    })
                } */}
                            {this.renderSubmittedInfoModal()}
                        </div>
                    </main>
                    {/* <footer className="container-fluid footer text-center"><a className="text-blue">Copyright © 2018 OpenBeds<sup>®</sup></a>
        </footer> */}
                    <Footer />
                </div>
            </React.Fragment>
        );
    }

    renderSubmittedInfoModal() {
        if (this.props.patientRrs) {
            const data = this.state.submittedInfoModalData;
            const patientInfo = data
                ? {
                      patient_id: data.patient_id,
                      completing_for: data.completing_for,
                      birth_year: data.birth_year,
                      no_of_years_using_substances: data.no_of_years_using_substances,
                      substances_using: this.getSubstances(data.substances_using, data.zip),
                      service_type_done: this.getTreatment(data.service_type_done, data.zip),
                      service_type_seeking: this.getTreatment(data.service_type_seeking, data.zip),
                      is_insurance: data.is_insurance,
                      insurance_name: data.insurance_name,
                      comment: data.comment,
                  }
                : {};
            return (
                <SubmittedInfoModal show={!!data} patientInfo={patientInfo} onClose={this.closeSubmittedInfoModal} />
            );
        }
    }

    closeSubmittedInfoModal = () => {
        this.setState({ submittedInfoModalData: null });
    };
}

const mapStateToProps = (state) => {
    //  console.log(state)
    ////  console.log(process.env)
    return {
        attributes: state.post,
        loginDetails: state.loginDetails,
        patientRrs: state.patientRrs,
        sendRequestMessage: state.sendRequest,
    };
};

export default connect(mapStateToProps, {
    submitLogin,
    patientRrsInfo,
    fetchPosts,
    sendRequest,
    clearRequestdata,
})(Rrs);
