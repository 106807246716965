import React from 'react';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";
import { Field, reduxForm, change } from 'redux-form'
import Header from './../layout/header'
import Footer from './../layout/footer'
import Select from 'react-select'
import {fetchPosts,updateZipcode,updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment} from './../../actions/selectattributes';
import {sendRequest,clearRequestdata} from './../../actions/login';
import openbedsApi from './../../openbedsApi';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal, Button,  OverlayTrigger, Popover} from 'react-bootstrap';
import {fetchStateAttributes, fetchContactMethodPatients} from "../../apiClient";
import {TreatmentDefinitionsModal} from "../search/TreatmentDefinitionsModal";
import {Messages} from "../messages";
import {appUrls} from "../../appUrls";
import {formatPhoneNumber} from "../../utils/phoneNumbers";

class ContactMe extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            emailError:false,
            email:'',
            zip_error:false,
            zipcode:'',
            birthyear:'',
            gender:'M',
            checked:false,
            acceptterms:false,
            showLoader:false,
            birth_error:false,
            contactmethod:'',
            contactmethod_error: false,
            phone:'',
            phone_error: false,
            show:false,
            zipcodeDisable: true,
            attributes: null,
            facility: '',
            contactMethodList: null
        };
      }

      async componentDidMount()
      {
        window.scrollTo(0, 0);
        this.fetchCMPatient();
      }

      acceptingContactMe = () => {
        let details = {
            facility: sessionStorage.getItem("org_id"),
            target_state: sessionStorage.getItem("state")
        }

        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain'
        }
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        let parent = this
        openbedsApi.post('/acceptingContactMe', formBody, {headers: postheaders})
            .then( (response) => {
              if (response.data.status == "ERROR") {
                this.props.history.push(appUrls.SEARCH_RESULT_LIST);
              }
            })
            .catch(function (error) {
                this.props.history.push(appUrls.SEARCH_RESULT_LIST);
            });
      }


    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.sendRequestMessage.status === 'SUCCESS') {
                this.props.clearRequestdata()
/*                NotificationManager.success('', 'Enquiry has been successfully submitted');
                setTimeout(()=>{*/
                    this.props.history.push("/patients/rrs?new_enquiry=1")
/*                    window.location.reload();
                },3000)    */
            } else if (this.props.sendRequestMessage.status === 'ERROR') {
                NotificationManager.error('', this.props.sendRequestMessage.message);
                this.props.clearRequestdata()
            }
        }
        if(this.props.loginDetails !== undefined && (this.props.loginDetails.type === "Provider" || this.props.loginDetails.type === "Provider" || this.props.loginDetails.type=== "4a provider")) {
            this.props.history.push('/search-treatment-facilities');
        }
    }

    async componentWillMount() {
      //let provider = this.props.loginDetails
      await this.acceptingContactMe();
      await  this.props.fetchPosts()
    }

    isEmailvalidate = (e) => { //only validation is regex check
        let email = e.target.value
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regex.test(email) || email === ''){
          this.setState({emailError:false,email:email})
        }else{
          this.setState({emailError:true,email:email})
        }
    }


    updateZipCode = (e) => {
        var zip = e.target.value;

        const regex = /^[0-9]{5}$/;
        if(regex.test(zip)){
            this.setState({zip_error:false,zipcode:e.target.value})
        } else if (zip === '') {
          this.setState({zip_error:false,zipcode:e.target.value, attributes:null})
          this.setState({zipcodeDisable: true})
        } else{
          this.setState({zip_error:true,zipcode:e.target.value})
          this.setState({zipcodeDisable: true})
        }

    }

    renderZipCodeError() {
        if (this.state.zip_error) {
            return (<span className="error" style={{color: 'red'}}>{this.state.zip_error}</span>);
        } else if (this.state.attributes === false) {
            return (
                <span className="error" style={{color: 'red'}}>{Messages.UNSUPPORTED_STATE}</span>);
        }
        return null;
    }

    fetchCMPatient = () => {
      fetchContactMethodPatients()
          .then((result) => {
            if (null !== result) {
                this.setState({contactMethodList: result});
                //console.log(result);
            }
          })
          .catch(() => {
            this.setState({contactMethodList: null});
          })
    };

    updatebirthyear = (e) => {
        const regex = /^[0-9]{4}$/;
        let currentYear = new Date().getFullYear()
        if((regex.test(e.target.value) || e.target.value === '') && e.target.value <=currentYear ){

            this.setState({birthyear:e.target.value,birth_error:false})
        }else{
            this.setState   ({birthyear:e.target.value,birth_error:true})
        }
    }

    updatephone = (e) => {
      this.setState({phone:e.target.value, phone_error: false})
    }

    updateGender = (e) =>{
        const gender = e.target.value
        this.setState({gender:gender})

    }

  contactMeOptions = () => {
    let contactMeArr = [];
    if(this.state.contactMethodList){
        this.state.contactMethodList.forEach(contact=>{
              contactMeArr.push({
                  value: contact.id,
                  label: contact.contactmethod
              })
        })
    }
    return contactMeArr
  }

  updateContactMethod = (selectedOption) => { // need to clear input field
      this.setState({contactmethod:selectedOption, contactmethod_error:false})
      this.setState({emailError:false, phone_error:false}) // we need to reset the contact method error field after this in case
      this.setState({email: ''})
      this.props.dispatch(change('patient-contactme-form','phone', null))
  }

    accept = () => {
        this.state.checked === false ? this.handleShow() :
        this.setState({checked:!this.state.checked,acceptterms:this.state.checked,show:false})
    }

    acceptTerms = () => {
        this.setState({checked:!this.state.checked,acceptterms:this.state.checked,show:false})
    }

    submitPatientContactMe = (phone) => {
        this.setState({showLoader:true})
        let details = {
            email: this.state.email,
            phone: phone,
            zip: this.state.zipcode,
            birth_year: this.state.birthyear,
            gender: this.state.gender,
            organization_id: sessionStorage.getItem("org_id"),
            organization_uuid: sessionStorage.getItem("org_uuid") || sessionStorage.getItem("org_id"),
            service_id: sessionStorage.getItem("service_uuid"),
            organization_services_id: sessionStorage.getItem("organization_services_id"),
            target_state: sessionStorage.getItem("state"),
            contact_method: this.state.contactmethod.value
        };

        let postheaders = {
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];

        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        let parent = this

        openbedsApi.post(
            '/contact_me',
            formBody,
            { headers: postheaders }
        )
            .then(async (response) => { //send the async response
                if (response.data.status === "ERROR" || response.data.status === "Failure") {
                    NotificationManager.error('', response.data.message);
                    parent.setState({ showLoader: false })
                } else if (response.data.status === "SUCCESS") {
                    NotificationManager.success('', response.data.message, 10000);
                    parent.props.history.push('/')
                }
            })
            .catch(function (error) {
                parent.setState({ showLoader: false })
            });
    }

    handleClose = (modal) => {
        let treatment = this.state.showTreatmentInfoModal;
        let agreement = this.state.showAgreementInfoModal;

        if(modal ==='treatment') {
            treatment = false;
        }

        if(modal ==='agreement') {
            agreement = false;
        }

        this.setState({
            showTreatmentInfoModal: treatment,
            showAgreementInfoModal: agreement,
            show:false
        });
    }

    showZipError = (e) => {
      alert("Please enter ZIP code");
    }

      handleShow = () => {
        this.setState({ show: true });
      }

      goback = () =>{
        this.props.history.push(appUrls.SEARCH_RESULT_LIST);
      }

      //insert thhis for field
      textInput = ({showpopover,checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
          let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
          if(className){
              elemClass =  elemClass+" hide"
          }
          return (
              <input {...input} className="form-control serviceAddressLine1 s_address"  placeholder={placeholder}  title={title} disabled={readonly} type={type}/>
          )
      }

      handleSubmit(fields) {
                this.setState({
                    showLoader:true,
                })

           let cState = this.state
            if(cState.checked === true
              && cState.birthyear !== ''
              && cState.birth_error===false
              && cState.zip_error === false
              && cState.zipcode!==''
              && cState.contactmethod !== ''
              && (((cState.contactmethod.value == "2" || cState.contactmethod.value == "5") && fields.target.phone.value.length == 14) || (cState.contactmethod.value == "3" && (cState.email !='' && !cState.emailError))) ){
              //check to make sure we have info for registering patient.
              //also add patient the contact method preference  add phone number check here too
                //console.log(fields.target.phone.value)
                if (fields.target.phone) {
                  this.submitPatientContactMe(fields.target.phone.value)
                } else {
                  this.submitPatientContactMe('')
                }
            }else{
                if(cState.contactmethod.value == "3" && cState.email === ''){
                    this.setState({emailError:true})
                }
                if((cState.contactmethod.value == "2" || cState.contactmethod.value == "5") && fields.target.phone.value.length != 14){
                    this.setState({phone_error:true})
                }
                if(cState.zipcode === ''){
                    this.setState({zip_error:true})
                }
                if(cState.checked===false){
                    this.setState({acceptterms:true})
                }
                if(cState.birthyear===''){
                    this.setState({birth_error:true})
                }
                if(cState.contactmethod === '') {
                    this.setState( {contactmethod_error: true })
                }
                this.setState({
                    showLoader:false
                })
            }
      }


     render(){
    return (
        <React.Fragment>

            <Helmet>
                <title>Contact Me - Treatment Connection</title>
            </Helmet>

        <div className="patient-contactme-form">
<Header page="list" />
	<div className ="container" role="main">

    			<div className="row">
    				<div className="col-md-12 heading-sec">
                <div className="pull-left"><h2 className="">Contact Me</h2>
                        <p>Contact a treatment facility by filling in the information below</p>
                        <div className="asterisk">*</div><p>Indicates required information</p>
    				</div>
    			</div>

      			<form className="form-horizontal form-panel-vertical patient-contactme-form" autoComplete="off" action="javascript:void(0)" method="post" id="patient-contactme-form" onSubmit={this.handleSubmit.bind(this)}>
                <div className="row">
                        <div className="col-sm-4 patient-signup-context">
                            <h4>Contact Method</h4>
                            <p>You will need to select a contact method from the list. Based on your preferred contact method, the provider will reach out to you.</p>
                            <h4>Basic Information</h4>
                            <p>The provider will need to know some basic information about you in order to serve your needs better. </p>
                        </div>
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="form-group required">
                                <label htmlFor="contactmethods" className="col-sm-6 control-label" aria-required="true">Which contact method would you prefer?</label>
                                <div className="col-sm-6" style={{color:'hsl(0,0%,20%)'}}>
                                  <Select options={this.contactMeOptions()} className="searchinput" placeholder="Contact Method" value={this.props.contactmethod} onChange={this.updateContactMethod} />
                                  {
                                          this.state.contactmethod_error === true ?
                                          <span className="error">Please select a contact method</span> : false

                                  }
                                </div>
                            </div>

                            {
                              this.state.contactmethod.value == "3" ?

                              <div className="form-group required">
                                  <label htmlFor="email_address" className="col-sm-6 control-label" aria-required="true">Email Address</label>
                                  <div className="col-sm-6">
                                      <input name="emailaddress" id="email_address" className="form-control" placeholder="Enter Email Address" type="text" onChange={this.isEmailvalidate}  value={this.state.email} />
                                      {
                                          this.state.emailError === true ?
                                              <span className="error">Please enter a valid email address.</span> : false
                                      }
                                  </div>
                              </div> : ""

                            }

                            {
                              this.state.contactmethod.value == "2" || this.state.contactmethod.value == "5" ?

                              <div className="form-group required">
                                  <label htmlFor="phone" className="col-sm-6 control-label" aria-required="true">Phone Number</label>
                                  <div className="col-sm-6">
                                    <Field name="phone" component={this.textInput}  title="Please enter a valid phone no."  placeholder="Mobile Phone No." onChange={this.updatephone}/>
                                    {
                                        this.state.phone_error === true ?
                                            <span className="error">Please enter a valid phone number.</span> : false
                                    }
                                  </div>
                              </div> : ""

                            }



                            <div className="form-group required">
                                <label htmlFor="zipcode" className="col-sm-6 control-label" aria-required="true">Your 5 Digit ZIP Code</label>
                                <div className="col-sm-6">
                                    <input name="zipcode" id="zipcode" className="form-control" placeholder="00000" type="text" pattern="[0-9]*" inputMode="numeric" onChange={this.updateZipCode} value={this.state.zipcode}/>
                                    {
                                        this.state.zip_error === true ?
                                        <span className="error">Please enter five Numbers</span> : false

                                    }
                                    {this.renderZipCodeError()}
                                </div>
                            </div>

                            <div className="form-group required">
                                <label htmlFor="birthyear" className="col-sm-6 control-label" aria-required="true">Birth Year</label>
                                <div className="col-sm-6">
                                    <input name="birthyear" id="birthyear" className="form-control" placeholder="yyyy" type="text" pattern="[0-9]*" inputMode="numeric" onChange={this.updatebirthyear} value={this.state.birthyear}/>
                                    {
                                        this.state.birth_error === true ?
                                        <span className="error">Enter Valid Birth Year</span> : false

                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="fname" className="col-sm-6 control-label" aria-required="true">Gender</label>
                                <div className="col-sm-6">
                                    <div className="controls radio-opt">
                                        <label className="radio-inline" htmlFor="male">
                                            <input name="gender" id="male" value="M" checked={this.state.gender === 'M' ? true : false} onChange={this.updateGender} type="radio"/>
                                        <span className="radiomark"></span>Male</label>
                                        <label className="radio-inline" htmlFor="female">
                                            <input name="gender" id="female" value="F" checked={this.state.gender === 'F' ? true : false} onChange={this.updateGender} type="radio"/>
                                        <span className="radiomark"></span>Female</label>
                                        <label className="radio-inline" htmlFor="non-binary">
                                            <input name="gender" id="non-binary" value="N" checked={this.state.gender === 'N' ? true : false} onChange={this.updateGender} type="radio"/>
                                        <span className="radiomark"></span>Non-binary</label>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>


      				<hr/>

                      <div className="row">
                          <div className="col-sm-4 patient-signup-context">
                          </div>
                          <div className="col-sm-8">
                              <div className="row">
                                  <div className="form-group required">
                                      <label htmlFor="terms-privacy" className="col-sm-6 control-label" aria-required="true">Check
                                          to agree that you have read and <br />agree to the terms and privacy policy</label>
                                      <div className="col-sm-6">
                                          <div className="checkbox">
                                              <label><input type="checkbox" value="accept" onChange={this.accept}
                                                            checked={this.state.checked}/>Click here to see Terms and Privacy
                                                  Policy</label>
                                              {
                                                  this.state.acceptterms === true ?
                                                      <span
                                                          className="error">Please agree to terms and privacy policy </span> : false
                                              }
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <hr/>
                      <div className="row">
                          <div className="form-group">
                              <label className="col-sm-6 control-label"></label>
                              <div className="col-sm-6 text-right">
                                  <button type="submit" className="btn btn-submit" onClick={this.submitContactMe}>Submit

                                      {
                                          this.state.showLoader ?
                                              <span className="fa fa-spinner fa-spin pageLoader">&nbsp;</span> : false
                                      }
                                  </button>
                                  <button type="reset" className="btn btn-cancel" onClick={this.goback}>Cancel
                                  </button>
                              </div>
                          </div>
                      </div>

    			</form>
		    </div>
	</div>



    <Modal show={this.state.show} onHide={() => this.handleClose('agreement')}  bsSize="large">
                <Modal.Header closeButton>
           </Modal.Header>
          <Modal.Body>
              <div className="container text-left">
                  <div className="row">
                      <div className="col-md-12 termDiv">
                      <img src="/images/openbeds_logo_header.png" className="TermImg" alt="Treatment Connection" />
                      <div className="text-center">  <span>Terms and Conditions of Use</span>
                      </div>
              <br/>
              <p>The OpenBeds-TreatmentConnection, however accessed, including this public facing portal and any related mobile applications (collectively, “OpenBeds-TreatmentConnection” or the “site”) is provided by OpenBeds Inc. (“OpenBeds” or “we”), a company organized under the laws of Delaware, U.S.A. </p>

              <h4>Acceptance of Terms and Conditions of Use</h4>
              <p>By accessing OpenBeds-TreatmentConnection, you accept the following Terms and Conditions of Use. You are only authorized to use OpenBeds-TreatmentConnection and its services if you agree to abide by all applicable laws and to these Terms and Conditions of Use (“Terms and Conditions”).  We may revise these Terms and Conditions from time to time by posting changes to OpenBeds-TreatmentConnection and indicating the date of the last revision.  Any such revisions shall be effective when posted. Your continued use of OpenBeds-TreatmentConnection following such changes constitutes your agreement to the modified terms.</p>

              <h4>User Access Requirements and Responsibilities</h4>
              <p>OpenBeds-TreatmentConnection may be accessed only by US residents age 18 years or older (“Users”). All other individuals are expressly prohibited from accessing the site. OpenBeds-TreatmentConnection is designed to (a) facilitate a User’s search for available substance use disorder or mental health treatment providers (“Treatment Providers”), and (b) assist in connecting Users with those Treatment Providers. Any other use of OpenBeds-TreatmentConnection other than those provided herein is expressly prohibited.</p>

              <p>Users are solely responsible for their own conduct and communications relating to their use of OpenBeds-TreatmentConnection and for any consequences thereof. Users are solely responsible for all obligations incurred, liabilities for harm caused, and actions taken through their use of or access to the OpenBeds-TreatmentConnection site. Users are solely responsible for providing accurate, complete, and valid information, and by using the OpenBeds-TreatmentConnection site each User represents and warrants that all information submitted is true and correct and that such User has the right to submit any such information.</p>

              <p>OpenBeds reserves the right to remove any User-submitted information which it determines, in its sole discretion, violates these Terms and Conditions of Use. OpenBeds reserves the right to terminate any User’s ability to access or use OpenBeds-TreatmentConnection and prevent further access if OpenBeds, in its sole discretion, believes that a User is violating or has violated the Terms and Conditions of Use or any applicable law relating to OpenBeds-TreatmentConnection. </p>
<p>By way of example, and not as a limitation, you agree that when using OpenBeds-TreatmentConnection you will not:</p>
<ul className="termul">
    <li>(a) defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others;</li>

    <li>(b) upload, post or otherwise make available any inappropriate, defamatory, obscene, or unlawful content;</li>
    <li>(c) upload, post or otherwise make available any content that infringes any patent, trademark, copyright, trade secret or other proprietary right of any party, unless you are the owner of the rights, or have the permission of the owner or other legal justification to use such content;</li>
    <li>(d) upload, post or otherwise make available any other content, message, or communication prohibited by applicable law, the Terms and Conditions of Use or any other applicable policies or guidelines;</li>
    <li>(e) download any file posted by another that you know, or reasonably should know, cannot be legally distributed in such manner;</li>
    <li>(f) impersonate another person or entity, or falsify or delete any author attributions or proprietary designations or labels of the origin or source of content, software or other material;</li>
    <li>(g) promote or provide instructional information about illegal activities;</li>
    <li>(h) promote physical harm or injury against any group or individual;</li>
    <li>(i) transmit any viruses, worms, defects, Trojan horses, or any items of a destructive nature.</li>
    <li>(j) download, share, access, or otherwise use the documentation and/or any other content on the OpenBeds-TreatmentConnection site for commercial purposes;
</li>
    <li>(k) rent, lease, loan, trade, or sell/re-sell the documentation or access to the services or documentation;</li>
    <li>(l) remove any copyright, trademark, or other proprietary rights notices contained in or on the OpenBeds-TreatmentConnection site or documentation;</li>
    <li>(m) use bots, scripts, or other automated methods to access the OpenBeds-TreatmentConnection site or any of the services or documentation;</li>
    <li>(n) engage in “framing,” “mirroring,” or otherwise simulating the appearance of function of the OpenBeds-TreatmentConnection site or any documentation; or</li>
    <li>(o) access the services and/or documentation through interfaces not expressly provided or authorized by OpenBeds.</li>
</ul>

<h4>Use of OpenBeds-TreatmentConnection Constitutes Express Consent to Share Information</h4>
<p>By voluntarily submitting personal information to the OpenBeds-TreatmentConnection site, each User expressly authorizes OpenBeds to disclose the following non-identifying User information to such Treatment Providers selected by the User for the purposes of facilitating User’s referral for substance use or mental health treatment services:</p>

<ul>
    <li>Five-digit ZIP Code;</li>
    <li>Birth Year;</li>
    <li>Gender;</li>
    <li>Substance Use Information</li>
    <li>Health Insurance Status; and</li>
    <li>The contents of any free text information provided by User in the comment section at registration.</li>
    </ul>
<p><b className="highlight-p"> OpenBeds will never share your name, e-mail address, or any other identifying personal information with Treatment Providers or any other third-parties without your express consent.
</b></p>
<p>As noted above, any free text information you voluntarily provide in the comment section during registration<b className="highlight-p">  will be shared with Treatment Providers</b>. When providing information in the comment section, you should take care to avoid sharing any identifying personal information that you would want to keep confidential.
</p>

<h4>Use of OpenBeds-TreatmentConnection Not a Substitute For Medical Advice; No Endorsements Made For Treatment Providers</h4>

<p>THE INFORMATION PROVIDED TO USERS BY OPENBEDS THROUGH THE OPENBEDS-TREATMENTCONNECTION SERVICE IS FOR EDUCATIONAL/INFORMATIONAL PURPOSES ONLY AND IS NOT A SUBSTITUTE FOR THE EXPERTISE OR ADVICE OF A QUALIFIED MEDICAL PROFESSIONAL, COUNSELOR, OR OTHER HEALTH TREATMENT PROVIDER. THE PROVISION OF INFORMATION THROUGH OPENBEDS-TREATMENTCONNECTION DOES NOT CONSTITUTE MEDICAL ADVICE, AND NO COUNSELING OR OTHER TREATMENT RELATIONSHIP IS CREATED HEREUNDER BETWEEN USER AND OPENBEDS OR ANY MEDICAL PROFESSIONAL OR TREATMENT PROVIDER. </p>

<p>The inclusion or omission of any particular Treatment Provider in the information provided by OpenBeds through OpenBeds-TreatmentConnection to User does not imply any referral, endorsement, recommendation, or lack thereof by OpenBeds of such Treatment Provider.
</p>



<h4>OpenBeds-TreatmentConnection Content Provided “As-Is”; Not Responsible For Links to Third-Party Sites</h4>

<p>All content published on OpenBeds-TreatmentConnection is provided “AS IS” and “as available” without any representation or warranty of any kind either express or implied. OpenBeds hereby specifically disclaims any and all representations and warranties, express or implied, regarding site content, functionality, or materials or information provided by us through the OpenBeds-TreatmentConnection site, including, without limitation, any warranty regarding infringement, ownership, accuracy, timeliness, completeness, and availability, and any implied warranties for merchantability, or fitness for a particular purpose. From time to time, OpenBeds-TreatmentConnection content may include unintentional technical inaccuracies or typographical errors. Changes and additions may be made by OpenBeds from time to time to any content contained on the OpenBeds-TreatmentConnection site.
</p>

<p>Where the OpenBeds-TreatmentConnection site provides or enables links to third-party sites, such as those managed by Treatment Providers, it does so for informational purposes only and OpenBeds is in no way liable for your use of the same.
</p>


<p><b>July 2019</b></p>






                      </div>
                  </div>
              </div>
           </Modal.Body>
          <Modal.Footer>
             <Button onClick={this.acceptTerms} className="btn-primary">Accept</Button>
            <Button onClick={() => this.handleClose('agreement')}>Close</Button>
          </Modal.Footer>
        </Modal>



            <TreatmentDefinitionsModal
                show={this.state.showTreatmentInfoModal}
                treatmentTypes={this.state.attributes ? this.state.attributes.service_type : []}
                onClose={() => { this.handleClose('treatment')}}
            />
    <NotificationContainer/>
    <Footer/>
        </div>
        </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  let errors = {}
  if(!formValues.phone || formValues.phone.length <= 9){
      if(formValues.phone){
          if(formValues.phone.length>=1){
              formValues.phone  = formValues.phone.replace(/[^\d]/g, "");
          }
      }
      errors.phone = "Please enter dedicated phone"
  }else{
      formValues.phone = formatPhoneNumber(formValues.phone)
  }

  return errors
}

let providerProfileForm = reduxForm({
    form: 'patient-contactme-form',
    validate
})(ContactMe);


const mapStateToProps=(state)=>{
   //  console.log(state)
    return {
        loginDetails:state.loginDetails,
        attributes:state.post,
        treatment:state.treatment,
        substances:state.substances,

        seekingTreatmentType:state.seekingTreatmentType,
        sendRequestMessage:state.sendRequest,
      }
  }


  providerProfileForm = connect(
      mapStateToProps,
      {
          fetchPosts,
          updateZipcode,updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment,sendRequest,clearRequestdata
      }
  )(providerProfileForm);

  export default providerProfileForm;
