import { useState, useEffect } from 'react';

export const CheckboxField = ({
    id,
    label,
    value,
    checked,
    handleChange,
    handleUpdate,
    ...props
  }) => {

    // State to hold the selected option object
    const [isChecked, setIsChecked] = useState(checked || false);

    useEffect(() => {
      setIsChecked(checked || false);
    }, [checked]);

    // Handler for when the checkbox is changed
    const handleOnChange = (e) => {
        const is_checked = e.target.checked;
      setIsChecked(is_checked);
      handleChange && handleChange(is_checked);
      handleUpdate && handleUpdate(is_checked);
    };

    const InputCheckbox = () => (
        <input
          type="checkbox"
          id={id}
          name={id}
          value={value}
          aria-label={label}
          checked={isChecked}
          onChange={handleOnChange}
          {...props}
        />
    )

    return (
      <div className="checkbox-fielď" id={`${id}-container`} data-testid={id}>
        {label? (
            <label className="label-def" htmlFor={id}>
                <InputCheckbox />
                <span className="ml-1">{label}</span>
            </label>
        ): (
          <InputCheckbox />
        )}        
      </div>
    );
};

export default CheckboxField;

