import { useState, useEffect } from 'react';
import AccessibleSelect from '../accessibility-helpers/accessible-select';

export const DistanceField = ({
    id,
    label,
    value,
    handleChange,
    handleUpdate,
    defaultOption = { value: 250, label: '250 Miles' },
    options = [
      { value: 5, label: '5 Miles' },
      { value: 10, label: '10 Miles' },
      { value: 15, label: '15 Miles' },
      { value: 25, label: '25 Miles' },
      { value: 50, label: '50 Miles' },
      { value: 100, label: '100 Miles' },
      { value: 250, label: '250 Miles' },
      { value: 500, label: '500 Miles' },
      { value: '', label: 'Any distance' },
    ],
    ...props
  }) => {

    // State to hold the selected option object
    const [selectedOption, setSelectedOption] = useState(value || defaultOption);

    useEffect(() => {
      setSelectedOption(value || defaultOption);
    }, [value]);

    // Handler for when the selection changes
    const handleOnSelectDistance = (option) => {
      setSelectedOption(option);
      handleChange && handleChange(option);
    };

    const finalizeSelection = () => {
      handleUpdate && handleUpdate(selectedOption);
    };

    return (
      <div className="distance-fielď" id={`${id}-container`} data-testid={id}>
        {label && <label className="label-def" htmlFor={id}>{label}</label>}
        <AccessibleSelect
          id={id}
          inputId={id}
          ariaLabel={label || 'Distance filter'}
          className="searchinput"
          value={selectedOption}
          options={options}
          onChange={handleOnSelectDistance}
          onBlur={finalizeSelection}
          {...props}
        />
      </div>
    );
};

export default DistanceField;
