import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Helping a Loved One with Mental and/or Substance Use Disorders - Treatment Connection');
    useMetaDescription('Learn how you can offer support and connect loved ones with mental health and/or substance use disorder to treatment and begin their recovery journey.');

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Helping a Loved One Dealing with Mental and/or Substance Use Disorders</h1>
                    <p>You may suspect or discover your loved one is dealing with a mental illness, drinking too much, or using drugs. As a family member, you can play a central role in getting them the help they need. The Substance Abuse Mental Health Services Administration (SAMHSA) offer this guide to starting the conversation with a loved one dealing with mental health and/or substance use disorders.</p>
                    <p>Family members may be more likely to notice when their loved ones are experiencing changes in mood or behavior. Being able to offer support, family members can connect those in need with treatment, resources, and services to begin and stay on their recovery journey.</p>

                    <h2>How you can help</h2>
                    <ul>
                        <li>Remember mental and substance use disorders are treatable. People can, and do, recover. Family support can make all the difference.</li>
                        <li>Talk to your loved one. Express your concern and tell them that you're there to help. Create a judgement-free and loving environment to foster conversation and openness.</li>
                        <li>Be open. Discuss your family history of mental illness or drug and alcohol use, if relevant. It may help your loved one feel less alone.</li>
                        <li>Show compassion. Be patient as you help your loved one locate resources and treatment services.</li>
                        <li>Be sure to care for yourself, too. Being a caregiver can be highly stressful and emotionally draining.</li>
                    </ul>

                    <h2>How to talk to your loved one</h2>
                    <ul>
                        <li>Identify an appropriate time and place. Consider a private setting with limited distractions, such as at home or on a walk.</li>
                        <li>Express concerns and be direct. Ask how they are feeling and describe the reasons for your concern. </li>
                        <li>Acknowledge their feelings and listen. Listen openly, actively, and without judgement.</li>
                        <li>Offer to help. Provide reassurance that mental and/or substance use disorders are treatable. Help them locate and connect to treatment services.</li>
                        <li>Be patient. Recognize that helping your loved one doesn't happen overnight. Continue reaching out with offers to listen and help.</li>
                    </ul>

                    <h2>What to say</h2>
                    <ul>
                        <li>"I've been worried about you. Can we talk? If not, who are you comfortable talking to?"</li>
                        <li>"I see you're going through something. How can I best support you?"</li>
                        <li>"I care about you and am here to listen. Do you want to talk about what's been going on?"</li>
                        <li>"I've noticed you haven't seemed like yourself lately. How can I help?"</li>
                    </ul>

                    <p>For more information, visit <a rel="noreferrer" target="_blank" href="https://www.samhsa.gov/families">https://www.samhsa.gov/families</a>.</p>
                </div>
            </div>
        </div>
    )
}
