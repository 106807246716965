import React from "react";
import Select from "react-select";
import ClearIndicator from "./accessibility-select-components/clear-indicator";
import Input from "./accessibility-select-components/input";
import Option from "./accessibility-select-components/option";
import MenuList from "./accessibility-select-components/menu-list";
import MultiValueRemove from "./accessibility-select-components/multi-value-remove";
import MultiValue from "./accessibility-select-components/multi-value";
import SelectContainer from "./accessibility-select-components/select-container";
import screenReaderAnnouncement from "../accessibility-helpers/screenReaderAnnouncement";
import { setTimeout } from "core-js";
import SingleValue from "./accessibility-select-components/single-value";

/*
  AccessibleSelect
  (An accessible wrapper around the react-select library.)

  @date 10/16/20
  @author Tyler Akin, Appriss Health
  
  -----

  Quick lil` README:
  The original react-select library is largely accessible but not along best-practices 
  for implementing accessibility, which causes issues when auditing for compliance.
  This proprietary, 'wrapper' component is intended to mitigate those issues, 
  with the intention of aligining with accessibility compliance checks.

  Original Component Resources
  - Original Source: https://github.com/jedwatson/react-select
  - Original Source Documentation: https://react-select.com/home
  - Replacable Components: https://react-select.com/components#replaceable-components

  The documentation is decently detailed but difficult to understand.

  Significant changes to note:
  - This proprietary component largely focuses on customizing child components 
    of react-select (See third resource, which is referenced earlier in this file).
    All custom components referenced in this file are imported from the folder:
    `src/components/accessibility-helpers/accessibility-select-components`
  - The react-select screen reader component is helpful but cannot be modified enough
    to align with proper accessibility, particularlly in light of other custom changes
    being made. The react-select screen reader component has been hidden with css,
    and the proprietary screenReaderAnnouncement component is being used throughout the custom
    components.
  - Note: other additions for accessibility include unique values for the select fields
    and aria values.
*/

class AccessibleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkForAnOpenMenu: false,
      activeDescendentOption: null,
      inputValue: '',
    };

    this.setUniqueIdentifier = Math.floor(Math.random() * 10000);
    this.uniqueMultivalueSelect =
      "multi_value_select_" + this.setUniqueIdentifier;
    this.menuListElement = React.createRef();
    this.theInput = React.createRef();
    this.selectContainerElement = React.createRef();
    this.handleActiveDescendentSetting = this.handleActiveDescendentSetting.bind(
      this
    );
  }

  objectIterator = (objectElement) => {
    var res = [];
    for (var i in objectElement) {
        if (objectElement.hasOwnProperty(i)) {
            res.push(objectElement[i]);
        }
    }
    return res;
  }
  
  /* Helper functions for setting state-controled attributes */
  handleActiveDescendentSetting = () => {
    let getTheList = this.menuListElement.current;

    let findElement;
    let getId;

    if (getTheList) {
      findElement = getTheList.querySelector("[data-selected-id]");
      if (findElement) {
        getId = findElement.getAttribute("id");
        this.setState({ activeDescendentOption: getId });
      } else {
        this.setState({ activeDescendentOption: null });
      }
    }
  };

  toggleMenuOpenStatus = () => {
    this.setState({
      checkForAnOpenMenu: !this.state.checkForAnOpenMenu,
    });

    setTimeout(() => {
      let status = this.state.checkForAnOpenMenu
        ? "combobox expanded"
        : "combobox collapsed";
      screenReaderAnnouncement(status, "polite");
    }, 300);
  };

  /* 
    Replaced React-Select Components 
    Note: There is an additional layer of abstraction to each of these components;
    see the imports for the individual component files,
    which  actually call the original React-select components.
  */
  selectInput = (props) => {
      let valueUpdate = this.props.value;

      if (! this.props.isMulti) {
          valueUpdate = this.objectIterator(valueUpdate)[1];
      } else {
        valueUpdate = '';
      }
 
    return (
      <React.Fragment>
        <Input
          {...props}
          aria-label={this.props.ariaLabel}
          aria-expanded={this.state.checkForAnOpenMenu}
          aria-owns={this.uniqueMultivalueSelect}
          aria-required={this.props.ariaRequired}
          aria-invalid={this.props.ariaInvalid}
          aria-describedby={this.props.inputId + '_selectFieldValueIdentifier'}
          aria-activedescendant={this.state.activeDescendentOption}
          onKeyUp={this.handleActiveDescendentSetting}
        />
        {/* 
        Accessibility work-around; 
        Hidden span allows the value for the single value slect fields to be
        read out.
        */}
        <span className="sr-only" id={this.props.inputId + '_selectFieldValueIdentifier'}>
          {valueUpdate}
        </span>
        </React.Fragment>
    );
  };

  menuList = (props) => {
    return (
      <div ref={this.menuListElement}>
        <MenuList
          {...props}
          id={this.uniqueMultivalueSelect}
          toggleMenuOpenStatus={this.toggleMenuOpenStatus}
        />
      </div>
    );
  };

    multiValue = (props) => {
    return (
      <MultiValue
        {...props}
        labelId={this.props.labelId}
        selectContainerRef={this.selectContainerElement.current}
      />
    );
  };

   selectContainer = (props) => {
    return (
      <div ref={this.selectContainerElement}>
        <SelectContainer
          {...props}
        />
      </div>
    );
  };

  singleValue = (props) => {
    return (
        <SingleValue
          {...props}
          triggerUpdate={this.state.inputValue}
          inputReference={this.theInput.current}
        />
    );
  };

  render() {

    const setAccessibleComponents = {
      MultiValueRemove,
      MultiValue: this.multiValue,
      ClearIndicator,
      Input: this.selectInput,
      MenuList: this.menuList,
      Option,
      SelectContainer: this.selectContainer,
      SingleValue: this.singleValue
    };

    const grayColor = '#737373';
    const whiteColor = '#ffffff';

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: (state.isHovered || state.isFocused) && !state.isSelected ? whiteColor : provided.color,
        backgroundColor: (state.isHovered || state.isFocused) && !state.isSelected 
          ? grayColor 
          : provided.backgroundColor,
        ':hover': {
          backgroundColor: state.isSelected ? provided.backgroundColor : grayColor,
          color: state.isSelected ? provided.color : whiteColor
        }
      }),
    };


    return (
      <React.Fragment>
        <Select
          {...this.props}
          aria-expanded={this.state.checkForAnOpenMenu}
          components={setAccessibleComponents}
          styles={customStyles} 
        />
      </React.Fragment>
    );
  }
}

export default AccessibleSelect;