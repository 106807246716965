var simulateClick = require('./simulate-click').default

const simulateKeyboardSelectionClick = (event) => {
	// accessibility requirement
    let eventKeyCode = event.key || event.keyCode;
    if (eventKeyCode === "Enter") {
        simulateClick(event.target);
    } else if (eventKeyCode === " ") { // space
        simulateClick(event.target);
        event.preventDefault();
        event.stopPropagation();
    }
};

export default simulateKeyboardSelectionClick;