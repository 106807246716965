import React from "react";
import { Helmet } from "react-helmet";

export default () => {
    return (
        <div className="edu_container">
        <Helmet>
          <title>Questions to Ask About Treatment Services - Treatment Connection</title>
          <meta name="description" content="Finding the right treatment facility can be difficult. Learn what questions you should ask when searching for substance use or mental health treatment." data-react-helmet="true"></meta>

        </Helmet>

            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Questions to ask about treatment services</h1>

                    <p>Deciding whether to enter treatment can be a difficult decision. There are various types
                        of treatment options and
                        finding the best one for you is possible. This chart provides some questions you may
                        want to ask about treatment
                        services.</p>
                    <h3>Questions</h3>
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td>
                                How do I know if this is a good treatment facility?
                            </td>
                            <td>
                                All treatment programs are required to be licensed by the state. Rules and
                                regulations vary by state. The program should also be accredited. Accredited
                                programs have undergone review by the accrediting body and therefore adhere to
                                strict standards of clinical quality.
                            </td>
                        </tr>
                        <tr>
                            <td>What type of services do they offer?</td>
                            <td>There are many different types of treatment. For example, inpatient,
                                outpatient and intensive outpatient are just a few. There are also MAT
                                programs which offer combined medication and behavioral therapies
                                to assist with overcoming addiction. You should work with your
                                provider to determine what level of care is the best fit for you
                            </td>
                        </tr>
                        <tr>
                            <td>What type of support or follow up services are provided?</td>
                            <td>Many people require ongoing support and medication. Quality
                                treatment programs will provide ongoing counseling and/recovery
                                support or will refer you to another program offering a lower level of
                                care. Other support services include recovery housing, sober support,
                                and peer support.
                            </td>
                        </tr>
                        <tr>
                            <td>How long will the treatment last?</td>
                            <td>The length of treatment depends on the level of care and your
                                individualized need. Inpatient programs can last one week, 30 days or
                                more and outpatient programs can be ongoing. Have a discussion with
                                your provider to determine the best option for you.
                            </td>
                        </tr>
                        <tr>
                            <td>Will I miss days of work/school?</td>
                            <td>This depends on the level of treatment. Outpatient and Intensive
                                outpatient treatment will allow you to participate while attending to
                                your other obligations. Most will have a minimum number of hours
                                that you must be engaged in treatment. However, inpatient treatment
                                will require you to remain in the facility to complete the program.
                            </td>
                        </tr>
                        <tr>
                            <td>Will my family/friends be able to visit?</td>
                            <td>Facilities have different rules about visitors. Some will allow visitors
                                once a week and some won’t allow visitors at all. And some programs
                                will offer family counseling to get the family involved in your
                                treatment.
                            </td>
                        </tr>
                        <tr>
                            <td>Can they accommodate other medical conditions?</td>
                            <td>You will want to tell the staff about any medical conditions or physical
                                limitations to ensure they are able to accommodate.
                            </td>
                        </tr>
                        <tr>
                            <td>How will I pay for treatment?</td>
                            <td>If you have insurance, you will need to ensure that the facility accepts
                                your insurance. If you do not have insurance many facilities offer other
                                ways to pay. Make sure you ask about the cost and payment options
                                for treatment
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p style={{textWeight: "bold"}}>Sources:</p>
                    <ol>
                        <li>SAMHSA. Finding Quality Treatment</li>
                        <li>National Institute on Alcohol Abuse and Alcoholism. Ten Questions to Ask Alcohol
                            Treatment Programs.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
