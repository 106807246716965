import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '../autocomplete/Autocomplete';
import '../autocomplete/Autocomplete.scss';
import { performLocationSearch} from '../../actions/selectattributes';
import { Messages } from '../../components/messages';

export const LocationField = ({
  id='geo_location_search',
  label='Location',
  className = 'form-control homeinput',
  labelClass='label-def d-block',
  name='location',
  isLoading = false,
  defaultValue = {},
  handleUpdate,
  handleChange,
  disableForm = () => {},
  attributes,
  parent,
  placeholder = 'Search for Zip Code, County or State',
  autoComplete = 'off',
  required = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const [searchLocation, setLocation] = useState(defaultValue);
  const [locationText, setLocationText] = useState(searchLocation?.Text || '');
  const [locationPlaceId, setLocationPlaceId] = useState(searchLocation?.PlaceId || '');
  const [searchResults, setSearchResults] = useState([]);
  const [locationError, setLocationError] = useState('');
  const [isSearching, setIsSearching] = useState(isLoading);

  const timeoutRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    setIsSearching(isLoading);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      abortControllerRef.current.abort();
    };
  },[isLoading]);

  useEffect(() => {
    if (locationError) {
      handleSelectLocation({}); // Clear the selected location
    }
  }, [locationError]);

  const performSearch = useCallback((value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    abortControllerRef.current.abort(); // Abort previous requests
    abortControllerRef.current = new AbortController(); // Create a new AbortController for the new request

    timeoutRef.current = setTimeout(() => {
      const formData = new FormData();
      formData.append('search', value);
      if(value?.length < 2) {
        return;
      }
      setIsSearching(true);
      dispatch(performLocationSearch(formData, abortControllerRef.current))
      .then((results) => {
        setSearchResults(results);
        if (results?.length === 0){
          setLocationError(Messages.UNSUPPORTED_STATE);
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          setLocationError(Messages.LOCATION_ERROR?.replace(':error:', error.message || ''));
        }
      })
      .finally(() => {
        setIsSearching(false);
      });
    }, 1000); // Delay of 1s
  }, [dispatch]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocationText(value);
    performSearch(value);
  };

  const handleKeypress = (e) => {
    disableForm(true)
    setLocationError('');
    if (e.key === 'Enter' && !isSearching) {
      e.preventDefault();
      handleInputChange(e);
    }
  }

  const handleSelectLocation = (result) => {
    setLocation(result);
    setLocationText(result.Text);
    setLocationPlaceId(result.PlaceId);
    handleChange && handleChange(result);
    handleUpdate && handleUpdate(result);
    setSearchResults([]);
  };

  const renderLocationError = (value) => {
    if (!value) {
      setLocationError(Messages.EMPTY_LOCATION);
    } else if (value.length < 2) {
      setLocationError("Enter at least 2 characters");
    }
  };
  const handleBlur = (e) => {
    const udpatedValue = e.target.value;
    renderLocationError(udpatedValue);
    locationError !== '' || disableForm(false) 
  }

  return (
    <>
      {label && <label className={labelClass} htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
        {isSearching && (<span className="fa fa-spinner fa-spin float-right"></span>)}
        </label>}
      <Autocomplete
        id={id}
        searchResults={searchResults}
        onSelectSearchResult={handleSelectLocation}
        selectedPlaceId={locationPlaceId}
        {...props}
      >
        <input
          id={`search-${id}`}
          type="search"
          name={`${name}_autocomplete`}
          placeholder={placeholder}
          className={className}
          value={locationText}
          onChange={handleInputChange}
          onKeyDown={handleKeypress}
          onBlur={handleBlur}
          aria-label="Search for Location"
          required
          aria-describedby={`search-${id}`}
          data-testid={`search-${id}`}
          autoComplete={autoComplete}
        />
      </Autocomplete>
      <span className="error" id={id} aria-live="polite">{locationError}</span>
    </>
  );
};

export default LocationField;
