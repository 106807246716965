import React from 'react';
import { withCookies } from 'react-cookie';
import { allowedEnvironments } from '../configuration/googleAnalytics';

const hostname = window && window.location && window.location.hostname;

const ignoreAnalyticsCollection = () => {
    if(allowedEnvironments.indexOf(hostname) > -1) {
        return false;
    } else {
        return true;
    }
}

class BannerGA extends React.Component {

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            hideGANotice: cookies.get('hideGANotice') || false,
            showCookieNotice: false
        };
    }

    componentDidMount() {
        const { cookies } = this.props;
        if(ignoreAnalyticsCollection()) {
            this.setState({ hideGANotice: true })
            return;
        } else {
            this.setState({ hideGANotice: cookies.get('hideGANotice') || false })
            return;
        }
    }

    acceptCookies() {
        const { cookies } = this.props;
        let dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 1); //calculate 24 hours token expiration
        cookies.set('hideGANotice', true, { path: '/', expires: dateNow });
        this.setState({ hideGANotice: true }); //set the state to hide the notice
    }

    handleClose = () => {
        this.setState({ showCookieNotice: false } );
    }

    handleShowCookieNotice = () => {
        this.setState({ showCookieNotice: true } );
    }

    render () {
        return (
        <>

        </>
        );
    }
}

export default withCookies(BannerGA);
