import React from "react";
import {connect} from "react-redux";
import {submitLogin} from "../../actions/login";
import Header from "../layout/header";
import Footer from "../layout/footer";
import {PhoneNumberLink} from "../generic/PhoneNumberLink";
import {getConfigurations} from "../../actions/selectattributes";

class ProviderNeedHelp extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        }
        //attempt to load configurations keep spinning loader on until it finishes
        this.configurationLoader();
    }

    async configurationLoader() {
        await this.props.getConfigurations();
        this.setState({showLoader: false});
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                {this.state.showLoader ? <div className="main-content-loader">
                        <div className="rotating-loader"/>
                    </div> :
                    <div className="container" role="main">
                        {/* <div className="row back_button_row">
                        <div className="col-md-12">
                            <div className="pull-right">
                            <button onClick={this.props.history.goBack} className="btn btn-primary">Back</button>
                            </div>
                        </div>
                    </div> */}
                        <div className="row privacy_policy_panel">

                            <div className="col-md-push-2 col-md-8">

                                <div className="panel panel-default">
                                    <div className="panel-body">

                                        <h3 className="contactTxt">Please contact System Administrator through Customer
                                            Support or by Phone:</h3>
                                        <div className="row contactData">
                                            <div className="col-xs-5 text-right contactLines">Contact:</div>
                                            <div className="col-xs-7 text-left contactLines">
                                            <span><a
                                                href={this.props.pfsConfigurations ? this.props.pfsConfigurations.customer_support_url : null}
                                                target="_blank" rel="noopener noreferrer">Customer Support</a></span>
                                            </div>
                                        </div>
                                        <div className="row contactData">
                                            <div className="col-xs-5 text-right contactLines">Phone:</div>
                                            <div className="col-xs-7 text-left contactLines">
                                            <span aria-label="Phone number">
                                                <PhoneNumberLink
                                                    phoneNumber={this.props.pfsConfigurations ? this.props.pfsConfigurations.support_phone_number : null}/>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="row contactData">
                                            <div className="col-xs-5 text-right contactLines">Hours:</div>
                                            <div className="col-xs-6 text-left contactLines">
                                                <span>{this.props.pfsConfigurations ? this.props.pfsConfigurations.customer_support_hours : null}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                <Footer/>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        pfsConfigurations: state.pfsConfigurations,
    }
}
export default connect(mapStateToProps, {getConfigurations})(ProviderNeedHelp);
