import React from "react";
import { Helmet } from "react-helmet";

export default () => {
    return (
        <div className="edu_container">
        <Helmet>
          <title>Addiction: Stages of Relapse - Treatment Connection</title>
          <meta name="description" content="Addiction is a chronic disease. Understanding the process of relapse is key in understanding a path to recovery. Here are the three stages of relapse." data-react-helmet="true"></meta>

        </Helmet>

            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Addiction: Stages of Relapse</h1>

                    <div>
                        Addiction is a chronic disease; therefore, relapse is considered a common aspect of the disease.
                        NIDA, The National
                        Institute on Drug Abuse, relates the relapse rate of addiction similar to those of other chronic
                        diseases such as asthma,
                        hypertension and diabetes, estimating it to be between 40 and 60 percent.
                    </div>

                    <h3>Relapse does not mean failure.</h3>
                    <p>
                        Understanding the process of relapse is key in establishing your path to recovery. Relapse can
                        be divided into 3 stages
                        and the earlier you are able to identify and begin using healthy coping skills the more likely
                        you are to see success.
                    </p>
                    <h3>The three stages</h3>
                    <ol>
                        <li>
                            <p><strong>Emotional Relapse:</strong> This stage is defined by poor self-care. It occurs
                                before one starts thinking about using.</p>
                            <p>Warning signs: anxiety, isolation, anger, keeping your feelings to yourself, not
                                participating in treatment, poor
                                eating and sleeping habits</p>
                            <p>What should I do? Recognize that you need to change your behaviors and practice
                                self-care. This includes
                                talking to someone about these emotions, exercise regularly, eat three healthy meals
                                every day, try to get 8
                                hours of sleep every night, practice good hygiene and find healthy activities that keep
                                you around others.
                            </p>
                        </li>
                        <li>

                            <p><strong>Mental Relapse:</strong> Think of this stage as a war of the mind; part of them
                                wants to use and part of them doesn’t.</p>
                            <p>Warning signs: thinking about people you used to use with, places and things that remind
                                you of when you
                                used, glamorizing past use, lying, thinking that you can use just once, and planning a
                                relapse</p>
                            <p>What should I do? Tell someone; a loved one or a professional about your urges. Identify
                                high risk situations and
                                create a plan to avoid use. Evidence based therapy can help you find ways to distract
                                you from your thoughts or
                                help restructure the thought pattern. Practice relaxation techniques such as
                                meditation.</p>

                        </li>
                        <li>
                            <p><strong>Physical Relapse:</strong> When the individual starts using again.</p>
                            <p>This is the stage people usually refer to when they think of relapse. The negative
                                emotions have become
                                overwhelming and a lack of coping skills have led to unhealthy thoughts that justify use
                                and finally you have
                                sought out and used the substance.</p>
                            <p>What should I do? Don’t give up. Get help immediately and be honest about your relapse.
                                Take time to
                                understand the emotional and mental stages that occur before the physical relapse. Learn
                                which emotions and
                                thoughts led here and how to avoid these or how to cope in the future.</p>
                        </li>
                    </ol>
                    <p>Rather than viewing a brief relapse as a failure, treat it as a challenge and try to get back on
                        track as soon as possible.
                    </p>
                    <p style={{textWeight: "bold"}}>Sources:</p>
                    <ol>
                        <li><a href={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4553654/"} target={"__blank"}>Relapse
                            Prevention and the Five Rules of Recovery. Yale Journal of Biology and Medicine. 2015 Sept;
                            88(3).</a></li>
                        <li>
                            <a
                                href={"https://www.drugabuse.gov/publications/drugs-brains-behavior-science-addiction/treatment-recovery"}
                                target={"__blank"}>
                                Treatment and Recovery. National Institute on Drug Abuse.
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
