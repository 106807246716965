import { useState, useEffect} from 'react';

export const SearchField = ({ 
    id = "search_terms_for_search", 
    label, 
    value = '', 
    placeholder = 'Enter Search Term(s)', 
    handleChange, 
    handleUpdate, 
    autoComplete = 'off',
    ...props 
  }) => {
    const [searchTermsError, setSearchTermsError] = useState(null);
    const [newSearchTerms, setNewSearchTerms] = useState(value);
  
    useEffect(() => {
      setNewSearchTerms(value);
    }, [value]);
  
    const validateSearchTerms = (searchTerms) => {
      if (!searchTerms || searchTerms.length === 0 || searchTerms.length >= 3) {
        setSearchTermsError(null);
        return true;
      }
      setSearchTermsError('Please enter minimum of three characters to see providers available for your state.');
      return false;
    };
  
    const handleSearchTermsChange = (e) => {
      const searchTerms = e.target.value;
      setNewSearchTerms(searchTerms);
      if (validateSearchTerms(searchTerms)) {
        handleChange && handleChange(searchTerms);
      }
    };
  
    const handleSearchTermsUpdate = () => {
      if (validateSearchTerms(newSearchTerms)) {
        handleUpdate && handleUpdate(newSearchTerms);
      }
    };
  
    const handleSearchTermsKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSearchTermsUpdate(newSearchTerms);
      }
    };
  
    return (
      <>
        {label && <label className="label-def" htmlFor={id}>{label}</label>}
        <input
          type="text"
          className="form-control searchinput"
          placeholder={placeholder}
          value={newSearchTerms}
          onChange={handleSearchTermsChange}
          onBlur={handleSearchTermsUpdate}
          onKeyDown={handleSearchTermsKeyPress}
          id={id}
          aria-describedby="search_terms_error_message"
          aria-invalid={!!searchTermsError}
          autoComplete={autoComplete}
          {...props}
        />
        <span className="error" id="search_terms_error_message">{searchTermsError}</span>
      </>
    );
  };

  export default SearchField