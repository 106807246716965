const simulateClick = (elem) => {
    var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    elem.dispatchEvent(evt);
    var evt2 = document.createEvent("MouseEvents");
    evt2.initMouseEvent("click", true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
    elem.dispatchEvent(evt2);

    var evt3 = new MouseEvent("mousedown", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    elem.dispatchEvent(evt3);
};

export default simulateClick;