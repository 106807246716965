import React from 'react';
import { Link } from 'react-router-dom';
import openbedsApi from './../../openbedsApi';
import { connect } from 'react-redux';
import { appUrls } from '../../appUrls';
import Footer from '../layout/footer';
import { apiUrl } from '../../apiurl';
import SigninForgotPassword from './SigninForgotPassword';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        const values = this.props.location.pathname.split('/');

        this.state = {
            emailError: false,
            email: '',
            success: false,
            successMessage: '',
            failure: false,
            failureMessage: '',
            showLoader: false,
            showSpinner: true,
            passwordError: '',
            autoReset: this.props.autoReset,
            validateToken: values[2],
        };
    }
    async componentWillMount() {
        if (this.props.history.location.state) {
            let transferredEmail = this.props.history.location.state.email
                ? this.props.history.location.state.email
                : '';
            let passwordError = this.props.history.location.state.passwordError
                ? this.props.history.location.state.passwordError
                : '';
            this.setState({ email: transferredEmail, passwordError: passwordError });
        }

        if (this.props.autoReset && this.state.validateToken) {
            this.sendAutoReset(this.state.validateToken);
        } else {
            this.setState({ showSpinner: false });
        }
    }

    sendAutoReset = (validateToken) => {
        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        };

        openbedsApi
            .get(apiUrl + '/auto_reset_link/' + validateToken, null, { headers: postheaders })
            .then((response) => {
                if (response.data.status === 'ERROR') {
                    this.setState({ passwordError: response.data.message, showSpinner: false });
                } else if (response.data.status === 'SUCCESS') {
                    this.setState({ success: true, successMessage: response.data.message, showSpinner: false });
                }
            });
    };

    componentDidUpdate(prevProps) {}

    isEmailvalidate = (e) => {
        let email = e.target.value;
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(email)) {
            this.setState({ emailError: false, email: email, failure: false });
        } else {
            this.setState({ emailError: true, email: email, failure: false });
        }
    };

    forgot = () => {
        this.setState({ showLoader: true });

        let details = {
            email: this.state.email,
        };

        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let parent = this;
        openbedsApi.post('/forgot_password', formBody, { headers: postheaders }).then(function (response) {
            //remove transfer request on any update
            parent.setState({ email: '', passwordError: '' });
            //  console.log(response)
            if (response.data.status === 'ERROR') {
                parent.setState({
                    failure: true,
                    failureMessage: response.data.message,
                    showLoader: false,
                });
            } else if (response.data.status === 'SUCCESS') {
                parent.setState({
                    success: true,
                    successMessage: response.data.message,
                    showLoader: false,
                });
            }
        });
    };

    submitForgot = () => {
        if (this.state.email != '' && this.state.emailError === false) {
            this.forgot();
        } else {
            if (this.state.email === '') {
                this.setState({ emailError: true });
            }
        }
    };
    render() {
        const { error } = this.props;

        return (
            <div>
                <div className="container" role="main">
                    <div className="row makeCenter">
                        <div className="col-lg-5 col-md-4  col-sm-4 loginForm">
                            <h3 className="text-center">
                                <Link to="/">
                                    <img
                                        src="/images/treatmentconnection_logo_header.png"
                                        className="loginImg"
                                        alt="Treatment Connection"
                                    />
                                </Link>
                            </h3>
                            {this.props.error && this.state.success === false ? (
                                <div className="alert alert-danger">{this.props.error}</div>
                            ) : (
                                ''
                            )}

                            {this.state.passwordError != '' ? (
                                <div className="alert alert-success">{this.state.passwordError}</div>
                            ) : (
                                ''
                            )}

                            <h4 className="text-center mt20">
                                {this.props.autoReset ? 'Reset Password' : 'Forgot Password'}
                            </h4>
                            <SigninForgotPassword fromChangePassword={false} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(ForgotPassword);
