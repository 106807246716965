function getUrlParameter(name) {
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(global.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export { getUrlParameter };


export function fetchUrlParameters(name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    if (name) {
        return urlParams.get(name) || '';
    } else {
        const allParameters = {};
        for (const [key, value] of urlParams.entries()) {
            allParameters[key] = value;
        }
        return allParameters;
    }
}
