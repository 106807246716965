import React from "react";
import { Pagination } from "react-bootstrap";
export const ListPagination = (props) => {
    const pageNumbers = [];
    const totalPages = +props.pageCount;
    const visiblePageCount = props.visiblePageCount ?? totalPages;
    const currentPage =
        props.currentPage !== null || typeof props.currentPage !== "undefined"
            ? props.currentPage.page
            : null;
    const onPageChange = props.onPageChange;

    if (totalPages === 1) {
        return null;
    }

    // Add ellipsis if totalPages > visiblePageCount
    if (totalPages > visiblePageCount) {
        if (currentPage <= 4) {
            for (let i = 1; i <= 5; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push("...");
            pageNumbers.push(totalPages);
        } else if (currentPage >= totalPages - 3) {
            pageNumbers.push(1);
            pageNumbers.push("...");
            for (let i = totalPages - 4; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            pageNumbers.push("...");
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push("...");
            pageNumbers.push(totalPages);
        }
    } else {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    }

    // add for left, center and right alignment
    const alignment = () => {
        if (props.alignment === "center") {
            return "pagination-align-center";
        } else if (props.alignment === "right") {
            return "pagination-align-right";
        } else {
            return "pagination-align-left";
        }
    };

    return (
        <div className={alignment()}>
            <Pagination size="lg">
                <Pagination.Prev
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &#10094;
                </Pagination.Prev>
                {pageNumbers.map((page, index) => {
                    if (page === "...") {
                        return (
                            <Pagination.Ellipsis key={index} disabled className="ellipsis" />
                        );
                    }
                    return (
                        <Pagination.Item
                            key={index}
                            active={currentPage === page}
                            activeLabel=""
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </Pagination.Item>
                    );
                })}
                <Pagination.Next
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    &#10095;
                </Pagination.Next>
            </Pagination>
        </div>
    );
};
