import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    submitLogin,
    sendRequest,
    clearRequestdata,
    submitLoginSecondFactor,
    userLoggedIn,
} from './../../actions/login';
import './../css/login.css';
import { connect } from 'react-redux';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import openbedsApi from '../../openbedsApi';
import csrfMiddleware from '../../infrastructure/axios/csrfMiddleware';
import { appUrls } from '../../appUrls';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Signin from './Signin';

function getCounter() {
    let counter = 0;
    return function () {
        return counter++;
    };
}
let count = getCounter();

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailError: false,
            pswdError: false,
            email: '',
            password: '',
            provider: false,
            showLoader: false,
            registerDone: 'false',
            validation: typeof this.props.match.params.token !== 'undefined',
            validationProcess: false,
            secondFactorAuthentication: false,
            secondFactorCode: '',
            secondFactorAuthenticationToken: null,
            loginDetails: props.loginDetails,
            errorMessage: null,
            cognitoForm: this.props.cognitoForm,
            displayCognitoError: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.sendRequestMessage.status === 'SUCCESS') {
                this.props.clearRequestdata();
                this.props.history.push('/patients/rrs?new_enquiry=1');
            } else if (this.props.sendRequestMessage.status === 'ERROR') {
                NotificationManager.error('', this.props.sendRequestMessage.message);
                this.props.clearRequestdata();
            }

            if (this.props.loginDetails !== this.state.loginDetails) {
                this.setState({
                    loginDetails: this.props.loginDetails,
                });
                if (this.props.loginDetails.status !== 'SUCCESS' && !this.state.validationProcess) {
                    if (
                        this.props.loginDetails.flag === 'USER_VALIDATION_TOKEN_NEEDED' &&
                        this.props.match.params.token
                    ) {
                        return;
                    }
                    this.setState({ errorMessage: this.props.loginDetails.message });
                } else {
                    this.setState({ errorMessage: null });
                }
            }
        }
    }
    async componentDidMount() {
        //let provider = this.props.loginDetails

        if (this.props.location.state) {
            if (this.props.location.state.loginTimeout) {
                NotificationManager.error('', 'You have been logged out due to account inactivity');
            }
        }
    }

    submitLogin = () => {
        this.setState({ errorMessage: null });
        if (
            this.state.email != '' &&
            this.state.password != '' &&
            this.state.pswdError === false &&
            this.state.emailError === false
        ) {
            this.setState({
                showLoader: true,
            });
            this.goLogin();
        } else {
            if (this.state.email === '') {
                this.setState({ emailError: true });
            }
            if (this.state.password === '') {
                this.setState({ pswdError: true });
            }
        }
    };

    submitSecondFactor = async () => {
        await this.props.submitLoginSecondFactor(
            this.state.secondFactorCode,
            this.state.secondFactorAuthenticationToken
        );
        await this.postLogin();
    };

    async goLogin() {
        csrfMiddleware.clearPromise();
        await this.props.submitLogin(this.state.email, this.state.password);
        await this.postLogin();
    }

    async postLogin() {
        if (this.props.loginDetails) {
            if (this.props.loginDetails.status === 'SUCCESS') {
                if ('TWO_FACTOR_AUTHENTICATION' === this.props.loginDetails.flag) {
                    this.showSecondFactorAuthentication();
                    return;
                }

                sessionStorage.userDetails = JSON.stringify(this.props.loginDetails);

                if (
                    this.props.loginDetails.type === 'Provider' ||
                    this.props.loginDetails.type === '4a provider' ||
                    this.props.loginDetails.type === 'Admin'
                ) {
                    this.props.history.push('/search-treatment-facilities');
                    this.setState({
                        showLoader: false,
                    });
                } else {
                    let orgid = sessionStorage.getItem('org_id');
                    if (orgid) {
                        this.props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
                    } else {
                        this.props.history.push(appUrls.SEARCH_RESULT_LIST);
                    }
                }
            } else if (this.props.loginDetails.flag === 'USER_VALIDATION_TOKEN_NEEDED') {
                if (typeof this.props.match.params.token !== 'undefined') {
                    this.setState(
                        {
                            validationProcess: true,
                        },
                        this.validatePatient
                    );
                } else {
                    this.setState({
                        showLoader: false,
                    });
                }
            } else if (this.props.loginDetails.error_constant === 'PASSWORD_EXPIRED') {
                this.props.history.push(appUrls.FORGOT_PASSWORD, {
                    passwordError: this.props.loginDetails.data.message,
                    email: this.state.email,
                });
            } else if (this.props.loginDetails.error_constant === 'USER_REQUIRE_COGNITO_SIGN_IN') {
                this.setState({
                    displayCognitoError: true,
                    errorMessage: this.props.loginDetails.data.message,
                });
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        }
    }

    validatePatient = () => {
        const postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };

        const data = new FormData();
        data.append('token', this.props.match.params.token);

        let parent = this;

        openbedsApi
            .post('/patient_validation', data, { headers: postheaders })
            .then((response) => {
                if (response.data.status === 'SUCCESS') {
                    this.setState({
                        showLoader: false,
                    });
                    sessionStorage.setItem('userDetails', JSON.stringify(response.data));
                    NotificationManager.success('', 'Account activated');
                    this.props.userLoggedIn(response.data);
                    if (response.data.orgid) {
                        //insert state id
                        sessionStorage.setItem('state', response.data.state);
                        sessionStorage.setItem('org_id', response.data.orgid);
                        sessionStorage.setItem('org_uuid', response.data.org_uuid);
                        sessionStorage.setItem('organization_services_id', response.data.organization_services_id);
                        sessionStorage.setItem('service_id', response.data.service_id);
                        sessionStorage.setItem('service_uuid', response.data.service_uuid);
                        this.props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
                    } else {
                        if (this.props.match.params.token) {
                            this.props.history.push(appUrls.SEARCH_RESULT_LIST);
                        } else {
                            this.props.history.push('/patients/rrs');
                        }
                    }
                } else {
                    NotificationManager.error('', response.data.message);
                    this.props.history.push('/');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.provider ? `Provider` : `User`} Login - Treatment Connection</title>
                </Helmet>

                <Header />
                <div className="container" role="main">
                    <div className="row">
                        <div className="col-md-6 col-md-push-1 col-lg-push-3 loginForm">
                            {this.state.registerDone && this.state.provider ? (
                                <div className="alert alert-success">
                                    {' '}
                                    Your request for approval has been received by OpenBeds. You will be notified by
                                    e-mail about acceptance. Please refer to your junk folder also for email
                                    notification, in case it ends up there.
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="col-md-8 col-md-push-2">
                                {this.state.validation === false ? (
                                    <>
                                        <h1
                                            className="text-center mt20"
                                            style={{ fontSize: '1.4em', color: '#17376F' }}
                                        >
                                            {this.state.provider ? 'Provider Login' : 'User Login'}{' '}
                                        </h1>
                                        <p className="text-center">
                                            {this.state.provider ? (
                                                <>
                                                    {' '}
                                                    <span className="provider_login_message">
                                                        To start the process of listing your treatment facilities,
                                                        please create an account.
                                                    </span>{' '}
                                                </>
                                            ) : (
                                                <span className="provider_login_message">
                                                    You only need to create an account if you plan to reach out to
                                                    treatment facilities and express interest through our anonymous
                                                    online portal.
                                                </span>
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <h4 className="text-center mt20">Validating User Account </h4>
                                        <p className="text-center">
                                            <span className="provider_login_message">
                                                To finish validating your account, please sign in:
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                            <br />

                            <div className="col-md-8 col-md-push-2">
                                {this.state.errorMessage != '' ? (
                                    <div id="errorMessage">
                                        <span className="required">{this.state.errorMessage}</span>
                                    </div>
                                ) : null}
                                {this.state.displayCognitoError ? (
                                    <>
                                        <b>
                                            <a href="/provider/signin">Please click here to sign in</a>
                                        </b>
                                        <br />
                                        <br />
                                    </>
                                ) : null}
                                {this.renderCognitoForm()}
                                {this.state.validation === false ? (
                                    <>
                                        <hr />
                                        <p>
                                            {this.state.provider === true ? (
                                                <a href="/providersignup" className="btn btn-default btn-block">
                                                    Create an Account
                                                </a>
                                            ) : (
                                                <a href="/patients/register" className="btn btn-default btn-block">
                                                    Create an Account
                                                </a>
                                            )}
                                        </p>
                                        <p>
                                            <Link
                                                to={appUrls.COGNITO_FORGOT_PASSWORD}
                                                className="btn btn-default btn-block"
                                            >
                                                Forgot password?
                                            </Link>
                                        </p>
                                        {this.state.provider === true ? (
                                            <p>
                                                <a href="/provider/help" className="btn btn-default btn-block">
                                                    Help
                                                </a>
                                            </p>
                                        ) : (
                                            <p>
                                                <a href="/patients/need_help" className="btn btn-default btn-block">
                                                    Help
                                                </a>
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <br />
                                        <br />
                                        <br />{' '}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
                <Footer />
            </div>
        );
    }
    renderCognitoForm() {
        return <Signin {...this.props}></Signin>;
    }

    showSecondFactorAuthentication() {
        this.setState({
            secondFactorAuthentication: true,
            secondFactorAuthenticationToken: this.props.loginDetails.requestToken,
            showLoader: false,
        });
    }

    noOpHandler = () => false;
}

const mapStateToProps = (state) => {
    //  console.log(state)
    return {
        loginDetails: state.loginDetails,
        sendRequestMessage: state.sendRequest,
    };
};

export default connect(mapStateToProps, {
    sendRequest,
    submitLogin,
    clearRequestdata,
    userLoggedIn,
    submitLoginSecondFactor,
})(Login);
