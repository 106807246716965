export function Autocomplete({ searchResults, onSelectSearchResult, selectedPlaceId, children }) {
    return (
        <>
            {children}
            <input type="hidden" name="placeId" value={selectedPlaceId} data-testid="placeId" />
            {searchResults.length > 0 && (
                <>
                    <div className="search-results" data-testid="search-results">
                        {searchResults.map((result, idx) => (
                            <div
                                key={idx}
                                id={'searchResult' + idx}
                                className="search-result"
                                onClick={(e) => onSelectSearchResult(result)}
                            >
                                {result.Text}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}

export default Autocomplete;