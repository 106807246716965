import React from "react";
import {Button, Modal} from "react-bootstrap";
import Linkify from 'react-linkify';

export class TreatmentDefinitionsModal extends React.Component {
  
    render() {

        return (
            <Modal show={this.props.show} onHide={this.handleClose} bsSize="large" aria-modal="true" aria-describedby="treatment_definition_modal_header" aria-labelledby="treatment_definition_modal_header" role="dialog">
                <Modal.Header closeButton>
                    <Modal.Title aria-labelledby="treatment_definition_modal_header">
                        <span className="treatModalHeader m-l-1" id="treatment_definition_modal_header"> Treatment Types</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="m-l-1">
                        <div className="text-center">
                            <img className="service-definitions" src="/images/treatmentconnection_logo_header.png" alt="Treatment Connection"/>
                        </div>
                        {this.props.treatmentTypes.map(this.renderTreatmentType)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose} data-dismiss="modal" aria-label="Close">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderTreatmentType = (serviceType, index) => {
        if(!serviceType.definition){
            return null;
        }
        let bulletGroup = serviceType.definition.split('{bullet}'); //this is a workaround for bullet list / dangerously set html
        let bulletGroupJSX = [];
        //if there is a bullet list, then make one
        if ( bulletGroup.length > 1 ) {
            for ( let i = 1; i < bulletGroup.length; i++ ) {
                bulletGroupJSX.push( <li key={i}>{bulletGroup[i]}</li> );
            }
            bulletGroupJSX = <ul> {bulletGroupJSX }</ul>;
        }
        return (
            <React.Fragment key={index}>
                <h4>{serviceType.service_type_title || serviceType.service_type}</h4>
                <p><Linkify>{bulletGroup[0]}</Linkify></p>
                { bulletGroupJSX }
            </React.Fragment>
        )
    };

    handleClose = () => {
        this.props.onClose();
    }
}
