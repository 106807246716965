import React from 'react';

class Redirect extends React.Component {
    componentDidMount(){
       // sessionStorage.clear()
      
        this.props.history.push('/provider/myprofile')
       
    }
    render() {
      return (
        <div>
            &nbsp;
        </div>
      );
    }
  }
  
  export default Redirect;