import React from "react";
import {connect} from "react-redux";
import { Helmet } from "react-helmet";
import {submitLogin} from "../../actions/login";
import Header from "../layout/header";
import Footer from "../layout/footer";
import {PhoneNumberLink} from "../generic/PhoneNumberLink";
import {getConfigurations} from "../../actions/selectattributes";

class ProviderNeedHelp extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        }
        //attempt to load configurations keep spinning loader on until it finishes
        this.configurationLoader();
    }

    async configurationLoader() {
        await this.props.getConfigurations();
        this.setState({showLoader: false});
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Help - Treatment Connection</title>
                </Helmet>
                <Header/>
                {this.state.showLoader ? <div className="main-content-loader">
                        <div className="rotating-loader"/>
                    </div> :
                    <div className="container" role="main">
                        {/* <div className="row back_button_row">
                        <div className="col-md-12">
                            <div className="pull-right">
                            <button onClick={this.props.history.goBack} className="btn btn-primary">Back</button>
                            </div>
                        </div>
                    </div> */}
                        <div className="row privacy_policy_panel">

                            <div className="col-md-push-2 col-md-8">
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <img src="/images/treatmentconnection_logo_header.png" className="helpImg"
                                             alt="Treatment Connection"/>

                                        <h4 className="helpHead">Contact</h4>
                                        <p>
                                            <b className="highlight-p darkblue"> OpenBeds-TreatmentConnection</b> technical
                                            support may be reached through Customer Support or by Phone:<br/>
                                            <div className="helpData">
                                                Contact: <a
                                                href={this.props.pfsConfigurations ? this.props.pfsConfigurations.customer_support_url : null}
                                                target="_blank" rel="noopener noreferrer">Customer Support</a><br/>
                                                Phone: <span><PhoneNumberLink
                                                phoneNumber={this.props.pfsConfigurations ? this.props.pfsConfigurations.support_phone_number : null}/></span><br/>
                                                Hours: <span>{this.props.pfsConfigurations ? this.props.pfsConfigurations.customer_support_hours : null}</span>

                                            </div>
                                        </p>
                                        <h4 className="helpHead">Log in or Create an Account as Treatment Provider</h4>
                                        <p>
                                            At <a
                                            href="https://TreatmentConnection.com">https://TreatmentConnection.com</a>,
                                            select the ‘Login/Join Provider Network’ button at the top right. If you
                                            have an existing account, log in directly from here.<br/><br/>
                                            Alternatively, you can create an account by clicking on the ‘Create an
                                            Account’ button. Fill the registration form and press the ‘Submit’ button.
                                            You will be notified if your credentials have been accepted and account
                                            created. You may login with your user ID and password.
                                        </p>

                                        <h4 className="helpHead">Configure your Organization’s Treatment Service(s)</h4>
                                        <p>
                                            Users of the <b
                                            className="highlight-p"> OpenBeds-TreatmentConnection</b> public-facing
                                            portal can see the details of your treatment service(s). To configure these,
                                            select ‘Service Administration’ from the dropdown menu under your name.
                                            Next, select ‘Add New Service’ and fill your service details at the ‘Manage
                                            Receiving Service Details’ page, and submit to save. You may configure
                                            additional services using the same procedure.

                                        </p>
                                        <h4 className="helpHead">Update the Availability of Treatment Service(s)</h4>
                                        <p>
                                            Once your treatment service is configured, you will see list of your
                                            service(s) at the ‘Update Service Availability’ tab. You can enter your
                                            service availability information in the table here. For inpatient or
                                            residential services, fill the beds available. For outpatient services, fill
                                            in next available appointment date and/or whether or not you have walk-in
                                            hours. Note that only whether or not you are accepting patients will be
                                            displayed to the public. You should keep this information current by
                                            updating at least once daily or whenever your status changes. Accordingly,
                                            on the Search page of OpenBeds-TreatmentConnection public facing portal,
                                            users searching for treatment will know whether or not you are taking
                                            patients at any point in time.

                                        </p>
                                        <h4 className="helpHead">Add Users to Service(s)</h4>
                                        <p>To add one or more Receiving Administrators, select ‘Manage Users’ from the
                                            dropdown menu under your name. Next, select the ‘Add Receiving
                                            Administrator’ button at the top right of the ‘Manage Users’ page. After
                                            filling out and submitting this form, the person whose account has been
                                            created will get an email notification with a link to complete account
                                            creation. The new Receiving Administrator(s) will be assigned to all
                                            services you are responsible for and will have the same administrative
                                            privileges.<br/><br/>
                                            To add one or more Receiving Providers to a specific service, select
                                            ‘Service Administration’ from the dropdown menu under your name. Next,
                                            select the service you would like to add Provider(s) to. You will be brought
                                            to the ‘Manage Receiving Service Details’ page for that service. Click on
                                            the ‘Add/Remove Receiving Provider’ button. Enter the provider’s name and
                                            email, and press submit. The person whose account has been created will get
                                            an email notification with a link to complete the account creation. The
                                            Receiving Provider cannot configure services or add/remove users.
                                        </p>
                                        <h4 className="helpHead">Monitor Requests and Communicate with Patients</h4>
                                        <p>
                                            The ‘Request Status’ page lists requests that you have received from users
                                            of the <b
                                            className="highlight-p"> OpenBeds-TreatmentConnection</b> public-facing
                                            portal. You can review the user’s information by clicking on the ‘i’ button
                                            at the ‘Request Status’ page. You can respond to the request by clicking on
                                            the arrow in the last column. This brings you to the ‘Treatment Request
                                            Messaging’ page. <br/> <br/>
                                            At the bottom left of the ‘Treatment Request Messaging’ there is a
                                            prepopulated text box. The text is what you had configured when you set up
                                            your service. However, you may edit it now before sending, should you wish.
                                            Press the ‘Send’ to send the message to the public user. They will receive
                                            your message by email. Their email address is kept confidential by the
                                            system. <br/> <br/>
                                            The text box at the bottom right of the ‘Treatment Request Messaging’ page
                                            allows you to write notes for yourself or for your team members who are also
                                            registered in the system. You can send notifications regarding messages sent
                                            to the public user or local messages to your team members as well by using
                                            the ‘cc’ dropdown menus. An audit trail of all messages and local actions
                                            are recorded at the top of the ‘Treatment Request Messaging’ page. <br/>
                                            <br/>
                                            Once transactions are complete, you may close a case at the ‘Treatment
                                            Request Messaging’ page, and should you wish, archive the case at the
                                            ‘Request Status’ page. <br/> <br/> <br/>

                                            September 2019

                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                <Footer/>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        pfsConfigurations: state.pfsConfigurations,
    }
}
export default connect(mapStateToProps, {getConfigurations})(ProviderNeedHelp);
