import React from 'react';
import {Link, Route} from 'react-router-dom'
import openbedsApi from './../../openbedsApi';
import {connect} from 'react-redux';
import ForgotPassword from './forgotpassword';
import ResetPassword from './resetpassword';
class validateToken extends React.Component{
    constructor(props) {
        super(props);
        const values = this.props.location.pathname.split('/');
        this.state = {       
            emailError:false,           
            email:'',
            success:false,
            successMessage:'',
            validateToken: values[2],
            failure:false,
            failureMessage:'', 
            showLoader:false,   
            status:'',
            error:'',      
     };
    } 
   
     async componentWillMount()
     {
        await this.validateToken()
     }
     validateToken()
     {
        let parent = this

        let details = {
            token:this.state.validateToken
        }

        let postheaders = {
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];

        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        openbedsApi.post('/validate_token',formBody,{headers: postheaders})
            .then(function (response) {

                // console.log(response)
                if(response.data.status==="ERROR"){
                    parent.setState({
                        status:response.data.status,
                        error:response.data.message,
                        showLoader:false
                    })
                
                }else if(response.data.status==="SUCCESS"){
                    parent.setState({
                        status:response.data.status, 
                        showLoader:false
                    })
                }


            })
            .catch(function (error) {
                /* console.log(error);*/
            });
     }

    render(){  
          
    return (
        <div>
           {
               this.state.status === 'ERROR' ?
                   <ForgotPassword {...this.props} error={this.state.error}/>
               :
               <ResetPassword token={this.state.validateToken}/>
           }
        </div>
    )
  }
}

const mapStateToProps=(state)=>{  
    return {}
  }
  
  export default connect(mapStateToProps,{})(validateToken);