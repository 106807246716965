import React from 'react';
import { Helmet } from "react-helmet";
import Footer from './../layout/footer'
import Header from './../layout/header'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import openbedsApi from './../../openbedsApi';
import {
    fetchPosts,
    updateDistance,
    updatePayment, updateSubstances, updateTreatment,
    updateZipcode, getStates
} from './../../actions/selectattributes'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Typeahead} from 'react-bootstrap-typeahead';
import Select from 'react-select'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {OverlayTrigger, Popover} from "react-bootstrap";
import queryString from "query-string";
import {appUrls} from "../../appUrls";
import {formatPhoneNumber} from "../../utils/phoneNumbers";
/*localStorage.contactInfo = ""
let orgData =[]*/
let disablefields = true
let mobileValid = true;
/*let data = {}
/!*if(localStorage.orgDetails){
   data = JSON.parse(localStorage.orgDetails)
}*!/
data.organization_address1 = data.address_line1
data.organization_address2 = data.address_line2
data.organization_website_url = data.org_url
data.organization_phone = data.org_phone_no
data.mobile = data.phone_number

//data.statevalue = data.state
data.zipcode = data.zip
data.first_name = data.fname
data.last_name = data.lname
data.contactMethods = data.contact_method*/
// data.contact_method_id={
//                     label:"Email",
//                     value:"3"
//                 }
// }

const changeState = (status) => {
    if(status===false){
        if (window.confirm("Are you sure you want to create a new organization?")) {
        disablefields = false
      }
    }
}

const contactMethodPopover = (
    <Popover id="popover-positioned-top" title="Contact Method Definitions">
        <p>If you are a <u><b>Referring Provider</b></u>, you will be notified using:</p>
        <ol>
            <li>Your selected <u>Contact Method </u> regarding messages sent to you by a receiving provider using the
                ‘Referral Request Messaging’ page of OpenBeds, and
            </li>
            <li> Your selected <u>Contact Method</u> regarding messages you are copied on by users in your organization
                using the ‘Referral Request Messaging’ page of OpenBeds.
            </li>
        </ol>
        <p>If you are a <u><b>Receiving Provider</b></u>, you will be notified using:</p>
        <ol>
            <li> Your <u>Service’s e-mail address</u> concerning:
                a) referrals to your Service from a referring provider using the ‘Referral Request’ page of OpenBeds,
                and
                b) subsequent messages from the referring provider using the ‘Referral Request Messaging’ page of
                OpenBeds.
            </li>
            <li> Your selected <u>Contact Method</u> regarding messages you are copied on by users in your organization
                using the ‘Referral Request Messaging’ page of OpenBeds.
            </li>
        </ol>
    </Popover>
);



class ProviderMyProfile extends React.Component{
    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search)
         this.state = {
          states:[],
          loadStates:false,
          statevalue:'',
          csrf:{},
          contact:"",

          treatment:[],
          orgnameOptions:[],
          showLoaderScr:true,
          user_id: values.user_id ? values.user_id : '',
          pending_users: values.pending_users ? true : false,
          org_management: values.org_management ? true : false,
          stateError:false,
          service_types:[],
          primaryService:[],
          additionalTreatment:[],
             profile: {
                 first_name: '',
                 last_name: '',
                 organization_name: '',
                 organization_phone: '',
                 organization_website_url: '',
                 organization_address1: '',
                 organization_address2: '',
                 city: '',
                 state: '',
                 zipcode: '',
                 email: '',
                 mobile: '',
                 contactMethods: '',
                 service_types:'',
             },
             providerOptions: [
                 { value: '8',label: 'Receiving Provider' },
                 { value: '4',label: 'Receiving Service Administrator' },
                 { value: '16',label: 'OpenBeds System Administrator' },
             ]
         }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.getStates();
    }

    componentWillMount(){
        let parent = this;

        let provider = this.props.loginDetails

        if(this.props.loginDetails.type=== "Provider" || this.props.loginDetails.type=== "4a provider" || this.props.loginDetails.type === "Admin"){
          this.props.fetchPosts()
          this.getProviderProfileDetails()

        }else{
            this.props.history.push(appUrls.USER_LOGIN)
        }
    }

    componentDidUpdate(prevProps) {
        let provider =  this.props.loginDetails

        if(prevProps.states!==this.props.states) {
            this.setState({states: this.props.states});
        }
    }

    getProviderProfileDetails = () => {
        let parent = this

        let access = this.props.loginDetails.access_token

        let userDetails = sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails) : ""

        let details = {}
        if(this.state.user_id !== '') {
            details.user_id = this.state.user_id;
        }

        let postheaders = {
            'access-token':access,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];

        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        openbedsApi.post('/provider_profile_details',formBody,{headers: postheaders})
            .then(function (response) {


                if(response.data.data.length != 0) {


                    let providerProfile = {}
                    providerProfile = {
                        first_name: response.data.data.fname,
                        last_name: response.data.data.lname,
                        organization_name: response.data.data.organization_name,
                        organization_phone: response.data.data.org_phone_no,
                        organization_website_url: response.data.data.org_url,
                        organization_address1: response.data.data.address_line1,
                        organization_address2: response.data.data.address_line2,
                        city: response.data.data.city,
                        state: response.data.data.state,
                        zipcode: response.data.data.zip,
                        email: response.data.data.email,
                        email_hidden: response.data.data.email,
                        mobile: response.data.data.phone_number,
                        contactMethods: response.data.data.contact_method,
                        role: response.data.data.role,
                        admin_activated: response.data.data.admin_activated,

                    }
                    parent.updateList(response.data.data)

                    parent.setState({
                        profile: providerProfile,
                        showLoaderScr: false
                    }, () => {
                        parent.props.initialize( parent.state.profile );
                    });

                }


            });
    }

    statesVal = () =>{
        return this.state.states.states.map(state=>{
            return <option value={state.id} key={state.id}>{state.name}</option>
        })
    }

    renderError = ({error,touched},isValid,checkvalid) => {
        if(error && touched && (isValid || checkvalid)){
            return <span className="error">{error} </span>

        }
    }

    updateContact = (e) => {
        this.setState({contact:e.target.value})
    }

    stateInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className, options}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }

        return (
            <div className={elemClass}>
                <label className="control-label">
                    {label}
                    {
                        isValid ? <span className="text-red strongFont font10">*</span>: false
                    }
                    {
                        pretext
                    }
                </label>
                <select  {...input}  className="form-control" value={input.value} >
                    <option value="">-- Select State--</option>
                    { options ?
                        options.states.map(state=>{
                            return <option value={state.id} key={state.id}>{state.name} ({state.code})</option>
                        })
                        : false
                    }
                </select>
                {/* <input {...input} className="form-control serviceAddressLine1 s_address"  placeholder={placeholder}  title={title} type="text" readonly={readonly} type={type}/> */}
                {this.renderError(meta,isValid,checkvalid)}
            </div>
        )
    }

    selectInputContact = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
        <div className={elemClass}>
            {/* <label className="control-label">
            {label}
            {
                isValid ? <sapn className="text-red strongFont font10">*</sapn>: false
            }
            {
                pretext
            }
            </label> */}
             <label className="control-label">Select Contact Method<span className="text-red strongFont font10">*</span> <small>(may change later from My Profile)</small>
                 <OverlayTrigger trigger="click" placement="top" overlay={contactMethodPopover} rootClose>
                     <a href="javascript:void(0);"><span className="glyphicon glyphicon-question-sign" aria-hidden="true" data-toggle="tooltip" data-placement="left"></span></a>
                 </OverlayTrigger>

                 </label>

            <select {...input} className="form-control" id="contactMethods" required="" aria-required="true">
													<option value="">-- Select --</option>
													<option value="1">Call Institution</option>
													<option value="2">Call Mobile</option>
													<option value="3">E-Mail</option>
													<option value="4">Use OpenBeds site for messaging</option>
													<option value="5">Text Mobile</option>
												</select>
            {/* <input {...input} className="form-control serviceAddressLine1 s_address"  placeholder={placeholder}  title={title} type="text" readonly={readonly} type={type}/> */}
            {this.renderError(meta,isValid,checkvalid)}
        </div>
        )
    }

    selectInput = (props) =>{
        let elemClass = props.meta.error && props.meta.touched ? "form-group has-error" : "form-group" ;
        return (
            <div className={props.elemClass}>
                <label className="control-label">{props.label}
                    {
                        props.isValid ? <span className="text-red strongFont font10">*</span>: false
                    }
                </label>

                <select  {...props.input} disabled={props.readonly} className="form-control" value={props.input.value} placeholder={props.placeholder}  >

                    { props.options ? (
                            <>
                                {
                                    props.options.map(o => {
                                        return <option value={o.value} key={o.value}>{o.label}</option>
                                    })
                                }
                            </>)
                        : false
                    }
                </select>
                {/*<Select {...props.input} value={props.input.value}  defaultValue={props.input.value} options={props.options} onChange={props.input.onChange} className="searchinput" placeholder={props.placeholder} isMulti={props.multi} />*/}
                {this.renderError(props)}
            </div>
        )
    }
    radioInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
            return (
                <label className={elemClass}>&nbsp;

                    {/* {
                readonly === true ? "abc": false
            } */}

                    <input {...input} placeholder={placeholder}
                           title={title} type="text" disabled={readonly} type={type}/>{label}
                    {this.renderError(meta, isValid, checkvalid)}
                </label>
            )
    }

    textInput = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        if(type !== 'hidden') {
            return (
                <div className={elemClass}>
                    <label className="control-label">
                        {label}
                        {
                            isValid ? <span className="text-red strongFont font10">*</span> : false
                        }
                        {
                            pretext
                        }
                    </label>
                    {/* {
                readonly === true ? "abc": false
            } */}

                    <input {...input} className="form-control serviceAddressLine1 s_address" placeholder={placeholder}
                           title={title} type="text" disabled={readonly} type={type}/>
                    {this.renderError(meta, isValid, checkvalid)}
                </div>
            )
        } else {
            // console.log(input);
            return ( <input {...input} type={type} /> );
        }
    }
    textInputauto = ({checkvalid,input,label,placeholder,isValid,title,readonly,pretext,type,meta,className}) =>{
        let elemClass = meta.error && meta.touched &&  (isValid || checkvalid) ? "form-group has-error" : "form-group" ;
        if(className){
            elemClass =  elemClass+" hide"
        }
        return (
        <div className={elemClass}>
            <label className="control-label">
            {label}
            {
                isValid ? <span className="text-red strongFont font10">*</span>: false
            }
            {
                pretext
            }
            </label>
            <Typeahead
               {...input}
               disabled
                options={this.state.orgnameOptions}
                />
          {/* <input {...input} className="form-control serviceAddressLine1 s_address"  placeholder={placeholder}  title={title} type="text" readonly={readonly} type={type}/> */}
            {this.renderError(meta,isValid,checkvalid)}
        </div>
        )
    }

    formSubmit = (formValues) => {
        let provider =  this.props.loginDetails

        this.setState({showLoader:true})
//        let details = formValues

        let details = {};

        details.contact_method = formValues.contactMethods
        details.phone_number=formValues.organization_phone
        details.org_url = formValues.organization_website_url
        details.address_line1 = formValues.organization_address1
        details.address_line2 = formValues.organization_address2
        details.city = formValues.city
        details.state = formValues.state
        details.zip = formValues.zipcode
        details.mobile = formValues.mobile;

        if(this.state.user_id !== '') {
            details.user_id = this.state.user_id;

            if(this.props.loginDetails.type === 'Provider' || this.props.loginDetails.type === 'Admin') {
                if(formValues.password !== undefined && formValues.password !== '') {
                    details.password = formValues.password;
                }
            }

            if(this.props.loginDetails.type === 'Admin') {
                if(JSON.stringify(formValues.email) !== JSON.stringify(this.state.profile.email)) {
                    details.email = formValues.email.trim();
                }
                if(JSON.stringify(formValues.first_name) !== JSON.stringify(this.state.profile.first_name)) {
                    details.fname = formValues.first_name;
                }
                if(JSON.stringify(formValues.last_name) !== JSON.stringify(this.state.profile.last_name)) {
                    details.lname = formValues.last_name;
                }
                details.admin_activated = formValues.admin_activated;

                if(details.admin_activated === '1') {
                    details.status = 1;
                } else {
                    details.status = 0;
                }
            }
        }

        let postheaders = {
            'access-token':this.props.loginDetails.access_token,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];
          for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          let parent = this
            openbedsApi.post('/provider_myprofile',formBody,{headers: postheaders})
            .then(function (response) {
               //  console.log(response)
                if(response.data.status==="ERROR" || response.data.status==="Failure"){
                    NotificationManager.error('', response.data.message);
                    parent.setState({
                        showLoader:false
                    })
                }else if(response.data.status==="SUCCESS"){
                    NotificationManager.success('', response.data.message);
/*                    localStorage.removeItem('orgDetails');*/
                   // NotificationManager.success('', 'Redirecting to Login');
                    parent.setState({
                        showLoader:false
                    })
                   setTimeout(()=>{
                    //    localStorage.removeItem('reloadAfter')
                    window.location.reload()
                    //    parent.props.history.push('/redirect')
                    },500)
                }
            });
    }

    

    updateUsStates = (event) => {
        let stateError = false
        if(event.target.value === "" || event.target.value === "-- Select State--"){
            stateError = true
        }
        this.setState({state:event.target.value,stateError})

    }
    additionalTypeOptions = () => {
        let serviceTypesArr = []
        if(this.props.attributes!==null){
            this.props.attributes.data.service_type.forEach(service=>{
                let substancesOption = {}
                substancesOption.value = service.id
                substancesOption.label = service.service
                serviceTypesArr.push(substancesOption)
            })
        }
        let additionalArr = serviceTypesArr.filter(ser=>{
             return ser.value !== this.state.primaryService
        })
        return additionalArr
    }

    updateList = (arr) => {
        let isdeleted = arr.is_deleted

        let serviceTypeArrFinal = []
        if(arr.service_types !== null) {

            let serviceTypeArr = arr.service_types.split(',');
            this.props.attributes.data.service_type.forEach(state => {
                serviceTypeArr.forEach(insub => {
                    if (state.id === insub) {
                        let sub = {
                            label: state.service,
                            value: state.id
                        }
                        serviceTypeArrFinal.push(sub)

                    }

                })

            })
            // console.log(serviceTypeArrFinal)
            this.setState({

                service_types:serviceTypeArrFinal,

            })
        }


    }
    serviceTypeOptions = () => {
        let serviceTypesArr = []
        if(this.props.attributes!==null){
            this.props.attributes.data.service_type.forEach(service=>{
                let substancesOption = {}
                substancesOption.value = service.id
                substancesOption.label = service.service
                serviceTypesArr.push(substancesOption)
            })
        }

        return serviceTypesArr

    }

    selectInputMulti = ({input,label,placeholder,selvalue,selonChange,isValid,multi,options,meta,disabled}) =>{
        let elemClass = meta.error && meta.touched ? "form-group has-error" : "form-group" ;

        return (
            <div className={elemClass}>
            <label className="control-label">{label}
            {
                isValid ? <span className="text-red strongFont font10">*</span>: false
            }
            </label>
            {
                disabled ?
                <div>{selvalue}</div>
                :
                <Select value={selvalue} onChange={selonChange} options={options} className="searchinput" placeholder={placeholder} isMulti={multi} isDisabled={true}/>
            }
             {this.renderError(meta)}
            </div>
        )
    }




    render(){

          return (
              <React.Fragment>
                  <Helmet>
                    <title>Provider Profile - Treatment Connection</title>
                </Helmet>
<div>
{
this.state.showLoaderScr ? <div className="se-pre-con_ajax"></div> : false
}
<Header/>
    <div className="container" role="main">
            <div className="row">
				<div className="col-lg-12">
                    {/* <div className="text-right col-md-12"><p><a href="https://carma.openbeds.net/index.php/help" className="btn">Need Help?</a></p></div> */}
                        {/* <h3 className="text-center"><img src="https://carma.openbeds.net/assets/images/banner.png" /></h3>
                        <hr className="clean"/> */}
                    		<div className="panel panel-default">
                        		<div className="panel-heading">
                        			<span className="heading-txt">My Profile</span>
                        			{/* <span className="pull-right">
                        				<a href="javascript:void(o)" onClick={this.goback} className="btn btn-primary text-right">Back</a>
                        			</span> */}
                        			<div className="clearfix"></div>
                        		</div>
                        		<div className="panel-body">

                                    <form method="post" onSubmit={this.props.handleSubmit(this.formSubmit)} className="validator-form register_user" id="profile" name="profile">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name="first_name" readonly={
                                                (this.props.loginDetails.type === 'Admin' && this.state.user_id !== '')
                                                    ? false
                                                    : disablefields
                                                }
                                               component={this.textInput} label="First name"
                                               title="Please enter at least 3 and no more than 50 characters"
                                               placeholder="First name" isValid={true}/>
                                        </div>
                                        <div className="col-md-6">
                                        <Field name="last_name" readonly={
                                            (this.props.loginDetails.type === 'Admin' && this.state.user_id !== '')
                                                ? false
                                                : disablefields
                                        }  component={this.textInput} label="Last name" title="Please enter at least 3 and no more than 50 characters" placeholder="Last name" isValid={true}/>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <Field name="organization_name" readonly={disablefields} component={this.textInput} label="Organization Name" title="Please enter at least 3 and no more than 50 characters" placeholder="Organization Name" isValid={true}/>

                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-6">
                                        <Field name="organization_phone" component={this.textInput} label="Organization Phone No." placeholder="Organization Phone No." isValid={true} title="Please enter a valid phone no."  />


                                        </div>
                                        <div className="col-md-6">
                                        <Field name="organization_website_url"  checkvalid={true} component={this.textInput} label="Organization Website Url" placeholder={`valid url, e.g. http://abc.com  or https://abc.com`} isValid={false} title={`Please enter valid url eg. http://abc.com  or https://abc.com" type="url`}/>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                        <Field name="organization_address1"   component={this.textInput} label="Organization Address line 1" placeholder="Organization Address" isValid={true} />


                                        </div>
                                        <div className="col-md-6">
                                        <Field name="organization_address2"   component={this.textInput} label="Organization Address line 2" placeholder="Organization Address" isValid={false} />


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <Field name="city" component={this.textInput}  label="City"  placeholder="City" isValid={true}/>


                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <Field name="state"  readonly="disabled" component={this.stateInput} options={this.props.states} label="State" title=""  placeholder="" isValid={true} type="select" disabled="disabled" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        <Field name="zipcode"   component={this.textInput} label="Zip Code" placeholder="Zip Code" isValid={true}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-md-6">
                                           <Field name="role" readonly={disablefields}  options={this.state.providerOptions} component={this.selectInput} label="Type of Provider" placeholder="Type of Provider" multi={false} isValid={true}/>
                                       </div>
                                        <div className="col-md-6">
                                       {
                                           (this.props.loginDetails.type === 'Admin'  && this.state.user_id === '') ?
                                              true
                                           :

                                               <Field name="service_type"  options={this.serviceTypeOptions()} component={this.selectInputMulti} label="Service Type" placeholder="Service Type" multi={true} isValid={false} selvalue={this.state.service_types} />

                                       }
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <Field name="email" readonly={
                                            (this.props.loginDetails.type === 'Admin' && this.state.user_id !== '')
                                                ? false
                                                : disablefields
                                        } component={this.textInput} label="Email" pretext={<small>(Please use your work email, DO not use personal email)</small>} placeholder="Email" isValid={true}/>

                                        </div>
                                        { (this.props.loginDetails.type === 'Admin' && this.state.user_id !== '')
                                        ?
                                        <Field name="email_hidden" component={this.textInput} type="hidden" />
                                        : ''
                                        }
                                        <div className="col-md-6">

                                        { mobileValid === true ?
                                          <Field name="mobile" component={this.textInput} label="Mobile Phone No." title="Please enter a valid phone no."  placeholder="Mobile Phone No." isValid={mobileValid} />
                                        :
                                          <Field name="mobile" component={this.textInput} label="Mobile Phone No." title="Please enter a valid phone no."  placeholder="Mobile Phone No."  />
                                        }


                                        </div>
                                    </div>
                                        {
                                            ((this.props.loginDetails.type === 'Provider' || this.props.loginDetails.type === 'Admin') && this.state.user_id !== '') ?
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Field name="password" component={this.textInput} label="Password" title="Please enter at least one number, one uppercase, lowercase letter, one special character and at least 8 or more characters"  placeholder="password" isValid={true} type="password"/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Field name="confirm_password" component={this.textInput} label="Confirm Password" title="Please enter at least one number, one uppercase, lowercase letter, one special character and at least 8 or more characters"  placeholder="password" isValid={true} type="password"/>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name="contactMethods" component={this.selectInputContact} label="Select Contact Method" title="(may change later from My Profile)"  placeholder="Select Contact Method" isValid={true} type="select"/>
                                        </div>
                                        {(this.props.loginDetails.type === 'Admin' && this.state.user_id !== '')
                                            ?
                                            <div className="col-md-6">
                                                <label className="control-label">
                                                    Status
                                                </label>
                                                <div>
                                                <Field name="admin_activated" label="Accept" component={this.radioInput} type="radio" value="1"/>
                                                <Field name="admin_activated" label="Reject"  component={this.radioInput} type="radio" value="2"/>
                                                </div>
                                            </div>
                                            : ''
                                        }

                                    </div>
                                     <div className="col-md-12">
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary mr10">Submit
                                                    {this.state.showLoader ? <span
                                                        className="fa fa-spinner fa-spin pageLoader">&nbsp;</span> : false
                                                    }</button>
                                                    {this.state.user_id && <a className="btn btn-info" href="/updateavailability">Cancel</a>}
                                            </div>
                                     </div>
                                    <div className="row">
                                        <hr className="dotted"/>
                                        <div className="col-md-12 serviceadminTitle">  </div>
                                        <div className="col-md-12 serviceadminDetail">                                        </div>

                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
			</div>
		</div>
<NotificationContainer/>
<Footer />
</div>
            </React.Fragment>
        );
  }
}

// const getContactInfo = () => {
//     alert(contactInfo)
//     // if(contactInfo === ""){
//     //     return false
//     // }else{
//         return true
//    // }
// }

const validate = (formValues) => {
  console.log("validate")
    let errors ={}

    if(!formValues.first_name || formValues.first_name.length <=2){
        errors.first_name = "Please enter at least 3 and no more than 50 characters"
    }
    if(!formValues.last_name || formValues.last_name.length <=2){
        errors.last_name = "Please enter at least 3 and no more than 50 characters"
    }
    ////  console.log('formValues.organization_name')
    ////  console.log(formValues.organization_name)

    // if(!formValues.organization_name || formValues.organization_name.length <=2){
    //     errors.organization_name = "Please enter at least 3 and no more than 50 characters"
    // }
    // else{
    //    //  console.log(orgData)
    //     let orgPresent = false
    //     let OrgContent = {}
    //     orgData.list.forEach(org=>{
    //         if(org.org_name === formValues.organization_name){
    //             orgPresent = true
    //             OrgContent = org.list
    //         }
    //     })
    //     if(orgPresent === true){
    //         formValues.organization_address1 = OrgContent.address_line1
    //         formValues.organization_address2 = OrgContent.address_line2
    //         formValues.organization_website_url = OrgContent.org_url
    //         formValues.organization_phone = OrgContent.phone_number
    //         formValues.city = OrgContent.city
    //         formValues.statevalue = OrgContent.state
    //         formValues.zipcode = OrgContent.zip
    //     }


    //         changeState(orgPresent)


    // }

    if (formValues.organization_phone) {
        formValues.organization_phone = formatPhoneNumber(formValues.organization_phone)
    }

    if (!formValues.organization_phone || formValues.organization_phone.length <= 9){
        errors.organization_phone = "Please enter dedicated phone"
    }

    if(!formValues.organization_address1){
        errors.organization_address1 = "Please enter valid Organization Address"
    }
    if(!formValues.city || formValues.city.length<3 ){
        errors.city = "Please enter valid City"
    }

    if(!formValues.state){
        errors.state = "Please select state"
    }


    const zipregex = /^[0-9]{5,5}$/;
    if(!formValues.zipcode || !zipregex.test(formValues.zipcode)){
        errors.zipcode = "Please enter valid 5 digit Zip Code"
    }


    const url = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if(formValues.organization_website_url){
        if(!url.test(formValues.organization_website_url)){
            errors.organization_website_url = "Please enter valid url, e.g. http://abc.com or https://abc.com"
        }
    }
    const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!formValues.email || !emailregex.test(formValues.email)){
        errors.email = "Please enter valid email address"
    }

    if(formValues.mobile){
        formValues.mobile = formatPhoneNumber(formValues.mobile)
    }

    if(!formValues.contactMethods) {
        errors.contactMethods = "Please select contact method"
    }
    else {
        if(formValues.contactMethods==="2" || formValues.contactMethods==="5") {

            mobileValid = true
            document.getElementsByClassName("font10")[10].style.visibility = "visible"

            if (!formValues.mobile || formValues.mobile.length <= 9){
                errors.mobile = "Please enter dedicated phone"
            }
        }
        else {
            mobileValid = false
            document.getElementsByClassName("font10")[10].style.visibility = "hidden"
        }
    }


    if(formValues.password !== undefined && formValues.password != '') {
        const passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/
        if (!formValues.password || !passwordregex.test(formValues.password)) {
            errors.password = "Please enter at least one number, one uppercase, lowercase letter, one special character ( @, !, #, $, & ) and at least 8 or more characters"
        }

        if (!formValues.confirm_password || formValues.confirm_password !== formValues.password) {
            errors.confirm_password = "Password and Confirm password did not match"
        }
    }

    return errors
}

const asyncValidate = (values, dispatch, props,field, state) => {
    console.log("async validate")

    if(props.loginDetails.type === 'Admin') {
        if(JSON.stringify(values.email) === JSON.stringify(values.email_hidden)) {
            return new Promise((resolve, reject)=> { resolve() });
        }
        let userDetails = sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails) : ""

        let access = userDetails.access_token;

        let details = {
            'email': values.email
        }

        let postheaders = {
            'access-token': access,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain'
        }
        let formBody = [];

        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        return openbedsApi.post('/emailExist', formBody, {headers: postheaders})
            .then(function (response) {
                //  this.renderError({email : response.data.message})
                if (response.data.message !== 'Email Not Found') {
                    throw {email: 'This email address is already registered'}
                }
            })
    } else {
        return new Promise((resolve, reject)=> { resolve() });
    }
}

let providerProfileForm = reduxForm({
    form: 'profile',
    validate,
    enableReinitialize: true,
    keepDirty: true,
    keepDirtyOnReinitialize: true,
    asyncValidate,
    asyncBlurFields: ['email']
})(ProviderMyProfile);

const mapStateToProps=(state)=>{

    return {
        attributes:state.post,
        loginDetails:state.loginDetails,
        initialValues: state.profile,
        states: state.states,
    }
}


providerProfileForm = connect(
    mapStateToProps,
    {
        fetchPosts,
        getStates
    }
)(providerProfileForm);

export default providerProfileForm;
