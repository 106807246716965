import React from "react";
import { components } from "react-select";

class Input extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
             <components.Input
                role="combobox"
                {...this.props}
            />
        );
    }
};

export default Input;