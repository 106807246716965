// import Select from 'react-select'
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { patientProfileDetails } from './../actions/login';
// We are using 2.x version of library, latest version is 3.x
// Documentation of older version is available only through searching repo history
//  to commit with docs of older version
// https://github.com/springload/react-accessible-accordion/tree/e137e91b8b59619a16f8334c39dd554137a438df
import {
    fetchPosts,
    getStates,
    updateDistance,
    updatePayment,
    updateSeekingTreatment,
    updateSubstances,
    updateSearchTerms,
    updateLimitToState,
    fetchSearchlist,
    getMoreInfo,
    updateGenderAndAge,
    updateSpecialPopulation,
    updateLanguagesServed,
    updateProvidersOnsite,
    getConfigurations,
    updateLocation,
    fetchAttributes
} from './../actions/selectattributes';
import { fetchUrlParameters } from '../utils/url';
import { sendRequest, clearRequestdata } from './../actions/login';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import Header from './layout/header';
import Footer from './layout/footer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { MapModal } from './searchlist/MapModal';
import { PhoneNumberLink } from './generic/PhoneNumberLink';
import { gaPageView, gaLogEvent } from '../actions/googleAnalytics';
import { appUrls } from '../appUrls';
import { CancelToken } from '../openbedsApi';
import openbedsApi from './../openbedsApi';
import { backendUrl } from '../apiurl';
import { formatPhoneNumber } from '../utils/phoneNumbers';
import ContactModal from "./searchlist/ContactModal";
import { SearchFilters } from './searchlist/SearchFilters';
import SearchResults from './searchlist/SearchResults';
import StateHelpline from './searchlist/StateHelpline';
import { fetchConfigurations  } from '../apiClient';

const defaultDistance = { value: 250, label: '250 miles' };

class SearchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view_mode: 'list', //this could be list or map view, this is the default view mode
            selected_panel: '',
            search_terms_error: false,
            serviceProviders: [],
            showloader: false,
            show: false,
            mapshow: false,
            listMapFacility: [],
            headerHeight: 174,
            mapHeight: 600,
            filterHeight: 650,
            lat: '',
            long: '',
            organisation: '',
            page: 1,
            limit: 25,
            treatment: [],
            treatmentType: [],
            substances: [],
            providersOnsite: [],
            additionalServicesArr: [],
            languagesServed: [],
            specialPopulation: [],
            searchTerms: '',
            limitToState: '',
            showLoaderScr: true,
            showHideFilters: true,
            resolutionSize: 0,
            resolutionSize_H: 0,
            genderAndAge: [],
            againExpressingInterestInOrganization: null,
            againExpressingInterestInService: null,
            againExpressingInterestInState: null,
            filter_toggle: true,
            attributes: null,
            displayedAttributes: null,
            wasSelected: {},
            showRedisSearchLoader: false,
            googleApiKey: false,
            totalPage: 0,
            isPageChanged: false,
            location_error: null,
            location_name: '',
            searchPerformed: false,
        };

        this.cancelTokenSource = CancelToken.source();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.facilityRefs = {};
        this.headerRef = React.createRef();
        this.controlsRef = React.createRef();
        this.lastWidth = 0;
        this.filterSidebar = React.createRef();
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        //set page and limit from url params if exists
        const urlParams = fetchUrlParameters();
        this.setState({ page: urlParams.page ?? 1, limit: urlParams.limit ?? 25 });
        this.setState({ showLoaderScr: false});

        //attempt to fetch generic pfs configurations
        if(!this.props.pfsConfigurations || Object.keys(this.props.pfsConfigurations)?.length === 0) {
            await this.props.getConfigurations();
        }
        if(!this.props.attributes || Object.keys(this.props.attributes.data)?.length === 0) {
            await this.props.fetchPosts();
        }
        if(!this.props.states?.states || this.props.states.states?.length === 0) {
           await this.props.getStates();
        }

        this.fetchApiKey();

        let lastSearchDetailsJSON = sessionStorage.getItem('lastSearchDetails');
        if (lastSearchDetailsJSON) {
            try {
                const lastSearchDetails = JSON.parse(lastSearchDetailsJSON);
                if (lastSearchDetails) {
                    this.props.updateDistance(lastSearchDetails.distance);
                    this.props.updateSubstances(lastSearchDetails.substances);
                    this.props.updateSeekingTreatment(lastSearchDetails.treatmentType);
                    this.props.updatePayment(lastSearchDetails.paymentType);
                    this.props.updateGenderAndAge(lastSearchDetails.genderAndAge);
                    this.props.updateSearchTerms(lastSearchDetails.searchTerms);
                    this.props.updateLimitToState(lastSearchDetails.limitToState);
                    this.props.updateSpecialPopulation(lastSearchDetails.specialPopulation);
                    this.props.updateProvidersOnsite(lastSearchDetails.providersOnsite);
                    this.props.updateLanguagesServed(lastSearchDetails.languagesServed);
                }
            } catch (e) {}
        } else {
            const urlParams = fetchUrlParameters()
            if (Object.values(urlParams).length > 0) {
                const { state, zip, zipcode, zip_code, city, county } = urlParams || {}
                const ZipCode = zip || zipcode || zip_code;
                const searchLocation = {
                    State: state || '',
                    County: county || '',
                    ZipCode: ZipCode,
                    Text: [city, county, state, ZipCode].filter(Boolean).join(' ')
                }
                const locationValue = searchLocation.State || searchLocation.ZipCode;
                if(locationValue){
                    this.props.updateLocation(searchLocation);
                    this.updateStateAttributes(locationValue);
                }
            }
        }
        
        this.setState({ showLoaderScr: false });
        const viewMode = sessionStorage.getItem('view_mode') ?? this.state.view_mode;
        this.setState({ view_mode: viewMode });
        if (
            typeof this.props.searchlist === 'undefined' ||
            (this.props.history.action !== 'replace' &&
                this.props.history.state &&
                this.props.history.state.from !== 'facility-details' &&
                !sessionStorage.getItem('backFromFacilityDetails'))
        ) {
            this.getSearchList(this.state.page); //call get search list on component did mount
            sessionStorage.removeItem('backFromFacilityDetails');
            sessionStorage.removeItem('view_mode');
        } else if(sessionStorage.getItem('backFromFacilityDetails')) {
            sessionStorage.removeItem('backFromFacilityDetails');
            sessionStorage.removeItem('view_mode');
        }

        window.addEventListener('resize', this.updateDimensions);

        // set the map height
        setTimeout(() => {
            this.updateDimensions();
        }, 1000);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            if (this.props.sendRequestMessage.status === 'SUCCESS') {
                this.props.clearRequestdata();
                this.props.history.push('/patients/rrs?new_enquiry=1');
            } else if (this.props.sendRequestMessage.status === 'ERROR') {
                NotificationManager.error('', this.props.sendRequestMessage.message);
                this.props.clearRequestdata();
            }
        }

        if (prevProps.states !== this.props.states) {
            this.setState({ states: this.props.states });
        }

        // Fetch searchList on change of the page
        if (
            prevState.isPageChanged !== this.state.isPageChanged &&
            this.state.isPageChanged === true
        ) {
            this.getSearchList(this.state.page);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    } 

    // componentWillMount = async() => {
    //     this.updateDimensions();
    //     if (window.innerWidth < 992) {
    //         this.setState({ filter_toggle: false });
    //     }        
    // };


    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }  

    // Autoclose the filters when tabbing past them on mobile
    handleFiltersOpenStateWhenTabbing = (event) => {
        if (this.state.filter_toggle && this.state.resolutionSize < 992) {
            let getTheFilterSidebar = this.filterSidebar.current;
            var focusableEls = getTheFilterSidebar.querySelectorAll(
                'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="password"]:not([disabled]), select:not([disabled])'
            );
            let firstFocusableEl = focusableEls[0];
            let lastFocusableEl = focusableEls[focusableEls.length - 1];
            let KEYCODE_TAB = 9;

            var isTabPressed = event.key === 'Tab' || event.keyCode === KEYCODE_TAB;

            if (isTabPressed && event.shiftKey && event.target === firstFocusableEl) {
                this.setState({ filter_toggle: false });
            } else if (isTabPressed && !event.shiftKey && event.target === lastFocusableEl) {
                this.setState({ filter_toggle: false });
            } else if (isTabPressed && !event.shiftKey && !focusableEls.includes(event.target)) {
                this.setState({ filter_toggle: false });
            } else {
            }
        }
    };

    //highlight the correct panel
    selectThisPanel = (panel, center) => {
        this.setState({ flipFlop: !this.state.flipFlop });

        if (center.lat && center.lng) {
            this.setState({ selected_panel: panel, center: center });
        } else {
            this.setState({ selected_panel: panel, center: { lat: 38.9, lng: -77 } });
        }

        //set the map center
    };

    //
    deselectPanels = () => {
        this.setState({ selected_panel: '' });
    };

    setSelected = (org_serv_id) => {
        this.setState({ selected_panel: org_serv_id });
        //scroll to selected on the list
        if (org_serv_id) {
            var panelToScroll = document.getElementById(org_serv_id);
            panelToScroll.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    getState = (state_name) => {
        let statename = '';
        if (this.props.states !== undefined) {
            this.props.states.states?.forEach((st) => {
                if (st.name === state_name) {
                    statename = st.code;
                }
            });
        }

        return statename;
    };

    getState2 = (provider) => {
        let statename = '';
        if (this.props.states !== undefined) {
            this.props.states.states.forEach((st) => {
                if (st.id == provider.service_state) {
                    statename = st.code;
                }
            });
        }

        return statename;
    };

    trackPageChange = (currentPage) => {
        if (currentPage <= this.state.totalPage) {
            this.setState({
                page: currentPage,
                isPageChanged: true,
            });
        }
    };

    

    handleClose = (modal) => {
        sessionStorage.removeItem('org_id');
        sessionStorage.removeItem('state');

        let login = this.state.show;
        let map = this.state.mapshow;

        if (modal == 'login') {
            login = false;
        }
        if (modal == 'map') {
            map = false;
        }

        this.setState({ mapshow: map, show: login });
    };

    handlemapShow = (prov) => {
        let center = {
            lat: parseFloat(prov.service_latitude),
            lng: parseFloat(prov.service_longitude),
        };

        this.setState({
            mapshow: true,
            lat: prov.service_latitude,
            long: prov.service_longitude,
            center,
            organisation: prov.organisation,
            listMapFacility: [prov],
        });
    };

    handleServiceDetailsModal = (prov) => {
        //this.props.getMoreInfo(prov.service_id);
        this.props.gaPageView('list/service_details/' + prov.service_id);
        this.props.gaLogEvent({
            category: 'Search Results',
            action: 'More Information Button',
        });
        //selected provider will contain additional data
        this.props.history.push({
            pathname: appUrls.FACILITY_DETAILS,
            state: {
                provider: prov,
                states: this.state.states,
                googleApiKey: this.state.googleApiKey,
                view_mode: this.state.view_mode,
            },
        });
    };

    handleShow = (organization_id, service_id, organisation_services_id, state) => {
        this.setState({ show: true });
        sessionStorage.setItem('org_id', organization_id);
        sessionStorage.setItem('service_id', service_id);
        sessionStorage.setItem('organisation_services_id', organisation_services_id);
        sessionStorage.setItem('state', state);
    };

    handleExpressInterest = (organizationId, serviceId, organizationServiceId, state, expressingInterestAgain) => {
        this.props.gaLogEvent({
            category: 'Search Results',
            action: 'Contact Treatment Button',
        });
        if (expressingInterestAgain) {
            this.setState({ againExpressingInterestInOrganization: organizationId });
            this.setState({ againExpressingInterestInService: serviceId });
            this.setState({ againExpressingInterestInOrganizationService: organizationServiceId });
            this.setState({ againExpressingInterestInState: state });
        } else {
            this.expressInterest(organizationId, serviceId, organizationServiceId, state);
        }
    };

    resetAgainExpressingInterestState = () => {
        this.setState({ againExpressingInterestInOrganization: null });
        this.setState({ againExpressingInterestInService: null });
        this.setState({ againExpressingInterestInOrganizationService: null });
        this.setState({ againExpressingInterestInState: null });
    }

    expressInterest = (organization_id, service_id, organisation_services_id, state) => {
        if (this.props.loginDetails) {
            if (this.props.loginDetails.type === 'Patient') {
                this.setState({ showloader: true });
                sessionStorage.setItem('org_id', organization_id);
                sessionStorage.setItem('service_id', service_id);
                sessionStorage.setItem('organisation_services_id', organisation_services_id);
                sessionStorage.setItem('state', state);
                this.props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
            } else {
                this.handleShow(organization_id, service_id, organisation_services_id, state);
            }
        }
    };

    getSearchList = (page) => {
        if (page === undefined) {
            page = 1;
        }
        if (page) {
            this.setState({ page: page }, this.getAllValues());
        }
    };

    getAllValues = (givenPage) => {
        if(givenPage) {
            this.setState({ page: givenPage });
        }
        if (!this.props.searchLocation) {
            return;
        }
        
        sessionStorage.setItem(
            'lastSearchDetails',
            JSON.stringify({
                searchLocation: this.props.searchLocation,
                distance: this.props.distance,
                searchTerms: this.props.searchTerms,
                limitToState: this.props.limitToState,
                substances: this.props.substances,
                treatmentType: this.props.seekingTreatmentType,
                payment: this.props.payment,
                genderAndAge: this.props.genderAndAge,
                specialPopulation: this.props.specialPopulation,
                additionalServices: this.props.additionalServices,
                providersOnsite: this.props.providersOnsite,
                languagesServed: this.props.languagesServed,
            })
        );

        var fieldsFilled = '';
        if (this.props.searchLocation != null) {
            fieldsFilled = fieldsFilled + 'Location, ';
        }
        if (this.props.distance != null) {
            fieldsFilled = fieldsFilled + 'Distance, ';
        }
        if (this.props.searchTerms != null && this.props.searchTerms.length !== 0) {
            fieldsFilled = fieldsFilled + 'Search, ';
        }
        if (this.props.limitToState) {
            fieldsFilled = fieldsFilled + 'Limit to State, ';
        }
        if (this.props.substances != null && this.props.substances.length !== 0) {
            fieldsFilled = fieldsFilled + 'Substances, ';
        }
        if (this.props.seekingTreatmentType != null && this.props.seekingTreatmentType.length !== 0) {
            fieldsFilled = fieldsFilled + 'Service Type, ';
        }
        if (this.props.payment != null && this.props.payment.length !== 0) {
            fieldsFilled = fieldsFilled + 'Payment, ';
        }
        if (this.props.genderAndAge != null && this.props.genderAndAge.length !== 0) {
            fieldsFilled = fieldsFilled + 'Gender and Age, ';
        }
        if (this.props.specialPopulation != null && this.props.specialPopulation.length !== 0) {
            fieldsFilled = fieldsFilled + 'Special Population, ';
        }
        if (this.props.providersOnsite != null && this.props.providersOnsite.length !== 0) {
            fieldsFilled = fieldsFilled + 'Providers Onsite, ';
        }
        if (this.props.languagesServed != null && this.props.languagesServed.length !== 0) {
            fieldsFilled = fieldsFilled + 'Languages Served, ';
        }

        fieldsFilled = fieldsFilled.substring(0, fieldsFilled.length - 2);

        this.props.gaLogEvent({
            category: 'Search Results',
            action: 'Search Results - Filters - ' + fieldsFilled,
        });

        let substancesArr = [];
        let treatmentArr = [];
        let paymentArr = [];
        let genderAndAgeArr = [];
        let additionalServicesArr = [];
        let specialPopulation = [];
        let providersOnsite = [];
        let languagesServed = [];

        if (this.props.substances) {
            this.props.substances.forEach((sub) => {
                if (sub !== undefined) {
                    if (sub.value !== '') {
                        substancesArr.push(sub.value);
                    }
                }
            });
        }

        if (this.props.seekingTreatmentType) {
            this.props.seekingTreatmentType.forEach((treat) => {
                if (treat !== undefined) {
                    treatmentArr.push(treat.value);
                }
            });
        }

        if (this.props.payment) {
            this.props.payment.forEach((pay) => {
                if (pay.value !== '') {
                    paymentArr.push(pay.value);
                }
            });
        }

        if (this.props.genderAndAge) {
            this.props.genderAndAge.forEach((gender) => {
                if (gender.value !== '') {
                    genderAndAgeArr.push(gender.value);
                }
            });
        }

        if (this.props.specialPopulation) {
            this.props.specialPopulation.forEach((pop) => {
                if (pop.value !== '') {
                    specialPopulation.push(pop.value);
                }
            });
        }

        if (this.props.providersOnsite) {
            this.props.providersOnsite.forEach((provider) => {
                if (provider.value !== '') {
                    providersOnsite.push(provider.value);
                }
            });
        }

        if (this.props.languagesServed) {
            this.props.languagesServed.forEach((lang) => {
                if (lang.value !== '') {
                    languagesServed.push(lang.value);
                }
            });
        }

        let details = {
            page: givenPage || this.state.page,
            limit: this.state.limit,
            stateCode: this.props.searchLocation?.State || '',
            lat: this.props.searchLocation?.Latitude || '',
            long: this.props.searchLocation?.Longitude || '',
            place_id: this.props.searchLocation?.PlaceId || '',
            search_terms: this.props.searchTerms,
            limit_to_state: this.props.limitToState,
            distance: this.props.distance?.value || '',
            substances: substancesArr,
            treatment_type: treatmentArr,
            payment_type: paymentArr,
            gender_and_age: genderAndAgeArr,
            special_populations: specialPopulation,
            providers_on_site: providersOnsite,
            languages_served: languagesServed
        };

        //loop through array and append to url
        const params = new URLSearchParams({});
        for (const [key, value] of Object.entries(details)) {
            if (value instanceof Array) {
                //will need to concat before appending
                if (value.length > 0) {
                    params.append(key, value.join());
                }
            } else {
                if (value !== '' && value !== null) {
                    params.append(key, value);
                }
            }
        }
        this.props.history.push("?" + params);

        if(this.props.searchLocation?.ZipCode) {
            details.zipcode = this.props.searchLocation?.ZipCode;
        }

        if(additionalServicesArr.length > 0) {
            details.additional_services = additionalServicesArr;
        }
        this.setState({ showRedisSearchLoader: true });            
        this.updateServiceData(details).finally(() => {
            this.setState({ showRedisSearchLoader: false });
        });
    };

    //ERIK 8 6 adding loader to update service data
    async updateServiceData(details) {
        // this.setState({ showRedisSearchLoader: true });

        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('by user'); //cancel all previous fetchSearchlist axios posts
        }

        this.cancelTokenSource = CancelToken.source(); //need a new cancel token
        let message = await this.props.fetchSearchlist(this.cancelTokenSource, details, this.props.gaLogEvent);

        this.setState({ searchPerformed: true });
        //update the center when you get new zip code
        if (this.props.searchlist) {
            var ind = this.props.searchlist.length;
            this.props.searchlist.map((provider, index) => {
                if (provider) {
                    if (index < ind && provider.service_latitude && provider.service_longitude) {
                        let center = {
                            lat: parseFloat(provider.service_latitude),
                            lng: parseFloat(provider.service_longitude),
                        };
                        this.setState({ center: center });
                        ind = index;
                    }
                }
            }); //end of update center function
        }
        //another search got started and "finished" early don't set Loader state
        if (message !== 'CANCELLED') {
        }
        // this.setState({ showRedisSearchLoader: false });
    }


    getFormattedTime = (inputDate) => {
        if (inputDate !== 'Always') {
            var currentDate = new Date('1970-01-01T' + inputDate);

            let hours = currentDate.getHours();
            let minutes = currentDate.getMinutes();

            let ampm = hours >= 12 ? 'PM' : 'AM';

            if (minutes < 10) minutes = '0' + minutes;

            var dateString = hours + ':' + minutes + ' ' + ampm;
            return dateString;
        } else {
            return '';
        }
    };

    updateStateConfigs =  async(state_code) => {
        if(!state_code) {
            return;
        }
        const configs = await fetchConfigurations(state_code);
        sessionStorage.setItem( 'lastStateConfigs', JSON.stringify(configs) );
    };

    updateStateAttributes = async(stateObj) => {
        const stateName = stateObj?.State || stateObj || this.props.searchLocation?.State;
        if (stateName) {
            this.setState({ showRedisSearchLoader: true });
            try {
                const givenState = this.props.states?.states.find(st => st.name === stateName);
                const currStateCode = this.props.stateAttributes?.state_code?.toUpperCase();
                if(givenState?.code?.toUpperCase() === currStateCode) {
                    return;
                }
                const result = await this.props.fetchAttributes(stateName, true)
                if(result){
                    this.setState({ stateAttributes: result });
                    this.setState({ displayedAttributes: result });
                    await this.updateStateConfigs(result.state_code);
                }
            } catch (error) {
            } finally{
                this.setState({ showRedisSearchLoader: false });
            }
        } else {
            this.setState({ stateAttributes: {} });
        }
    };

    areAttributesValid = () => {
        return !!this.state.attributes;
    };

    applyFilters = (prevProps) => {
        if (prevProps?.states !== this.props?.states) {
            this.setState({
                states: this.props.states,
            });
        }
        this.setState({ page: 1 }, () => this.getSearchList(1));
    };

    clearFilters = () => {
        this.props.updateDistance(defaultDistance);
        this.props.updateSubstances([]);
        this.props.updateSeekingTreatment([]);
        this.props.updatePayment([]);
        this.props.updateGenderAndAge([]);
        this.props.updateSearchTerms([]);
        this.props.updateLimitToState([]);
        this.props.updateSpecialPopulation([]);
        this.props.updateProvidersOnsite([]);
        this.props.updateLanguagesServed([]);

        this.setState({ page: 1 }, () => this.getSearchList(1));
    };

    showHideFilters = () => {
        let filtersShown = this.state.showHideFilters;

        filtersShown = !filtersShown;
        this.setState({
            showHideFilters: filtersShown,
        });
    };

    updateDimensions = () => {
        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth,
            height = w.innerHeight,
            // iOS scrolling triggers resize event. This ensures we only respond to resize
            // events where the width is truly different.
            hasDimensionDelta = width !== this.lastWidth;

        if (hasDimensionDelta) {
            if (width >= 992) {
                this.setState({ filter_toggle: true });
            } else {
                this.setState({ filter_toggle: false });
            }
            this.lastWidth = width;
        }

        let showFilters = true;
        if (this.state.resolutionSize !== 0 && this.state.resolutionSize < 992 && width < 992) {
            showFilters = this.state.showHideFilters;
        } else if (width < 992) {
            showFilters = false;
        } else {
            showFilters = true;
        }

        this.setState({
            resolutionSize: width,
            resolutionSize_H: height,
            showHideFilters: showFilters,
        });

        //this is for setting the map height
        if (this.headerRef.current && this.controlsRef.current) {
            var menubar_h = document.getElementById('header').offsetHeight;
            var header_h = this.headerRef.current.offsetHeight + 5; //5 padding
            var controls_h = this.controlsRef.current.offsetHeight + 10; //for hr 15 padding
            this.setState({ mapHeight: height - header_h - controls_h - menubar_h });
            this.setState({ filterHeight: height - header_h - menubar_h });
            this.setState({ headerHeight: header_h + menubar_h });
        }
    };

    toggleFilters = (e) => {
        if (this.state.filter_toggle) {
            this.setState({ filter_toggle: false });
        } else {
            this.setState({ filter_toggle: true });
        }
    };

    changeViewMode = (mode) => {
        if (mode === 'list') {
            this.setState({ selected_panel: '' });
        }
        this.setState({ view_mode: mode });
    };

    logEvent(action) {
        this.props.gaLogEvent({
            category: 'Search Results',
            action: action,
        });
    }

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Search Substance Use or Mental Health Treatment Facilities - Treatment Connection</title>
                    <meta
                        name="description"
                        content="Search substance use or mental health treatment facilities near you with our free, confidential treatment locator. Enter your zip code, state or county to get started."
                    ></meta>
                </Helmet>

                {this.state.showLoaderScr ? <div className="se-pre-con_ajax"></div> : false}

                <Header page="list" />

                {this.state.showloader ? <div className="se-pre-con_ajax"></div> : false}
                <section
                    id="searchPage"
                    className={
                        this.state.resolutionSize < 992
                            ? 'container searchlistPage searchlistPage-mobile'
                            : 'container searchlistPage'
                    }
                >
                    {this.state.resolutionSize < 992 ? (
                        <div id="search-list-header-percent" ref={this.headerRef}>
                            <div className="row">
                                <div className="col-md-12 search-list-header" role="region">
                                    <div className="col-md-12">
                                        <h1>Search Substance Use or Mental Health Treatment Facilities</h1>
                                    </div>
                                    <div className="row-fluid search-list-header-mobile">
                                        <div className="col-xs-8">
                                            <p>
                                                Use filters to narrow down your search results and find treatment
                                                facilities.
                                            </p>
                                        </div>
                                        <div className="col-xs-4 search-list-flex-filter">
                                            <button
                                                type="button"
                                                className="clear btn btn-default"
                                                style={{ padding: '3px 30px' }}
                                                onClick={this.toggleFilters}
                                                aria-expanded={this.state.filter_toggle}
                                            >
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row" ref={this.headerRef} role="region">
                            <div className="col-md-12 search-list-header">
                                <div className="col-md-12">
                                    <h1>Search Substance Use or Mental Health Treatment Facilities</h1>
                                    <p>Use filters to narrow down your search results and find treatment facilities.</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row searchListContentArea">
                        <div className="col-md-12">
                            {this.state.filter_toggle && (
                                <aside
                                    role="complementary"
                                    id="filters_dialog_modal"
                                    ref={this.filterSidebar}
                                    onKeyDown={this.handleFiltersOpenStateWhenTabbing}
                                    className={
                                        `col-md-4 col-lg-3 filter-sec
                                        ${this.state.resolutionSize < 992
                                            ? this.state.view_mode === 'list'
                                                ? ' filter-list-modal'
                                                : ' filter-sec-modal'
                                            : this.state.view_mode === 'list'
                                            ? ''
                                            : ' filter-sidebar'}`
                                    }
                                    style={
                                        this.state.resolutionSize < 992 && this.state.view_mode === 'map'
                                            ? { top: this.state.headerHeight }
                                            : this.state.resolutionSize >= 992 && this.state.view_mode === 'map'
                                            ? { height: this.state.filterHeight }
                                            : undefined
                                    }
                                >
                                    <div id="filters" className={`search-filters ${this.state.showRedisSearchLoader? 'loading': 'loaded'}`}>
                                        <SearchFilters parent={this} {...this.props} {...this.state} />
                                        <StateHelpline parent={this} {...this.props} {...this.state} />
                                    </div>

                                    <div className="margintop">
                                        <div className="tech-help">
                                            <h2 className="h4">Technical Support</h2>
                                            <ul className="tech-help__list">
                                                <li className="row">
                                                    <div className="col-sm-3 col-md-2 col-5">
                                                        <span id="lbl-contact-title">Contact:</span>
                                                    </div>
                                                    <div className="col-md-8 col-6" aria-labelledby="lbl-contact-title">
                                                        <a
                                                            href={
                                                                this.props.pfsConfigurations
                                                                    ? this.props.pfsConfigurations.customer_support_url
                                                                    : null
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            aria-label="Customer Support"
                                                        >
                                                            Customer Support
                                                        </a>
                                                    </div>
                                                </li>
                                                <li className="row">
                                                    <div className="col-sm-3 col-md-2 col-5">
                                                        <span id="lbl-contact-phone">Phone:</span>
                                                    </div>
                                                    <div className="col-md-8 col-6" aria-labelledby="lbl-contact-phone">
                                                        <PhoneNumberLink
                                                            phoneNumber={
                                                                this.props.pfsConfigurations
                                                                    ? formatPhoneNumber(
                                                                          this.props.pfsConfigurations
                                                                              .support_phone_number
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                    </div>
                                                </li>
                                                <li className="row">
                                                    <div className="col-sm-3 col-md-2 col-5">
                                                        <span id="lbl-contact-hours">Hours:</span>
                                                    </div>
                                                    <div className="col-md-8 col-6" aria-labelledby="lbl-contact-hours">
                                                        {this.props.pfsConfigurations?.customer_support_hours || null}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            )}
                            <main className="col-md-8 col-lg-9 search-content">
                                <span id="main-content" tabIndex="-1"></span>
                                {this.state.showRedisSearchLoader ? (
                                    <div className="search-content-loader">
                                        <div className="rotating-loader" />
                                    </div>
                                ) : (
                                    <SearchResults parent={this} {...this.state} {...this.props} />
                                )}
                            </main>
                        </div>
                    </div>
                </section>
                <ContactModal
                    show={this.state.show}
                    onHide={() => {
                        this.handleClose('login');
                    }}
                    logEvent={(event) => {
                        this.logEvent(event);
                    }}
                    againExpressingInterestInOrganization={this.state.againExpressingInterestInOrganization}
                    againExpressingInterestInService={this.state.againExpressingInterestInService}
                    againExpressingInterestInOrganizationService={
                        this.state.againExpressingInterestInOrganizationService
                    }
                    againExpressingInterestInState={this.state.againExpressingInterestInState}
                    expressInterest={this.expressInterest}
                    resetAgainExpressingInterestState={this.resetAgainExpressingInterestState}
                />

                <MapModal
                    show={this.state.mapshow}
                    lat={this.state.lat}
                    long={this.state.long}
                    center={this.state.center}
                    organisation={this.state.organisation}
                    facility_list={this.state.listMapFacility}
                    onHide={() => {
                        this.handleClose('map');
                    }}
                    googleApiKey={this.state.googleApiKey}
                />
                <NotificationContainer />
                {this.state.resolutionSize < 1200 ? '' : <Footer />}
            </React.Fragment>
        );
    }

    fetchApiKey() {
        let data = new FormData();
        data.append('secretKeyName', 'google_maps.api_key');

        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };
        let parent = this;

        const axiosConfig = {
            baseURL: backendUrl,
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
        };

        openbedsApi
            .post('api/get_api_keys', data, axiosConfig)
            .then(function (response) {
                if (response.data.status === 'SUCCESS') {
                    parent.setState({ googleApiKey: response.data.key });
                }
            })
            .catch(function (error) {
                throw Error(error.message);
            });
    }
}


const mapStateToProps = (state) => {
    return {
        profileData: state.patientProfile,
        attributes: state.post,
        states: state.states,
        distance: state.distance,
        payment: state.payment,
        treatment: state.treatment,
        substances: state.substances,
        searchTerms: state.searchTerms,
        limitToState: state.limitToState,
        specialPopulation: state.specialPopulation,
        treatmentType: state.treatmentType,
        additionalServices: state.additionalServices,
        providersOnsite: state.providersOnsite,
        languagesServed: state.languagesServed,
        facility: state.facility,
        searchlist: state.searchlist?.data || [],
        totalRecords: state.searchlist?.pagination ? state.searchlist?.pagination?.total_records : 0,
        loginDetails: state.loginDetails,
        sendRequestMessage: state.sendRequest,
        service_details: state.service_details,
        seekingTreatmentType: state.seekingTreatmentType,
        genderAndAge: state.genderAndAge,
        pfsConfigurations: state.pfsConfigurations,
        stateConfigurations: state.stateConfigurations,
        stateAttributes: state.stateAttributes,
        searchLocation: state.searchLocation,
    };
};

export default connect(
    mapStateToProps,

    {
        fetchPosts,
        fetchAttributes,
        getConfigurations,
        getStates,
        updateDistance,
        updatePayment,
        updateSeekingTreatment,
        updateGenderAndAge,
        updateSubstances,
        updateSearchTerms,
        updateLimitToState,
        updateLocation,
        updateProvidersOnsite,
        updateSpecialPopulation,
        updateLanguagesServed,
        fetchSearchlist,
        sendRequest,
        clearRequestdata,
        patientProfileDetails,
        getMoreInfo,
        gaPageView,
        gaLogEvent,
    }
)(SearchList);
