import React from 'react';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";
import Header from './../layout/header'
import Footer from './../layout/footer'
import Select from 'react-select'
import AccessibleSelect from '../accessibility-helpers/accessible-select'
import {fetchPosts,updateZipcode,updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment} from './../../actions/selectattributes'
import openbedsApi from './../../openbedsApi';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {fetchStateAttributes} from "../../apiClient";
import {Messages} from "../messages";
import {appUrls} from "../../appUrls";

class PatientMyProfile extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            emailError:false,
            pswdError:false,
            email:'',
            password:'',
            repassword:'',
            repswdError:false,
            zip_error:false,
            zipcode:'',
            birthyear:'',
            gender:'M',
            substanceUsingFrom:'',
            insurance:'N',
            showInsurance:false,
            insurancetype:'',
            insurancetype_error: false,
            checked:false,
            acceptterms:false,
            emailTaken:false,
            showLoader:false,
            birth_error:false,
            noYears_error:false,
            substancesUsing:[],
            substance_error:false,
            treatment:[],
            treatmentSeeking:[],
            showPasswordError:false,
            showLoaderScr:true,
            comment: '',
            completing_for: 'self',
            attributes: null,
        };
      }
    async componentWillMount(){
        this.setState({
            showLoaderScr:false
        },this.getMyProfileInfo)
    }

    async componentDidMount(){
     //  this.refreshCapcha()
        this.props.fetchPosts()
        let provider = await this.props.loginDetails

        if(provider.type === "Provider" || provider.type === "Admin" || provider.type === '4a provider') {
            this.props.history.push('/search-treatment-facilities');
        }

        if (provider.type === "Patient") {
        } else {

            this.props.history.push(appUrls.USER_LOGIN)
        }



    }

    getMyProfileInfo = () => {
        let parent = this;

        const userDetails = JSON.parse(sessionStorage.userDetails);

        const headers = {
            'access-token': userDetails.access_token,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        };
        //Erik 1/29/21: we should probably replace this call with the patientProfileDetails action,
        //but that would require to recode this page to use props instead of assigned states.
        openbedsApi.post('/patient_profile_details', null, { headers })
            .then(function (response) {
                if (response.data.status==="ERROR" || response.data.status==="Failure"){
                    NotificationManager.error('', response.data.message);
                    parent.setState({
                        showLoaderScr:false
                    })

                    return
                }

                if(response.data.status==="SUCCESS"){
                    let patientData = response.data.data
                    parent.setState({
                        zipcode:patientData.zip,
                        email:patientData.email,
                        birthyear:patientData.birth_year,
                        substanceUsingFrom:patientData.no_of_years_using_substances,
                        insurance:patientData.is_insurance,
                        showInsurance:patientData.is_insurance === "Y" ? true : false,
                        gender:patientData.gender,
                        insurancetype:patientData.insurance_name,
                        comment: patientData.comment,
                        completing_for:patientData.completing_for,
                        treatment: patientData?.service_type_done ? patientData.service_type_done.split(',') : [],
                        treatmentSeeking: patientData?.service_type_seeking ? patientData.service_type_seeking.split(',') : [],
                    })
                    parent.fetchAttributes(patientData.zip).then(() => {
                        parent.updateSubAndTeatment(patientData);
                    });

                    return
                }
            })
            .catch(function (error) {
                parent.setState({error:error,showLoader:false})
                if (error.response.status == 401) {
                    sessionStorage.clear()
                    let errorArr = {
                        'requestStatus':'ERROR',
                        data:error
                    }
                    if (this.props.loginDetails.type == 'Patient') {
                        this.props.history.push({pathname: appUrls.USER_LOGIN, state: {loginTimeout: true}})
                    }
                }
            }.bind(this));
    }

    fetchAttributes = async (zip) => {
        return fetchStateAttributes(zip, true)
            .then((result) => {
                return new Promise((resolve, reject) => {
                    if (null !== result) {
                        this.setState({attributes: result}, () => {
                            resolve();
                        });
                    } else {
                        this.setState({attributes: null}, () => {
                            reject();
                        });
                    }
                });
            });
    };

    showPasswordErrorStatus = () => {
        this.setState({
            showPasswordError:true
        })
    }

    isEmailvalidate = (e) => {
        let email = e.target.value;

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(regex.test(email)){
          this.setState({emailError:false,email:email});
          let details ={
            email:email
           }
          let formBody = [];
          for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          let parent = this
            openbedsApi.post('/emailExist',formBody)
            .then(function (response) {
                if(response.data.status==="SUCCESS"){
                  //  parent.setState({emailTaken:false})
                  if(response.data.message==='Email Already Exist'){
                    parent.setState({emailTaken:true})
                  }else{
                     parent.setState({emailTaken:false})
                 }
                }
            })
            .catch(function (error) {
            });
        }else{
          this.setState({emailError:true,email:email})
        }
    }

    isPasswordvalidate = (e) => {
        let pswd = e.target.value
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        if(regex.test(pswd)){
          this.setState({pswdError:false,password:pswd})
        }else{
          this.setState({pswdError:true,password:pswd})
        }
    }

    reEnter = (e) => {
        let pswd = e.target.value
        if(this.state.password === pswd){
          this.setState({repswdError:false,repassword:pswd})
        }else{
          this.setState({repswdError:true,repassword:pswd})
        }
    }

    updateZipCode = (e) => {
        const zip = e.target.value;

        const regex = /^[0-9]{5}$/;
        if(regex.test(zip)){
            this.setState({zip_error:false, zipcode: zip});
            this.fetchAttributes(zip);
        }else{
          this.setState({zip_error:true, zipcode: zip});
        }

    }

    updateComment = (e) => {
        const comment = e.target.value
        if(comment.length <= 299) {
            this.setState({comment: comment})
        }
    }

    updatebirthyear = (e) => {
        const regex = /^[0-9]{4}$/;
        let currentYear = new Date().getFullYear();
        if(regex.test(e.target.value) && e.target.value <= currentYear && e.target.value !== '0000'){
            this.setState({birthyear:e.target.value, birth_error:false})
        }else{
            this.setState({birthyear:e.target.value, birth_error:true})
        }
    }

    updateInsuranceType = (e) => {
        if (e.target.value.length > 100) {
            this.setState( {insurancetype: e.target.value, insurancetype_error: true });
        } else {
            this.setState({insurancetype: e.target.value, insurancetype_error: false });
        }
    }

    updateGender = (e) =>{
        const gender = e.target.value
        this.setState({gender:gender})

    }

    updateCompletingFor= (e) =>{
        const completing_for = e.target.value
        this.setState({completing_for:completing_for})

    }

    updateInsurance = (e) =>{
        const insurance = e.target.value
        if(insurance==='Y'){
            this.setState({insurance:insurance,showInsurance: true})
        }else{
            this.setState({insurance:insurance,showInsurance: false})
        }


    }

    updatesubstanceUsingFrom = (e) => {
        const regex = /^[0-9]{1,2}$/;
        const value = e.target.value;
        if(!value || regex.test(value)){
            this.setState({substanceUsingFrom: value, noYears_error: false})
        }else{
            this.setState({substanceUsingFrom: value, noYears_error: true})
        }
    }

    getTreatmentData = (servicesArray) => {
        // Ensure that we have a unique set of services
        servicesArray = [...new Set(servicesArray)];
        return this.state.attributes.service_type
            .filter(service => servicesArray.includes(service.id))
            .map(service => ({value: service.id, label: service.service_type}));
    }

    updateSubAndTeatment = (patientData) =>{
        if(!this.state.attributes) {
            return;
        }
        // for substances
        const substancesUsingData = patientData.substances_using.split(',')
        const substancesArr = this.state.attributes.substances
            .filter(s => substancesUsingData.includes(s.id))
            .map(s => ({ value: s.id, label: s.substance }));
        this.updateSubstances(substancesArr);

        // for treatment type
        const treatmentTypesDone = this.getTreatmentData(patientData?.service_type_done ? patientData.service_type_done.split(',') : [])
        this.updateTreatmentType(treatmentTypesDone)

        const treatmentTypesSeeking = this.getTreatmentData(patientData?.service_type_seeking ? patientData.service_type_seeking.split(',') : [])
        this.updateSeekingTreatmentType(treatmentTypesSeeking)
    }

    serviceTypeOptions = () => {
        let serviceTypesArr = []
        if(this.state.attributes!==null && this.state.attributes.service_type) {
            this.state.attributes.service_type.forEach(service=>{
                let substancesOption = {}
                substancesOption.value = service.id
                substancesOption.label = service.service_type
                serviceTypesArr.push(substancesOption)
            })
        }

        return serviceTypesArr
    }

    substancesOptions = () => {
        let substancesArr = []
        if(this.state.attributes!==null && this.state.attributes.substances){
            this.state.attributes.substances.forEach(substance=>{
                let substancesOption = {}
                substancesOption.value = substance.id
                substancesOption.label = substance.substance
                substancesArr.push(substancesOption)
            })
        }
        return substancesArr
    }

    filterdsubstancesOptions = () => {
        let substancesArr = this.substancesOptions();
       return substancesArr.filter(sub=>{
            return sub.label!='All'
        })
    }

    updateTreatmentType = (selectedOption) => {
        this.props.updateTreatment(selectedOption)
        let arr=  selectedOption.map(opt=>{
            return opt.value
        })
        this.setState({treatment:arr})

    }

    updateSeekingTreatmentType = (selectedOption) => {
        this.props.updateSeekingTreatment(selectedOption)
        let arr=  selectedOption.map(opt=>{
            return opt.value
        })
        this.setState({treatmentSeeking:arr})
    }

    updateSubstances = (selectedOption) => {
        let isSelectAll = false
        selectedOption.forEach(opt=>{
            if(opt.label==='All'){
                isSelectAll = true
            }
        })
        if(isSelectAll === true) {
            this.setState({substancesUsing:[],substance_error:false})
            this.props.updateSubstances([])
            selectedOption = this.filterdsubstancesOptions();
        }
        let arr = selectedOption.map(opt => opt.value);
        setTimeout(() => {
            this.setState({substancesUsing: arr, substance_error: false})
            this.props.updateSubstances(selectedOption)
        }, isSelectAll ? 100 : 0);
    }

    registerPatient = () => {
        let provider = JSON.parse(sessionStorage.userDetails);
        this.setState({showLoader:true})
        let details ={
            fname:'',
            lname:'',
            zip:this.state.zipcode,
            birth_year:this.state.birthyear,
            gender:this.state.gender,
            no_of_years_using_substances:this.state.substanceUsingFrom,
            substances_using:this.state.substancesUsing,
            service_type_done:this.state.treatment,
            service_type_seeking:this.state.treatmentSeeking,
            is_insurance:this.state.insurance,
            insurance_name:this.state.insurance === "Y" ? this.state.insurancetype : "",
            comment: this.state.comment || "",
            completing_for: this.state.completing_for
           }

        let postheaders = {
            'access-token':provider.access_token,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept':'application/json, text/plain'
        }
        let formBody = [];
          for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          let parent = this
            openbedsApi.post('/patient_myprofile',formBody,{headers: postheaders})
            .then(function (response) {
                if(response.data.status==="ERROR" || response.data.status==="Failure"){
                    NotificationManager.error('', response.data.message);
                    parent.setState({
                        showLoader:false
                    })
                }else if(response.data.status==="SUCCESS"){
                    NotificationManager.success('', response.data.message);
                    parent.setState({
                        showLoader:false
                    })
                    setTimeout(()=>{
                        window.location.href=appUrls.SEARCH_RESULT_LIST
                    },3000)
                }
            })
            .catch(function (error) {
                parent.setState({showLoader:false})
                if(error.response.status == 401 || error.response.status == 403) {
                  sessionStorage.clear()
                  let errorArr = {
                      'requestStatus':'ERROR',
                      data:error
                  }
                  if (this.props.loginDetails.type == 'Patient') {
                    this.props.history.push({pathname: appUrls.USER_LOGIN, state: {loginTimeout: true}})
                  }
                }
            }.bind(this));
    }

    submitPatient = () => {
       let cState = this.state
        if(cState.attributes !== false && cState.substance_error === false && cState.noYears_error===false && cState.birth_error===false && cState.insurancetype_error ===false && cState.email !=='' && cState.emailError === false &&  cState.zip_error === false  && cState.zipcode!==''){
            this.registerPatient()
        }else{
            if(cState.zipcode === ''){
                this.setState({zip_error:true})
            }
            if(cState.birthyear===''){
                this.setState({birth_error:true})
            }

        }
    }

    goback = () => {
        this.props.history.push(appUrls.SEARCH_RESULT_LIST);
    }

    renderZipCodeError() {
        if (this.state.attributes === false) {
            return (
                <span className="error" id="zipcode_error_message">{Messages.UNSUPPORTED_STATE}</span>);
        }
        return null;
    }

      render(){
    return (
        <React.Fragment>
            <Helmet>
                <title>Patient Profile - Treatment Connection</title>
            </Helmet>
        <div>
               {
this.state.showLoaderScr ? <div className="se-pre-con_ajax"></div> : false
}
             <Header />

        <div className="patient-info-form">

	<section>
		<div className="container" role="main">
            <span id="main-content" tabIndex="-1"></span>
			<div className="row">
				<div className="col-md-12 heading-sec">
					<div className="pull-left"><h1 className="blue-heading">My Profile</h1></div>
					{/* <div className="pull-right"><a className="btn btn-primary" href="javascript:void(o)" onClick={this.goback}>Back</a></div> */}
				</div>
			</div>
			<form className="form-horizontal" id="patient-info-form">
				<div className="row">
					<div className="form-group">
                        <label htmlFor="email_address" className="col-md-6 control-label">Email Address<br/>
                        <span>(The provider will not see your email, only and ID number.<br/> Your email will only be used by the system to communicate with you.)</span>
                        </label>
                        <div className="col-md-6">
                            <input name="emailaddress" disabled={true} id="email_address" className="form-control" placeholder="Enter Email Address" type="email" onChange={this.isEmailvalidate} value={this.state.email} aria-required="true" aria-describedby="email_address_error_message" aria-invalid={this.state.emailError} autoComplete="email" />
                            {
                                this.state.emailError === true ?
                                    <span className="error" id="email_address_error_message">Please enter a valid email address.</span> : false
                            }
                            {
                                this.state.emailTaken === true ?
                                    <span className="error" id="email_address_error_message">Email already in use!</span> : false
                            }
                        </div>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="choosepassword" className="col-md-6 control-label" aria-required="true">Choose Password</label>
                        <div className="col-md-6">
                            <input name="choosepassword" id="choosepassword" className="form-control" onChange={this.isPasswordvalidate} value={this.state.password} placeholder="Enter Password" type="password"/>
                            {
                                this.state.pswdError === true ?
                                    <span className="error">Please enter at least one number, one uppercase, lowercase letter, one special character ( @, !, #, $, & ) and at least 8 or more characters</span> : false
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="reenterpassword" className="col-md-6 control-label" aria-required="true">Re-enter Password</label>
                        <div className="col-md-6">
                            <input name="reenterpassword" id="reenterpassword" className="form-control" placeholder="Re-enter Password" type="password" onChange={this.reEnter} value={this.state.repassword} onBlur={this.showPasswordErrorStatus}/>
                            {
                                this.state.repswdError === true && this.state.showPasswordError===true ?
                                    <span className="error">Password and Re-enter Password did not match </span> : false
                            }
                        </div>
                    </div> */}
				</div>
                <hr />
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="fname" className="col-md-6 control-label">Are you completing an application for yourself or a loved one?</label>
                        <div className="col-md-6">
                            <div className="controls radio-opt">
                                <label className="radio-inline" htmlFor="self">
                                    <input name="completing_for" id="self" value="self" checked={this.state.completing_for === 'self' ? true : false} onChange={this.updateCompletingFor} type="radio"/>
                                    <span className="radiomark"></span>Self</label>
                                <label className="radio-inline" htmlFor="loved_one">
                                    <input name="completing_for" id="loved_one" value="loved_one" checked={this.state.completing_for === 'loved_one' ? true : false} onChange={this.updateCompletingFor} type="radio"/>
                                    <span className="radiomark"></span>Loved One or Someone Else</label>
                            </div>
                        </div>
                    </div>
                </div>
				<hr/>
				<div className="row">
					<div className="form-group required">
                        <label htmlFor="zipcode" className="col-sm-6 control-label">Your 5 Digit ZIP Code</label>
                        <div className="col-sm-6">
                            <input name="zipcode" id="zipcode" className="form-control" placeholder="00000" type="text" pattern="[0-9]*" inputMode="numeric" aria-required="true" aria-describedby={`${this.state.zip_error === true ? 'zipcode_error_message' : ''}`} onChange={this.updateZipCode} value={this.state.zipcode} aria-invalid={this.state.zip_error} autoComplete="postal-code" />
                            {this.state.zip_error === true ? (
                                <span id="zipcode_error_message" className="error">
                                    Please enter five Numbers
                                </span>
                            ) : (
                                false
                            )}
                            {this.renderZipCodeError()}
                        </div>
                    </div>
                    <div className="form-group required">
                        <label htmlFor="birthyear" className="col-sm-6 control-label">Birth Year</label>
                        <div className="col-sm-6">
                            <input name="birthyear" id="birthyear" className="form-control" placeholder="yyyy" type="text" pattern="[0-9]*" inputMode="numeric" aria-required="true" onChange={this.updatebirthyear} value={this.state.birthyear} aria-invalid={this.state.birth_error} aria-describedby={`${this.state.birth_error === true ? 'birth_error_message' : ''}`} autoComplete="bday-year" />
                            {
                                this.state.birth_error === true ?
                                <span className="error" id="birth_error_message">Enter Valid Birth Year</span> : false
                            }
                        </div>
                    </div>
                    <fieldset className="form-group">
                        <legend htmlFor="fname" className="col-md-6 control-label">Gender</legend>
                        <div className="col-md-6">
                            <div className="controls radio-opt">
                            	<label className="radio-inline" htmlFor="male">
                            	    <input name="gender" id="male" value="M" checked={this.state.gender === 'M' ? true : false} onChange={this.updateGender} type="radio"/>
                            	<span className="radiomark"></span>Male</label>
							    <label className="radio-inline" htmlFor="female">
                                    <input name="gender" id="female" value="F" checked={this.state.gender === 'F' ? true : false} onChange={this.updateGender} type="radio"/>
                                <span className="radiomark"></span>Female</label>
							    <label className="radio-inline" htmlFor="non-binary">
                                    <input name="gender" id="non-binary" value="N" checked={this.state.gender === 'N' ? true : false} onChange={this.updateGender} type="radio"/>
                                <span className="radiomark"></span>Non-binary</label>
                            </div>
                        </div>
                    </fieldset>

				</div>
				<hr/>
				<div className="row">
					<div className="form-group">
                        <label htmlFor="noyears" className="col-sm-6 control-label">How long have you been using substance?</label>
                        <div className="col-sm-6">
                            <input name="noyears" id="noyears" className="form-control" placeholder="Number of years" aria-describedby="noyearerror" type="text" value={this.state.substanceUsingFrom} onChange={this.updatesubstanceUsingFrom}/>
                            {
                                this.state.noYears_error === true ?
                                <span id="noyearerror" className="error">Enter Number of years</span> : false

                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="substances" className="col-md-6 control-label">Which substances are you using?</label>
                        <div className="col-md-6" style={{color: "rgb(51, 51, 51)"}}>
                        <AccessibleSelect options={this.substancesOptions()} className="searchinput" placeholder="Substances" isMulti value={this.props.substances} onChange={this.updateSubstances} inputId="substances" />
                        {
                                this.state.substance_error === true ?
                                <span className="error">Please select one or more substances</span> : false

                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="treatment" className="col-md-6 control-label">What treatment have you had ?</label>
                        <div className="col-md-6">
                        <AccessibleSelect options={this.serviceTypeOptions()} className="searchinput" placeholder="Treatment Type" isMulti value={this.props.treatment} onChange={this.updateTreatmentType} inputId="treatment" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="seeking" className="col-md-6 control-label">Are you seeking specific treatment ?</label>
                        <div className="col-md-6">
                        <AccessibleSelect options={this.serviceTypeOptions()} className="searchinput" placeholder="Treatment Type" isMulti value={this.props.seekingTreatmentType} onChange={this.updateSeekingTreatmentType} inputId="seeking" />

                        </div>
                    </div>
				</div>
				<hr/>
				<div className="row">
					<fieldset className="form-group">
                        <legend htmlFor="fname" className="col-md-6 control-label">Do you have Insurance ?</legend>
                        <div className="col-md-6">
                            <div className="controls radio-opt">
                            	<label className="radio-inline" htmlFor="yes">
                            		<input name="insurance" id="yes" value="Y" onChange={this.updateInsurance} checked={this.state.insurance==='Y' ? true : false} type="radio"/>
                            		<span className="radiomark"></span>
							      Yes
							    </label>
							    <label className="radio-inline" htmlFor="no">
							    	<input name="insurance" id="no" value="N" type="radio"  onChange={this.updateInsurance} checked={this.state.insurance==='N' ? true : false}/>
							    	<span className="radiomark"></span>
							      No
							    </label>
                            </div>
                        </div>
                    </fieldset>
                    {
                        this.state.showInsurance === true ?
                    <div className="form-group">
                        <label htmlFor="insurance" className="col-md-6 control-label">If yes, what insurance do you have ?<br/>
                            <span>(Maximum 100 characters)</span>
                        </label>
                        <div className="col-md-6">
                            <input name="insurance" id="insurance" className="form-control" placeholder="Name of Insurance" type="text" maxLength="100" value={this.state.insurancetype} onChange={this.updateInsuranceType}/>
                            {
                                this.state.insurancetype_error === true ?
                                    <span className="error">Please enter less than 100 characters</span> : false

                            }
                        </div>
                    </div>
                    : false
                    }
				</div>
				<hr/>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="comment" className="col-md-6 control-label">Any Additional Information?

                        </label>
                        <div className="col-md-6">
                            <textarea name="comment" id="comment" className="form-control" onChange={this.updateComment} value={this.state.comment || ""} ></textarea>
                        </div>
                    </div>
                </div>
                <hr />
				<div className="row">
					<div className="form-group">
                        <label className="col-md-6 control-label"></label>
                        <div className="col-md-6 text-right">
                            <button type="button" className="btn btn-submit" onClick={this.submitPatient}>Submit</button>
                            <button type="reset" className="btn btn-cancel" onClick={this.goback}>Cancel</button>
                        </div>
                    </div>
				</div>
			</form>
		</div>
	</section>
    <NotificationContainer/>
    <Footer/>
        </div> </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps=(state)=>{
    return {
        loginDetails:state.loginDetails,
        treatment:state.treatment,
        substances:state.substances,
        seekingTreatmentType:state.seekingTreatmentType,
      }
  }

  export default connect(mapStateToProps,{fetchPosts,updateZipcode,updateDistance,updatePayment,updateTreatment,updateSubstances,updateSeekingTreatment})(PatientMyProfile);
