import React from 'react';
import { Link } from 'react-router-dom'
import openbedsApi from './../../openbedsApi';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from 'react-redux';
import {appUrls} from "../../appUrls";
import Footer from "../layout/footer";
import PasswordField from "../Forms/Components/PasswordField";

class ResetPassword extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            emailError:false,
            email:'',
            success:false,
            successMessage:'',

          pswdError:false,
          cpswdError:false,
          passwordsMatch: false,
          password:'',
          confirmpassword:'',
            failure:false,
            failureMessage:'',
            showLoader:false,

     };
    }
    async componentWillMount(){
    }

    isPasswordvalidate = (pswd) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        let error = false;
        if(regex.test(pswd)){
          this.setState({pswdError:false,password:pswd})
        } else {
            error = true
            this.setState({pswdError:true,password:pswd})
        }
        if (pswd !== '') {
            this.setState({failure: false, failureMessage: ''});
        }
        return error;
    }

    isConfirmPasswordvalidate = (pswd) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        let passwordsMatch = false;
        let error = false;
        if((regex.test(pswd) && pswd === this.state.password )){
          passwordsMatch = (pswd !== '');
          this.setState({cpswdError:false,confirmpassword:pswd, passwordsMatch})
        }else{
          error = true
          this.setState({cpswdError:true,confirmpassword:pswd, passwordsMatch})
        }
        if (pswd !== '') {
            this.setState({failure: false, failureMessage: ''});
        }

        return error;
    }
    forgot = () => {
        this.setState({showLoader:true})
        let details ={
            password:this.state.password
           }

    let postheaders = {
        'Content-Type':'application/x-www-form-urlencoded',
        'Accept':'application/json, text/plain'
    }
          let formBody = [];
          for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          let parent = this
            openbedsApi.post('/reset_password/'+this.props.token,formBody,{headers: postheaders})
            .then(function (response) {
                if(response.data.status==="ERROR"){
                    parent.setState({
                        failure:true,
                        failureMessage:response.data.message,
                        showLoader:false
                    })
                }else if(response.data.status==="SUCCESS"){
                    parent.setState({
                        success:true,
                        successMessage:response.data.message,
                        showLoader:false
                    })
                }
            })
            .catch(function (error) {
               //  console.log(error);
                parent.setState({showLoader:false})
                if(error.response.status == 401) {
                  sessionStorage.clear()
                  let errorArr = {
                      'requestStatus':'ERROR',
                      data:error
                  }
                  if (this.props.loginDetails.type == 'Patient') {
                    this.props.history.push({pathname: appUrls.USER_LOGIN, state: {loginTimeout: true}})
                  }
                }
            }.bind(this));
    }

    submitForgot = () => {
      const { password, confirmpassword } = this.state;
      let passwordValid = this.isPasswordvalidate(password);
      let confirmPasswordValid = this.isConfirmPasswordvalidate(confirmpassword);

      if (!passwordValid && !confirmPasswordValid) {
        this.forgot();
      } else {
        const passwordError = this.state.pswdError || this.state.password === '';
        const confirmpasswordError = this.state.cpswdError || this.state.confirmpassword === '';
        this.setState({ emailError: true, pswdError: passwordError, cpswdError: confirmpasswordError });
      }
    }

    render(){
       const {token} = this.props
    return (
        <div>
                 <div className="container" role="main">
            <div className="row">
				<div className="col-lg-4 col-lg-offset-4 col-md-4 col-md-offset-4 col-sm-4 col-sm-offset-4 loginForm">
          <h3 className="text-center">
            <Link to="/">
              <img src="/images/treatmentconnection_logo_header.png" className="loginImg" alt="Treatment Connection"/>
            </Link>
          </h3>


                  {/* <h4 className="text-center mt20">Provider login for Public Portal</h4> */}
                  <h4 className="text-center mt20">Reset Password</h4>

                  { this.state.success === false ?
				  	<div>
            <form autoComplete="off" action="javascript:void(0)" method="post" name="forgot_password">
          
				    <PasswordField
                      updatePasswordValue={(e) =>
                        this.isPasswordvalidate(e.target.value)
                      }
                      placeholderValue="New Password"
                      passwordValue={this.state.password}
                      passwordHasError={this.state.pswdError}
                      passwordErrorMessage="Please enter at least one number, one uppercase,
                        lowercase letter, one special character ( @, !, #, $, &
                        ) and at least 8 or more characters"
                    />

                    <PasswordField
                      labelText="Confirm Password"
                      updatePasswordValue={(e) =>
                        this.isConfirmPasswordvalidate(e.target.value)
                      }
                      placeholderValue="Confirm Password"
                      passwordValue={this.state.confirmpassword}
                      passwordHasError={this.state.cpswdError}
                      passwordErrorMessage="Password and Confirm Password do not match"
                    />

                    {this.state.passwordsMatch ? (
                      <span className="error">Passwords match!</span>
                    ) : null}

                    {this.state.failure === true ? (
                      <span className="error">{this.state.failureMessage}</span>
                    ) : (
                      false
                    )}
				    <button type="submit" onClick={this.submitForgot} disabled={this.state.showLoader} name="login" value="Login" className="btn btn-primary btn-block">Reset Password
                    { this.state.showLoader === true ?
                        <i className="fa fa-circle-o-notch fa-spin spinner"></i> :false
                    }
                    </button>
                    </form>

                 </div>:
                      <>
                     <span className="success">{this.state.successMessage}</span>
                          <p className="text-center"><a href={appUrls.USER_LOGIN}>Please click here to go to the login page</a></p>
                     </>
                  }
				</div>
			</div>
		</div>
        <Footer/>
        </div>
    )
  }
}

const mapStateToProps=(state)=>{
    return {}
  }

  export default connect(mapStateToProps,{})(ResetPassword);
