import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { submitLogin } from "../../actions/login";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Modal, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { PhoneNumberLink } from "../generic/PhoneNumberLink";
import { appUrls } from "../../appUrls";
import { getConfigurations } from "../../actions/selectattributes";

const homePopOver = (
  <Popover id="homePopOver">
   <img src="/images/1.png" className="techImg" alt="Home Page" />
    <p> Home Page </p>
  </Popover>
);

const searchPopOver = (
  <Popover id="searchPopOver" aria-live="polite">
   <img src="/images/2.png" className="techImg" alt="Search Page" />
    <p> Search Page </p>
  </Popover>
);

const educationalPopOver = (
  <Popover id="educationalPopOver">
   <img src="/images/9.PNG" className="techImg" alt="Educational Materials" />
    <p> Educational Materials </p>
  </Popover>
);

const triagePopOver = (
  <Popover id="triagePopOver">
   <img src="/images/8.png" className="techImg" alt="Self-Assessment Page" />
    <p> Self-Assessment Page </p>
  </Popover>
);

const loginPopOver = (
  <Popover id="loginPopOver" aria-live="polite">
   <img src="/images/3.png" className="techImg" alt="Login Page" />
    <p> Login Page </p>
  </Popover>
);

const requestsPopOver = (
  <Popover id="requestsPopOver" aria-live="polite">
   <img src="/images/4.png" className="techImg" alt="Request Page" />
    <p> Request Page </p>
  </Popover>
);

const profilePopOver = (
  <Popover id="profilePopOver" aria-live="polite">
   <img src="/images/10.PNG" className="techImg" alt="Profile Page" />
    <p> Profile Page </p>
  </Popover>
);

class PatientNeedHelp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1,
    };
    //attempt to fetch generic pfs configurations
    this.props.getConfigurations();

    this.searchPopOverRef = React.createRef();
    this.searchPopOverRef2 = React.createRef();
    this.homePopOverRef = React.createRef();
    this.educationalPopOverRef = React.createRef();
    this.triagePopOverRef = React.createRef();
    this.loginPopOverRef = React.createRef();
    this.requestsPopOverRef = React.createRef();
    this.profilePopOverRef = React.createRef();
  }

  componentDidMount = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get("tab");

    if (this.props.location.state) {
      this.setState({ tab: parseInt(this.props.location.state.tab) });
    } else if (foo) {
      this.setState({
        tab: parseInt(foo),
      });
    }
  };

  setTabParm() {
    setTimeout(() => {
      this.props.history.push("?tab=" + this.state.tab);
    }, 500);
  }

  goBack() {
    if (this.state.tab != 1) {
      this.setState({ tab: this.state.tab - 1 });
      this.setTabParm();
    }
  }

  goForward() {
    if (this.state.tab != 3) {
      this.setState({ tab: this.state.tab + 1 });
      this.setTabParm();
    }
  }

  goClick(tab) {
    this.setState({ tab: tab });
    this.setTabParm();
  }

  showOverlay = (overlayRef) => {
    const overlayRefs = [
      this.searchPopOverRef,
      this.searchPopOverRef2,
      this.homePopOverRef,
      this.educationalPopOverRef,
      this.triagePopOverRef,
      this.loginPopOverRef,
      this.requestsPopOverRef,
      this.profilePopOverRef,
    ];
    overlayRefs.map((ref) => {
      if (ref === overlayRef && overlayRef.current) {
        overlayRef.current.show();
      } else if (ref.current) {
        ref.current.hide();
      }
    });
  };

  hideOverlay = (overlayRef) => {
    if (overlayRef.current) overlayRef.current.hide();
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Help - Treatment Connection</title>
        </Helmet>
        <Header />
        <div className="container" role="main">
          <div className="row back_button_row">
            <div className="col-md-12">
              <div className="pull-right">
                <button
                  onClick={() => this.props.history.push("/#about")}
                  className="btn btn-primary"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="row privacy_policy_panel" id="patient_help">
            <div className="col-md-push-2 col-md-8">
              <div className="print_only">
                <img
                  src="/images/treatmentconnection_logo_header.png"
                  className="helpImg"
                  alt="Treatment Connection"
                />
                <span className="logoTxt">TreatmentConnection</span>
              </div>
              <div className="tableheading1">
                <span className="fa-2x"> Help </span>
                <a
                  className="btn btn-primary no-print needhelp_print patients-need-help-print-btn"
                  href="/help_documents/treatmentconnection_help.pdf"
                  target={"_blank"}
                >
                  Print
                </a>
              </div>
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="bubble-container">
                    <div className="row bubble-switcher no-print" role="tablist" aria-label="Help Navigation Tabs">
                      <a
                        role="button"
                        href="#"
                        className="bubble-nav"
                        onClick={this.goBack.bind(this)}
                        aria-label="Previous"
                        tabIndex="0"
                      >
                        {" "}
                        &lt;{" "}
                      </a>
                      <a 
                        className="bubble-tab"
                        role="tab"
                        aria-selected="true"
                        tabindex="0"
                        id="tab-1"
                        >
                        <div className="bubble-float">
                          {this.state.tab == 1 ? (
                            <a
                              href="#"
                              className="bubble"
                              onClick={() => this.goClick(1)}
                            >
                              <span>1</span>
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="bubble deselected"
                              onClick={() => this.goClick(1)}
                            >
                              <span>1</span>
                            </a>
                          )}
                        </div>
                        <div className="bubble-header">
                          {" "}
                          {this.state.tab == 1
                            ? "Search for Treatment"
                            : ""}{" "}
                        </div>
                      </a>
                      <a 
                        className="bubble-tab bubble-middle-tab"
                        role="tab"
                        aria-selected="false"
                        tabindex="-1"
                        id="tab-2"
                        >
                        <div className="bubble-float">
                          {this.state.tab == 2 ? (
                            <a
                              href="#"
                              className="bubble"
                              onClick={() => this.goClick(2)}
                            >
                              <span>2</span>
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="bubble deselected"
                              onClick={() => this.goClick(2)}
                            >
                              <span>2</span>
                            </a>
                          )}
                        </div>
                        <div className="bubble-header">
                          {" "}
                          {this.state.tab == 2
                            ? "Learn about Treatment"
                            : ""}{" "}
                        </div>
                      </a>
                      <a 
                        className="bubble-tab"
                        role="tab"
                        aria-selected="false"
                        tabindex="-1"
                        id="tab-3"
                        >
                        <div className="bubble-float">
                          {this.state.tab == 3 ? (
                            <a
                              href="#"
                              className="bubble"
                              onClick={() => this.goClick(3)}
                            >
                              <span>3</span>
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="bubble deselected"
                              onClick={() => this.goClick(3)}
                            >
                              <span>3</span>
                            </a>
                          )}
                        </div>
                        <div className="bubble-header">
                          {" "}
                          {this.state.tab == 3 ? "Express Interest" : ""}
                        </div>
                      </a>
                      <a
                        role="button"
                        href="#"
                        className="bubble-nav"
                        onClick={this.goForward.bind(this)}
                        aria-label="Next"
                        tabIndex="0"
                      >
                        {" "}
                        &gt;{" "}
                      </a>
                    </div>

                    <div
                      className="bubble-modal-container"
                      id={"bubble" + this.state.tab}
                    >
                      <div
                        className={`bubble-modal ${this.state.tab !== 1 ? 'hidden' : ''}`}
                        id={this.state.tab == 1 ? "bubble-modal-active" : ""}
                        role="tabpanel" 
                        tabindex="0"
                        aria-labelledby="tab-1"
                      >
                        <div className="print_only">
                          <img
                            src="/images/1.png"
                            className="techImg"
                            alt="Search for Treatment"
                          />
                          <span className="bubble">
                            <span>1</span>
                          </span>
                          <div className="bubble-header">
                            Search for Treatment
                          </div>
                        </div>

                        <p className="textInline">
                          To search for a treatment facility, first enter the
                          zip code in which you are seeking treatment. The{" "}
                        </p>

                        <OverlayTrigger
                          ref={this.homePopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={homePopOver}
                          rootClose
                        >
                          <a
                            tabIndex={this.state.tab === 1 ? "0" : "-1"}
                            aria-label="Open Home page"
                            href="#"
                            className="textInline"
                            onClick={() => {
                              window.open("../");
                            }}
                            onMouseOver={() => this.showOverlay(this.homePopOverRef)}
                            onFocus={() => this.showOverlay(this.homePopOverRef)}
                            onBlur={() => this.hideOverlay(this.homePopOverRef)}
                          >
                            Home Page{" "}
                          </a>
                        </OverlayTrigger>

                        <p className="textInline">
                          has basic filters that you can use to refine your
                          search. The{" "}
                        </p>

                        <OverlayTrigger
                          ref={this.searchPopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={searchPopOver}
                          rootClose
                        >
                          <a 
                            tabIndex={this.state.tab === 1 ? "0" : "-1"}
                            aria-label="Search Page"
                            href="#"
                            className="textInline"
                            onClick={() => {
                              window.open(appUrls.SEARCH_RESULT_LIST);
                            }}
                            onMouseOver={() => this.showOverlay(this.searchPopOverRef)}
                            onFocus={() => this.showOverlay(this.searchPopOverRef)}
                            onBlur={() => this.hideOverlay(this.searchPopOverRef)}
                          >
                            Search Page{" "}
                          </a>
                        </OverlayTrigger>

                        <p className="textInline">
                          has more filters that you can use to further refine
                          your search. You can access the Home page from any
                          page in the system by clicking on the OpenBeds icon at
                          the top left corner of the screen. <br />
                          <br />
                        </p>
                      </div>

                      <div
                        className="bubble-modal"
                        id={this.state.tab == 2 ? "bubble-modal-active" : ""}
                        role="tabpanel" 
                        tabindex="0"
                        aria-labelledby="tab-2"
                      >
                        <div className="print_only">
                          <img
                            src="/images/8.png"
                            className="techImg"
                            alt="Learn about Treatment"
                          />
                          <span className="bubble">
                            <span>2</span>
                          </span>
                          <div className="bubble-header">
                            Learn about Treatment
                          </div>
                        </div>
                        <p className="textInline">Select the </p>
                        <OverlayTrigger
                          ref={this.educationalPopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={educationalPopOver}
                          rootClose
                        >
                          <a
                            tabIndex={this.state.tab === 2 ? "0" : "-1"}
                            aria-label="Educational Material"
                            href="#"
                            className="textInline"
                            onClick={() => {
                              window.open(appUrls.RESOURCE_LIST);
                            }}
                            onMouseOver={() => this.showOverlay(this.educationalPopOverRef)}
                            onFocus={() => this.showOverlay(this.educationalPopOverRef)}
                            onBlur={() => this.hideOverlay(this.educationalPopOverRef)}
                          >
                            Educational Material{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">
                          button to access substance use treatment resources.
                          Select the{" "}
                        </p>
                        <OverlayTrigger
                          ref={this.triagePopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={triagePopOver}
                          rootClose
                        >
                          <a
                            tabIndex={this.state.tab === 2 ? "0" : "-1"}
                            className="textInline"
                            href="/assessment"
                            target="_blank"
                            onMouseOver={() => this.showOverlay(this.triagePopOverRef)}
                            onFocus={() => this.showOverlay(this.triagePopOverRef)}
                            onBlur={() => this.hideOverlay(this.triagePopOverRef)}
                          >
                            What level of treatment do I need{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">
                          {" "}
                          button to take a self-assessment to determine the
                          appropriate type of treatment for you or your loved
                          one.{" "}
                        </p>
                      </div>

                      <div
                        className="bubble-modal"
                        id={this.state.tab == 3 ? "bubble-modal-active" : ""}
                        role="tabpanel" 
                        tabindex="0"
                        aria-labelledby="tab-3"
                      >
                        <div className="print_only">
                          <img
                            src="/images/2.png"
                            className="techImg"
                            alt="Express Interest"
                          />
                          <span className="bubble">
                            <span>3</span>
                          </span>
                          <div className="bubble-header">Express Interest</div>
                        </div>
                        <p className="textInline">
                          To contact a treatment provider select the ‘Contact
                          About Treatment’ button on the{" "}
                        </p>
                        <OverlayTrigger
                          ref={this.searchPopOverRef2}
                          trigger={null}
                          placement="top"
                          overlay={searchPopOver}
                          rootClose
                        >
                          <a
                            tabIndex={this.state.tab === 3 ? "0" : "-1"}
                            aria-label="Search page"
                            href="#"
                            className="textInline"
                            onClick={() => {
                              window.open(appUrls.SEARCH_RESULT_LIST);
                            }}
                            onMouseOver={() => this.showOverlay(this.searchPopOverRef2)}
                            onFocus={() => this.showOverlay(this.searchPopOverRef2)}
                            onBlur={() => this.hideOverlay(this.searchPopOverRef2)}
                          >
                            Search page.{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">You will be prompted to </p>

                        <OverlayTrigger
                          ref={this.loginPopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={loginPopOver}
                          rootClose
                        >
                          <a
                            tabIndex={this.state.tab === 3 ? "0" : "-1"}
                            href="#"
                            className="textInline"
                            onClick={() => {
                              window.open(appUrls.USER_LOGIN);
                            }}
                            onMouseOver={() => this.showOverlay(this.loginPopOverRef)}
                            onFocus={() => this.showOverlay(this.loginPopOverRef)}
                            onBlur={() => this.hideOverlay(this.loginPopOverRef)}
                          >
                            log in to your account or create an account.{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">
                          {" "}
                          If you already have an account, select the ‘Login’
                          button to login. If you would like to create an
                          account, select ‘Create an Account’. Creating an
                          account involves completing your profile. This profile
                          communicates information to the provider such that
                          they can better serve you or your loved one’s needs.
                          The provider will not see your email address.{" "}
                        </p>
                        <br></br>
                        <br></br>
                        <p className="textInline">
                          {" "}
                          Your outreach to providers is logged at the{" "}
                        </p>
                        <OverlayTrigger
                          ref={this.requestsPopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={requestsPopOver}
                          rootClose
                        >
                          <a
                              tabIndex={this.state.tab === 3 ? "0" : "-1"}
                              href="#"
                              className="textInline"
                              onMouseOver={() => this.showOverlay(this.requestsPopOverRef)}
                              onFocus={() => this.showOverlay(this.requestsPopOverRef)}
                              onBlur={() => this.hideOverlay(this.requestsPopOverRef)}
                          >
                            My Requests page.{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">
                          {" "}
                          You can modify your profile at any time by selecting{" "}
                        </p>
                        <OverlayTrigger
                          ref={this.profilePopOverRef}
                          trigger={null}
                          placement="top"
                          overlay={profilePopOver}
                          rootClose
                        >
                          <a
                              tabIndex={this.state.tab === 3 ? "0" : "-1"}
                              href="#"
                              className="textInline"
                              onMouseOver={() => this.showOverlay(this.profilePopOverRef)}
                              onFocus={() => this.showOverlay(this.profilePopOverRef)}
                              onBlur={() => this.hideOverlay(this.profilePopOverRef)}
                          >
                            My Profile{" "}
                          </a>
                        </OverlayTrigger>
                        <p className="textInline">
                          from the dropdown menu at the top right.
                        </p>
                        <p className="textInline">
                          {" "}
                          The treatment provider’s response will be sent to your
                          email using a confidential numeric ID (without knowing
                          your email or identity). They will typically let you
                          know next steps as to how you can access their
                          services.
                        </p>
                      </div>
                    </div>
                    <div className="no-print">
                      {this.state.tab == 1 ? (
                       <img src="/images/1.png" className="techImg" alt="Look For Treatment Image - Search Form" />
                      ) : (
                        ""
                      )}
                      {this.state.tab == 2 ? (
                       <img src="/images/8.png" className="techImg" alt="Learn About Treatment Image - Assessment" />
                      ) : (
                        ""
                      )}
                      {this.state.tab == 3 ? (
                       <img src="/images/2.png" className="techImg" alt="Express Interest Image - Search Result" />
                      ) : (
                        ""
                      )}
                    </div>

                    <br></br>
                    <h4 className="helpHead">Contact</h4>
                    <div className="panel-body-p">
                      <b className="highlight-p darkblue">
                        {" "}
                        OpenBeds-TreatmentConnection
                      </b>{" "}
                      technical support may be reached through Customer Support
                      or by Phone:
                      <br />
                      <div className="helpData bubble-helpData">
                        Contact:{" "}
                        <a
                          href={
                            this.props.pfsConfigurations
                              ? this.props.pfsConfigurations
                                  .customer_support_url
                              : '#'
                          }
                          aria-label="Contact Customer Support"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Customer Support
                        </a>
                        <br />
                        Phone:{" "}
                        <span>
                          <PhoneNumberLink
                            phoneNumber={
                              this.props.pfsConfigurations
                                ? this.props.pfsConfigurations
                                    .support_phone_number
                                : ""
                            }
                          />
                        </span>
                        <br />
                        Hours:{" "}
                        <span>
                          {this.props.pfsConfigurations
                            ? this.props.pfsConfigurations
                                .customer_support_hours
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pfsConfigurations: state.pfsConfigurations,
  };
};
export default connect(mapStateToProps, { getConfigurations })(PatientNeedHelp);
