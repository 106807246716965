import { useState, useEffect, useMemo } from 'react';
import AccessibleSelect from '../accessibility-helpers/accessible-select';

export const SearchFilter = ({
    id,
    name,
    label,
    className="form-control searchinput",
    type="checkboxes",
    placeholder = "Search...",
    isSearchable = true,
    attributeData,
    attributeName = 'name',
    attributeKey = 'id',
    attributes,
    handleChange,
    handleUpdate,
    selectedValues,
    autoComplete = 'off',
    isMulti = true,
    isClearable = false,
    hiideSelectedOptions=true,
    ...props
  }) => {
    const allOptions = useMemo(() => {
      if (!attributes || !attributes[attributeData]) return [];
      
      return attributes[attributeData]
        .filter((item) => item[attributeName] !== 'All')
        .map((item) => {
          const { [attributeKey]: value, [attributeName]: label, ...rest } = item;
          return { value, label , ...rest }
        });
    }, [attributes, attributeData, attributeName, attributeKey]);
  
    // Initialize the selected values and filtered options
    const [newSelectedValues, setNewSelectedValues] = useState(selectedValues || []);
    const [filteredOptions, setFilteredOptions] = useState(allOptions);
    const [searchString, setSearchString] = useState('');
  
    // Update filtered options when searchString changes
    useEffect(() => {
      const filtered = allOptions.filter(option =>
        !searchString || searchString == ''|| option.label.toLowerCase().includes(searchString?.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }, [searchString, allOptions]);
  
    // Update the component state when the selectedValues prop changes
    useEffect(() => {
      setNewSelectedValues(selectedValues || []);
    }, [selectedValues]);
  
    const handleOptionsChange = (optionValue, isChecked) => {
      let updatedValues;
  
      if (isChecked) {
        // Add the selected option
        const selectedOption = allOptions.find(option => option.value === optionValue);
        updatedValues = [...new Set([...newSelectedValues, selectedOption])];
      } else {
        // Remove the unselected option
        updatedValues = newSelectedValues.filter(option => option.value !== optionValue);
      }
  
      setNewSelectedValues(updatedValues); // update local state
      handleChange && handleChange(updatedValues); // update parent state with hook
    };

    const handleSelection = (selectedValue) => {
      setNewSelectedValues(selectedValue); // update local state
      finalizeSelection(selectedValue);
    };
  
    const handleSearchChange = (e) => {
      const value = e.target.value || e.value || '';
      setSearchString(value); // Update search string state
    };
  
    // Call handleUpdate when the user has finished making their selection
    const finalizeSelection = (selection) => {
      handleUpdate && handleUpdate(selection);
    };
  
    const clearSelection = (event) => {
      setSearchString("");
      event.target.previousSibling.focus();
    };

    const selectAllFilters = (evt) => {
      const _newValues = evt.target?.checked === true ? allOptions : [];
      setNewSelectedValues(_newValues);
      handleChange && handleChange(_newValues);
    }
  
    return (
      <>
        {type === "checkboxes" && (
          <>
          {label && <label className="label-def" htmlFor={id}>{label}</label>}
          {isSearchable && (
            <div className="input-group">
              {/* <span className="input-group-addon btn-flat px-0 pr-1 bg-trans border-0">
                <input type="checkbox" aria-label="select all" onChange={selectAllFilters} />
              </span> */}
              <input
                id={`search-${id}`}
                name={name}
                type="text"
                className={className}
                placeholder={placeholder}
                onChange={handleSearchChange}
                value={searchString}
                onBlur={() => finalizeSelection(newSelectedValues)} // Finalize on blur
                autoComplete={autoComplete}
              />
              <span className="input-group-addon btn" onClick={clearSelection}> x </span>
            </div>
          )}
          <div id={id} data-testid={id} className="scrollable mt-20" onBlur={() => finalizeSelection(newSelectedValues)}> 
            {filteredOptions.map((option, index) => (
              <div className="form-check checkbox" key={`option-${id}-${index}`}>
                <label className="form-check-label" htmlFor={`option-${id}-${index}`}>
                  <input
                    id={`option-${id}-${index}`}
                    className="form-check-input"
                    type="checkbox"
                    name={name}
                    value={option.value}
                    checked={newSelectedValues.some(selected => selected.value === option.value)}
                    onChange={(e) => handleOptionsChange(option.value, e.target.checked)}
                    {...props}
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </>
        )}
        {type === "select" && (
          <>
            {label && <label className="label-def" htmlFor={id}>{label}</label>}
            <AccessibleSelect
              inputId={id}
              data-testid={id}
              labelId={label? undefined: `label-${id}`}
              options={allOptions}
              className={className || 'searchinput'}
              placeholder={placeholder}
              value={newSelectedValues}
              onChange={handleSelection}
              isMulti={isMulti}
              isClearable={isClearable}
              isSearchable={isSearchable}
              hideSelectedOptions={hiideSelectedOptions}
              {...props}
            />
          </>
        )}
        </>
    );
}
  
  export default SearchFilter;