export const appUrls = {
    ABOUT_SECTION_HOMEPAGE: "/#about",
    USER_LOGIN: "/signin",
    FORGOT_PASSWORD: "/forgotpassword",
    COGNITO_FORGOT_PASSWORD: "/forgotpassword",
    SEARCH_RESULT_LIST: "/search-treatment-facilities",
    RESOURCE_LIST: "/resources",
    RESOURCE_BASE: "/resources",
    RESOURCE_PAGES: {
        QUESTIONS_ABOUT_TREATMENT: "/questions-about-treatment",
        STAGES_OF_RELAPSE: "/stages-of-relapse",
        STIGMA_OF_ADDICTION: "/stigma-of-addiction",
        WHAT_IS_MAT: "/what-is-mat",
    },
    FACILITY_DETAILS: "/facility-details",
};
