import React from "react";
import { withRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { allowedEnvironments } from "../configuration/googleAnalytics";
import BannerGA from "../utils/bannerGA";
import { connect } from "react-redux";
import { gaLogEvent, gaPageView } from "./../actions/googleAnalytics";
import { trackEvent, trackPageView } from "@redux-beacon/google-analytics-gtag";

export const ignoreAnalyticsCollection = () => {
  const hostname = window && window.location && window.location.hostname;

  if (allowedEnvironments.indexOf(hostname) > -1) {
    return false;
  } else {
    return true;
  }
};

class GoogleAnalytics extends React.Component {
  componentDidMount() {
    if (ignoreAnalyticsCollection()) {
      return;
    }

    let url = this.buildUrl(this.props.location);

    this.props.gaPageView(url);
  }

  componentDidUpdate() {
    if (ignoreAnalyticsCollection()) {
      return;
    }

    let url = this.buildUrl(this.props.location);
    this.props.gaPageView(url);
  }

  buildUrl(location) {
    let url = location.pathname;
    if (location.hash !== undefined) {
      url += location.hash;
    }
    return url;
  }

  render() {
    return (
      <CookiesProvider>
        <BannerGA></BannerGA>
      </CookiesProvider>
    );
  }
}

/*
 * Use this if you are calling ReactGA directly.
 * */
export function analyticsShouldLog() {
  if (ignoreAnalyticsCollection()) {
    return false;
  } else {
    return true;
  }
}

export const googleAnalyticsEvents = {
  GA_LOG_PAGE: trackPageView((action) => {
    return {
      location: action.payload,
    };
  }),
  GA_LOG_EVENT: trackEvent((action) => {
    return action.payload;
  }),
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, { gaLogEvent, gaPageView })(GoogleAnalytics)
);
