import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Footer from './../layout/footer';
import AccessibleSelect from '../accessibility-helpers/accessible-select';
import {
    fetchPosts,
    updateZipcode,
    updateDistance,
    updatePayment,
    updateTreatment,
    updateSubstances,
    updateSeekingTreatment
} from './../../actions/selectattributes';
import { sendRequest, clearRequestdata } from './../../actions/login';
import openbedsApi from './../../openbedsApi';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal, Button} from 'react-bootstrap';
import { fetchStateAttributes, isEmailTaken} from '../../apiClient';
import { TreatmentDefinitionsModal } from '../search/TreatmentDefinitionsModal';
import { Messages } from '../messages';
import { appUrls } from '../../appUrls';
import simulateKeyboardSelectionClick from '../accessibility-helpers/simulate-keyboard-selection-click';

class PatientSignup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailError: false,
            pswdError: false,
            email: '',
            password: '',
            repassword: '',
            repswdError: false,
            zip_error: false,
            zipcode: '',
            /*username: '',*/
            birthyear: '',
            gender: 'M',
            substanceUsingFrom: '',
            insurance: 'N',
            insurancetype_error: false,
            showInsurance: false,
            insurancetype: '',
            checked: false,
            acceptterms: false,
            emailTaken: false,
            showLoader: false,
            birth_error: false,
            noYears_error: false,
            substancesUsing: [],
            treatment: [],
            treatmentSeeking: [],
            show: false,
            showPasswordError: false,
            showTreatmentInfoModal: false,
            showAgreementInfoModal: false,
            comment: '',
            completing_for: 'self',
            zipcodeDisable: false,
            attributes: null,
            checkingEmailUniqueness: false,
            valid_zip_state: true,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.sendRequestMessage.status === 'SUCCESS') {
                this.props.clearRequestdata();
                this.props.history.push('/patients/rrs?new_enquiry=1');
            } else if (this.props.sendRequestMessage.status === 'ERROR') {
                NotificationManager.error('', this.props.sendRequestMessage.message);
                this.props.clearRequestdata();
            }
        }

        if (
            this.props.loginDetails !== undefined &&
            (this.props.loginDetails.type === 'Provider' ||
                this.props.loginDetails.type === 'Provider' ||
                this.props.loginDetails.type === '4a provider')
        ) {
            this.props.history.push('/search-treatment-facilities');
        }
    }

    checkStateAnyEmailError = () => {
        // patch to control aria-required on email field.
        // Seems these state error checks could be named better or better controlled.
        if (this.state.emailError || this.state.emailTaken) {
            return true;
        } else {
            return false;
        }
    };

    async componentWillMount() {
        //let provider = this.props.loginDetails
        await this.props.fetchPosts();
        await this.updateSubstances(this.props.substances);
        await this.fetchAttributes('19701'); //default to de fetch state attributes
    }

    showPasswordErrorStatus = () => {
        this.setState({
            showPasswordError: true,
        });
    };

    removeRoleDocument = () => {
        // accessibility requirement
        // Note: corrected by the third-party in latest version (compatible with bootstrap4)
        // should be able to remove this functionality then.
        let modalInstances = document.querySelectorAll('.modal');
        let modalContentInstances = document.querySelectorAll('.modal-content');

        modalInstances.forEach((modalInstance) => {
            let modalElement = modalInstance;
            let parentNode = modalInstance.parentNode;
            modalElement.setAttribute('tabindex', '0');
            parentNode.removeAttribute('role');
        });

        modalContentInstances.forEach((modalContentInstance) => {
            let modalContentElement = modalContentInstance;
            modalContentElement.removeAttribute('role');
        });
    };

    isEmailvalidate = (e) => {
        let email = this.state.email;

        isEmailTaken(email)
            .then((taken) => {
                this.setState({ emailTaken: taken });
            });

        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(email) || email === '') {
            this.setState({ emailError: false, email: email, emailTaken: false });
        } else {
            this.setState({ emailError: true, email: email });
        }
    };

    isPasswordvalidate = (e) => {
        let pswd = e.target.value;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        if (regex.test(pswd) || pswd === '') {
            this.setState({ pswdError: false, password: pswd });
        } else {
            this.setState({ pswdError: true, password: pswd });
        }
        if (this.state.repassword !== '') {
            this.setState({
                repswdError: !this.isValidRePass(pswd, this.state.repassword),
            });
        }
    };

    reEnter = (e) => {
        let pswd = e.target.value;
        this.setState({
            repassword: pswd,
            repswdError: !this.isValidRePass(this.state.password, pswd),
        });
    };

    isValidRePass = (pass, rePass) => {
        return pass === rePass;
    };

    updateZipCode = (e) => {
        var zip = e.target.value;

        const regex = /^[0-9]{5}$/;
        if (regex.test(zip)) {
            this.setState({ zip_error: false, zipcode: e.target.value });
            if (zip.length > 0) {
                this.fetchAttributes(zip);
            }
        } else if (zip === '') {
            this.setState({ zip_error: false, zipcode: e.target.value });
            //this.setState({zipcodeDisable: true})
            if (zip.length > 0) {
                this.fetchAttributes(zip);
            }
        } else {
            this.setState({ zip_error: true, zipcode: e.target.value });
            //this.setState({zipcodeDisable: true})
        }
    };

    renderZipCodeError() {
        if (this.state.zip_error) {
            return <span className="error">{this.state.zip_error}</span>;
        } else if (this.state.valid_zip_state === false) {
            return <span className="error">{Messages.UNSUPPORTED_STATE}</span>;
        }
        return null;
    }

    fetchAttributes = (zip) => {
        fetchStateAttributes(zip, true)
            .then((result) => {
                if (null !== result) {
                    if (result !== false) {
                        this.setState({ attributes: result });
                        this.setState({ zipcodeDisable: false });
                        this.setState({ valid_zip_state: true });
                    } else {
                        this.setState({ valid_zip_state: false }); //change the valid state error to be stored in new state variable not linked to attributes
                    }
                }
            })
            .catch(() => {
                //this.setState({attributes: null});
            });
    };

    /*    updateUsername = (e) => {
        this.setState({username:e.target.value})
    }*/

    updatebirthyear = (e) => {
        const regex = /^[0-9]{4}$/;
        let currentYear = new Date().getFullYear();
        if (
            (regex.test(e.target.value) || e.target.value === '') &&
            e.target.value <= currentYear &&
            e.target.value != '0000'
        ) {
            this.setState({ birthyear: e.target.value, birth_error: false });
        } else {
            this.setState({ birthyear: e.target.value, birth_error: true });
        }
    };

    updateInsuranceType = (e) => {
        if (e.target.value.length > 100) {
            this.setState({
                insurancetype: e.target.value,
                insurancetype_error: true,
            });
        } else {
            this.setState({
                insurancetype: e.target.value,
                insurancetype_error: false,
            });
        }
    };

    updateComment = (e) => {
        const comment = e.target.value;
        if (comment.length <= 299) {
            this.setState({ comment: comment });
        }
    };

    updateGender = (e) => {
        const gender = e.target.value;
        this.setState({ gender: gender });
    };

    updateCompletingFor = (e) => {
        const completing_for = e.target.value;
        this.setState({ completing_for: completing_for });
    };

    updateInsurance = (e) => {
        const insurance = e.target.value;
        if (insurance === 'Y') {
            this.setState({ insurance: insurance, showInsurance: true });
        } else {
            this.setState({ insurance: insurance, showInsurance: false });
        }
    };

    updatesubstanceUsingFrom = (e) => {
        const regex = /^[0-9]{1,2}$/;
        if (regex.test(e.target.value) || e.target.value === '') {
            this.setState({
                substanceUsingFrom: e.target.value,
                noYears_error: false,
            });
        } else {
            this.setState({
                substanceUsingFrom: e.target.value,
                noYears_error: true,
            });
        }
    };

    serviceTypeOptions = () => {
        /*let serviceTypesArr = []
        if(this.props.attributes!==null){
            this.props.attributes.data.service_type.forEach(service=>{
                let substancesOption = {}
                substancesOption.value = service.id
                substancesOption.label = service.service
                serviceTypesArr.push(substancesOption)
            })
        }
        return serviceTypesArr*/
        let serviceTypesArr = [];
        if (this.state.attributes) {
            this.state.attributes.service_type.forEach((service) => {
                if (service.type == 'receiving') {
                    serviceTypesArr.push({
                        value: service.id,
                        label: service.service_type,
                    });
                }
            });
        }
        return serviceTypesArr;
    };

    substancesOptions = () => {
        /*let substancesArr = []
        if(this.props.attributes!==null){
            this.props.attributes.data.substances.forEach(substance=>{
                let substancesOption = {}
                substancesOption.value = substance.id
                substancesOption.label = substance.substance
                substancesArr.push(substancesOption)
            })
        }
        return substancesArr*/
        let substancesArr = [];
        if (this.state.attributes) {
            this.state.attributes.substances.forEach((substance) => {
                if (substance.substance !== 'All') {
                    substancesArr.push({
                        value: substance.id,
                        label: substance.substance,
                    });
                }
            });
        }
        return substancesArr;
    };

    filterdsubstancesOptions = () => {
        let substancesArr = this.substancesOptions();
        return substancesArr.filter((sub) => {
            return sub.value != 1;
        });
    };

    updateTreatmentType = (selectedOption) => {
        this.props.updateTreatment(selectedOption);
        let arr = selectedOption.map((opt) => {
            return opt.value;
        });
        this.setState({ treatment: arr });
    };

    updateSeekingTreatmentType = (selectedOption) => {
        this.props.updateSeekingTreatment(selectedOption);
        let arr = selectedOption.map((opt) => {
            return opt.value;
        });
        this.setState({ treatmentSeeking: arr });
    };

    updateSubstances = (selectedOption) => {
        let isSelectAll = false;
        selectedOption.forEach((opt) => {
            if (opt.label === 'All') {
                isSelectAll = true;
            }
        });
        let options = isSelectAll === true ? this.filterdsubstancesOptions() : selectedOption;
        let arr = options.map((opt) => {
            return opt.value;
        });
        this.setState({ substancesUsing: arr });
        this.props.updateSubstances(options);
    };

    handleSelectKeyDown = (e) => {
        simulateKeyboardSelectionClick(e.target);
    };

    accept = () => {
        this.state.checked === false
            ? this.acceptTerms()
            : this.setState({
                  checked: !this.state.checked,
                  acceptterms: this.state.checked,
                  show: false,
              });
    };

    acceptTerms = () => {
        this.setState({
            checked: !this.state.checked,
            acceptterms: this.state.checked,
            show: false,
        });
    };

    registerPatient = () => {
        this.setState({ showLoader: true });
        let details = {
            email: this.state.email,
            fname: '',
            lname: '',
            password: this.state.password,
            zip: this.state.zipcode,
            /*username:this.state.username,*/
            birth_year: this.state.birthyear,
            gender: this.state.gender,
            no_of_years_using_substances: this.state.substanceUsingFrom,
            substances_using: this.state.substancesUsing,
            service_type_done: this.state.treatment,
            service_type_seeking: this.state.treatmentSeeking,
            is_insurance: this.state.insurance,
            insurance_name: this.state.insurancetype,
            completing_for: this.state.completing_for,
            organization_id: sessionStorage.getItem('org_id'),
            service_id: sessionStorage.getItem('service_uuid'),
            organization_services_id: sessionStorage.getItem('organization_services_id'),
            state: sessionStorage.getItem('state'),
            comment: this.state.comment,
        };

        let postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            if (details[property] === 'undefined') {
                details[property] = '';
            }
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let parent = this;
        openbedsApi
            .post('/patient_signup', formBody, { headers: postheaders })
            .then(async (response) => {
                if (response.data.status === 'ERROR' || response.data.status === 'Failure') {
                    NotificationManager.error('', response.data.message);

                    let error = {
                        showLoader: false,
                    };
                    if (response.data.constant !== undefined && response.data.constant === 'EMAIL_TAKEN') {
                        error.emailTaken = true;
                    }

                    parent.setState(error);
                } else if (response.data.status === 'SUCCESS') {
                    NotificationManager.success('', response.data.message, 10000);
                    parent.props.history.push('/');
                }
            })
            .catch(function (error) {
                parent.setState({ showLoader: false });
            });
    };

    checkMandatoryFields = () => {
        const state = this.state;
        const errors = {
            emailError: state.emailError,
            pswdError: state.pswdError,
            repswdError: state.repswdError,
            zip_error: state.zip_error,
            acceptterms: state.acceptterms,
            birth_error: state.birth_error,
            noYears_error: state.noYears_error,
        };

        if (state.email === '') {
            errors.emailError = true;
        }
        if (state.password === '') {
            errors.pswdError = true;
        }
        if (state.repassword === '') {
            errors.repswdError = true;
        }
        if (state.zipcode === '') {
            errors.zip_error = true;
        }
        if (state.checked === false) {
            errors.acceptterms = true;
        }
        if (state.birthyear === '') {
            errors.birth_error = true;
        }
        for (let key in errors) {
            if (errors[key]) {
                this.setState(errors);
                return false;
            }
        }
        return true;
    };

    submitPatient = () => {
        this.showPasswordErrorStatus();
        if (this.state.captch === '') {
            this.isEmailvalidate();
        }
        if (true === this.checkMandatoryFields()) {
            this.setState({
                showLoader: true,
            });
            this.registerPatient();
        }
    };

    handleClose = (modal) => {
        let treatment = this.state.showTreatmentInfoModal;
        let agreement = this.state.showAgreementInfoModal;

        if (modal === 'treatment') {
            treatment = false;
        }

        if (modal === 'agreement') {
            agreement = false;
        }

        this.setState({
            showTreatmentInfoModal: treatment,
            showAgreementInfoModal: agreement,
            show: false,
        });
    };

    showZipError = (e) => {
        alert('Please enter ZIP code');
    };

    handleShowTreatmentInfoModal = (e) => {
        // For mouse click
        if (e.type === 'click' && e.clientX !== 0) {
            this.activateModal(e);
        }
    
        // For key presses (Enter or Space)
        if (e.type === 'keydown' && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();  // Prevent any default action for these keys
            this.activateModal(e);
        }
    };
    
    activateModal = (e) => {
        e.persist();
        this.setState({ showTreatmentInfoModal: true });
        setTimeout(() => {
            this.removeRoleDocument();
        }, 1000);
    }
    

    handleShow = () => {
        this.setState({ show: false });

        setTimeout(() => {
            this.removeRoleDocument();
        }, 1000);
    };

    goback = () => {
        this.props.history.push(appUrls.SEARCH_RESULT_LIST);
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Patient Signup - Treatment Connection</title>
                </Helmet>
                <div className="patient-info-form">
                    <header>
                        <nav className="navbar navbar-default navbar-static-top">
                            <div className="container">
                                <div className="navbar-header">
                                    <a className="need-help" href="/patients/need_help">
                                        Help
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <section>
                        <div className="container" role="main">
                            <div className="row">
                                <div className="col-md-12 heading-sec">
                                    <div className="pull-left">
                                        <h1 className="h2 profile-page-heading">My Profile</h1>
                                        <p>
                                            You need an anonymous account to send your request and to receive a response
                                            from the selected treatment provider.
                                        </p>

                                        <div className="asterisk">*</div>
                                        <p>Indicates required information</p>
                                    </div>

                                    <span className="pull-right">
                                        <button onClick={this.props.history.goBack} className="btn btn-primary">
                                            Back
                                        </button>
                                    </span>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <form
                                className="form-horizontal"
                                autoComplete="off"
                                action="javascript:void(0)"
                                method="post"
                                id="patient-info-form"
                            >
                                <div className="row">
                                    <div className="col-md-4 patient-signup-context">
                                        <h2 className="h4 blue-heading">Email</h2>
                                        <p>
                                            The provider will not see your email address. The provider’s response will
                                            be sent to you by email using a confidential ID assigned to you by the
                                            system.
                                        </p>

                                        <h2 className="h4 blue-heading">Password</h2>
                                        <p id="password_instructions_for_field" tabIndex="-1">
                                            Password must have at least one number, one uppercase, lowercase letter, one
                                            special character ( @, !, #, $, & ) and at least 8 or more characters.
                                        </p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            {/*                            <div className="form-group patient-signup-username">
                                <label htmlFor="username" className="col-md-6 control-label" aria-required="true">Your Username</label>
                                <div className="col-md-6">
                                    <input name="username" id="username" data-lpignore="true" autoComplete="new-password" className="form-control" type="text" onChange={this.updateUsername} value={this.state.username} />
                                </div>
                            </div>*/}
                                            <div className="form-group required" role="group" aria-labelledby="email_address_label">
                                                <label id="email_address_label" htmlFor="email_address" className="col-md-6 control-label">
                                                    Email Address
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="emailaddress"
                                                        id="email_address"
                                                        aria-describedby={`spam-junk-email-notice emailplaceholder ${this.state.emailError === true ? 'emailErrorText1' : ''} ${(this.state.emailTaken === true && this.state.email !== '') ? 'emailErrorText2' : ''}`}
                                                        className="form-control"
                                                        placeholder="Enter Email Address"
                                                        type="email"
                                                        onChange={(e) => {
                                                            this.setState({ email: e.target.value }, () =>
                                                                this.isEmailvalidate()
                                                            );
                                                        }}
                                                        value={this.state.email}
                                                        aria-required="true"
                                                        aria-invalid={this.checkStateAnyEmailError()}
                                                        autoComplete="email"
                                                    />

                                                    {this.state.emailError === true ? (
                                                        <span className="error" id="emailErrorText1">
                                                            Please enter a valid email address.
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                    {this.state.emailTaken === true && this.state.email !== '' ? (
                                                        <span className="error" id="emailErrorText2">
                                                            Email already in use!
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                                <span className="sr-only" id="emailplaceholder">
                                                    Enter Email Address
                                                </span>
                                                <span
                                                    id="spam-junk-email-notice"
                                                    aria-hidden="true"
                                                    className="col-md-6 col-md-offset-6 pt-1 spam-junk-email-notice"
                                                >
                                                    Please regularly check your spam and/or junk folders to ensure that
                                                    you don't miss any important messages.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group required" role="group" aria-labelledby="choosepassword_label">
                                                <label id="choosepassword_label" htmlFor="choosepassword" className="col-md-6 control-label">
                                                    Choose Password
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="choosepassword"
                                                        id="choosepassword"
                                                        aria-describedby={`password_instructions_for_field paswordplaceholder ${this.state.pswdError === true ? 'password_error_text_1' : ''}`}
                                                        className="form-control"
                                                        onChange={this.isPasswordvalidate}
                                                        value={this.state.password}
                                                        placeholder="Enter Password"
                                                        type="password"
                                                        aria-required="true"
                                                        aria-invalid={this.state.pswdError}
                                                    />
                                                     <span className="sr-only" id="paswordplaceholder">
                                                        Enter Password
                                                    </span>
                                                    {this.state.pswdError === true ? (
                                                        <span
                                                            className="error"
                                                            id="password_error_text_1"
                                                            tabIndex="-1"
                                                        >
                                                            {' '}
                                                            Please enter at least one number, one uppercase, lowercase
                                                            letter, one special character ( @, !, #, $, & ) and at least
                                                            8 or more characters
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group required">
                                                <label htmlFor="reenterpassword" className="col-md-6 control-label">
                                                    Re-enter Password
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="reenterpassword"
                                                        id="reenterpassword"
                                                        aria-describedby={`${(this.state.repswdError === true && this.state.showPasswordError === true) ? 'password_error_text_2' : ''}`}
                                                        className="form-control"
                                                        placeholder="Re-enter Password"
                                                        type="password"
                                                        onChange={this.reEnter}
                                                        value={this.state.repassword}
                                                        onBlur={this.showPasswordErrorStatus}
                                                        aria-required="true"
                                                        aria-invalid={
                                                            this.state.repswdError === true &&
                                                            this.state.showPasswordError === true
                                                                ? 'true'
                                                                : 'false'
                                                        }
                                                    />
                                                    {this.state.repswdError === true &&
                                                    this.state.showPasswordError === true ? (
                                                        <span
                                                            className="error"
                                                            id="password_error_text_2"
                                                            tabIndex="-1"
                                                        >
                                                            Password and Re-enter Password did not match{' '}
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4 patient-signup-context">
                                        <h2 className="h4 blue-heading">
                                            Are you completing an application
                                            <br /> for yourself or a loved one?
                                        </h2>
                                        <p>
                                            Please indicate as to whether you are reaching out to the provider for
                                            yourself or on behalf of a loved one.{' '}
                                        </p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <fieldset className="form-group">
                                                <legend className="col-md-6 control-label borderbottom_none">
                                                    Are you completing an application
                                                    <br /> for yourself or a loved one?
                                                </legend>
                                                <div className="col-md-6">
                                                    <div className="controls radio-opt">
                                                        <label className="radio-inline" htmlFor="self">
                                                            <input
                                                                name="completing_for"
                                                                id="self"
                                                                value="self"
                                                                checked={
                                                                    this.state.completing_for === 'self' ? true : false
                                                                }
                                                                onChange={this.updateCompletingFor}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>Self
                                                        </label>
                                                        <label className="radio-inline" htmlFor="loved_one">
                                                            <input
                                                                name="completing_for"
                                                                id="loved_one"
                                                                value="loved_one"
                                                                checked={
                                                                    this.state.completing_for === 'loved_one'
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={this.updateCompletingFor}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>Loved One or Someone Else
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4 patient-signup-context">
                                        <h2 className="h4 blue-heading">Zip Code, Birth Year, and Gender</h2>
                                        <p>
                                            This information will be used by the system to identify treatment providers
                                            that meet your needs so that you can send enquiry to them.
                                        </p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="form-group required" role="group" aria-labelledby="zipcode_label">
                                                <label id="zipcode_label" htmlFor="zipcode" className="col-md-6 control-label">
                                                    Your 5 Digit ZIP Code
                                                    <br />
                                                    <span>Numerical Values Only</span>
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="zipcode"
                                                        id="zipcode"
                                                        className="form-control"
                                                        placeholder="00000"
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        inputMode="numeric"
                                                        onChange={this.updateZipCode}
                                                        value={this.state.zipcode}
                                                        aria-required="true"
                                                        aria-describedby={`${this.state.zip_error === true ? 'zipcode_error_message' : ''}${this.state.valid_zip_state === false ? 'invalid_zip_state' : ''}`}
                                                        aria-invalid={this.state.zip_error === true || this.state.valid_zip_state === false}
                                                        autoComplete="postal-code"
                                                    />
                                                    {this.state.zip_error === true ? (
                                                        <span id="zipcode_error_message" className="error">
                                                            Please enter five Numbers
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                    <span className="sr-only" id="invalid_zip_state">
                                                        {Messages.UNSUPPORTED_STATE}
                                                    </span>
                                                    {this.renderZipCodeError()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group required" role="group" aria-labelledby="birthyear_label">
                                                <label id="birthyear_label" htmlFor="birthyear" className="col-md-6 control-label">
                                                    Birth Year
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="birthyear"
                                                        id="birthyear"
                                                        className="form-control"
                                                        placeholder="yyyy"
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        inputMode="numeric"
                                                        onChange={this.updatebirthyear}
                                                        value={this.state.birthyear}
                                                        aria-required="true"
                                                        aria-describedby={`${this.state.birth_error === true  ? 'birthday_error_message' : ''}`}
                                                        aria-invalid={this.state.birth_error === true}
                                                        autoComplete="bday-year"
                                                    />
                                                    {this.state.birth_error === true ? (
                                                        <span id="birthday_error_message" className="error">
                                                            Enter Valid Birth Year
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <fieldset className="form-group required">
                                                <legend className="col-md-6 control-label borderbottom_none">
                                                    Gender <span className="sr-only">, required</span>
                                                </legend>
                                                <div className="col-md-6">
                                                    <div className="controls radio-opt">
                                                        <label className="radio-inline">
                                                            <input
                                                                name="gender"
                                                                id="male"
                                                                value="M"
                                                                checked={this.state.gender === 'M' ? true : false}
                                                                onChange={this.updateGender}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>Male
                                                        </label>
                                                        <label className="radio-inline">
                                                            <input
                                                                name="gender"
                                                                id="female"
                                                                value="F"
                                                                checked={this.state.gender === 'F' ? true : false}
                                                                onChange={this.updateGender}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>Female
                                                        </label>
                                                        <label className="radio-inline">
                                                            <input
                                                                name="gender"
                                                                id="non-binary"
                                                                value="N"
                                                                checked={this.state.gender === 'N' ? true : false}
                                                                onChange={this.updateGender}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>Non-binary
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {this.state.zipcodeDisable ? (
                                    <div className="row sectionNoClick" onClick={this.showZipError}></div>
                                ) : (
                                    ''
                                )}
                                <div className="row">
                                    <fieldset className="col-md-4 patient-signup-context">
                                        <legend className="h4 blue-heading borderbottom_none">Substance Information</legend>
                                        <p>
                                            By providing this information regarding your substance use, providers can
                                            quickly let you know if they will be able to help you. No other personal
                                            information is sent. Additionally, this information is used to show only
                                            search results that can help you.
                                        </p>

                                        <button
                                            className="btn btn-primary"
                                            onClick={this.handleShowTreatmentInfoModal}
                                            onKeyDown={this.handleShowTreatmentInfoModal}
                                            disabled={this.state.zipcodeDisable || !this.state.attributes}
                                        >
                                            Descriptions of Treatment Types
                                        </button>
                                    </fieldset>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="form-group" role="group" aria-labelledby="noyears_label">
                                                <label id="noyears_label" htmlFor="noyears" className="col-md-6 control-label">
                                                    How long have you been using the substance(s)?
                                                    <br />
                                                    <span>Enter Number of years (Numerical values only)</span>
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="noyears"
                                                        id="noyears"
                                                        className="form-control"
                                                        disabled={this.state.zipcodeDisable}
                                                        type="text"
                                                        value={this.state.substanceUsingFrom}
                                                        onChange={this.updatesubstanceUsingFrom}
                                                        aria-invalid={this.state.noYears_error}
                                                        aria-describedby={`${this.state.noYears_error === true  ? 'no_numerical_years_error' : ''}`}
                                                    />
                                                    {this.state.noYears_error === true ? (
                                                        <span id="no_numerical_years_error" className="error">
                                                            Entry must be numerical
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group" role="group" aria-labelledby="substances_label">
                                                <label
                                                    htmlFor="substances"
                                                    className="col-md-6 control-label"
                                                    id="substances_label"
                                                >
                                                    Which substances are you using?
                                                    <br />
                                                    <span>Multiple substances can be selected</span>
                                                </label>
                                                <div className="col-md-6" style={{ color: 'hsl(0,0%,20%)' }}>
                                                    <AccessibleSelect
                                                        inputId="substances"
                                                        labelId="substances_label"
                                                        options={this.substancesOptions()}
                                                        getValue
                                                        className="searchinput"
                                                        placeholder="Substances"
                                                        isMulti
                                                        value={this.props.substances}
                                                        onChange={this.updateSubstances}
                                                        onKeyDown={this.handleSelectKeyDown}
                                                        isDisabled={this.state.zipcodeDisable}
                                                        hideSelectedOptions={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group" role="group" aria-labelledby="treatment_label">
                                                <label
                                                    htmlFor="treatment"
                                                    className="col-md-6 control-label"
                                                    id="treatment_label"
                                                >
                                                    What treatment have you had?
                                                    <br />
                                                    <span>Multiple treatments can be selected</span>
                                                </label>
                                                <div className="col-md-6">
                                                    <AccessibleSelect
                                                        inputId="treatment"
                                                        labelId="treatment_label"
                                                        options={this.serviceTypeOptions()}
                                                        getValue
                                                        className="searchinput"
                                                        placeholder="Treatment Type"
                                                        isMulti
                                                        value={this.props.treatment}
                                                        onChange={this.updateTreatmentType}
                                                        onKeyDown={this.handleSelectKeyDown}
                                                        isDisabled={this.state.zipcodeDisable}
                                                        hideSelectedOptions={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group" role="group" aria-labelledby="seeking_id">
                                                <label
                                                    htmlFor="seeking"
                                                    className="col-md-6 control-label"
                                                    id="seeking_id"
                                                >
                                                    Are you seeking specific treatment?
                                                    <br />
                                                    <span>Multiple treatments can be selected</span>
                                                </label>
                                                <div className="col-md-6">
                                                    <AccessibleSelect
                                                        inputId="seeking"
                                                        labelId="seeking_id"
                                                        options={this.serviceTypeOptions()}
                                                        getValue
                                                        className="searchinput"
                                                        placeholder="Treatment Type"
                                                        isMulti
                                                        value={this.props.seekingTreatmentType}
                                                        onChange={this.updateSeekingTreatmentType}
                                                        onKeyDown={this.handleSelectKeyDown}
                                                        isDisabled={this.state.zipcodeDisable}
                                                        hideSelectedOptions={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4 patient-signup-context">
                                        <h2 className="h4 blue-heading">Insurance Information</h2>
                                        <p>
                                            By entering this information, treatment providers very quickly determine if
                                            they can assist you based on the insurance companies they work with.
                                        </p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <fieldset className="form-group">
                                                <legend className="col-md-6 control-label borderbottom_none">
                                                    Do you have Insurance?
                                                </legend>
                                                <div className="col-md-6">
                                                    <div className="controls radio-opt">
                                                        <label className="radio-inline" htmlFor="yes">
                                                            <input
                                                                name="insurance"
                                                                id="yes"
                                                                value="Y"
                                                                onChange={this.updateInsurance}
                                                                checked={this.state.insurance === 'Y' ? true : false}
                                                                type="radio"
                                                            />
                                                            <span className="radiomark"></span>
                                                            Yes
                                                        </label>
                                                        <label className="radio-inline" htmlFor="no">
                                                            <input
                                                                name="insurance"
                                                                id="no"
                                                                value="N"
                                                                type="radio"
                                                                onChange={this.updateInsurance}
                                                                checked={this.state.insurance === 'N' ? true : false}
                                                            />
                                                            <span className="radiomark"></span>
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        {this.state.showInsurance === true ? (
                                            <div className="form-group">
                                                <label
                                                    htmlFor="insurance"
                                                    className="col-md-6 control-label"
                                                    aria-required="true"
                                                >
                                                    If yes, what insurance do you have ?
                                                    <span>(Maximum 100 characters)</span>
                                                </label>
                                                <div className="col-md-6">
                                                    <input
                                                        name="insurance"
                                                        id="insurance"
                                                        className="form-control"
                                                        placeholder="Name of Insurance"
                                                        type="text"
                                                        maxlength="100"
                                                        value={this.state.insurancetype}
                                                        onChange={this.updateInsuranceType}
                                                    />
                                                    {this.state.insurancetype_error === true ? (
                                                        <span className="error">
                                                            Please enter less than 100 characters
                                                        </span>
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            false
                                        )}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-md-4 patient-signup-context">
                                        <h2 className="h4 blue-heading">Additional Information</h2>
                                        <p>
                                            This additional information will be sent to the provider when you perform an
                                            enquiry
                                        </p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="comment"
                                                    className="col-md-6 control-label"
                                                    aria-required="true"
                                                >
                                                    Any Additional Information?
                                                </label>
                                                <div className="col-md-6">
                                                    <textarea
                                                        name="comment"
                                                        id="comment"
                                                        className="form-control"
                                                        onChange={this.updateComment}
                                                        value={this.state.comment}
                                                        placeholder="Enter additional information here up to 300 characters"
                                                        aria-describedby="additionalinformation"
                                                    />
                                                    <span className="sr-only" id="additionalinformation">
                                                        Enter additional information here up to 300 characters
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-md-4 patient-signup-context"></div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="form-group required">
                                                <label htmlFor="terms-privacy" className="col-md-6 control-label">
                                                Terms and Privacy Policy
                                                </label>
                                                <div className="col-md-6">
                                                    <div className="checkbox terms_privacy_checkbox_wrapper ml-20">
                                                        <input
                                                            aria-required="true"
                                                            id="terms-privacy"
                                                            type="checkbox"
                                                            value="accept"
                                                            onChange={this.accept}
                                                            checked={this.state.checked}
                                                            aria-describedby={`${this.state.acceptterms === true ? 'accepttermserrormessage' : ''}`}
                                                            aria-invalid={this.state.acceptterms}
                                                        />
                                                        By clicking this check box, you agree to the <a class="" href="https://bamboohealth.com/terms-and-conditions-of-use/" target="_blank" rel="noopener noreferrer" aria-label="Terms and Conditions">Terms</a> and <a class="" href="https://bamboohealth.com/privacy-policy/" target="_blank" rel="noopener noreferrer" aria-label="Privacy Policy">Privacy Policy</a>
                                                        {this.state.acceptterms === true ? (
                                                            <span id="accepttermserrormessage" className="error">
                                                                Please agree to terms and privacy policy{' '}
                                                            </span>
                                                        ) : (
                                                            false
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group">
                                                <label className="col-md-6 control-label"></label>
                                                <div className="col-md-6 text-right">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-submit"
                                                        onClick={this.submitPatient}
                                                        disabled={this.state.checkingEmailUniqueness}
                                                    >
                                                        Submit
                                                        {this.state.showLoader ? (
                                                            <span className="fa fa-spinner fa-spin pageLoader">
                                                                &nbsp;
                                                            </span>
                                                        ) : (
                                                            false
                                                        )}
                                                    </button>
                                                    <button
                                                        type="reset"
                                                        className="btn btn-cancel"
                                                        onClick={this.goback}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                    <Modal
                        show={this.state.show}
                        onHide={() => this.handleClose('agreement')}
                        bsSize="large"
                        aria-labelledby="terms_and_conditions"
                    >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div className="container text-left">
                                <div className="row">
                                    <div className="col-md-12 termDiv">
                                        <img
                                            src="/images/treatmentconnection_logo_header.png"
                                            className="TermImg"
                                            alt="treatment connection"
                                        />
                                        <div className="text-center">
                                            {' '}
                                            <h2 id="terms_and_conditions">Terms and Conditions of Use</h2>
                                        </div>
                                        <br />
                                        <p>
                                            The OpenBeds-TreatmentConnection, however accessed, including this public
                                            facing portal and any related mobile applications (collectively,
                                            “OpenBeds-TreatmentConnection” or the “site”) is provided by OpenBeds Inc.
                                            (“OpenBeds” or “we”), a company organized under the laws of Delaware, U.S.A.{' '}
                                        </p>

                                        <h4>Acceptance of Terms and Conditions of Use</h4>
                                        <p>
                                            By accessing OpenBeds-TreatmentConnection, you accept the following Terms
                                            and Conditions of Use. You are only authorized to use
                                            OpenBeds-TreatmentConnection and its services if you agree to abide by all
                                            applicable laws and to these Terms and Conditions of Use (“Terms and
                                            Conditions”). We may revise these Terms and Conditions from time to time by
                                            posting changes to OpenBeds-TreatmentConnection and indicating the date of
                                            the last revision. Any such revisions shall be effective when posted. Your
                                            continued use of OpenBeds-TreatmentConnection following such changes
                                            constitutes your agreement to the modified terms.
                                        </p>

                                        <h4>User Access Requirements and Responsibilities</h4>
                                        <p>
                                            OpenBeds-TreatmentConnection may be accessed only by US residents age 18
                                            years or older (“Users”). All other individuals are expressly prohibited
                                            from accessing the site. OpenBeds-TreatmentConnection is designed to (a)
                                            facilitate a User’s search for available substance use disorder or mental
                                            health treatment providers (“Treatment Providers”), and (b) assist in
                                            connecting Users with those Treatment Providers. Any other use of
                                            OpenBeds-TreatmentConnection other than those provided herein is expressly
                                            prohibited.
                                        </p>

                                        <p>
                                            Users are solely responsible for their own conduct and communications
                                            relating to their use of OpenBeds-TreatmentConnection and for any
                                            consequences thereof. Users are solely responsible for all obligations
                                            incurred, liabilities for harm caused, and actions taken through their use
                                            of or access to the OpenBeds-TreatmentConnection site. Users are solely
                                            responsible for providing accurate, complete, and valid information, and by
                                            using the OpenBeds-TreatmentConnection site each User represents and
                                            warrants that all information submitted is true and correct and that such
                                            User has the right to submit any such information.
                                        </p>

                                        <p>
                                            OpenBeds reserves the right to remove any User-submitted information which
                                            it determines, in its sole discretion, violates these Terms and Conditions
                                            of Use. OpenBeds reserves the right to terminate any User’s ability to
                                            access or use OpenBeds-TreatmentConnection and prevent further access if
                                            OpenBeds, in its sole discretion, believes that a User is violating or has
                                            violated the Terms and Conditions of Use or any applicable law relating to
                                            OpenBeds-TreatmentConnection.{' '}
                                        </p>
                                        <p>
                                            By way of example, and not as a limitation, you agree that when using
                                            OpenBeds-TreatmentConnection you will not:
                                        </p>
                                        <ul className="termul">
                                            <li>
                                                (a) defame, abuse, harass, stalk, threaten or otherwise violate the
                                                legal rights (such as rights of privacy and publicity) of others;
                                            </li>

                                            <li>
                                                (b) upload, post or otherwise make available any inappropriate,
                                                defamatory, obscene, or unlawful content;
                                            </li>
                                            <li>
                                                (c) upload, post or otherwise make available any content that infringes
                                                any patent, trademark, copyright, trade secret or other proprietary
                                                right of any party, unless you are the owner of the rights, or have the
                                                permission of the owner or other legal justification to use such
                                                content;
                                            </li>
                                            <li>
                                                (d) upload, post or otherwise make available any other content, message,
                                                or communication prohibited by applicable law, the Terms and Conditions
                                                of Use or any other applicable policies or guidelines;
                                            </li>
                                            <li>
                                                (e) download any file posted by another that you know, or reasonably
                                                should know, cannot be legally distributed in such manner;
                                            </li>
                                            <li>
                                                (f) impersonate another person or entity, or falsify or delete any
                                                author attributions or proprietary designations or labels of the origin
                                                or source of content, software or other material;
                                            </li>
                                            <li>
                                                (g) promote or provide instructional information about illegal
                                                activities;
                                            </li>
                                            <li>
                                                (h) promote physical harm or injury against any group or individual;
                                            </li>
                                            <li>
                                                (i) transmit any viruses, worms, defects, Trojan horses, or any items of
                                                a destructive nature.
                                            </li>
                                            <li>
                                                (j) download, share, access, or otherwise use the documentation and/or
                                                any other content on the OpenBeds-TreatmentConnection site for
                                                commercial purposes;
                                            </li>
                                            <li>
                                                (k) rent, lease, loan, trade, or sell/re-sell the documentation or
                                                access to the services or documentation;
                                            </li>
                                            <li>
                                                (l) remove any copyright, trademark, or other proprietary rights notices
                                                contained in or on the OpenBeds-TreatmentConnection site or
                                                documentation;
                                            </li>
                                            <li>
                                                (m) use bots, scripts, or other automated methods to access the
                                                OpenBeds-TreatmentConnection site or any of the services or
                                                documentation;
                                            </li>
                                            <li>
                                                (n) engage in “framing,” “mirroring,” or otherwise simulating the
                                                appearance of function of the OpenBeds-TreatmentConnection site or any
                                                documentation; or
                                            </li>
                                            <li>
                                                (o) access the services and/or documentation through interfaces not
                                                expressly provided or authorized by OpenBeds.
                                            </li>
                                        </ul>

                                        <h4>
                                            Use of OpenBeds-TreatmentConnection Constitutes Express Consent to Share
                                            Information
                                        </h4>
                                        <p>
                                            By voluntarily submitting personal information to the
                                            OpenBeds-TreatmentConnection site, each User expressly authorizes OpenBeds
                                            to disclose the following non-identifying User information to such Treatment
                                            Providers selected by the User for the purposes of facilitating User’s
                                            referral for substance use or mental health treatment services:
                                        </p>

                                        <ul>
                                            <li>Five-digit ZIP Code;</li>
                                            <li>Birth Year;</li>
                                            <li>Gender;</li>
                                            <li>Substance Use Information</li>
                                            <li>Health Insurance Status; and</li>
                                            <li>
                                                The contents of any free text information provided by User in the
                                                comment section at registration.
                                            </li>
                                        </ul>
                                        <p>
                                            <b className="highlight-p">
                                                {' '}
                                                OpenBeds will never share your name, e-mail address, or any other
                                                identifying personal information with Treatment Providers or any other
                                                third-parties without your express consent.
                                            </b>
                                        </p>
                                        <p>
                                            As noted above, any free text information you voluntarily provide in the
                                            comment section during registration
                                            <b className="highlight-p"> will be shared with Treatment Providers</b>.
                                            When providing information in the comment section, you should take care to
                                            avoid sharing any identifying personal information that you would want to
                                            keep confidential.
                                        </p>

                                        <h4>
                                            Use of OpenBeds-TreatmentConnection Not a Substitute For Medical Advice; No
                                            Endorsements Made For Treatment Providers
                                        </h4>

                                        <p>
                                            THE INFORMATION PROVIDED TO USERS BY OPENBEDS THROUGH THE
                                            OPENBEDS-TREATMENTCONNECTION SERVICE IS FOR EDUCATIONAL/INFORMATIONAL
                                            PURPOSES ONLY AND IS NOT A SUBSTITUTE FOR THE EXPERTISE OR ADVICE OF A
                                            QUALIFIED MEDICAL PROFESSIONAL, COUNSELOR, OR OTHER HEALTH TREATMENT
                                            PROVIDER. THE PROVISION OF INFORMATION THROUGH OPENBEDS-TREATMENTCONNECTION
                                            DOES NOT CONSTITUTE MEDICAL ADVICE, AND NO COUNSELING OR OTHER TREATMENT
                                            RELATIONSHIP IS CREATED HEREUNDER BETWEEN USER AND OPENBEDS OR ANY MEDICAL
                                            PROFESSIONAL OR TREATMENT PROVIDER.{' '}
                                        </p>

                                        <p>
                                            The inclusion or omission of any particular Treatment Provider in the
                                            information provided by OpenBeds through OpenBeds-TreatmentConnection to
                                            User does not imply any referral, endorsement, recommendation, or lack
                                            thereof by OpenBeds of such Treatment Provider.
                                        </p>

                                        <h4>
                                            OpenBeds-TreatmentConnection Content Provided “As-Is”; Not Responsible For
                                            Links to Third-Party Sites
                                        </h4>

                                        <p>
                                            All content published on OpenBeds-TreatmentConnection is provided “AS IS”
                                            and “as available” without any representation or warranty of any kind either
                                            express or implied. OpenBeds hereby specifically disclaims any and all
                                            representations and warranties, express or implied, regarding site content,
                                            functionality, or materials or information provided by us through the
                                            OpenBeds-TreatmentConnection site, including, without limitation, any
                                            warranty regarding infringement, ownership, accuracy, timeliness,
                                            completeness, and availability, and any implied warranties for
                                            merchantability, or fitness for a particular purpose. From time to time,
                                            OpenBeds-TreatmentConnection content may include unintentional technical
                                            inaccuracies or typographical errors. Changes and additions may be made by
                                            OpenBeds from time to time to any content contained on the
                                            OpenBeds-TreatmentConnection site.
                                        </p>

                                        <p>
                                            Where the OpenBeds-TreatmentConnection site provides or enables links to
                                            third-party sites, such as those managed by Treatment Providers, it does so
                                            for informational purposes only and OpenBeds is in no way liable for your
                                            use of the same.
                                        </p>

                                        <p>
                                            <b>July 2019</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.acceptTerms} className="btn-primary">
                                Accept
                            </Button>
                            <Button onClick={() => this.handleClose('agreement')}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    <TreatmentDefinitionsModal
                        show={this.state.showTreatmentInfoModal}
                        treatmentTypes={this.state.attributes ? this.state.attributes.service_type : []}
                        onClose={() => {
                            this.handleClose('treatment');
                        }}
                    />
                    <NotificationContainer />
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
        attributes: state.post,
        treatment: state.treatment,
        substances: state.substances,

        seekingTreatmentType: state.seekingTreatmentType,
        sendRequestMessage: state.sendRequest,
    };
};

export default connect(mapStateToProps, {
    fetchPosts,
    updateZipcode,
    updateDistance,
    updatePayment,
    updateTreatment,
    updateSubstances,
    updateSeekingTreatment,
    sendRequest,
    clearRequestdata,
})(PatientSignup);
