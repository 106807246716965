import React from 'react';
import Footer from "../layout/footer";


export default () => {
    return (
        <div>
            <div className="container">
                <div className="row">

                    <div className="col-md-6 col-md-push-3 loginForm">
                        <h3 className="text-center">
                            <a href="/"><img src="/images/treatmentconnection_logo_header.png" alt="Treatment Connection"/></a>
                        </h3>
                        <h3 className="text-center mt20" style={{color: "#777"}}>Your link has expired</h3>
                        <p className="text-center">Please contact your Service Administrator to have your account created again.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
