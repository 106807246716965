import React from "react";
import simulateKeyboardSelectionClick from '../simulate-keyboard-selection-click'
import screenReaderAnnouncement from "../../accessibility-helpers/screenReaderAnnouncement";

class ClearIndicator extends React.Component {
    constructor(props) {
        super();
    }

    componentWillUnmount() {
        screenReaderAnnouncement('all values deselected', 'polite');
    }

    render() {
        return (
            <button ref={this.props.innerRef} {...this.props.innerProps} aria-hidden="false" className="multi_value_remove_all" onKeyDown={simulateKeyboardSelectionClick} type="button">
                <span className="sr-only">Remove all selected values</span>
                <span aria-hidden="true">x</span>
            </button>
        );
    }
   
};

export default ClearIndicator;