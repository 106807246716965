const buildScreenReaderTextNode = (textToAnnounce, politeness_setting) => {
  if (!politeness_setting) {
    politeness_setting = "assertive";
  }

  let screenReaderTextElement = document.createElement("p");
  screenReaderTextElement.classList.add("sr-only");
  screenReaderTextElement.setAttribute("id", "x508-sr-alert");
  screenReaderTextElement.setAttribute("aria-live", politeness_setting);
  screenReaderTextElement.setAttribute("aria-atomic", "true");
  screenReaderTextElement.setAttribute("aria-relevant", "additions text");
  screenReaderTextElement.innerHTML = textToAnnounce;

  return screenReaderTextElement;
};

const screenReaderAnnouncement = (textToAnnounce, politeness_setting) => {
  const documentBodyElement = document.querySelector("body");
  const screenReaderAnnouncement = buildScreenReaderTextNode(
    textToAnnounce,
    politeness_setting
  );

  documentBodyElement.appendChild(screenReaderAnnouncement);

  setTimeout(function () {
    documentBodyElement.removeChild(screenReaderAnnouncement);
  }, 1500);
};

export default screenReaderAnnouncement;
