import axios from "axios";
import { apiUrl } from "./apiurl";
import { injectMiddleware } from "./infrastructure/axios/injectMiddleware";
import csrfMiddleware from "./infrastructure/axios/csrfMiddleware";
import { NotificationManager } from "react-notifications";
import axiosRetry from "axios-retry";

export function openbedsApiClient() {
  const axiosConfig = {
    baseURL: apiUrl,
    headers: {
      Accept: "application/json, text/plain",
    },
    withCredentials: true,
  };

  if (process.env.REACT_APP_API_XDEBUG_TOKEN) {
    const params = axiosConfig.params || {};
    axiosConfig.params = {
      XDEBUG_SESSION_START: process.env.REACT_APP_API_XDEBUG_TOKEN,
      ...params,
    };
  }

  return axios.create(axiosConfig);
}

export const CancelToken = axios.CancelToken;

let axiosInstance = openbedsApiClient();

axiosRetry(axiosInstance, {
  retries: 1,
  retryCondition: (error) => {
    if (
      (error.response && error.response.status !== 403) ||
      sessionStorage.userDetails
    ) {
      return false;
    }
    csrfMiddleware.clearPromise();
    return true;
  },
});

axiosInstance.interceptors.request.use(
  function (request) {
    // Do something with request data
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    var errorMessage = "There was an error";

    if (axios.isCancel(error)) {
      throw new axios.Cancel("CANCELLED");
    } else {
      if (error.response) {
        // handle error
        switch (error.response.status) {
          case 401:
          case 403:
            if (sessionStorage.userDetails) {
              //if user is logged in log them out
              errorMessage = "You've been logged out due to inactivity";
              // We'll check for this value globally in header.js
              sessionStorage.error = errorMessage;
              //trigger component update here, don't retry
              NotificationManager.error("", sessionStorage.getItem("error"));
              sessionStorage.clear(); //clear the session
              setTimeout(() => {
                window.location.reload();
              }, 1000); //refresh the details
              return Promise.reject(error);
            }
            break;
          default:
            // Don't change error message
            break;
        }
      }
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

axiosInstance = injectMiddleware(axiosInstance, [csrfMiddleware]);

export default axiosInstance;
