import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import * as ACTION_TYPES from "../actions/actionTypes";

const postReducer = (post = null, action) => {
  if (action.type === ACTION_TYPES.FETCH_POSTS) {
    return action.payload;
  }

  return post;
};

const statesReducer = (post = null, action) => {
  if (action.type === ACTION_TYPES.FETCH_STATES) {
    return action.payload;
  }

  return post;
};

const pfsConfigReducer = (post = null, action) => {
  if (action.type === ACTION_TYPES.GET_PFS_CONFIG) {
    return action.payload.data;
  }

  return post;
};

const stateConfigReducer = (post = null, action) => {
  if (action.type === ACTION_TYPES.GET_STATE_CONFIG) {
    return action.payload.data;
  }

  return post;
};

const zipReducer = (zip = "", action) => {
  if (action.type === ACTION_TYPES.UPDATE_ZIPCODE) {
    return action.payload;
  }

  return zip;
};

const locationReducer = (stateName = "", action) => {
  if (action.type === ACTION_TYPES.UPDATE_LOCATION) {
    return action.payload;
  }

  return stateName;
};

const distanceReducer = (distance = null, action) => {
  if (action.type === ACTION_TYPES.UPDATE_DISTANCE) {
    return action.payload;
  }

  return distance;
};

const paymentTypeReducer = (payment = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_PAYMENT_TYPE) {
    //return [...payment,action.payload]
    return action.payload;
  }

  return payment;
};

const genderAndAgeReducer = (genderAndAge = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_GENDER_AND_AGE_TYPE) {
    //return [...payment,action.payload]
    return action.payload;
  }

  return genderAndAge;
};

const treatmentTypeReducer = (treatment = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_TREATMENT_TYPE || action.type === ACTION_TYPES.UPDATE_TREATMENT) {
    if (action.payload !== undefined) {
      return action.payload;
    } else {
      return treatment;
    }
  }

  return treatment;
};

const substancesReducer = (substances = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_SUBSTANCES) {
    if (action.payload !== undefined) {
      return action.payload;
    }
  }

  return substances;
};

const specialPopulationsReducer = (specialPopulation = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_SPECIAL_POPULATION) {
    return action.payload;
  }

  return specialPopulation;
};
const languagesServedReducer = (languagesServed = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_LANGUAGES_SERVED) {
    return action.payload;
  }

  return languagesServed;
};

const providersOnSiteReducer = (providersOnsite = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_PROVIDERS_ON_SITE) {
    return action.payload;
  }

  return providersOnsite;
};

const searchTermsReducer = (searchTerms = "", action) => {
  if (action.type === ACTION_TYPES.UPDATE_SEARCH_TERMS) {
    return action.payload;
  }

  return searchTerms;
};

const limitToStateReducer = (limitToState = false, action) => {
  if (action.type === ACTION_TYPES.UPDATE_LIMIT_TO_STATE) {
    return action.payload;
  }

  return limitToState;
};

const organizationSearchReducer = (searchList = [], action) => {
  if (action.type === ACTION_TYPES.ORGANIZATION_SEARCH) {
    if (action.payload.results !== undefined) {
      if (action.payload.results.length === 0) {
        return [
          {
            id: -1,
            label: "No results found",
          },
        ];
      } else {
        return action.payload.results;
      }
    } else {
      return [];
    }
  }

  return searchList;
};

const moreInfoReducer = (service_details = null, action) => {
  if (action.type === ACTION_TYPES.FETCH_MORE_INFO) {
    service_details = {
      service_info: action.payload.service_data[0],
      service_hours: action.payload.ref_hours,
    };
    return service_details;
  }
  return service_details;
};

const searchlistReducer = (searchlist = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SEARCHLIST:
      return action.payload;
    case ACTION_TYPES.FETCH_SEARCHLIST_CONCAT:
      let oldStatedata = searchlist.data;
      // let newlist = [...oldStatedata,action.payload.data]
      let newlist = oldStatedata.concat(action.payload.data);
      let newPayload = {};
      newPayload.data = newlist;
      newPayload.status = action.payload.status;
      newPayload.total_records = action.payload.total_records;
      return newPayload;
    default:
      return searchlist;
  }
};

const loginDetailsReducer = (login = [], action) => {
  if (action.type === ACTION_TYPES.FETCH_LOGIN) {
    return action.payload;
  } else if (sessionStorage.userDetails) {
    login = JSON.parse(sessionStorage.userDetails);
  }
  return login;
};

const accountSelectorReducer = (status = false, action) => {
  if (action.type === ACTION_TYPES.ACCOUNT_SELECTOR) {
    return action.payload == "true" ? true : false;
  } else if (sessionStorage.getItem("accountSelector") == "true") {
    status = true;
  }
  return status;
};

const LogoutReducer = (logout = [], action) => {
  if (action.type === ACTION_TYPES.FETCH_LOGOUT) {
    return action.payload;
  }
  return logout;
};

const sendrequestReducer = (req = { status: "", message: "" }, action) => {
  if (action.type === ACTION_TYPES.SEND_REQUEST) {
    return action.payload;
  } else if (action.type === ACTION_TYPES.UPDATE_REQUEST) {
    return action.payload;
  }
  return req;
};
const patientProfileReducer = (profile = [], action) => {
  if (action.type === ACTION_TYPES.PATIENT_PROFILE_DETAILS) {
    return action.payload;
  }
  return profile;
};

const patientRrsReducer = (rrs = [], action) => {
  if (action.type === ACTION_TYPES.PATIENT_RRS_INFO) {
    return action.payload;
  }
  return rrs;
};

const providerRrsReducer = (rrs = [], action) => {
  if (action.type === ACTION_TYPES.PROVIDER_RRS_INFO) {
    return action.payload;
  }
  return rrs;
};

const seekingTreatmentTypeReducer = (treatment = [], action) => {
  if (action.type === ACTION_TYPES.UPDATE_SEEKING_TREATMENT_TYPE) {
    if (action.payload !== undefined) {
      return action.payload;
    }
  }

  return treatment;
};

const rrsPatientInfoReducer = (rrs = [], action) => {
  if (action.type === ACTION_TYPES.RRS_PATIENT_INFO) {
    return action.payload;
  }
  return rrs;
};

const archivedrrsPatientInfoReducer = (rrs = [], action) => {
  if (action.type === ACTION_TYPES.ARCHIVED_PROVIDER_RRS_INFO) {
    return action.payload;
  }
  return rrs;
};

const serviceDetailReducer = (rrs = [], action) => {
  if (action.type === ACTION_TYPES.SERVICE_DETAIL) {
    return action.payload;
  }
  return rrs;
};

const disableStatusReducer = (status = false, action) => {
  if (action.type === ACTION_TYPES.DISABLE_STATUS) {
    return action.payload;
  }
  return status;
};

const serviceProvidersReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD:
      return {
        data: action.data,
      };
    default:
      return state;
  }
};
const superAdminOrganizationsReducer = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.SUPER_ADMIN_ORGANIZATION_LIST:
      if (action.payload !== undefined) {
        return action.payload;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const stateAttributesReducer = (stateAttributes = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_STATE_ATTRIBUTES:
      if (action.payload !== undefined) {
        return action.payload;
      } else {
        return stateAttributes;
      }

    default:
      return stateAttributes;
  }
};

export default combineReducers({
  post: postReducer,
  states: statesReducer,
  zipcode: zipReducer,
  distance: distanceReducer,
  payment: paymentTypeReducer,
  treatment: treatmentTypeReducer,
  treatmentType: treatmentTypeReducer,
  genderAndAge: genderAndAgeReducer,
  substances: substancesReducer,
  searchTerms: searchTermsReducer,
  limitToState: limitToStateReducer,
  pfsConfigurations: pfsConfigReducer,
  stateConfigurations: stateConfigReducer,
  searchlist: searchlistReducer,
  loginDetails: loginDetailsReducer,
  accountSelector: accountSelectorReducer,
  logout: LogoutReducer,
  sendRequest: sendrequestReducer,
  patientProfile: patientProfileReducer,
  patientRrs: patientRrsReducer,
  seekingTreatmentType: seekingTreatmentTypeReducer,
  providerRrs: providerRrsReducer,
  rrsPatientInfo: rrsPatientInfoReducer,
  serviceDetail: serviceDetailReducer,
  archivedrrsPatientInfo: archivedrrsPatientInfoReducer,
  organizationSearch: organizationSearchReducer,
  disableStatus: disableStatusReducer,
  service_details: moreInfoReducer,
  serviceProviders: serviceProvidersReducer,
  superAdminOrganizations: superAdminOrganizationsReducer,
  specialPopulation:specialPopulationsReducer,
  languagesServed:languagesServedReducer,
  providersOnsite:providersOnSiteReducer,
  searchLocation: locationReducer,
  stateAttributes: stateAttributesReducer,
  form: formReducer,
});
