import React from "react";
import resource_routes_and_route_names from "./resource-routes-and-route-names";
import user_routes_and_route_names from "./user-routes-and-route-names";
import about_openbeds_routes_and_route_names from "./about-openbeds-routes-and-route-names";

import { Helmet } from "react-helmet";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { connect } from "react-redux";

class SiteMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let getCurrentRole = this.props.loginDetails.type;

    let setResourceRoutes = resource_routes_and_route_names;

    let userRoutes;
    userRoutes = user_routes_and_route_names;

    let aboutRoutes;
    aboutRoutes = about_openbeds_routes_and_route_names;

    return (
      <div>
        <Helmet>
          <title>Sitemap - Treatment Connection</title>
        </Helmet>
        <Header />
        <main className="main-content">
          <span id="main-content" tabIndex="-1"></span>
          <div className="container">
            <div className="row">
              <div className="col-md-push-2 col-md-8 d-flex flex-wrap w-100">
                <h1 className="col-2 blue-heading h1">Sitemap</h1>
              </div>
            </div>

            <div className="row">
              <ul
                className="col-md-push-2 col-md-8 d-flex flex-wrap"
                aria-label="Open Beds Sitemap"
              >
                {aboutRoutes.length > 0 ? (
                  <li className="col-xs-12 col-sm-6 col-lg-3 mb-30 sitemap_list users">
                    <ul aria-label="About Open Beds" className="list-unstyled">
                      {aboutRoutes.map((route, i) => (
                        <li
                          key={i}
                          className={
                            route.category_heading === "true"
                              ? "row blue-heading"
                              : null
                          }
                        >
                          {route.url ? (
                            <a href={route.url}>{route.page_name}</a>
                          ) : (
                            <h2 className="col-md-10 mt-0 mb-0 h3">{route.page_name}</h2>
                          )}
                        </li>
                      ))}{" "}
                    </ul>
                  </li>
                ) : null}
                {userRoutes.length > 0 ? (
                  <li className="col-xs-12 col-sm-6 col-lg-2 mb-30 sitemap_list users">
                    <ul aria-label="My Profile" className="list-unstyled">
                      {userRoutes.map((route, i) =>
                        route.user_types.includes(
                          getCurrentRole || "Signed Out"
                        ) ? (
                          <li
                            key={i}
                            className={
                              route.category_heading === "true"
                                ? "row blue-heading"
                                : null
                            }
                          >
                            {route.url ? (
                              <a href={route.url}>{route.page_name}</a>
                            ) : (
                              <h2 className="col-md-10 mt-0 mb-0 h3">{route.page_name}</h2>
                            )}
                          </li>
                        ) : null
                      )}{" "}
                    </ul>
                  </li>
                ) : null}
                {setResourceRoutes.length > 0 ? (
                  <li className="col-xs-12 col-sm-6 col-lg-3 mb-30 sitemap_list resources">
                    <ul aria-label="Resources" className="list-unstyled">
                      {setResourceRoutes.map((route, i) => (
                        <li
                          key={i}
                          className={
                            route.category_heading === "true"
                              ? "row blue-heading"
                              : null
                          }
                        >
                            {route.category_heading ? (
                              <a className="col-md-10 mt-0 mb-0 h3" href={route.url}>{route.page_name}</a>
                            ) : (
                              <a href={route.url}>{route.page_name}</a>
                            )}
                        </li>
                      ))}{" "}
                    </ul>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>

        </main>
        
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //  console.log(state)
  return {
    loginDetails: state.loginDetails,
    // logoutDetails: state.logout,
  };
};

export default connect(mapStateToProps, {})(SiteMap);
