import React from "react";
import useDocumentTitle from '../../../utils/useDocumentTitle';
import useMetaDescription from '../../../utils/useMetaDescription';

export default () => {
    useDocumentTitle('Warning Signs of a Mental Health Crisis or Suicide - Treatment Connection');
    useMetaDescription("It's not easy to tell the difference between typical behaviors  and a potential mental health crisis. View this infographic to learn the warning signs.");

    return (
        <div className="edu_container">
            <div className="row flexrow">
                <div className="col-m-12 col-sm-12 flex-col-1">
                    <h1 className="header-font">Warning Signs of a Mental Health Crisis or Suicide</h1>
                    <p>It's not easy to tell the difference between typical behaviors and what might be a mental health crisis, but some common actions may be a clue that a crisis is developing.</p>
                    <p>Check out the infographic below from the Nation Alliance on Mental Health (NAMI) to better understand potential warning signs of a mental health crisis and suicide.</p>

                    <div className="infographic-wrapper">
                        <object className="infographic" type="application/pdf" data="/educational_materials/OpenBeds_Warning_Signs.pdf#zoom=100&scrollbar=0&toolbar=0&navpanes=0&view=Fit">
                            <a href="/educational_materials/OpenBeds_Warning_Signs.pdf">
                                <img src="/images/infographics/warning-signs.png" alt="It's not easy to tell the difference between typical behaviors  and a potential mental health crisis. View this infographic to learn the warning signs." />
                            </a>
                        </object>
                    </div>
                </div>
            </div>
        </div>
    )
}
