import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import { withRouter } from "react-router-dom";
import { change, Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  fetchPosts,
  getStates,
  updateDistance,
  updatePayment,
  updateSubstances,
  updateTreatment,
  updateZipcode,
} from "../../actions/selectattributes";
import { changedisableState, searchForOrganization } from "../../actions/login";
import openbedsApi from "../../openbedsApi";
import OrganizationSearch from "./Components/OrganizationSearch";
import { NotificationManager } from "react-notifications";
import handleOverlayScroll from "../accessibility-helpers/tooltip-scroll.js";
import {formatPhoneNumber} from "../../utils/phoneNumbers";

let mobileValid = false;
let disablefields = true;

const passwordhelpToppopover = (
  <Popover id="password-popover" title="Password help" role="tooltip">
    <p>
      Password must have at least one number, one uppercase, lowercase letter,
      one special character ( @, !, #, $, & ) and at least 8 or more characters
    </p>
  </Popover>
);

const roleToppopover = (
  <Popover id="user-role-def-popover" title="Role Definitions" role="tooltip">
    <b>USER-ROLE DEFINITIONS</b>
    <br />
    <br />
    If your organization is already a member of OpenBeds, please contact your
    service administrator to be added as a user.
    <br /> <br />
    If your organization is registering for the first time,{" "}
    <u>
      please identify as to whether your organization will be using OpenBeds to
      primarily refer or receive referrals
    </u>
    , and refer to the appropriate sections below to determine your role. System
    privileges for each of the four roles are explained in detail in the Help
    section.
    <br />
    <br />
    <b>REFERRING ORGANIZATION</b>
    <br />
    This includes medical, justice system, community and county authority
    organizations. Please select one of the roles below:
    <br />
    <br />
    <b>Referring Administrator:</b> Select this role if you are the
    administrator of a referring organization. A Referring Administrator can
    search, refer, manage Referring Providers, and view the Service’s analytics.
    After completing the registration form, you will receive an email
    notification to log into the site. Then you can add and manage your
    Service(s), and enter the names and work emails of Referring Providers in
    your organization who may use OpenBeds. They will receive an e-mail
    notification and link to access their OpenBeds account.
    <br />
    <br />
    <b>Receiving Provider:</b> A Receiving Provider can search, update
    availability, receive referrals and view the analytics of the assigned
    Service. Once your administrator configures their account, they may add you
    as a Receiving Provider. You will receive an email notification to access
    your account and complete registration by entering your password and contact
    information.{" "}
    <u>
      Note that if you will be the only provider at your organization using
      OpenBeds, then please select the Receiving Administrator role
    </u>
    .
  </Popover>
);

const contactToppopover = (
  <Popover
    id="contact-method-def-popover"
    title="Contact Method Definitions"
    role="tooltip"
  >
    <p>
      If you are a{" "}
      <u>
        <b>Referring Provider</b>
      </u>
      , you will be notified using:
    </p>
    <ol>
      <li>
        Your selected <u>Contact Method </u> regarding messages sent to you by a
        receiving provider using the ‘Referral Request Messaging’ page of
        OpenBeds, and
      </li>
      <li>
        {" "}
        Your selected <u>Contact Method</u> regarding messages you are copied on
        by users in your organization using the ‘Referral Request Messaging’
        page of OpenBeds.
      </li>
    </ol>
    <p>
      If you are a{" "}
      <u>
        <b>Receiving Provider</b>
      </u>
      , you will be notified using:
    </p>
    <ol>
      <li>
        {" "}
        Your <u>Service’s e-mail address</u> concerning: a) referrals to your
        Service from a referring provider using the ‘Referral Request’ page of
        OpenBeds, and b) subsequent messages from the referring provider using
        the ‘Referral Request Messaging’ page of OpenBeds.{" "}
      </li>
      <li>
        {" "}
        Your selected <u>Contact Method</u> regarding messages you are copied on
        by users in your organization using the ‘Referral Request Messaging’
        page of OpenBeds.
      </li>
    </ol>
  </Popover>
);

const validate = (formValues) => {
  let errors = {};

  if (!formValues.first_name || formValues.first_name.length <= 2) {
    errors.first_name =
      "Please enter at least 3 and no more than 50 characters";
  }
  if (!formValues.last_name || formValues.last_name.length <= 2) {
    errors.last_name = "Please enter at least 3 and no more than 50 characters";
  }

  if (!formValues.organization_name) {
    errors.organization_name =
      "Please enter at least 3 and no more than 50 characters";
  } else if (
    typeof formValues.organization_name === "string" &&
    formValues.organization_name.length <= 2
  ) {
    errors.organization_name =
      "Please enter at least 3 and no more than 50 characters";
  }

  if (
    !formValues.organization_phone ||
    formValues.organization_phone.length <= 9
  ) {
    if (formValues.organization_phone) {
      if (formValues.organization_phone.length >= 1) {
        formValues.organization_phone = formValues.organization_phone.replace(
          /[^\d]/g,
          ""
        );
      }
    }
    errors.organization_phone = "Please enter dedicated phone";
  } else {
    formValues.organization_phone = formatPhoneNumber(formValues.organization_phone);
  }

  if (!formValues.organization_address1) {
    errors.organization_address1 = "Please enter valid Organization Address";
  }
  if (!formValues.city || formValues.city.length < 3) {
    errors.city = "Please enter valid City";
  }

  if (!formValues.statevalue) {
    errors.statevalue = "Please select state";
  }

  const zipregex = /^[0-9]{5,5}$/;

  if (!zipregex.test(formValues.zipcode)) {
    errors.zipcode = "Please enter valid 5 digit Zip Code";
  }

  const url =
    /(http|https):\/\/(\w+:{?}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

  if (formValues.organization_website_url) {
    if (!url.test(formValues.organization_website_url)) {
      errors.organization_website_url =
        "Please enter valid url, e.g. http://abc.com or https://abc.com";
    }
  }
  const emailregex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailregex.test(formValues.email)) {
    errors.email = "Please enter valid email address";
  }
  if (!formValues.notify) {
    errors.notify = "Please select Notification method";
  }

  if (!formValues.contactMethods) {
    errors.contactMethods = "Please select contact method";
  } else {
    if (
      formValues.contactMethods === "2" ||
      formValues.contactMethods === "5"
    ) {
      mobileValid = true;
      document.getElementsByClassName("font10")[10].style.visibility =
        "visible";

      if (!formValues.mobile || formValues.mobile.length <= 9) {
        if (formValues.mobile) {
          if (formValues.mobile.length >= 1) {
            formValues.mobile = formValues.mobile.replace(/[^\d]/g, "");
          }
        }
        errors.mobile = "Please enter dedicated phone";
      }
    } else {
      mobileValid = false;
      document.getElementsByClassName("font10")[10].style.visibility = "hidden";
    }
  }

  if (formValues.mobile) {
    if (formValues.mobile.length >= 9) {
      formValues.mobile = formatPhoneNumber(formValues.mobile);
    }
  }

  const passwordregex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;

  if (!formValues.password || !passwordregex.test(formValues.password)) {
    errors.password =
      "Please enter at least one number, one uppercase, lowercase letter, one special character ( @, !, #, $, & ) and at least 8 or more characters";
  }

  if (
    !formValues.confirm_password ||
    formValues.confirm_password !== formValues.password
  ) {
    errors.confirm_password = "Password and Confirm password did not match";
  }

  return errors;
};

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      loadStates: false,
      statevalue: "",
      csrf: {},
      contact: "",
      orgnameOptions: [],
      disablefields: true,
      selectedOrg: "",
      selectedOrgZip: "",
      showLoader: false,
      orgStatus: false,
      organizationSearch: [],
    };

    if (this.props.systemAdmin === true) {
      disablefields = false;
      mobileValid = false;
    }

    this.props.changedisableState(disablefields);
  }

  componentDidMount() {
    this.props.changedisableState(disablefields);
    this.props.getStates();
  }

  renderError = ({ error, touched }, isValid, checkvalid) => {
    if (error && touched && (isValid || checkvalid)) {
      return <span className="error">{error} </span>;
    }
  };

  checkForError = ({ error, touched }, isValid, checkvalid) => {
    if (error && touched && (isValid || checkvalid)) {
      return true;
    } else {
      return false;
    }
  };

  stateInput = ({
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
    options,
    id,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }

    if (id) {
      id = id;
    } else {
      id = Math.random();
    }

    const unique_error_message_id = "error_message_for_" + id;

    return (
      <div className={elemClass}>
        <label className="control-label" htmlFor={id}>
          {label}
          {isValid ? (
            <span className="text-red strongFont font10">*</span>
          ) : (
            false
          )}
          {pretext}
        </label>
        <select
          {...input}
          disabled={readonly}
          className="form-control"
          value={input.value}
          id={id}
          aria-invalid={this.checkForError(meta, isValid, checkvalid)}
          aria-describedby={unique_error_message_id}
          aria-required={isValid}
        >
          <option value="">-- Select State--</option>
          {options
            ? options.states.map((state) => {
                return (
                  <option value={state.id} key={state.id}>
                    {state.name} ({state.code})
                  </option>
                );
              })
            : false}
        </select>
        <span id={unique_error_message_id}>
          {this.renderError(meta, isValid, checkvalid)}
        </span>
      </div>
    );
  };

  selectInputContact = ({
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
    id,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }

    if (id) {
      id = id;
    } else {
      id = Math.random();
    }

    const unique_error_message_id = "error_message_for_" + id;

    return (
      <div className={elemClass}>
        <label className="control-label" htmlFor="contactMethods">
          Select Contact Method
          <span className="text-red strongFont font10">*</span>{" "}
          <small>(may change later from My Profile)</small>
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={contactToppopover}
            rootClose
          >
            <button
              type="button"
              className="overlay_trigger"
              aria-disabled="true"
              aria-describedby={contactToppopover.props.id}
              onKeyDown={(e) => handleOverlayScroll(e, contactToppopover)}
              onMouseEnter={(e) => {
                return false;
              }}
            >
              <span
                className="glyphicon glyphicon-question-sign"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="left"
              ></span>
              <span className="sr-only">
                Select Contact Method, additional information
              </span>
            </button>
          </OverlayTrigger>
        </label>

        <select
          {...input}
          className="form-control"
          id="contactMethods"
          required=""
          aria-required={isValid}
          aria-invalid={this.checkForError(meta, isValid, checkvalid)}
          aria-describedby={unique_error_message_id}
        >
          <option value="" defaultValue={true}>
            -- Select --
          </option>
          <option value="1">Call Institution</option>
          <option value="2">Call Mobile</option>
          <option value="3">E-Mail</option>
          <option value="4">Use OpenBeds site for messaging</option>
          <option value="5">Text Mobile</option>
        </select>
        <span id={unique_error_message_id}>
          {this.renderError(meta, isValid, checkvalid)}
        </span>
      </div>
    );
  };

  textInputDummy = ({
    showpopover,
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }
    return (
      <div className={elemClass}>
        <label className="control-label">
          {label}
          {isValid ? (
            <span className="text-red strongFont font10"></span>
          ) : (
            false
          )}
          {pretext}
          {showpopover ? (
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={passwordhelpToppopover}
              rootClose
            >
              <button
                type="button"
                aria-disabled="true"
                aria-describedby={passwordhelpToppopover.props.id}
              >
                <span
                  className="glyphicon glyphicon-question-sign"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="left"
                ></span>
              </button>
            </OverlayTrigger>
          ) : (
            false
          )}
        </label>
        <input
          {...input}
          className="form-control serviceAddressLine1 s_address"
          placeholder={placeholder}
          title={title}
          type="text"
          disabled={readonly}
          type={type}
          aria-required={isValid}
        />
        {this.renderError(meta, isValid, checkvalid)}
      </div>
    );
  };

  textInput = ({
    showpopover,
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
    id,
    autoComplete,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }

    // Mandates an id for label/input/error message relations
    if (id) {
      id = id;
    } else {
      id = Math.random();
    }

    const unique_error_message_id = "error_message_for_" + id;

    return (
      <div className={elemClass}>
        <label className="control-label" htmlFor={id}>
          {label}
          {isValid ? (
            <span className="text-red strongFont font10">*</span>
          ) : (
            false
          )}
          {pretext}
          {showpopover ? (
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={passwordhelpToppopover}
              rootClose
            >
              <button
                type="button"
                className="overlay_trigger"
                aria-disabled="true"
                aria-describedby={passwordhelpToppopover.props.id}
              >
                <span
                  className="glyphicon glyphicon-question-sign"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="left"
                ></span>
                <span className="sr-only">{label}, additional information</span>
              </button>
            </OverlayTrigger>
          ) : (
            false
          )}
        </label>
        <input
          {...input}
          className="form-control serviceAddressLine1 s_address"
          placeholder={placeholder}
          autoComplete={autoComplete ? autoComplete : "on"}
          title={title}
          disabled={readonly}
          type={type ? type : "text"}
          id={id}
          aria-describedby={unique_error_message_id}
          aria-invalid={this.checkForError(meta, isValid, checkvalid)}
          aria-required={isValid}
        />

        <span id={unique_error_message_id}>
          {this.renderError(meta, isValid, checkvalid)}
        </span>
      </div>
    );
  };

  selectInputNotify = ({
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }
    return (
      <div className={elemClass}>
        {
          <label className="control-label">
            Send Notification to user
            <span className="text-red strongFont font10">*</span>
          </label>
        }
        <select
          {...input}
          className="form-control"
          id="notify"
          required=""
          aria-required="true"
        >
          <option value="" defaultValue={true}>
            -- Select --
          </option>
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>

        {this.renderError(meta, isValid, checkvalid)}
      </div>
    );
  };

  updateOrgName = (e) => {
    this.setState({ orgname: e.target.value });
  };

  textInputauto = ({
    checkvalid,
    input,
    label,
    placeholder,
    isValid,
    title,
    readonly,
    pretext,
    type,
    meta,
    className,
    id,
    ariaDescribedby,
    ariaInvalid,
    autoComplete,
    ariaAutocomplete,
    optionList,
  }) => {
    let elemClass =
      meta.error && meta.touched && (isValid || checkvalid)
        ? "form-group has-error"
        : "form-group";
    if (className) {
      elemClass = elemClass + " hide";
    }

    // Mandates an id for label/input/error message relations
    if (id) {
      id = id;
    } else {
      id = Math.random();
    }

    let element = document.getElementById(id);
    if (element) {
      element.removeAttribute("aria-haspopup"); // accessibility requirement
      element.removeAttribute("autocomplete"); // accessibility requirement
    }

    const propsForInput = {
      id: id,
      "aria-describedby": ariaDescribedby,
      "aria-invalid": this.checkForError(meta, isValid, checkvalid),
      // autoComplete: autoComplete,
      "aria-required": isValid,
    };

    const unique_error_message_id = ariaDescribedby;

    return (
      <div className={elemClass}>
        <label className="control-label" htmlFor={id}>
          {label}
          {isValid ? (
            <span className="text-red strongFont font10">*</span>
          ) : (
            false
          )}
          {pretext}
        </label>

        <OrganizationSearch
          {...input}
          inputProps={propsForInput}
          organizationSearch={optionList}
          searchForOrganization={this.props.searchForOrganization}
          thisForm={this}
          placeholder=""
          /*                             onBlurOverride={true}
                                            onBlur={this.onOrgBlur}*/
        />

        <span id={unique_error_message_id}>
          {this.renderError(meta, isValid, checkvalid)}
        </span>
      </div>
    );
  };

  updateUsStates = (event) => {
    this.setState({ statevalue: event.target.value });
  };

  reload = () => {
    window.location.reload();
  };

  stateChange = (e) => {
    e.preventDefault();
    this.setState({ disablefields });
  };

  formSubmit = (formValues) => {
    this.setState({
      showLoader: true,
    });

    this.setState({ showLoader: true });
    let details = formValues;
    details.contact_method = formValues.contactMethods;
    details.state = formValues.statevalue;
    details.role = 4;

    details.organization_name = details.organization_name.replace(
      "-" + details.zipcode,
      ""
    );

    let postheaders = {
      "access-token": sessionStorage.userDetails
        ? JSON.parse(sessionStorage.userDetails).access_token
        : null,
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let parent = this;
    openbedsApi
      .post("/provider_signup", formBody, { headers: postheaders })
      .then(function (response) {
        //  console.log(response)
        if (
          response.data.status === "ERROR" ||
          response.data.status === "Failure"
        ) {
          if (response.data.message) {
            NotificationManager.error("", response.data.message);
          } else {
            NotificationManager.error("", response.data.data.message);
          }
          parent.setState({
            showLoader: false,
          });
        } else if (response.data.status === "SUCCESS") {
          if (parent.props.systemAdmin === true) {
            NotificationManager.success(
              "",
              response.data.message + ", Redirecting to Search"
            );
            parent.setState({
              showLoader: false,
            });
            setTimeout(() => {
              sessionStorage.setItem("registerDone", "true");
              parent.props.history.push("search-treatment-facilities");
            }, 3000);
          } else {
            NotificationManager.success(
              "",
              response.data.message + ", Redirecting to Login"
            );
            parent.setState({
              showLoader: false,
            });
            setTimeout(() => {
              sessionStorage.setItem("registerDone", "true");
              parent.props.history.push("provider/login");
            }, 3000);
          }
        }
      })
      .catch(function (error) {
        NotificationManager.error("", "Server error");
        parent.setState({ showLoader: false });
      });
  };

  render() {
    return (
      <>
        <form
          method="post"
          onSubmit={this.props.handleSubmit(this.formSubmit)}
          className="validator-form register_user"
          id="register_user"
          name="register_user"
        >
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="first_name"
                component={this.textInput}
                label="First name"
                title="Please enter at least 3 and no more than 50 characters"
                placeholder="First name"
                isValid={true}
                id="first_name"
                autoComplete="given-name"
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="last_name"
                component={this.textInput}
                label="Last name"
                title="Please enter at least 3 and no more than 50 characters"
                placeholder="Last name"
                isValid={true}
                id="last_name"
                autoComplete="family-name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="organization_name"
                component={this.textInputauto}
                optionList={this.props.organizationSearch}
                label="Organization Name"
                title="Please enter at least 3 and no more than 50 characters"
                placeholder="Organization Name"
                isValid={true}
                id="organization_name"
                ariaDescribedby="organization_name_error_message"
              />
            </div>
            <div className="col-sm-6"></div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="organization_phone"
                readonly={this.props.disableStatus}
                component={this.textInput}
                label="Organization Phone No."
                placeholder="Organization Phone No."
                isValid={true}
                title="Please enter a valid phone no."
                id="organization_phone"
                autoComplete="tel"
                type="tel"
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="organization_website_url"
                readonly={this.props.disableStatus}
                checkvalid={true}
                component={this.textInput}
                label="Organization Website Url"
                placeholder={`valid url, e.g. http://abc.com  or https://abc.com`}
                isValid={false}
                title={`Please enter valid url eg. http://abc.com  or https://abc.com" type="url`}
                id="organization_website_url"
                autoComplete="url"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                name="organization_address1"
                readonly={this.props.disableStatus}
                component={this.textInput}
                label="Organization Address line 1"
                placeholder="Organization Address"
                isValid={true}
                id="organization_address1"
                autoComplete="address-line1"
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="organization_address2"
                readonly={this.props.disableStatus}
                component={this.textInput}
                label="Organization Address line 2"
                placeholder="Organization Address"
                isValid={false}
                id="organization_address2"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="city"
                component={this.textInput}
                readonly={this.props.disableStatus}
                label="City"
                placeholder="City"
                isValid={true}
                id="city"
                autoComplete="address-level2"
              />
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Field
                  name="statevalue"
                  readonly={this.props.disableStatus}
                  component={this.stateInput}
                  options={this.props.states}
                  label="State"
                  title=""
                  placeholder=""
                  isValid={true}
                  type="select"
                  id="statevalue"
                />
              </div>
            </div>
            <div className="col-sm-2">
              <Field
                name="zipcode"
                readonly={this.props.disableStatus}
                component={this.textInput}
                label="Zip Code"
                placeholder="Zip Code"
                isValid={true}
                id="zipcode"
                autoComplete="postal-code"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="roles">
                  Role<span className="text-red strongFont font10">*</span>
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={roleToppopover}
                    rootClose
                  >
                    <button
                      type="button"
                      className="overlay_trigger"
                      aria-disabled="true"
                      aria-describedby={roleToppopover.props.id}
                      onKeyDown={(e) => handleOverlayScroll(e, roleToppopover)}
                    >
                      <span
                        className="glyphicon glyphicon-question-sign"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="left"
                      ></span>
                      <span className="sr-only">
                        Role, additional information
                      </span>
                    </button>
                  </OverlayTrigger>
                </label>
                <select
                  name="roles"
                  className="form-control"
                  id="roles"
                  required=""
                  aria-required="true"
                >
                  <option value="4">Receiving Service Administrator</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="email"
                component={this.textInput}
                label="Email"
                pretext={
                  <small>
                    (Please use your work email, DO not use personal email)
                  </small>
                }
                placeholder="Email"
                isValid={true}
                id="email"
                autoComplete="email"
                type="email"
                ariaDescribedby="email-taken-error-message"
              />
              {this.state.emailTaken === true ? (
                <span id="email-taken-error-message" className="error">
                  Email already in use!
                </span>
              ) : (
                false
              )}
            </div>
            <div className="col-sm-6">
              {mobileValid === true ? (
                <Field
                  name="mobile"
                  component={this.textInput}
                  label="Mobile Phone No."
                  title="Please enter a valid phone no."
                  placeholder="Mobile Phone No."
                  isValid={mobileValid}
                  id="mobile"
                  autoComplete="tel"
                  type="tel"
                />
              ) : (
                <Field
                  name="mobile"
                  component={this.textInput}
                  label="Mobile Phone No."
                  title="Please enter a valid phone no."
                  placeholder="Mobile Phone No."
                  id="mobile"
                  autoComplete="tel"
                  type="tel"
                  isValid={false}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="password"
                component={this.textInput}
                label="Password"
                title="Please enter at least one number, one uppercase, lowercase letter, one special character and at least 8 or more characters"
                placeholder="password"
                isValid={true}
                type="password"
                showpopover={true}
                isValid={true}
                id="password"
                autoComplete="new-password"
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="confirm_password"
                component={this.textInput}
                label="Confirm Password"
                title="Please enter at least one number, one uppercase, lowercase letter, one special character and at least 8 or more characters"
                placeholder="password"
                isValid={true}
                type="password"
                id="confirm_password"
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="contactMethods"
                component={this.selectInputContact}
                label="Select Contact Method"
                title="(may change later from My Profile)"
                placeholder="Select Contact Method"
                isValid={true}
                isValid={true}
                type="select"
                id="contactMethods"
              />
            </div>
            {this.props.systemAdmin === true ? (
              <div className="col-sm-6">
                <Field
                  name="notify"
                  component={this.selectInputNotify}
                  label="Send Notification to user"
                  placeholder="Send Notification to user"
                  isValid={true}
                  type="select"
                  id="selectInputNotify"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <button type="submit" className="btn btn-primary mr10">
                Submit
                {this.state.showLoader ? (
                  <span className="fa fa-spinner fa-spin pageLoader">
                    &nbsp;
                  </span>
                ) : (
                  false
                )}
              </button>
              <button
                type="reset"
                className="btn btn-info"
                id="reset"
                value="Cancel"
                onClick={this.reload}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

let OrganizationFormRedux = reduxForm({
  form: "register_user",
  validate,
})(OrganizationForm);

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    organizationSearch: state.organizationSearch,
    states: state.states,
    disableStatus: state.disableStatus,
  };
};

OrganizationFormRedux = connect(mapStateToProps, {
  fetchPosts,
  updateZipcode,
  updateDistance,
  updatePayment,
  updateTreatment,
  updateSubstances,
  searchForOrganization,
  changedisableState,
  getStates,
})(withRouter(OrganizationFormRedux));

export default OrganizationFormRedux;
