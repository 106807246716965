import { useState, useEffect } from 'react';

const STORAGE_TYPES = {
  SESSION: 'session',
  LOCAL: 'local',
  COOKIE: 'cookie',
};

const parseJSON = (value) => {
  try {
    return JSON.parse(value)
  } catch(error) {
    return value;
  }
}

const getStoredValue = (type, key, defaultValue) => {
  switch (type) {
    case STORAGE_TYPES.SESSION:
      return sessionStorage.getItem(key) || JSON.stringify(defaultValue);
    case STORAGE_TYPES.LOCAL:
      return localStorage.getItem(key) || JSON.stringify(defaultValue);
    case STORAGE_TYPES.COOKIE:
      return document.cookie.split('; ').find((cookie) => cookie.startsWith(key))?.split('=')[1] || JSON.stringify(defaultValue);
    default:
      throw new Error(`Unsupported storage type: ${type}`);
  }
};

export const useAppStorage = (storageType, storageKey, initialData) => {
  const [storageData, setStorageData] = useState(() => {
    const storedData = getStoredValue(storageType, storageKey, initialData);
    return parseJSON(storedData);
  });

  const setStorage = (data) => {
    const updatedData = typeof data === 'object' ? data : { [storageKey]: data };
    const serializedData = JSON.stringify(updatedData);
    switch (storageType) {
      case STORAGE_TYPES.SESSION:
        sessionStorage.setItem(storageKey, serializedData);
        break;
      case STORAGE_TYPES.LOCAL:
        localStorage.setItem(storageKey, serializedData);
        break;
      case STORAGE_TYPES.COOKIE:
        const expirationDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
        document.cookie = `${storageKey}=${serializedData}; expires=${expirationDate.toUTCString()}`;
        break;
    }
    //  Update state only if different to reflect the change in storage.
    if (JSON.stringify(storageData) !== serializedData) {
      setStorageData(updatedData);
    }
  };

  const clearStorage = () => {
    let data = {};
    switch (storageType) {
      case STORAGE_TYPES.SESSION:
        if(storageKey){
          sessionStorage.removeItem(storageKey);
        }
        else {
          sessionStorage.clear();
        }
        setStorageData(data);
      case STORAGE_TYPES.LOCAL:
        if(storageKey){
          localStorage.removeItem(storageKey);
          setStorageData(data);
        } else {
          localStorage.clear();
          setStorageData(data);
        }
    }
  
    // Update state
  };
  

  useEffect(() => {
    const handleStorageEvent = () => {
      const newStorageData = getStoredValue(storageType, storageKey, {});
      if (JSON.stringify(storageData) !== JSON.stringify(newStorageData)) {
        setStorageData(newStorageData);
      }
    }
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    }
  }, [storageData]);

  return [ storageData, setStorage, clearStorage ];
};

export default useAppStorage;
