import React from "react";
import {Button, Modal} from "react-bootstrap";
import SimpleMap from "../simplemap";

export class MapModal extends React.Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} bsSize="large" id="map-modal" data-testid="map-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Location - {this.props.organisation}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {this.renderBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderBody() {
        if (!this.isValidNumber(this.props.lat) || !this.isValidNumber(this.props.long)) {
            return (
                <p>A map cannot be displayed at this time</p>
            )
        }

        return (
            <SimpleMap
                mapHeight={"600"}
                lat={this.props.lat}
                long={this.props.long}
                center={this.props.center}
                zoom={14}
                organisation={this.props.organisation}
                facility_list= {this.props.facility_list}
                stateName={[""]}
                googleApiKey={this.props.googleApiKey}
            />
        );
    }

    isValidNumber(number) {
        const float = parseFloat(number);
        return typeof float === "number" && !isNaN(float);
    }
}
export default MapModal;