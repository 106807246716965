/**  */
import { useState} from 'react';
import { useDispatch} from 'react-redux';
import { dispatchAttribute, useAppStorage} from '../../hooks';
import {
  SUBSTANCES,
  GENDER_AND_AGE,
  SPECIAL_POPULATION,
  PROVIDERS_ONSITE,
  LANGUAGES_SERVED,
  PAYMENT_TYPE,
  SEEKING_TREATMENT,
  TREATMENT_TYPE
} from '../../actions/constants';

export const ActiveFilters = ({parent, ...props}) => {
  const dispatch = useDispatch();
  const [ session, setSession, removeSession ] = useAppStorage('session', 'lastSearchDetails', {});
  const {
    payment,
    substances,
    seekingTreatment,
    treatmentType,
    genderAndAge,
    specialPopulation,
    providersOnsite,
    languagesServed,
  } = session

  const [filtersLimit, setFiltersLimit ] = useState(props.filtersLimit || 10);
  const [showMore, setShowMore] = useState(false);

  // Consolidate all filters into one array, tagging each with its category
  const allFilters = [
    ...(seekingTreatment || treatmentType || []).map(item => ({ ...item, category: TREATMENT_TYPE.TYPE })),
    ...(substances || []).map(item => ({ ...item, category: SUBSTANCES.TYPE })),
    ...(payment || []).map(item => ({ ...item, category: PAYMENT_TYPE.TYPE })),
    ...(genderAndAge || []).map(item => ({ ...item, category: GENDER_AND_AGE.TYPE })),
    ...(specialPopulation || []).map(item => ({ ...item, category: SPECIAL_POPULATION.TYPE })),
    ...(providersOnsite || []).map(item => ({ ...item, category: PROVIDERS_ONSITE.TYPE })),
    ...(languagesServed || []).map(item => ({ ...item, category: LANGUAGES_SERVED.TYPE })),
  ];

  // Determine which filters to display
  const displayedFilters = showMore ? allFilters : allFilters.slice(0, filtersLimit);

  const normalizeCategory = (filterCategory, normalized = TREATMENT_TYPE.TYPE) => {
    if([TREATMENT_TYPE.TYPE, SEEKING_TREATMENT.TYPE].includes(filterCategory)){
      filterCategory = normalized;  
    }
    return filterCategory
  }
  

  const setNewFilter = (attrType, newAttrValues) => {
    attrType = normalizeCategory(attrType, SEEKING_TREATMENT.TYPE);
    dispatchAttribute(attrType, newAttrValues, dispatch);
    attrType = normalizeCategory(attrType, TREATMENT_TYPE.TYPE);
    setSession({ ...session, [attrType]: newAttrValues });
  };

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const clearOneFilter = (filterCategory, filterValue, refresh = false) => {
    filterCategory = normalizeCategory(filterCategory);
    const existingFilters = (session[filterCategory] || []).map(item => ({value: item.value, label: item.label}));
    const updatedFilters = existingFilters.filter(item => item.value !== filterValue);
    removeSession();
    setNewFilter(filterCategory, updatedFilters);
    if(refresh){
      setTimeout(() => parent?.applyFilters(props));
    }
  };
  

  const clearAllFilters = () => {
    // Reset logic for all filters
    removeSession();
    [
      SEEKING_TREATMENT.TYPE,
      TREATMENT_TYPE.TYPE,
      SUBSTANCES.TYPE,
      PAYMENT_TYPE.TYPE,
      GENDER_AND_AGE.TYPE,
      SPECIAL_POPULATION.TYPE,
      PROVIDERS_ONSITE.TYPE,
      LANGUAGES_SERVED.TYPE
    ].forEach(category => {
      clearOneFilter(category, []);
    });
    parent?.clearFilters();
  };

  return displayedFilters.length > 0 && (
    <div id="active-filters" className="list-group-item active-filters">
      <div className="h4">
        <strong>Filtered By:</strong>
        <button id="btn-clear-filters" className="small mx-3 btn-link" onClick={clearAllFilters}>Clear all</button>
      </div>
      <div className="btn-list">
      {displayedFilters.map((filter, index) => (
        <button
          key={`${filter.category}-${filter.value}-${index}`}
          id={`${filter.category}-${filter.value}-${index}`}
          onClick={() => clearOneFilter(filter.category, filter.value, true)}
          type="button"
          className="btn btn-sm btn-outline btn-rounded"
          data-title={`[${filter.category}]: ${filter.label}`}
        >
          {filter.label} x
        </button>
      ))}
        {allFilters.length > filtersLimit && (
          <button onClick={handleShowMoreClick} type="button" className="btn btn-sm btn-link">
            <strong>{showMore ? 'show less filters' : `more filters (${allFilters.length - filtersLimit})`}</strong>
          </button>
        )}
      </div>
    </div>
  );
};

export default ActiveFilters;