/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import React from 'react';
import { connect } from 'react-redux';
import SelectMFAType from '@aws-amplify/auth';
import { Amplify, Auth } from 'aws-amplify';
import { apiUrl, backendUrl } from './../../apiurl';
//import { Button } from "react-bootstrap";
import { Button, FormControl, ControlLabel, FormGroup, InputGroup } from 'react-bootstrap';
import querystring from 'querystring';
import getAmplifyConfiguration from '../../configuration/amplifyConfiguration';
import {
    submitLogin,
    cognitoLogin,
    sendRequest,
    clearRequestdata,
    submitLoginSecondFactor,
    userLoggedIn,
} from './../../actions/login';
import openbedsApi from '../../openbedsApi';
import PasswordField from '../Forms/Components/PasswordField';
import { NotificationManager } from 'react-notifications';
import { appUrls } from '../../appUrls';

let auth = getAmplifyConfiguration();

Amplify.configure({
    Auth: auth,
});

const MFATypes = {
    SMS: true, // if SMS enabled in your user pool
    TOTP: false, // if TOTP enabled in your user pool
    Optional: false, // if MFA is set to optional in your user pool
};

let shouldValidatePatient = true;

class Signin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            newPassword: '',
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: true,
            showForgotPassword: false,
            user: null,
            mfaCodeValue: '',
            loginSubmit: [],
            errorMessage: '',
            showSigningInScreen: false,
            showSSOLoginForm: false,
            ssoRedirectUri: '',
            verificationCode: '',
            message: '',
            showRequireNewPassword: false,
            loginDetails: props.loginDetails, //adding login details prop
            /*
             * Password validation comes from Cognito Requirements:
             * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
             */
            password_validation: {
                button_disabled: true,
                length: {
                    valid: false,
                    regex: /^.{8,}$/,
                },
                capital: {
                    valid: false,
                    regex: /[A-Z]/,
                },
                number: {
                    valid: false,
                    regex: /[0-9]/,
                },
                special_character: {
                    valid: false,
                    regex: /[= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & \/ \ , > < ' : ; | _ ~ `]/,
                },
            },
        };
    }

    async componentWillMount() {}

    componentDidMount() {
        //window.LoginComponentState = this;
        let parsed = querystring.parse(window.location.hash.slice(1));

        if (parsed.hasOwnProperty('logout')) {
            Auth.signOut();
            window.location.href = '/login/logout';
            return;
        }

        if (parsed.access_token !== undefined) {
            this.sendTokenToBackend(parsed.access_token);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.loginDetails !== this.state.loginDetails) {
                this.setState({
                    loginDetails: this.props.loginDetails,
                });
                if (this.props.loginDetails.status !== 'SUCCESS' && !this.state.validationProcess) {
                    if (
                        this.props.loginDetails.flag === 'USER_VALIDATION_TOKEN_NEEDED' &&
                        this.props.match.params.token
                    ) {
                        return;
                    }
                    this.setState({ errorMessage: this.props.loginDetails.message });
                } else {
                    this.setState({ errorMessage: '' });
                }
            }
        }
    }

    checkSSO = async (e) => {
        let params = {
            username: this.state.email,
            pfsEndpoint: true,
        };

        const axiosConfig = {
            baseURL: backendUrl,
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
            params: params,
        };

        openbedsApi
            .get('/login/checkSSODomain', axiosConfig)
            .then((response) => {
                let data = response.data;
                if (data.status === 'SUCCESS') {
                    data = data.data;
                    this.setState({
                        showLoginForm: false,
                        showSSOLoginForm: true,
                        ssoRedirectUri: data.redirectUri,
                        errorMessage: '',
                    });
                } else {
                    if (data.error_constant === 'ORGANIZATION_NO_DIRECT_ACCOUNT_LOGIN') {
                        this.setState(
                            {
                                errorMessage: data.data.message,
                            },
                            () => {
                                this.displayScreen();
                            }
                        );
                    } else {
                        this.setState(
                            {
                                ssoRedirectUri: '',
                            },
                            () => {
                                this.displayScreen('showLoginForm');
                            }
                        );
                    }
                }
            })
            .catch((error) => {
                //we do not want to throw errors
            });
    };

    setEmail = (e) => {
        this.setState({
            email: e.target.value,
        });
    };

    isPasswordvalidate = (e) => {
        let pswd = e.target.value;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
        if (regex.test(pswd) || pswd === '') {
            this.setState({ pswdError: false, password: pswd });
        } else {
            this.setState({ pswdError: false, password: pswd });
        }
    };
    isEmailvalidate = (e) => {
        let email = e.target.value;
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(email) || email === '') {
            this.setState({ emailError: false, email: email });
        } else {
            this.setState({ emailError: true, email: email });
        }
    };

    mfaCodeInput = (e) => {
        this.setState({
            mfaCodeValue: e.target.value,
        });
    };

    mfaChallenge = async (e) => {
        e.preventDefault();
        let mfaType = 'SMS_MFA';

        console.log(this.state.user);

        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        try {
            let user = await Auth.confirmSignIn(
                this.state.user, // Return object from Auth.signIn()
                this.state.mfaCodeValue, // Confirmation code
                mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
            );

            user.challengeName = 'SMS_PASSED'; //Overriding SMS_MFA to SMS_PASSED if there is no error

            this.processSignInStep(user);
        } catch (err) {
            this.processCaughtError(err);
        }
    };

    changePasswordRequired = async (e) => {
        e.preventDefault();
        await Auth.completeNewPassword(
            this.state.user, // the Cognito User Object
            this.state.newPassword // the new password
        )
            .then((data) =>
                this.setState({ message: 'Password updated successfully. Please login with new password.' }, () => {
                    this.displayScreen('showLoginForm');
                })
            )
            .catch((err) => this.processCaughtError(err));
    };

    changePasswordInput = (e) => {
        let length = RegExp(this.state.password_validation.length.regex);
        let special_character = RegExp(this.state.password_validation.special_character.regex);
        let capital = RegExp(this.state.password_validation.capital.regex);
        let number = RegExp(this.state.password_validation.number.regex);

        let password_validation = this.state.password_validation;

        password_validation.capital.valid = capital.test(e.target.value);
        password_validation.special_character.valid = special_character.test(e.target.value);
        password_validation.length.valid = length.test(e.target.value);
        password_validation.number.valid = number.test(e.target.value);

        if (
            password_validation.capital.valid === true &&
            password_validation.special_character.valid === true &&
            password_validation.length.valid === true &&
            password_validation.number.valid === true
        ) {
            password_validation.button_disabled = false;
        } else {
            password_validation.button_disabled = true;
        }

        this.setState({
            newPassword: e.target.value,
            password_validation: password_validation,
        });
    };

    changeVerificationCode = (e) => {
        e.preventDefault();
        this.setState({
            verificationCode: e.target.value,
        });
    };

    sendVerificationCode = (e) => {
        e.preventDefault();
        Auth.forgotPassword(this.state.email).catch((err) => console.log(err));
    };

    changePassword = async (e) => {
        e.preventDefault();
        try {
            Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.newPassword)
                .then((data) =>
                    this.setState({ message: 'Password updated successfully. Please login with new password.' }, () => {
                        this.displayScreen('showLoginForm');
                    })
                )
                .catch((err) => this.processCaughtError(err));
        } catch (err) {
            this.processCaughtError(err);
        }
    };

    async sendTokenToBackend(jwt) {
        this.displayScreen('showSigningInScreen');

        try {
            let data = new FormData();
            data.append('accessToken', jwt);

            data.append('pfsEndpoint', 'true'); //passing post parameter to tell verify function to give us back pfs redirects

            const axiosConfig = {
                baseURL: backendUrl,
                headers: {
                    Accept: 'application/json',
                },
                withCredentials: true,
            };

            // Find out if there is a token in the url, in case this is a patient activating their account.
            let url = window.location.href;
            let token = '';
            if (url.lastIndexOf('signin/') !== -1) {
                token = url.substring(url.lastIndexOf('signin/') + 7);
            }

            openbedsApi
                .post('/signin/verify', data, axiosConfig)
                .then(async (response) => {
                    if (response.data.status === 'SUCCESS') {
                        data = response.data.data;
                        //if we aren't going to the account selector screen, then set the props and sessionDetails
                        if (response.data.data.redirectUri !== '/account_selector') {
                            //calls dispatch to set userDetails prop
                            await this.props.cognitoLogin(response.data);
                            //sets userDetails in sessionStorage
                            sessionStorage.userDetails = JSON.stringify(response.data.data);
                        }
                        let redirectUri = response.data.data.redirectUri;
                        //if the user is a patient, and they have selected an org to send a referral to
                        if (response.data.data.type === 'Patient') {
                            let orgid = sessionStorage.getItem('org_id');
                            if (orgid) {
                                redirectUri = '/patients/rrs?new_enquiry=1';
                            }
                        }

                        if (shouldValidatePatient && this.props.match.params.token) {
                            this.validatePatient();
                        } else {
                            //navigate user where he is supposed to go.
                            window.location.href = redirectUri;
                        }
                        this.setState({
                            showLoader: false,
                        });
                    } else if (
                        response.data.status === 'ERROR' &&
                        (response.data.error_constant = 'USER_NOT_ACTIVATED') &&
                        token
                    ) {
                        // User hasn't been activated. If this is a patient, try activating them now.
                        // The following code was copied from validatePatient in login.js, since that method isn't called
                        // there anymore.
                        const postheaders = {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json, text/plain',
                        };

                        const data = new FormData();
                        data.append('token', token);

                        openbedsApi
                            .post('/patient_validation', data, { headers: postheaders })
                            .then((response) => {
                                if (response.data.status === 'SUCCESS') {
                                    NotificationManager.success('', 'Account activated');

                                    shouldValidatePatient = false;

                                    if (response.data.orgid) {
                                        //insert state id
                                        sessionStorage.setItem('state', response.data.state);
                                        sessionStorage.setItem('org_id', response.data.orgid);
                                        sessionStorage.setItem('org_uuid', response.data.org_uuid);
                                        sessionStorage.setItem(
                                            'organization_services_id',
                                            response.data.organization_services_id
                                        );
                                        sessionStorage.setItem('service_id', response.data.service_id);
                                        sessionStorage.setItem('service_uuid', response.data.service_uuid);
                                        this.props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
                                    }

                                    // Try to log in again now that the account has been activated.
                                    this.sendTokenToBackend(jwt);
                                } else {
                                    this.setState({
                                        showSigningInScreen: false,
                                        errorMessage: response.data.message,
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        this.setState({
                            showSigningInScreen: false,
                            errorMessage: response.data.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    throw Error(error.message);
                });
        } catch (err) {
            console.log(err);
        }
    }

    validatePatient = () => {
        const postheaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };

        const data = new FormData();
        data.append('token', this.props.match.params.token);

        let parent = this;

        openbedsApi
            .post('/patient_validation', data, { headers: postheaders })
            .then((response) => {
                if (response.data.status === 'SUCCESS') {
                    this.setState({
                        showLoader: false,
                    });
                    sessionStorage.setItem('userDetails', JSON.stringify(response.data));
                    NotificationManager.success('', 'Account activated');
                    this.props.userLoggedIn(response.data);
                    if (response.data.orgid) {
                        //insert state id
                        sessionStorage.setItem('state', response.data.state);
                        sessionStorage.setItem('org_id', response.data.orgid);
                        sessionStorage.setItem('organization_services_id', response.data.organization_services_id);
                        sessionStorage.setItem('service_id', response.data.service_id);
                        this.props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
                    } else {
                        if (this.props.match.params.token) {
                            this.props.history.push(appUrls.SEARCH_RESULT_LIST);
                        } else {
                            this.props.history.push('/patients/rrs');
                        }
                    }
                } else {
                    NotificationManager.error('', response.data.message);
                    this.props.history.push('/');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    processSignInStep(user = null) {
        if (user === null) {
            return false;
        }

        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            this.displayScreen('showMFAChallenge');
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.displayScreen('showRequireNewPassword');
        } else if (user.challengeName === 'MFA_SETUP') {
            this.displayScreen('showMFASetup');
        } else {
            let jwt = user.signInUserSession.accessToken.getJwtToken();
            this.sendTokenToBackend(jwt);
        }
    }

    processCaughtError(err) {
        let message = '';

        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            message = 'Email and Password do not match!';
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            this.displayScreen('showForgotPassword');
            return;
            //   message = 'Your password must be reset. Please click <a href="/forgot">here</a> to reset it.'
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            message = 'Email and Password do not match!';
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Email and Password do not match!';
        } else if (err.code === 'CodeMismatchException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'ExpiredCodeException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'LimitExceededException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'You have exceeded the number of times to login. Please try again after a few minutes.';
        } else if (err.code === 'MissingRequirements') {
            //The error happens when the username/email or password field is completely empty.
            message = 'Both email and password are required.';
        } else {
            message = 'There was an error';
            console.log(err);
        }
        this.setState(
            {
                errorMessage: message,
            },
            () => {
                this.displayScreen();
            }
        );
    }

    displayScreen(form = null) {
        let screens = {
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: false,
            showForgotPassword: false,
            showSigningInScreen: false,
            showSSOLoginForm: false,
            showRequireNewPassword: false,
        };

        if (form === 'showSigningInScreen') {
            screens.message = '';
            screens.errorMessage = '';
        }

        if (form) {
            screens[form] = true;
        }
        this.setState(screens);
    }

    signIn = async (e) => {
        e.preventDefault();

        const hostname = window && window.location && window.location.hostname;

        if (
            this.state.showSSOLoginForm === false &&
            (this.state.email === null ||
                this.state.email === '' ||
                this.state.password === null ||
                this.state.password === '')
        ) {
            //If the username/email or password fields are empty, throw an error, otherwise continue.
            let err = [];
            err.code = 'MissingRequirements';
            this.processCaughtError(err);

            return;
        }

        if (this.state.ssoRedirectUri === '') {
            Auth.signOut();
            //hostname should be the backend hostname, grab it from the apiUrl and trash the unnecessary stuff
            let context_host = new URL(apiUrl).hostname;

            await Auth.signIn(this.state.email, this.state.password, { context_host: context_host })
                .then((user) => {
                    this.setState({ user: user }, () => {
                        this.processSignInStep(this.state.user);
                    });
                })
                .catch((err) => {
                    this.processCaughtError(err);
                });
        } else {
            window.location.href = this.state.ssoRedirectUri;
        }
    };

    render() {
        return (
            <>
                {this.state.message && this.state.message !== '' ? (
                    <>
                        <div className="alert alert-success">{this.state.message}</div>
                    </>
                ) : (
                    <></>
                )}
                {this.state.errorMessage && this.state.errorMessage !== '' ? (
                    <>
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                        <Button
                            block
                            size="lg"
                            type="submit"
                            onClick={() => {
                                this.setState({
                                    showLoginForm: true,
                                    errorMessage: '',
                                });
                            }}
                        >
                            Go Back to Login Screen
                        </Button>
                    </>
                ) : (
                    <></>
                )}

                {this.state.showLoginForm ? (
                    <form>
                        <div className="form-group input-group mt0">
                            <span className="input-group-addon">
                                <i className="fa fa-envelope"></i>
                            </span>
                            <label htmlFor='email' className="sr-only" id="emailplaceholder">
                                Email Address (required)
                            </label>
                            <input
                                name="email"
                                id="email"
                                className="form-control"
                                autoComplete="email"
                                placeholder="Email Address"
                                value={this.state.email}
                                onChange={this.isEmailvalidate}
                                onBlur={this.checkSSO}
                                type="email"
                                aria-labelledby="emailplaceholder"
                                aria-describedby="emailplaceholder"
                                aria-required="true"
                            />
                        </div>
                        <br />
                        {this.state.emailError === true ? <span className="error">Enter Valid Email </span> : false}
                        <>
                            <PasswordField
                                updatePasswordValue={this.isPasswordvalidate}
                                passwordValue={this.state.password}
                                passwordHasError={this.state.pswdError}
                                hiddenLabel="true"
                                labelText="User Password"
                                placeholderValue="Password"
                                ariaDescribedByIds="errorMessage"
                                aria-required="true"
                                autoComplete="current-password"
                            />
                        </>
                        <Button
                            block
                            size="lg"
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={this.signIn}
                        >
                            Login
                        </Button>
                    </form>
                ) : (
                    <></>
                )}
                {this.state.showSSOLoginForm ? (
                    <form>
                        <FormGroup controlId="email">
                            <InputGroup size="lg">
                                <ControlLabel>Email</ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.setEmail}
                                    onBlur={this.checkSSO}
                                />
                            </InputGroup>
                        </FormGroup>
                        <Button
                            block
                            size="lg"
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={this.signIn}
                        >
                            Login Through Your Organization
                        </Button>
                    </form>
                ) : (
                    <></>
                )}
                {this.state.showRequireNewPassword ? (
                    <>
                        <b>You are required to change your password</b>
                        <br />
                        Please provide a new password below:
                        <br />
                        <br />
                        <form>
                            <>
                                <PasswordField
                                    updatePasswordValue={this.changePasswordInput}
                                    passwordValue={this.newPassword}
                                    passwordHasError={this.state.pswdError}
                                    hiddenLabel="true"
                                    labelText="Password"
                                    placeholderValue="Password"
                                    ariaDescribedByIds="errorMessage"
                                />
                            </>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <Button
                                block
                                size="lg"
                                type="submit"
                                onClick={this.changePasswordRequired}
                                disabled={this.state.password_validation.button_disabled}
                            >
                                Change Password
                            </Button>
                        </form>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showForgotPassword ? (
                    <>
                        <b>Change your password</b>
                        <br />
                        Please click the button below to send yourself a verification code. Please provide it and a new
                        password to reset your password.
                        <Button block size="sm" type="submit" onClick={this.sendVerificationCode}>
                            Send Verification Code
                        </Button>
                        <br />
                        <br />
                        <form>
                            <InputGroup size="lg" controlId="email">
                                <ControlLabel>Email</ControlLabel>
                                <FormControl autoFocus type="email" value={this.state.email} onChange={this.setEmail} />
                            </InputGroup>
                            <InputGroup size="lg" controlId="password">
                                <ControlLabel>Verification Code</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.verificationCode}
                                    onChange={this.changeVerificationCode}
                                />
                            </InputGroup>
                            <>
                                <PasswordField
                                    updatePasswordValue={this.changePasswordInput}
                                    passwordValue={this.newPassword}
                                    passwordHasError={this.state.pswdError}
                                    hiddenLabel="true"
                                    labelText="Password"
                                    placeholderValue="Password"
                                    ariaDescribedByIds="errorMessage"
                                />
                            </>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <i className="fa fa-check green"></i>
                                        ) : (
                                            <i className="fa fa-times red"></i>
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <Button
                                block
                                size="lg"
                                type="submit"
                                onClick={this.changePassword}
                                disabled={this.state.password_validation.button_disabled}
                            >
                                Change Password
                            </Button>
                        </form>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showMFAChallenge ? (
                    <form>
                        <InputGroup size="lg" controlId="email">
                            <ControlLabel>
                                Please provide the authentication code sent to the phone number on the account
                            </ControlLabel>
                            <FormControl
                                autoFocus
                                type="text"
                                value={this.state.mfaCodeValue}
                                onChange={this.mfaCodeInput}
                            />
                            <br />
                            <Button block size="lg" type="submit" onClick={this.mfaChallenge}>
                                Confirm
                            </Button>
                        </InputGroup>
                    </form>
                ) : (
                    <></>
                )}
                {this.state.showMFASetup ? (
                    <>
                        <SelectMFAType authData={this.props.authData} MFATypes={MFATypes}></SelectMFAType>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showSigningInScreen ? (
                    <>
                        <div className="alert alert-success">Logging In...</div>
                        <br />
                        <br />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    //  console.log(state)
    return {
        loginDetails: state.loginDetails,
        sendRequestMessage: state.sendRequest,
    };
};

export default connect(mapStateToProps, {
    sendRequest,
    submitLogin,
    cognitoLogin,

    clearRequestdata,
    userLoggedIn,
    submitLoginSecondFactor,
})(Signin);
