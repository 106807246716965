const user_routes_and_route_names = [
  {
    url: "",
    page_name: "My Profile",
    category_heading: "true",
    user_types: ["Admin", "Provider", "4a provider", "Patient", "Signed Out"],
  },
  {
    url: "/signin",
    page_name: "User Login",
    user_types: ["Signed Out"],
  },  
  {
    url: "/patients/rrs",
    page_name: "My Requests",
    user_types: ["Patient"],
  },
  {
    url: "/patients/myprofile",
    page_name: "My Profile",
    user_types: ["Patient"],
  },
  {
    url: "/patients/need_help",
    page_name: "Help",
    user_types: ["Patient"],
  },
  {
    url: "/provider/myprofile",
    page_name: "My Profile",
    user_types: ["Admin", "Provider", "4a provider"],
  },
  {
    url: "/provider/help",
    page_name: "Provider Help",
    user_types: ["Admin", "Provider", "4a provider"],
  },
];

export default user_routes_and_route_names;
