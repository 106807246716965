export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_API_KEYS = "FETCH_API_KEYS";
export const GET_STATE_ATTRIBUTES = "GET_STATE_ATTRIBUTES";
export const GET_ALL_ATTRIBUTES = "GET_ALL_ATTRIBUTES";
export const FETCH_STATES = "FETCH_STATES";
export const GET_PFS_CONFIG = "GET_PFS_CONFIG";
export const GET_RECAPTCHA_CONFIG = "GET_RECAPTCHA_CONFIG";
export const GET_STATE_CONFIG = "GET_STATE_CONFIG";
export const UPDATE_ZIPCODE = "UPDATE_ZIPCODE";
export const UPDATE_DISTANCE = "UPDATE_DISTANCE";
export const UPDATE_PAYMENT_TYPE = "UPDATE_PAYMENT_TYPE";
export const UPDATE_TREATMENT = "UPDATE_TREATMENT";
export const UPDATE_TREATMENT_TYPE = "UPDATE_TREATMENT_TYPE";
export const UPDATE_SUBSTANCES = "UPDATE_SUBSTANCES";
export const UPDATE_SEEKING_TREATMENT = "UPDATE_SEEKING_TREATMENT";
export const UPDATE_SEEKING_TREATMENT_TYPE = "UPDATE_SEEKING_TREATMENT_TYPE";
export const UPDATE_ADDITIONAL_SERVICES = "UPDATE_ADDITIONAL_SERVICES_TYPE";
export const UPDATE_GENDER_AND_AGE_TYPE = "UPDATE_GENDER_AND_AGE_TYPE";
export const UPDATE_SEARCH_TERMS = "UPDATE_SEARCH_TERMS";
export const UPDATE_LIMIT_TO_STATE = "UPDATE_LIMIT_TO_STATE";
export const UPDATE_PROVIDERS_ON_SITE = "UPDATE_PROVIDERS_ON_SITE";
export const UPDATE_SPECIAL_POPULATION = "UPDATE_SPECIAL_POPULATION";
export const UPDATE_LANGUAGES_SERVED = "UPDATE_LANGUAGES_SERVED";
export const ORGANIZATION_SEARCH = "ORGANIZATION_SEARCH";
export const FETCH_MORE_INFO = "FETCH_MORE_INFO";
export const FETCH_SEARCHLIST = "FETCH_SEARCHLIST";
export const FETCH_SEARCHLIST_CONCAT = "FETCH_SEARCHLIST_CONCAT";
export const FETCH_LOGIN = "FETCH_LOGIN";
export const ACCOUNT_SELECTOR = "ACCOUNT_SELECTOR";
export const FETCH_LOGOUT = "FETCH_LOGOUT";
export const SEND_REQUEST = "SEND_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const PATIENT_PROFILE_DETAILS = "PATIENT_PROFILE_DETAILS";
export const PATIENT_RRS_INFO = "PATIENT_RRS_INFO";
export const PROVIDER_RRS_INFO = "PROVIDER_RRS_INFO";
export const RRS_PATIENT_INFO = "RRS_PATIENT_INFO";
export const ARCHIVED_PROVIDER_RRS_INFO = "ARCHIVED_PROVIDER_RRS_INFO";
export const SERVICE_DETAIL = "SERVICE_DETAIL";
export const DISABLE_STATUS = "DISABLE_STATUS";
export const LOAD = "LOAD";
export const FORM = "FORM";
export const SUPER_ADMIN_ORGANIZATION_LIST = "SUPER_ADMIN_ORGANIZATION_LIST";
export const UPDATE_STATE_ATTRIBUTES = "UPDATE_STATE_ATTRIBUTES";
export const UPDATE_LOCATION = "UPDATE_LOCATION"
export const UPDATE_SEARCH_LOCATION = "UPDATE_SEARCH_LOCATION"
export const UPDATE_STATE_CONFIGURATIONS = "UPDATE_STATE_CONFIGURATIONS"
export const SEARCH_LOCATIONS = "SEARCH_LOCATIONS"