const resource_routes_and_route_names = [
  {
    url: "/resources",
    page_name: "Resources",
    category_heading: "true",
  },
  {
    url: "/resources/questions-about-treatment",
    page_name: "Questions About Treatment",
  },
  {
    url: "/resources/treatments_available",
    page_name: "Treatments Available",
  },
  {
    url: "/resources/stages-of-relapse",
    page_name: "Stages Of Relapse",
  },
  {
    url: "/resources/stigma-of-addiction",
    page_name: "Stigma Of Addiction",
  },
  {
    url: "/resources/what-is-mat",
    page_name: "What is Mat?",
  },
  {
    url: "/resources/treatment-settings",
    page_name: "Treatment Settings",
  },
  {
    url: "/resources/quality-treatment-sud",
    page_name: "Quality Treatment Sud",
  },
  {
    url: "/resources/helping-mental-health-sud",
    page_name: "Helping Mental Health Sud",
  },
  {
    url: "/resources/warning-signs",
    page_name: "Warning Signs",
  },
  {
    url: "/resources/mental-health-crisis",
    page_name: "Mental Health Crisis",
  },
  {
    url: "/resources/suicide",
    page_name: "Suicide",
  },
  {
    url: "/resources/preparing-mental-health-crisis",
    page_name: "Preparing For a Mental Health Crisis",
  },
  {
    url: "/resources/crisis-plan",
    page_name: "Crisis Plan",
  },
];

export default resource_routes_and_route_names;
