import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MenuItem, DropdownButton, Modal, Popover} from 'react-bootstrap';
import { Logout } from '../../actions/login';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import openbedsApi from './../../openbedsApi';
import { gaLogEvent } from '../../actions/googleAnalytics';
import { appUrls } from '../../appUrls';
import InactivityMonitor from '../login/InactivityMonitor';
import setupAllAccessibilityHelpers from '../accessibility-helpers/setupAllHelpers';
import SigninForgotPassword from '../login/SigninForgotPassword';

const passwordToppopover = (
    <Popover id="popover-positioned-top" title="Password help">
        <p>
            Password must have at least one number, one uppercase, lowercase letter, one special character ( @, !, #, $,
            & ) and at least 8 or more characters
        </p>
    </Popover>
);

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileMenuExpanded: false,
            showChangePassword: false,
            oldPassword: '',
            newPassword: '',
            rePassword: '',
            oldPasswordError: false,
            newPasswordError: false,
            rePasswordError: false,
            isOpen: false,
            isHomePage: window.location.pathname === '/',
        };

        this._menuToggle = this._menuToggle.bind(this);

        this._handleDocumentClick = this._handleDocumentClick.bind(this);

        this.handleScroll = this.handleScroll.bind(this);

        this.handleMobileMenuOpenStateWhenTabbing = this.handleMobileMenuOpenStateWhenTabbing.bind(this);

        this.menuToggle = React.createRef();
        this.lastMenuItem = React.createRef();
        this.Siteheader = React.createRef();
        this.mobileMenuGroup = React.createRef();
    }

    componentDidMount() {
        this.setHeightOfHeader();
        window.addEventListener('resize', this.setHeightOfHeader);

        document.addEventListener('scroll', this.handleScroll);
        if (window.screen.width <= 700) {
            document.addEventListener('click', this._handleDocumentClick, false);
        }
        window.addEventListener('resize', this.updateDimensions);
        setupAllAccessibilityHelpers();
    }

    componentWillUnmount() {
        if (window.screen.width <= 700) {
            document.removeEventListener('click', this._handleDocumentClick, false);
        }
    }

    setHeightOfHeader = () => {
        if (this.props.setHeight) {
            // for the homepage
            this.props.setHeight(this.Siteheader);
        }
    };

    _handleDocumentClick(e) {
        if (this.refs.root !== undefined) {
            if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
                this.setState({
                    isOpen: false,
                });
            }
        }
    }

    handleScroll() {
        if (window.screen.width <= 1200) {
            this.setState({ isOpen: false });
        }
    }

    // Helper metho to determine
    determineTabDirection = (event) => {
        let key = event.key;
        if (key === 'Tab' && event.shiftKey) {
            return 'Backward';
        } else if (key === 'Tab') {
            return 'Forward';
        }
    };

    // The following two methods trap focus when mobile menu is open
    handleMobileMenuOpenStateWhenTabbing(event) {
        if (this.state.isMobileMenuExpanded) {
            let getTheMobileMenuHeader = this.mobileMenuGroup.current;
            var focusableEls = getTheMobileMenuHeader.querySelectorAll(
                'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="password"]:not([disabled]), select:not([disabled])'
            );
            let firstFocusableEl = focusableEls[0];
            let lastFocusableEl = focusableEls[focusableEls.length - 1];
            let KEYCODE_TAB = 9;

            var isTabPressed = event.key === 'Tab' || event.keyCode === KEYCODE_TAB;

            if (isTabPressed && event.shiftKey && event.target === firstFocusableEl) {
                this.setState({
                    isOpen: false,
                    isMobileMenuExpanded: false,
                });
            } else if (isTabPressed && !event.shiftKey && event.target === lastFocusableEl) {
                this.setState({
                    isOpen: false,
                    isMobileMenuExpanded: false,
                });
            } else {
            }
        }
    }

    /**
    Escape event setup. The next two methods.
    The goal of these methods is to setup and tear down a keyup listener for
    escaping from the menu.
    */
    setUpEscapeEvent = (event) => {
        let key = event.key;

        let getTheMobileMenuHeader = this.mobileMenuGroup.current;

        if (key === 'Escape') {
            this._menuToggle(event);

            if (getTheMobileMenuHeader.contains(document.activeElement)) {
                this.menuToggle.current.focus();
            }
        }
    };

    setUpEscapeEventListener = (event) => {
        setTimeout(() => {
            if (this.state.isOpen) {
                window.addEventListener('keydown', this.setUpEscapeEvent, true);
            } else {
                window.removeEventListener('keydown', this.setUpEscapeEvent, true);
            }
        }, 100);
    };

    _menuToggle(e) {
        e.stopPropagation();
        this.setState(
            {
                isOpen: !this.state.isOpen,
                isMobileMenuExpanded: !this.state.isMobileMenuExpanded,
            },
            () => {
                this.setUpEscapeEventListener();
            }
        );
    }

    signOut = () => {
        sessionStorage.removeItem('org_id');
        this.props.Logout();
    };

    handleClose = () => {
        this.setState({ showChangePassword: false });
        //set all errors to false on close
        this.setState({
            oldPasswordError: false,
            newPasswordError: false,
            rePasswordError: false,
            rePasswordBlankError: false,
            samePasswordError: false,
        });
    };

    handleChangePassword = () => {
        this.setState({
            showChangePassword: true,
            oldPassword: '',
            newPassword: '',
            rePassword: '',
            oldPasswordError: false,
            newPasswordError: false,
            rePasswordError: false,
        });
    };

    updateoldPassword = (e) => {
        let oldPassword = e.target.value;
        this.setState({ oldPassword });
        setTimeout(() => {
            if (this.state.newPasswordError === true) {
                this.validateoldPassword();
            }
        }, 300);
    };

    updatenewPassword = (e) => {
        let newPassword = e.target.value;
        this.setState({ newPassword });
        setTimeout(() => {
            if (this.state.newPasswordError === true) {
                this.validatenewPassword();
            }
        }, 300);
    };

    updaterePassword = (e) => {
        let rePassword = e.target.value;
        this.setState({ rePassword });
        setTimeout(() => {
            if (this.state.rePasswordError === true) {
                this.validaterePassword();
            }
        }, 300);
    };

    validateoldPassword = () => {
        let oldPassword = this.state.oldPassword;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        if (regex.test(oldPassword)) {
            this.setState({ oldPasswordError: false });
        } else {
            this.setState({ oldPasswordError: true });
        }
    };

    validatenewPassword = () => {
        let newPassword = this.state.newPassword;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$&])[A-Za-z\d@!#$&]{8,}$/;
        if (regex.test(newPassword)) {
            this.setState({ newPasswordError: false });
        } else {
            this.setState({ newPasswordError: true });
        }
        if (this.state.oldPassword == newPassword) {
            this.setState({ samePasswordError: true });
        } else {
            this.setState({ samePasswordError: false });
        }
    };

    validaterePassword = () => {
        let rePassword = this.state.rePassword;
        if (!rePassword) {
            this.setState({ rePasswordBlankError: true });
            this.setState({ rePasswordError: false });
        } else {
            this.setState({ rePasswordBlankError: false });
            if (rePassword === this.state.newPassword) {
                this.setState({ rePasswordError: false });
            } else {
                this.setState({ rePasswordError: true });
            }
        }
    };

    changePasswordSubmit = (access) => {
        let parent = this;
        let details = {
            old_password: this.state.oldPassword,
            password: this.state.newPassword,
        };
        let postheaders = {
            'access-token': access,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain',
        };
        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');

        openbedsApi.post('/change_password', formBody, { headers: postheaders }).then(function (response) {
            if (response.data.status === 'ERROR' || response.data.status === 'Failure') {
                // NotificationManager.error('', response.data.message);
                if (response.data.status === 'ERROR' && response.data.message === 'Invalid old password') {
                    parent.setState({
                        showLoader: false,
                        oldPasswordError: true,
                        oldPassword: '',
                    });
                } else {
                    NotificationManager.error('', response.data.message);
                    parent.setState({
                        showLoader: false,
                    });
                }
                parent.setState({
                    showLoader: false,
                });
            } else if (response.data.status === 'SUCCESS') {
                NotificationManager.success('', response.data.message);
                parent.setState({
                    showLoader: false,
                });
                setTimeout(() => {
                    parent.handleClose();
                }, 2000);
            }
        });
    };

    ChangePassword = (e) => {
        e.preventDefault();
        let st = this.state;
        if (
            st.newPassword !== '' &&
            st.oldPassword !== '' &&
            st.rePassword !== '' &&
            st.oldPasswordError === false &&
            st.newPasswordError === false &&
            st.rePasswordError === false &&
            st.rePasswordBlankError === false &&
            st.samePasswordError === false &&
            st.newPassword === st.rePassword
        ) {
            this.setState({ showLoader: true });

            this.changePasswordSubmit(this.props.loginDetails.access_token);
        } else {
            if (st.newPassword === '') {
                this.setState({ newPasswordError: true });
            }
            if (st.oldPassword === '') {
                this.setState({ oldPasswordError: true });
            }
            if (st.rePassword === '') {
                this.setState({ rePasswordBlankError: true });
            }
            if (st.oldPassword === st.newPassword) {
                this.setState({ samePasswordError: true });
            }
            if (st.rePassword !== this.state.newPassword) {
                this.setState({ rePasswordError: true });
            }
        }
    };

    componentDidUpdate(prevProps) {
        const havePropsChanged = this.props.logoutDetails && this.props.logoutDetails !== prevProps.logoutDetails;

        if (havePropsChanged) {
            const isSuccessfulLogout =
                this.props.logoutDetails.requestStatus === 'SUCCESS' &&
                this.props.logoutDetails.data.status === 'SUCCESS';

            if (isSuccessfulLogout) {
                NotificationManager.success('', this.props.logoutDetails.data.message);
            } else if (this.props.logoutDetails.requestStatus === 'ERROR') {
                NotificationManager.error('', this.props.logoutDetails.data.message);
            }

            let type = JSON.parse(sessionStorage.userDetails).type;
            const redirectUrl = appUrls.USER_LOGIN;

            setTimeout(() => {
                sessionStorage.clear();
                window.location = redirectUrl;
            }, 1000);
        }
    }

    async componentWillMount() {
        this.updateDimensions();
    }

    updateDimensions = () => {
        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = body.clientWidth,
            height = body.clientHeight;

        this.setState({ resolutionSize: width });

        // if you are using ES2015 I'm pretty sure you can do this: this.setState({width, height});
    };

    logEvent(action) {
        this.props.gaLogEvent({
            category: 'Header',
            action: action,
        });
    }

    render() {
        let menuStatus = this.state.isOpen ? 'isopen' : '';
        //let resolutionSize = window.screen.width ;
        const linkTo =
            this.props.loginDetails.status === 'SUCCESS' &&
            (this.props.loginDetails.type === 'Provider' ||
                this.props.loginDetails.type === '4a provider' ||
                this.props.loginDetails.type === 'Admin')
                ? '/search-treatment-facilities'
                : '/#home';

        return (
            <>
                {/* Site Header - Start */}
                <a className="skip-link" href="#main-content">
                    Skip to main content
                </a>
                <header id="header" className="no-print" ref={this.Siteheader}>
                    {this.props.loginDetails.status === 'SUCCESS' && <InactivityMonitor />}

                    {/* Responsive Spacing Gap - Start */}
                    {window.location.pathname === '/' ? (
                        this.props.fixedToTop === true && this.state.resolutionSize <= 1200 ? (
                            <div className="responsive-spacing-mobile">&nbsp;</div>
                        ) : (
                            <div className="responsive-spacing-desktop">&nbsp;</div>
                        )
                    ) : (
                        ''
                    )}
                    {/* Responsive Spacing Gap - End */}
                    {/* Mobile Header and Menu - Start */}
                    {this.state.resolutionSize <= 1200 ? (
                        <div ref="root">
                            <div className={'menubar ' + (this.props.fixedToTop === true ? 'menu-fixed-top' : '')}>
                                <div
                                    className="mobileMenuGroup"
                                    ref={this.mobileMenuGroup}
                                    onKeyDown={this.handleMobileMenuOpenStateWhenTabbing}
                                >
                                    <button
                                        id="hambmenu"
                                        className={menuStatus}
                                        ref={this.menuToggle}
                                        onClick={this._menuToggle}
                                        aria-expanded={this.state.isMobileMenuExpanded}
                                        aria-label="Main Menu Toggle"
                                    >
                                        <span className="menu-span"></span>
                                        <span className="menu-span"></span>
                                        <span className="menu-span"></span>
                                        <span className="menu-span"></span>
                                    </button>
                                    <nav className={menuStatus} id="menu-header" aria-label="main">
                                        {this.props.loginDetails ? (
                                            this.props.loginDetails.status === 'SUCCESS' &&
                                            this.props.loginDetails.type === 'Patient' ? (
                                                <div>
                                                    <p className="menu-p">{this.props.loginDetails.email}</p>
                                                    <ul className="menu-ul">
                                                        {window.location.pathname === '/' ? (
                                                            ''
                                                        ) : (
                                                            <li
                                                                className={
                                                                    window.location.pathname ===
                                                                    appUrls.SEARCH_RESULT_LIST
                                                                        ? 'activeTab'
                                                                        : ''
                                                                }
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={
                                                                        window.location.pathname ===
                                                                        appUrls.SEARCH_RESULT_LIST
                                                                            ? 'activeTab fa fa-pencil-square-o '
                                                                            : 'fa fa-pencil-square-o'
                                                                    }
                                                                ></i>
                                                                <Link to={appUrls.SEARCH_RESULT_LIST}>
                                                                    <span
                                                                        className={
                                                                            window.location.pathname ===
                                                                            appUrls.SEARCH_RESULT_LIST
                                                                                ? 'activeTab'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        Search
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        )}
                                                        <li
                                                            className={
                                                                this.props.page && this.props.page === 'RRS'
                                                                    ? 'activeTab'
                                                                    : ''
                                                            }
                                                        >
                                                            <i
                                                                aria-hidden="true"
                                                                className={
                                                                    this.props.page && this.props.page === 'RRS'
                                                                        ? 'activeTab fa fa-bookmark-o'
                                                                        : 'fa fa-bookmark-o'
                                                                }
                                                            ></i>
                                                            <Link to="/patients/rrs">
                                                                <span
                                                                    className={
                                                                        this.props.page && this.props.page === 'RRS'
                                                                            ? 'activeTab'
                                                                            : ''
                                                                    }
                                                                >
                                                                    My Requests
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a href="/patients/myprofile">
                                                                <i aria-hidden="true" className="fa fa-user"></i>
                                                                My Profile
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn p-0"
                                                                onClick={this.handleChangePassword}
                                                            >
                                                                <i aria-hidden="true" className="fa fa-exchange"></i>
                                                                Change Password
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href={appUrls.ABOUT_SECTION_HOMEPAGE}
                                                                onClick={this.handleClose}
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-question-circle"
                                                                ></i>
                                                                About
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://blog.treatmentconnection.com/"
                                                                aria-label="Read our Blog"
                                                            >
                                                                Blog
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="/patients/need_help">
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-question-circle"
                                                                ></i>
                                                                Help
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                onClick={this.signOut}
                                                                ref={this.lastMenuItem}
                                                                onKeyDown={this.handleLastMenuItemKeyPress}
                                                            >
                                                                <i aria-hidden="true" className="fa fa-sign-out"></i>
                                                                Sign Out
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : this.props.loginDetails.status === 'SUCCESS' &&
                                                (this.props.loginDetails.type === 'Provider' ||
                                                    this.props.loginDetails.type === '4a provider' ||
                                                    this.props.loginDetails.type === 'Admin') ? (
                                                <div>
                                                    <p className="menu-p">{this.props.loginDetails.email}</p>
                                                    <ul className="menu-ul">
                                                        {window.location.pathname === '/' ? (
                                                            ''
                                                        ) : (
                                                            <li
                                                                className={
                                                                    this.props.page && this.props.page === 'list'
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                            >
                                                                <Link to={appUrls.SEARCH_RESULT_LIST}>
                                                                    <i aria-hidden="true" className="fa fa-edit"></i>
                                                                    Search
                                                                </Link>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <a href="/provider/myprofile">
                                                                <i aria-hidden="true" className="fa fa-user"></i>
                                                                My Profile
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn p-0"
                                                                onClick={this.handleChangePassword}
                                                            >
                                                                <i aria-hidden="true" className="fa fa-exchange"></i>
                                                                Change Password
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <Link to="/#about">
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-question-circle"
                                                                ></i>
                                                                About
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <a href="/provider/need_help">
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-question-circle"
                                                                ></i>
                                                                Help
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <button
                                                                className="btn p-0"
                                                                onClick={this.signOut}
                                                                ref={this.lastMenuItem}
                                                                onKeyDown={this.handleLastMenuItemKeyPress}
                                                            >
                                                                <i aria-hidden="true" className="fa fa-sign-out"></i>
                                                                Sign Out
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className="menu-container">
                                                    <ul className="menu-ul d-flex flex-column h-100 mt-10">
                                                        {window.location.pathname === '/' ? (
                                                            ''
                                                        ) : (
                                                            <li
                                                                className={
                                                                    window.location.pathname ===
                                                                    appUrls.SEARCH_RESULT_LIST
                                                                        ? 'activeTab'
                                                                        : ''
                                                                }
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={
                                                                        window.location.pathname ===
                                                                        appUrls.SEARCH_RESULT_LIST
                                                                            ? 'activeTab fa fa-pencil-square-o '
                                                                            : 'fa fa-pencil-square-o'
                                                                    }
                                                                ></i>
                                                                <Link to={appUrls.SEARCH_RESULT_LIST}>
                                                                    <span
                                                                        className={
                                                                            window.location.pathname ===
                                                                            appUrls.SEARCH_RESULT_LIST
                                                                                ? 'activeTab'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        Search
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <a
                                                                href={appUrls.ABOUT_SECTION_HOMEPAGE}
                                                                onClick={(e) => this._menuToggle(e)}
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-question-circle"
                                                                ></i>
                                                                About
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://blog.treatmentconnection.com/"
                                                                aria-label="Read our Blog"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-file"
                                                                ></i>
                                                                Blog
                                                            </a>
                                                        </li>
                                                        <li
                                                            className={
                                                                window.location.pathname === '/resources'
                                                                    ? 'activeTab'
                                                                    : ''
                                                            }
                                                        >
                                                            <a href="/resources">
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={
                                                                        window.location.pathname ===
                                                                        '/resources'
                                                                            ? 'activeTab fa fa-book'
                                                                            : 'fa fa-book'
                                                                    }
                                                                ></i>
                                                                <span
                                                                    className={
                                                                        window.location.pathname ===
                                                                        '/resources'
                                                                            ? 'activeTab'
                                                                            : ''
                                                                    }
                                                                >
                                                                    Resources
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            className={
                                                                window.location.pathname === '/patients/need_help'
                                                                    ? 'activeTab'
                                                                    : ''
                                                            }
                                                        >
                                                            <a href="/patients/need_help">
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={
                                                                        window.location.pathname ===
                                                                        '/patients/need_help'
                                                                            ? 'activeTab fa fa-question-circle'
                                                                            : 'fa fa-question-circle'
                                                                    }
                                                                ></i>
                                                                <span
                                                                    className={
                                                                        window.location.pathname ===
                                                                        '/patients/need_help'
                                                                            ? 'activeTab'
                                                                            : ''
                                                                    }
                                                                >
                                                                    Help
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="login-small">
                                                            <a
                                                                href={appUrls.USER_LOGIN}
                                                                ref={this.lastMenuItem}
                                                                onClick={() => this.logEvent('Public Login Button')}
                                                                onKeyDown={this.handleLastMenuItemKeyPress}
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    className="fa fa-key side-bar-key"
                                                                ></i>
                                                                User Login / Sign-up
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                        ) : false}
                                    </nav>
                                </div>
                                <div className="title">
                                    <Link to="/">
                                        <span className="sr-only">Homepage</span>
                                        <img
                                            src="/images/treatmentconnection_logo_header.png"
                                            className="headerLogo ml-10"
                                            alt="treatment connection Home | Bamboo Health Solution"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        /* Mobile Header and Menu - End */
                        // (Ternary). If screen larger than 1200px
                        /* Desktop Header and Menu - Start */
                        <div>
                            <div
                                className={
                                    'navbar ' +
                                    (this.props.fixedToTop === true ? 'navbar-fixed-top' : 'navbar-static-top')
                                }
                            >
                                {/* Desktop Header and Menu - Start */}
                                {/* Flex container includes two columns - Start */}
                                <div className="container mx-auto d-flex align-items-center px-0">
                                    {/* Column 1 of 2 - Start */}
                                    <div className="navbar-header col-md-3">
                                        <Link to="/">
                                            <span className="sr-only">Homepage</span>
                                            <img
                                                src="/images/treatmentconnection_logo_header.png"
                                                className="headerLogo ml-10"
                                                alt="treatment connection Home | Bamboo Health Solution"
                                            />
                                        </Link>
                                    </div>
                                    {/* Column 1 of 2 - End */}

                                    {/* Column 2 of 2 - Start */}

                                    <nav id="navbar" className="header col-md-10 row d-flex" aria-label="main">
                                        <ul className="nav navbar-nav navbar-right col-md-12 default-nav d-flex align-items-center pr-0">
                                            {this.props.loginDetails ? (
                                                this.props.loginDetails.status === 'SUCCESS' &&
                                                this.props.loginDetails.type === 'Patient' ? (
                                                    <React.Fragment>
                                                        {window.location.pathname === '/' ? (
                                                            ''
                                                        ) : (
                                                            //if we are home page do not display search button
                                                            //else we need to display search button
                                                            <li
                                                                className={
                                                                    window.location.pathname ===
                                                                    appUrls.SEARCH_RESULT_LIST
                                                                        ? 'menu-button-light mr-10 active'
                                                                        : 'menu-button-light mr-10'
                                                                }
                                                            >
                                                                <Link to={appUrls.SEARCH_RESULT_LIST}>Search</Link>
                                                            </li>
                                                        )}
                                                        <li
                                                            className={
                                                                this.props.page && this.props.page === 'RRS'
                                                                    ? 'menu-button-light mr-auto active'
                                                                    : 'menu-button-light mr-auto'
                                                            }
                                                        >
                                                            <Link to="/patients/rrs">My Requests</Link>
                                                        </li>
                                                    </React.Fragment>
                                                ) : this.props.loginDetails.status === 'SUCCESS' &&
                                                    (this.props.loginDetails.type === 'Provider' ||
                                                        this.props.loginDetails.type === '4a provider' ||
                                                        this.props.loginDetails.type === 'Admin') ? (
                                                    <React.Fragment>
                                                        {window.location.pathname === '/' ? (
                                                            ''
                                                        ) : (
                                                            //if we are home page do not display search button
                                                            //else we need to display search button
                                                            <li
                                                                className={
                                                                    this.props.page && this.props.page === 'list'
                                                                        ? 'menu-button-light mr-10 active'
                                                                        : 'menu-button-light mr-10'
                                                                }
                                                            >
                                                                <Link to={appUrls.SEARCH_RESULT_LIST}>Search</Link>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ) : false
                                            ) : false}
                                            {this.props.loginDetails ? (
                                                this.props.loginDetails.status === 'SUCCESS' ? (
                                                    <React.Fragment>
                                                        {this.props.loginDetails.type !== 'Provider' ||
                                                        this.props.loginDetails.type !== 'Admin' ||
                                                        this.props.loginDetails.type === '4a provider' ? (
                                                            <React.Fragment>
                                                                <li className='ml-auto'>
                                                                    <a
                                                                        className="spaced-border-flex-container"
                                                                        href="/#about"
                                                                    >
                                                                        About
                                                                        <span
                                                                            className="spaced-border-right"
                                                                            aria-hidden="true"
                                                                        >
                                                                            {' '}
                                                                            |
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        target="_blank"
                                                                        href="https://blog.treatmentconnection.com/"
                                                                    >
                                                                        Blog
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        className="spaced-border-flex-container"
                                                                        href="/resources"
                                                                    >
                                                                        <span
                                                                            className="spaced-border-left"
                                                                            aria-hidden="true"
                                                                        >
                                                                            {' '}
                                                                            |
                                                                        </span>
                                                                        Resources
                                                                    </a>
                                                                </li>
                                                            </React.Fragment>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <li>
                                                            <DropdownButton
                                                                variant="primary"
                                                                title={
                                                                    this.props.loginDetails.type === 'Provider' ||
                                                                    this.props.loginDetails.type === '4a provider' ||
                                                                    this.props.loginDetails.type === 'Admin' ? (
                                                                        <>
                                                                            {' '}
                                                                            {this.props.loginDetails.fname}&nbsp;
                                                                            {this.props.loginDetails.lname}{' '}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            User - {this.props.loginDetails.pid}{' '}
                                                                        </>
                                                                    )
                                                                }
                                                                id="dropdown-size-extra-small"
                                                                className="signedInButton"
                                                            >
                                                                <MenuItem
                                                                    eventKey="1"
                                                                    href={
                                                                        this.props.loginDetails.type === 'Provider' ||
                                                                        this.props.loginDetails.type ===
                                                                            '4a provider' ||
                                                                        this.props.loginDetails.type === 'Admin'
                                                                            ? '/provider/myprofile'
                                                                            : '/patients/myprofile'
                                                                    }
                                                                >
                                                                    My Profile
                                                                </MenuItem>
                                                                <MenuItem
                                                                    eventKey="2"
                                                                    onClick={this.handleChangePassword}
                                                                >
                                                                    Change Password
                                                                </MenuItem>
                                                                {this.props.loginDetails.type === 'Provider' ||
                                                                this.props.loginDetails.type === '4a provider' ||
                                                                this.props.loginDetails.type === 'Admin' ? (
                                                                    <MenuItem divider />
                                                                ) : false}

                                                                {this.props.loginDetails.type === 'Provider' ||
                                                                this.props.loginDetails.type === 'Admin' ? (
                                                                    <MenuItem divider />
                                                                ) : false}

                                                                {this.props.loginDetails.type === 'Provider' ||
                                                                this.props.loginDetails.type === '4a provider' ||
                                                                this.props.loginDetails.type === 'Admin' ? (
                                                                    <MenuItem eventKey="3" href="/provider/need_help">
                                                                        Help
                                                                    </MenuItem>
                                                                ) : (
                                                                    <MenuItem eventKey="3" href="/patients/need_help">
                                                                        Help
                                                                    </MenuItem>
                                                                )}
                                                                <MenuItem eventKey="4" onClick={this.signOut}>
                                                                    Sign Out
                                                                </MenuItem>
                                                            </DropdownButton>
                                                        </li>
                                                    </React.Fragment>
                                                ) : (
                                                    <>
                                                        <li className="ml-auto">
                                                            <a className="spaced-border-flex-container" href="/#about">
                                                                About
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://blog.treatmentconnection.com/"
                                                            >
                                                                <span className="spaced-border-left" aria-hidden="true">
                                                                    {' '}
                                                                    |
                                                                </span>
                                                                Blog
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="spaced-border-flex-container"
                                                                href="/resources"
                                                            >
                                                                <span className="spaced-border-left" aria-hidden="true">
                                                                    {' '}
                                                                    |
                                                                </span>
                                                                Resources
                                                            </a>
                                                        </li>
                                                        <li className="menu-button-dark hidden-sm hidden-xs">
                                                            <Link
                                                                to={appUrls.USER_LOGIN}
                                                                onClick={() => this.logEvent('Public Login Button')}
                                                            >
                                                                User Login / Sign-up
                                                            </Link>
                                                        </li>
                                                    </>
                                                )
                                            ) : (
                                                false
                                            )}
                                        </ul>
                                    </nav>
                                    {/* Column 2 of 2 - End */}
                                </div>
                                {/* Flex container includes two columns - End */}
                            </div>
                        </div>
                    )}
                    {/* Desktop Header and Menu - End */}

                    <Modal show={this.state.showChangePassword} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Password </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SigninForgotPassword
                                fromChangePassword={true}
                                fromAccountCreation={false}
                                username={this.props.loginDetails.username}
                            />
                        </Modal.Body>
                    </Modal>

                    <NotificationContainer />
                </header>
            </>
        );
    }
}

//loginDetails
const mapStateToProps = (state) => {
    //  console.log(state)
    return {
        loginDetails: state.loginDetails,
        logoutDetails: state.logout,
    };
};

export default connect(mapStateToProps, { Logout, gaLogEvent })(Header);
