import React from "react";
import { components } from "react-select";

const setUniqueIdentifier = Math.floor(Math.random() * 10000);
const uniqueMultivalueRemove = "multi_value_remove_" + setUniqueIdentifier;

class MultiValue extends React.Component {
  constructor(props) {
    super();
    this.multiValueElement = React.createRef();
  }

  componentDidMount() {
    this.triggerWrapAll();
  }

  componentWillUnmount() {
    this.unwrapCurrentElementForUnmount();
  }

  unwrapCurrentElementForUnmount = () => {
    let getTheSelectElement = this.props.selectContainerRef;

    if (! getTheSelectElement) {
      return;
    }

    let getElements = getTheSelectElement.querySelectorAll(".multiValue-container");
    let getFirstParent = getElements[0].parentNode;
    let getCurrentElement = this.multiValueElement.current;
    getCurrentElement.parentNode?.removeChild(getCurrentElement);
    getFirstParent.parentNode.insertBefore(getCurrentElement, getFirstParent);
  }

  triggerWrapAll = (nodesToBeWrapped) => {
    let getTheSelectElement = this.props.selectContainerRef;
    if (! getTheSelectElement) {
      return;
    }

    let getElements = getTheSelectElement.querySelectorAll(".multiValue-container");

    if (getElements.length > 0) {
      let getFirstParent = getElements[0].parentNode;
      if (getFirstParent.classList.contains("multi-values-wrapper")) {
        getElements.forEach((element) => {
          getFirstParent.parentNode.insertBefore(element, getFirstParent);
        });
      }
    }

    let allMultiValueElements = getTheSelectElement.getElementsByClassName(
      "multiValue-container"
    );
    let testWrapper = getTheSelectElement.querySelectorAll(".multi-values-wrapper");
    if (allMultiValueElements.length > 0) {
      this.wrapAllMultiValueNodes(allMultiValueElements);
    }
  };

  wrapAllMultiValueNodes = (nodesToBeWrapped) => {
    let getTheSelectElement = this.props.selectContainerRef;
    /* 
        Vanilla version of jQuery's wrap all
        Sourced from a comment on:
        https://stackoverflow.com/questions/3337587/wrapping-a-set-of-dom-elements-using-javascript 
    */

    let testWrappers = getTheSelectElement.querySelectorAll(".multi-values-wrapper");
    testWrappers.forEach((multiValuesWrapper) => {
      multiValuesWrapper.parentNode.removeChild(multiValuesWrapper);
    });

    let newElement = document.createElement("span");
    newElement.classList.add("multi-values-wrapper");
    newElement.setAttribute("role", "group");
    newElement.setAttribute("aria-labelledby", this.props.labelId ? this.props.labelId : '');

    const wrapAll = (nodes, wrapper) => {
      var parent = nodes[0].parentNode;
      var previousSibling = nodes[0].previousSibling;

      for (
        var i = 0;
        nodes.length - i;
        wrapper.firstChild === nodes[0] && i++
      ) {
        wrapper.appendChild(nodes[i]);
      }

      var nextSibling = previousSibling
        ? previousSibling.nextSibling
        : parent.firstChild;
      parent.insertBefore(wrapper, nextSibling);

      return wrapper;
    };

    wrapAll(nodesToBeWrapped, newElement);
  };

  render() {
    return (
      <div ref={this.multiValueElement} className="multiValue-container">
        <components.MultiValue {...this.props} />
      </div>
    );
  }
}

export default MultiValue;